import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";

import "./auth-form-input.styles.scss";

const AuthFormInput = ({
  label,
  name,
  type,
  hint,
  value,
  changeFunction,
  error,
  isDisabled,
}) => {
  const [passwordUpdate, setPasswordUpdate] = useState({
    current: "password",
  });

  const handlePassword = (type) => {
    passwordUpdate[type] =
      passwordUpdate[type] === "text" ? "password" : "text";
    setPasswordUpdate({
      ...passwordUpdate,
    });
  };

  return (
    <div>
      <div className="input-form">
        <p className="label">{label}</p>
        {isDisabled ? (
          <>
            <input
              className={error.length <= 0 ? "field" : "field-with-err"}
              type={type}
              placeholder={hint}
              value={value}
              name={name}
              onChange={changeFunction}
              disabled
            />

            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                handlePassword("current");
              }}
              // onMouseDown={handleMouseDownPassword}
            >
              {passwordUpdate.current === "password" ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </>
        ) : type === "password" ? (
          <>
            <input
              className={error.length <= 0 ? "field" : "field-with-err"}
              type={passwordUpdate.current}
              name={name}
              placeholder={hint}
              value={value}
              onChange={changeFunction}
            />

            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                handlePassword("current");
              }}
              // onMouseDown={handleMouseDownPassword}
            >
              {passwordUpdate.current === "password" ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </>
        ) : (
          <>
            <input
              className={error.length <= 0 ? "field" : "field-with-err"}
              type={type}
              name={name}
              placeholder={hint}
              value={value}
              onChange={changeFunction}
            />
            <IconButton
              aria-label="toggle password visibility"
              style={{ visibility: "hidden" }}
            >
              {passwordUpdate.current === "password" ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </>
        )}
      </div>
      {error.length <= 0 ? (
        ""
      ) : (
        <div className="err-space">
          <p className="err-text-field">{error}</p>
        </div>
      )}
    </div>
  );
};

export default AuthFormInput;
