import axios from "axios";
import { Toastify } from "../../App";

export const send_invitation_admin = (
  reqBody,

  setShowInviteOrgDialog,
  setInviteType,
  setInviteOrgData,
  setInviteOrgPersonEmail,
  setInviteOrgPersonName,
  getInvitedAdmins,
  super_token
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SEND_INVITATION_EXECUTE",
      });

      console.log("super_token", super_token);
      const res = await axios.post(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/invite/admin`,
        reqBody,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );

      if (res.status === 200) {
        dispatch({
          type: "SEND_INVITATION_SUCCESS",
        });
        console.log(res, "tes 200t");
        if (typeof getInvitedAdmins === "function") {
          getInvitedAdmins();
        }

        setShowInviteOrgDialog(false);
        setInviteOrgData();
        setInviteOrgPersonEmail();
        setInviteOrgPersonName();
        setInviteType(0);
        // setToggle("invited-admins");
        Toastify("success", "Invite Sent !");
      } else {
        dispatch({
          type: "SEND_INVITATION_ERROR",
        });
      }
    } catch (error) {
      dispatch({
        type: "SEND_INVITATION_ERROR",
      });

      Toastify("error", error.response?.data?.message);
    }
  };
};

export const send_invitation_user = (
  reqBody,

  setShowInviteOrgDialog,
  setInviteType,
  setInviteOrgData,
  setInviteOrgPersonEmail,
  setInviteOrgPersonName,
  getInvitedAdmins,
  adminDetails,
  super_token
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SEND_INVITATION_EXECUTE",
      });
      let res;
      if (adminDetails?.superAdmin) {
        res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/invite/new`,
          reqBody,
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        console.log("as superadmin");
      } else {
        res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/invite/new`,
          reqBody,
          {
            headers: {
              Authorization: `JWT ${adminDetails.admin.token}`,
            },
          }
        );

        console.log("as admin");
      }

      console.log("invite res", res.data);
      if (res.status === 200) {
        dispatch({
          type: "SEND_INVITATION_SUCCESS",
        });
        if (typeof getInvitedAdmins === "function") {
          getInvitedAdmins();
        }

        setShowInviteOrgDialog(false);
        setInviteOrgData();
        setInviteOrgPersonEmail();
        setInviteOrgPersonName();
        setInviteType(0);
        // window.alert("Invitation send !");
        Toastify("success", "Invite Sent !");
      } else {
        dispatch({
          type: "SEND_INVITATION_ERROR",
        });
      }
    } catch (error) {
      dispatch({
        type: "SEND_INVITATION_ERROR",
      });
      Toastify("error", error.response.data.message);
    }
  };
};

export const delete_organization = (org, super_token) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_ORG_EXECUTE",
      });

      const res = await axios.delete(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/delete?org=${org.id}`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );

      console.log("invite res", res.data);
      if (res.status === 200) {
        dispatch({
          type: "DELETE_ORG_SUCCESS",
        });

        // window.alert("Invitation send !");
        Toastify("success", "Organization deleted !");
        return true;
      } else {
        dispatch({
          type: "DELETE_ORG_ERROR",
        });
        Toastify("error", "Something went wrong");
        return false;
      }
    } catch (error) {
      dispatch({
        type: "DELETE_ORG_ERROR",
      });
      // Toastify("error", error.response.data.message);
      return false;
    }
  };
};
