import "./form-container.style.scss";
const FormContainer = ({ children, currentNetImg }) => {
  return (
    <div className="form-container">
      <div className="header">
        <div className="logo">
          <img alt="" src={currentNetImg} />
        </div>
        <div className="title">{}</div>
      </div>
      <div className="form-box">{children}</div>
    </div>
  );
};

export default FormContainer;
