import { Backspace } from "@mui/icons-material";
import Editor from "../editor";
import ImageInput from "../image-input";
import "./editor-container.styles.scss";
import { Toastify } from "../../../../App";

const EditorContainer = ({
  width1,
  width2,
  content,
  id,
  removeContentBlock,
}) => {
  return (
    <div className="editor-container">
      <div
        className="editors"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: content.type === 3 ? "row-reverse" : "row",
        }}
      >
        {content.type === 1 ? (
          <></>
        ) : (
          <Editor id={id} content={content} width={width1} />
        )}
        <div
          style={{
            height: "100%",
            width: content.type !== 2 && content.type !== 1 ? "20px" : "0",
          }}
        ></div>
        {content.type === 2 ? (
          <></>
        ) : (
          <ImageInput
            content={content}
            // removeContentBlock={removeContentBlock}
            // setContents={setContents}
            width={width2}
            // contents={contents}
          />
        )}
      </div>
      <Backspace
        onClick={() => {
          // console.log("test");
          Toastify('info', "Test");
          removeContentBlock(content);
        }}
        className="delete-button"
      />
    </div>
  );
};

export default EditorContainer;
