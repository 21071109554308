import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  ThemeProvider,
  createTheme,
  TextField,
  MenuItem,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import {
  Search,
  Add,
  Done,
  Pause,
  DeleteOutline,
  Refresh,
  Mail,
} from '@mui/icons-material';
import axios from 'axios';

import validator from 'validator';

import './admins.styles.scss';

import { Toastify } from '../../App';
import { send_invitation_admin } from '../../redux/action/super_admin.action';
import { useDispatch, useSelector } from 'react-redux';
import TableSkeleton from '../skeleton-loader/table';
import { debounce } from '../../utils/debounce';
import { useThrottledFn } from '../../utils/throttle';
import apiClient from '../../utils/axiosClient';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const Admins = () => {
  const [toggle, setToggle] = useState('all-admins');
  const [admins, setAdmins] = useState([]);
  const [adminBackup, setAdminsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState('');

  //token
  const super_token = JSON.parse(localStorage.getItem('super_token'));
  //redux
  const dispatch = useDispatch();
  const { superAdmin } = useSelector((state) => state.admin);
  const { send_invitation } = useSelector((state) => state.super_admin);
  //invite
  const [inviteOrgdata, setInviteOrgData] = useState();
  const [inviteType, setInviteType] = useState(0);
  const [inviteOrgPersonName, setInviteOrgPersonName] = useState();
  const [inviteOrgPersonEmail, setInviteOrgPersonEmail] = useState();
  const [showInviteOrgDialog, setShowInviteOrgDialog] = useState(false);

  const [orgs, setOrgs] = useState([]);
  //invite
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Format Date
  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    );
  };

  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current && super_token?.token) {
      get_ref.current = false;
      getAdmins();
      getOrgs();
    }

    // listOfBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [listLoad, setListLoad] = useState(true);
  // Function to get all users
  const getAdmins = () => {
    setToggle('all-admins');

    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/admin/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((response) => {
        console.log(
          response.data.sort(customSortBasedOnDate).reverse(),
          'admin'
        );

        setListLoad(false);
        setAdmins(response.data.sort(customSortBasedOnDate).reverse());
        setAdminsBackup(response.data.sort(customSortBasedOnDate).reverse());

        let userTrackStat = {};
        for (let i = 0; i < response.data.length; i++)
          userTrackStat[response.data[i]['id']] =
            response.data[i]['track_usage'];

        let userTeamStat = {};
        for (let i = 0; i < response.data.length; i++)
          userTeamStat[response.data[i]['id']] = response.data[i]['team'];
      })
      .catch((error) => {
        Toastify('error', error.response.data.message);
        console.log(error);
      });
  };

  const throttleGetAdmin = useThrottledFn(getAdmins, 1000);

  // Function to sort users data based on their date
  const customSortBasedOnDate = (a, b) =>
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

  // Function to allow user
  const allowUser = (user) => {
    apiClient
      .post(`/user/v1/admin/verify/${user.id}`)
      .then((_) => {
        getAdmins();
      })
      .catch((error) => {
        Toastify('error', error.response.data.message);
        console.log(error);
      });
  };

  const throttleAllowUser = useThrottledFn(allowUser, 1000);
  // Function to put user on hold
  const putUserOnHold = (user) => {
    apiClient
      .put(`user/v1/admin/hold/${user.id}`)
      .then((_) => getAdmins())
      .catch((error) => {
        Toastify('error', error.response.data.message);
        console.log(error);
      });
  };

  const throttlePutUserOnHold = useThrottledFn(putUserOnHold, 1000);
  // fuction to delete admin
  const deleteAdmin = (admin) => {
    axios
      .delete(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/admin/delete?admin_id=${admin.id}`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((_) => {
        getAdmins();
        Toastify('success', 'Deleted  Admin');
      })
      .catch((error) => {
        Toastify('error', error.response.data.message);
        console.error(error);
      });
  };
  const throttleDeleteAdmin = useThrottledFn(deleteAdmin, 1000);

  //function to delete invited admin

  const deleteInvitedAdmin = (admin) => {
    axios
      .delete(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/admin/invite/delete?admin_invite_id=${admin.id}`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((_) => {
        getInvitedAdmins();
        Toastify('success', 'Deleted Invited Admin');
      })
      .catch((error) => {
        Toastify('error', error.response.data.message);
        console.error(error);
      });
  };

  const throttleDeleteInvitedAdmin = useThrottledFn(deleteInvitedAdmin, 1000);
  //function to re send invitation to admin
  //send invitation
  const sendInvitation = async () => {
    try {
      if (
        typeof inviteOrgPersonName === 'undefined' ||
        inviteOrgPersonName === null ||
        inviteOrgPersonName === ''
      ) {
        Toastify('warning', 'Name of Admin is required!');
      } else if (
        typeof inviteOrgdata === 'undefined' ||
        inviteOrgdata === null ||
        inviteOrgdata === ''
      ) {
        Toastify('warning', 'Organization not selected!');
      } else if (
        typeof inviteOrgPersonEmail === 'undefined' ||
        inviteOrgPersonEmail === null ||
        inviteOrgPersonEmail === ''
      ) {
        Toastify('warning', 'Email of Admin is required!');
      } else if (!validator.isEmail(inviteOrgPersonEmail)) {
        Toastify('warning', 'Enter a valid email');
      } else {
        const reqBody = {
          organization: inviteOrgdata.id,
          name: inviteOrgPersonName,
          email: inviteOrgPersonEmail,
        };

        console.log(toggle, 'toggle');
        dispatch(
          send_invitation_admin(
            reqBody,

            setShowInviteOrgDialog,
            setInviteType,
            setInviteOrgData,
            setInviteOrgPersonEmail,
            setInviteOrgPersonName,
            toggle === 'invited-admins' ? getInvitedAdmins : '',
            super_token
          )
        );
      }
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, 'sendinvitation');
    }
  };

  const sendReInvitation = async (admin) => {
    try {
      if (
        typeof admin === 'undefined' ||
        typeof admin.name === 'undefined' ||
        admin.name === null ||
        admin.name === ''
      ) {
        Toastify('warning', 'Name of Admin is required!');
      } else if (
        typeof admin === 'undefined' ||
        typeof admin.email === 'undefined' ||
        admin.email === null ||
        admin.email === ''
      ) {
        Toastify('warning', 'Email of Admin is required!');
      } else if (!validator.isEmail(admin.email)) {
        Toastify('warning', 'Enter a valid email');
      } else {
        const reqBody = {
          admin_invite_id: admin.id,
        };
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/admin/invite/resend`,
          reqBody,
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );
        console.log(res, 'result');
        if (res.status === 200) {
          Toastify('success', 'Invite Sent !');
          // window.alert("Invitation send !");
        }
      }
      console.log(admin, 'admin');
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, 'sendinvitation');
    }
  };

  const throttleSendReInvitation = useThrottledFn(sendReInvitation, 1000);
  // Function to update data
  const updateDataOnToggle = (toggleActionName) => {
    setToggle(toggleActionName);
    setPage(0);

    if (toggleActionName === 'all-admins') {
      setAdmins(adminBackup);
    } else if (toggleActionName === 'invited-admins') {
      // setAdmins(adminBackup.filter((x) => x.admin_verified === 0));
      getInvitedAdmins();
    }
  };

  const getInvitedAdmins = async () => {
    try {
      const response = await axios.get(
        'https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/admin/invite/get',
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );
      console.log(
        response.data.sort(customSortBasedOnDate).reverse(),
        'response'
      );
      setAdmins(response.data.sort(customSortBasedOnDate).reverse());
      setAdminsBackup(response.data.sort(customSortBasedOnDate).reverse());
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  // Function to handle search results
  const searchResults = (query) => {
    let results = [];
    console.log(adminBackup, 'adminBackup');
    for (let i = 0; i < adminBackup.length; i++) {
      if (
        adminBackup[i]['name'].toLowerCase().includes(query.toLowerCase()) ||
        adminBackup[i]['email'].toLowerCase().includes(query.toLowerCase())
      )
        results.push(adminBackup[i]);
    }

    setAdmins(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));

  //org

  //sorting
  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const getOrgs = () => {
    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((response) => {
        setOrgs(response.data.sort(compare));
      })
      .catch((err) => {
        Toastify('error', err.response.data.message);
        console.error(err);
      });
  };
  return (
    <div className="admins">
      <Dialog disableEscapeKeyDown open={showInviteOrgDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Invite User
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <TextField
                select
                required
                size="small"
                label="Type"
                helperText="Eg. Admin "
                value={inviteType}
                onChange={(e) => {
                  console.log(e.target.value, 'e.target.value');
                  setInviteType(e.target.value);
                }}>
                {/* <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>User</MenuItem> */}
                {/* {console.log(orgs, "orgs")} */}
                <MenuItem
                  onClick={() => {
                    setInviteOrgData(null);
                  }}
                  value={0}>
                  Choose Organization
                </MenuItem>
                {orgs.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        setInviteOrgData(o);
                      }}
                      value={i + 1}>
                      {o.name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <TextField
                required
                size="small"
                label="Name"
                helperText="Eg. - Ross "
                value={inviteOrgPersonName}
                onChange={(event) => {
                  setInviteOrgPersonName(event.target.value);
                }}
              />
              <TextField
                required
                size="small"
                label="Email"
                type={'email'}
                helperText="Eg. - ross@lt.partners"
                value={inviteOrgPersonEmail}
                onChange={(event) => {
                  setInviteOrgPersonEmail(event.target.value);
                }}
              />

              {/* <div>Make sure the brand name matches with Affluent!</div> */}
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (!send_invitation) {
                sendInvitation();
              }
            }}>
            <div>
              {!send_invitation ? (
                <p>Send</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => setShowInviteOrgDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="head">
        <div className="head-txt">Admins</div>
        <Refresh className="head-refresh" onClick={throttleGetAdmin} />
        {superAdmin ? (
          <div className="btn-control-link">
            <Button className="add-user-btn">
              <div
                className="add-user-content"
                onClick={() => setShowInviteOrgDialog(true)}>
                <Add className="add-icon" />
                <p className="add-text">Invite Admin</p>
              </div>
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="toggle-div">
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle('all-admins');
            getAdmins();
          }}>
          <p className={toggle === 'all-admins' ? 'selected-toggle-text' : ''}>
            Active Admins
          </p>
          <div
            className={
              toggle === 'all-admins' ? 'selected-toggle-bar' : ''
            }></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle('invited-admins');
            getInvitedAdmins();
          }}>
          <p
            className={
              toggle === 'invited-admins' ? 'selected-toggle-text' : ''
            }>
            Invited Admins
          </p>
          <div
            className={
              toggle === 'invited-admins' ? 'selected-toggle-bar' : ''
            }></div>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Organization
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Email
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Registered at
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    // paddingLeft: "50px",
                  }}>
                  Controls
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}>
                        <img
                          alt="fav"
                          className="avtar"
                          src={row.organization.image}
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            height: '60px',
                            width: '120px',
                            objectFit: 'contain',
                            borderRadius: '5px',
                            marginRight: '15px',
                            cursor: 'pointer',
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}>
                          <div
                            style={{
                              fontWeight: '600',
                            }}>
                            {row.name}
                          </div>
                          <div
                            style={{
                              fontWeight: '500',
                              color: 'rgb(166, 0, 113)',
                              fontSize: '0.8rem',
                            }}>
                            {/* {userTeamStatus[row.id] === 1
                              ? "All Brands"
                              : `${row.organization.split(",").length} Brand(s)`} */}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.email}
                          </p>
                          {toggle !== 'invited-admins' ? (
                            <Tooltip
                              title={
                                row.verified === 0
                                  ? 'Email not verified!'
                                  : 'Email Verified!'
                              }
                              style={{
                                fontFamily: '"Montserrat", sans-serif',
                              }}>
                              <img
                                alt="status"
                                src={
                                  row.verified === 0
                                    ? process.env.PUBLIC_URL +
                                      '/assets/close.png'
                                    : process.env.PUBLIC_URL +
                                      '/assets/correct.png'
                                }
                                height="18"
                                style={{
                                  display: 'inline-block',
                                  verticalAlign: 'middle',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                          fontSize: '0.8rem',
                        }}>
                        {formatDate(row.created_at)}
                      </TableCell>
                      <TableCell>
                        <div className="actions-div-admin">
                          {row.admin_verified === 0 ||
                          row.admin_verified === 2 ||
                          row.admin_verified === 3 ? (
                            <Tooltip title="Allow">
                              <Done
                                onClick={() => throttleAllowUser(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(39, 181, 36)',
                                  backgroundColor: 'rgba(71, 237, 52, 0.3)',
                                  border: '1px solid rgb(39, 181, 36)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                          {row.admin_verified === 0 ||
                          row.admin_verified === 1 ||
                          row.admin_verified === 3 ? (
                            <Tooltip title="Hold">
                              <Pause
                                onClick={() => throttlePutUserOnHold(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(36, 169, 181)',
                                  backgroundColor: 'rgba(36, 169, 181, 0.3)',
                                  border: '1px solid rgb(36, 169, 181)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                          <Tooltip title="Delete">
                            <DeleteOutline
                              onClick={() => {
                                if (toggle === 'invited-admins') {
                                  throttleDeleteInvitedAdmin(row);
                                } else {
                                  throttleDeleteAdmin(row);
                                }
                              }}
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(252, 3, 3)',
                                backgroundColor: 'rgba(252, 3, 3, 0.3)',
                                border: '1px solid rgb(252, 3, 3)',
                                padding: '3px',
                                fontSize: '15px',
                                marginLeft: '10px',
                                borderRadius: '5px',
                              }}
                            />
                          </Tooltip>

                          {toggle === 'invited-admins' ? (
                            <Tooltip title="Resend">
                              <Mail
                                onClick={() => {
                                  throttleSendReInvitation(row);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(36, 169, 181)',
                                  backgroundColor: 'rgba(36, 169, 181, 0.3)',
                                  border: '1px solid rgb(36, 169, 181)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {listLoad ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={admins.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>

      <div className="foot">
        <p>All rights reserved | LT Partners</p>
      </div>
    </div>
  );
};

export default Admins;
