const initialState = {
  admin_auth: null,
  superAdmin: false,
  admin_profile: null,
};

const authReducer = (state = initialState, action) => {
  //schedule request

  switch (action.type) {
    // super_admin
    case "SUPERADMIN_SUCESS":
      state = {
        ...state,
        superAdmin: true,
      };
      break;
    case "SUPERADMIN_ERROR":
      state = {
        ...state,
        superAdmin: false,
      };
      break;
    case "ADMIN_AUTH_SUCESS":
      state = {
        ...state,
        admin_auth: action?.payload?.admin_auth,
      };
      break;

    case "ADMIN_AUTH_ERROR":
      state = {
        ...state,
        admin_auth: null,
      };
      break;

    case "ADMIN_LOGOUT":
      state = {
        ...state,
        admin_auth: null,
        superAdmin: false,
      };
      break;

    // get admin profile

    case "GET_ADMIN_PROFILE_EXE":
      state = {
        ...state,
        admin_profile: null,
      };
      break;
    case "GET_ADMIN_PROFILE_SUCCESS":
      state = {
        ...state,
        admin_profile: action?.payload?.admin_profile,
      };
      break;
    case "GET_ADMIN_PROFILE_ERROR":
      state = {
        ...state,
        admin_profile: null,
      };
      break;
    default:
      state = {
        ...state,
      };
  }

  return state;
};

export default authReducer;
