import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReceiptIcon from "@mui/icons-material/Receipt";
import axios from "axios";

import "./payments.styles.scss";
import { Toastify } from "../../App";
import TableSkeleton from "../skeleton-loader/table";

const AdminUsers = () => {
  const [toggle, setToggle] = useState("all-payments");
  const [admins, setPayments] = useState([]);
  const [paymentBackup, setPaymentsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  //super token
  const super_token = JSON.parse(localStorage.getItem("super_token"));

  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current && super_token?.token) {
      get_ref.current = false;
      getPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Format Date
  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds()
    );
  };
  const [loadList, setLoadList] = useState(true);
  const getPayments = () => {
    // setToggle("all-payments");

    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/payments/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((response) => {
        // setPayments(response.data.sort(customSortBasedOnDate).reverse());
        console.log(response.data, "res");
        setLoadList(false);
        setPaymentsBackup(response.data.sort(customSortBasedOnDate).reverse());
      })
      .catch((err) => {
        Toastify("error", err.response.data.message);
        console.log(err, "error");
      });
    paymentStatus(toggle);
  };

  useEffect(() => {
    if (toggle === "all-payments") {
      setPayments(paymentBackup);
      return;
    }

    let results = [];

    for (let i = 0; i < paymentBackup.length; i++) {
      if (
        paymentBackup[i]["status"].toLowerCase().includes(toggle.toLowerCase())
      ) {
        results.push(paymentBackup[i]);
      }
    }

    setPayments(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentBackup]);

  const paymentStatus = (status) => {
    if (status === "all-payments") {
      setPayments(paymentBackup);
      return;
    }
    let results = [];

    if (status === "failed") {
      for (let i = 0; i < paymentBackup.length; i++) {
        if (paymentBackup[i]["status"] === 2) {
          results.push(paymentBackup[i]);
        }
      }
    } else {
      for (let i = 0; i < paymentBackup.length; i++) {
        if (paymentBackup[i]["status"] === 1) {
          results.push(paymentBackup[i]);
        }
      }
    }
    setPayments(results);
  };

  // Function to sort users data based on their date
  const customSortBasedOnDate = (a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  // Function to update data
  const updateDataOnToggle = (toggleActionName) => {
    setToggle(toggleActionName);
    setPage(0);
    paymentStatus(toggleActionName);
  };

  return (
    <div className="admins">
      <div className="head">
        <div className="head-txt">Payments</div>
        <Refresh
          className="head-refresh"
          onClick={() => {
            getPayments();
          }}
        />
      </div>
      <div className="toggle-div">
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle("all-payments");
          }}
        >
          <p
            className={toggle === "all-payments" ? "selected-toggle-text" : ""}
          >
            All Payments
          </p>
          <div
            className={toggle === "all-payments" ? "selected-toggle-bar" : ""}
          ></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle("succeeded");
            // paymentStatus("succeeded");
          }}
        >
          <p className={toggle === "succeeded" ? "selected-toggle-text" : ""}>
            Succeeded
          </p>
          <div
            className={toggle === "succeeded" ? "selected-toggle-bar" : ""}
          ></div>
        </div>

        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle("failed");
            // paymentStatus("failed");
          }}
        >
          <p className={toggle === "failed" ? "selected-toggle-text" : ""}>
            Failed
          </p>
          <div
            className={toggle === "failed" ? "selected-toggle-bar" : ""}
          ></div>
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: "calc(100% - 60px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Organization
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Admin
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Date
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Coupon
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Payment Model
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Amount
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Status
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}
                      >
                        <img
                          alt="fav"
                          className="avtar"
                          src={row?.organization?.image}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            height: "60px",
                            width: "120px",
                            objectFit: "contain",
                            borderRadius: "5px",
                            marginRight: "15px",
                          }}
                        />
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {row?.organization?.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "500",
                              color: "rgb(166, 0, 113)",
                              fontSize: "0.8rem",
                            }}
                          >
                            {/* {userTeamStatus[row.id] === 1
                              ? "All Brands"
                              : `${row.organization.split(",").length} Brand(s)`} */}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}
                      >
                        {/* <img
                          alt="fav"
                          className="avtar"
                          src={row?.organization?.image}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            height: "60px",
                            width: "120px",
                            objectFit: "contain",
                            borderRadius: "5px",
                            marginRight: "15px",
                          }}
                        /> */}
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {row?.admin?.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "500",
                              color: "rgb(166, 0, 113)",
                              fontSize: "0.8rem",
                            }}
                          >
                            {/* {userTeamStatus[row.id] === 1
                              ? "All Brands"
                              : `${row.organization.split(",").length} Brand(s)`} */}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {formatDate(row?.organization?.created_at)}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                          fontSize: "0.8rem",
                        }}
                      >
                        {row?.organization?.payment_model === "1"
                          ? "Monthly"
                          : "Yearly"}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                          fontSize: "0.8rem",
                        }}
                      >
                        {row?.coupon}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        {new Intl.NumberFormat("ja-JP", {
                          style: "currency",
                          currency: "USD",
                        }).format(row.amount / 100)}
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div
                          style={
                            row.status === 2
                              ? {
                                  color: "rgb(252, 3, 3)",
                                  backgroundColor: "rgba(252, 3, 3, 0.3)",
                                  border: "1px solid rgb(252, 3, 3)",
                                  borderRadius: "20px",
                                  textAlign: "center",
                                  fontSize: "0.8rem",
                                  width: "75px",
                                  padding: "3px 4px",
                                }
                              : {
                                  color: "rgb(39, 181, 36)",
                                  backgroundColor: "rgba(71, 237, 52, 0.3)",
                                  border: "1px solid rgb(39, 181, 36)",
                                  borderRadius: "20px",
                                  textAlign: "center",
                                  fontSize: "0.8rem",
                                  width: "75px",
                                  padding: "3px 4px",
                                }
                          }
                        >
                          {row.status === 2 ? "Faild" : "Succeeded"}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <Tooltip title="Invoice">
                            <ReceiptIcon
                              style={{
                                cursor: "pointer",
                                color: "rgb(252, 198, 3)",
                                backgroundColor: "rgba(252, 227, 3, 0.3)",
                                border: "1px solid rgb(252, 198, 3)",
                                padding: "3px",
                                fontSize: "15px",
                                marginRight: "15px",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                console.log(row, "row");
                                window.open(
                                  "https://invoice.stripe.com/i/acct_1LwRk1Aw6YDq2ZPm/test_YWNjdF8xTHdSazFBdzZZRHEyWlBtLF9OY2F2cUZBS3Z2S3lQVzI3TnV1VjBTWkI0Q1R6aW5RLDcwNzI0NTI00200es7fZXPb?s=ap",
                                  "_black"
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Invoice PDF">
                            <PictureAsPdfIcon
                              style={{
                                cursor: "pointer",
                                color: "rgb(36, 169, 181)",
                                backgroundColor: "rgba(36, 169, 181, 0.3)",
                                border: "1px solid rgb(36, 169, 181)",
                                padding: "3px",
                                fontSize: "15px",
                                marginRight: "15px",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                console.log(row, "row");
                                window.open(
                                  "https://pay.stripe.com/invoice/acct_1LwRk1Aw6YDq2ZPm/test_YWNjdF8xTHdSazFBdzZZRHEyWlBtLF9OY2F2cUZBS3Z2S3lQVzI3TnV1VjBTWkI0Q1R6aW5RLDcwNzI0NTI00200es7fZXPb/pdf?s=ap"
                                );
                              }}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={admins.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: "10px",
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>

      <div className="foot">
        <p>All rights reserved | LT Partners</p>
      </div>
    </div>
  );
};

export default AdminUsers;
