import axios from 'axios';
import { Toastify } from '../App';
import apiClient from '../utils/axiosClient';

export const actionLogTrack = async (payload, isError, token) => {
  try {
    console.log(isError, token);
    if (payload.response) {
      delete payload.response;
    }

    const finalPayload = {
      isError: isError,
      payload: payload,
    };
    console.log(JSON.stringify(finalPayload), 'payload');
    const res = await apiClient.post(`/user/v1/admin/log/add`, {
      log: JSON.stringify(finalPayload),
    });
    // const res = await axios.post(`http://localhost:4010/v1/admin/log/add`, {
    //   log: JSON.stringify(finalPayload),
    // });
    return res;
  } catch (error) {
    Toastify('error', error.response.data.message);
    console.log(error, 'log error');
  }
};
