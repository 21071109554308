import React, { useState } from 'react';
import OnBoardTab from '../../components/onboarding/onboarding-tabs';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './onboarding.styles.scss';
// import InputOnboard from "../../components/auth-form-input/auth-form-input.componnt";
import WestIcon from '@mui/icons-material/West';
// import InputOnboard from "../../components/onboarding/onboarding-input";
import validator from 'validator';
import Pricing from '../../components/pricing';

import Upload from '../../components/image-input';
import {
  TextField,
  createTheme,
  CircularProgress,
  SwipeableDrawer,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Payment from '../../components/onboarding/payment';
import { useSelector } from 'react-redux';
import { keys } from '../../lib/consts/keys';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Impact from './forms/impact/impact';
import Admitad from './forms/admitad/admitad';
import AdTraction from './forms/ad-traction/ad-traction';
import AffiliateFuture from './forms/affiliate-future/affiliate-future';
import AvantLink from './forms/avant-link/avant-link';
import DaisyCon from './forms/daisy-con/daisy-con';
import EverFlow from './forms/ever-flow/ever-flow';
import LinkConnector from './forms/link-connector/link-connector';
import Odyssey from './forms/odyssey/odyssey';
import OneNetworkDirect from './forms/one-network-direct/one-network-direct';
import PaidOnResults from './forms/paid-on-results/paid-on-results';
import Partnerize from './forms/partnerize/partnerize';
import PartnerStack from './forms/partner-stack/partner-stack';
import PepperJam from './forms/pepper-jam/pepper-jam';
import RakutenLinkshare from './forms/rakuten-linkshare/rakuten-linkshare';
import Refersion from './forms/refersion/refersion';
import ShareASale from './forms/share-a-sale/share-a-sale';
import TradeTracker from './forms/trade-tracker/trade-tracker';
import Tune from './forms/tune/tune';
import WebGains from './forms/web-gains/web-gains';
import TradeDoubler from './forms/trade-doubler/trade-doubler';
import CommisionFactory from './forms/commission-factory/commision-factory';
import CommisionJunction from './forms/commision-junction/commision-junction';
import Awin from './forms/awin/awin';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const OnBoarding = () => {
  const payment = useSelector((state) => state.payment);

  const sub_click = React.useRef();
  React.useEffect(() => {
    let stage = JSON.parse(localStorage.getItem('stage'));
    if (stage !== null && stage !== undefined && stage !== '') {
      setOpen(stage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = React.useState(2);

  const data = [
    {
      icon: <PersonOutlineIcon />,
      type_content: "User's Detail",
    },
    {
      icon: <CorporateFareIcon />,
      type_content: 'Organization',
    },
    {
      icon: <BrandingWatermarkIcon />,
      type_content: 'Affiliate account details',
    },
    {
      icon: <AttachMoneyIcon />,
      type_content: 'Payment',
    },
  ];

  const networks = [
    {
      title: 'Impact',
      image: 'https://www.affluent.io/images/platforms/impact-logo.jpg',
      net: 'impact',
      checked: false,
    },
    // {
    //   title: "Google Analytics",
    //   image: "https://www.affluent.io/images/platforms/google-analytics.png",
    //   checked: true,
    // },
    {
      title: 'Admitad',
      image: 'https://www.affluent.io/images/platforms/admitad.png',
      net: 'admitad',
      checked: false,
    },
    {
      title: 'AdTraction',
      image: 'https://www.affluent.io/images/platforms/adtraction-logo.jpg',
      net: 'ad-traction',
      checked: false,
    },
    {
      title: 'Affiliate Future',
      image: 'https://www.affluent.io/images/platforms/affiliatefuture.png',
      checked: false,
      net: 'affiliate-future',
    },
    {
      title: 'AvantLink',
      image: 'https://www.affluent.io/images/platforms/avantlink-logo.png',
      checked: false,
      net: 'avant-link',
    },
    {
      title: 'Awin',
      image:
        'https://www.affluent.io/images/platforms/affiliate-window-logo.jpg',
      checked: false,
      net: 'awin',
    },
    {
      title: 'Commissionfactory',
      image: 'https://www.affluent.io/images/platforms/comfact.png',
      checked: false,
      net: 'commision-factory',
    },
    {
      title: 'Commision Junction',
      image:
        'https://www.affluent.io/images/platforms/commission-junction-logo.jpg',
      checked: false,
      net: 'commision-junction',
    },
    {
      title: 'DaisyCon',
      image: 'https://www.affluent.io/images/platforms/daisy.png',
      checked: false,
      net: 'daisy-con',
    },
    {
      title: 'Everflow',
      image: 'https://www.affluent.io/images/platforms/everflow-logo.png',
      checked: false,
      net: 'ever-flow',
    },
    {
      title: 'LinkConnector',
      image: 'https://www.affluent.io/images/platforms/linkconnector.png',
      checked: false,
      net: 'link-connector',
    },
    {
      title: 'Odyssey',
      image: 'https://www.affluent.io/images/platforms/odyssey-logo.png',
      checked: false,
      net: 'odyssey',
    },
    {
      title: 'oneNetwrokDirect',
      image: 'https://www.affluent.io/images/platforms/ond.png',
      checked: false,
      net: 'one-network-direct',
    },
    {
      title: 'Paid On Results',
      image: 'https://www.affluent.io/images/platforms/paidon.png',
      checked: false,
      net: 'paid-on-results',
    },
    {
      title: 'Partnerize',
      image: 'https://www.affluent.io/images/platforms/partnerize-logo.png',
      checked: false,
      net: 'partnerize',
    },
    {
      title: 'PartnerStack',
      image: 'https://www.affluent.io/images/platforms/partnerstack-logo.png',
      checked: false,
      net: 'partner-stack',
    },
    {
      title: 'Pepperjam',
      image: 'https://www.affluent.io/images/platforms/pepperjam-logo.jpg',
      checked: false,
      net: 'pepper-jam',
    },
    {
      title: 'Rakuten LinkShare',
      image: 'https://www.affluent.io/images/platforms/linkshare-logo.jpg',
      checked: false,
      net: 'rakuten-linkshare',
    },
    {
      title: 'Refersion',
      image: 'https://www.affluent.io/images/platforms/refersion-logo.png',
      checked: false,
      net: 'refersion',
    },
    {
      title: 'ShareASale',
      image: 'https://www.affluent.io/images/platforms/shareasale-logo.jpg',
      checked: false,
      net: 'share-a-sale',
    },
    {
      title: 'Tradedoubler',
      image: 'https://www.affluent.io/images/platforms/tradedoubler-logo.png',
      checked: false,
      net: 'trade-doubler',
    },
    {
      title: 'TradeTracker',
      image: 'https://www.affluent.io/images/platforms/tradetracker.png',
      checked: false,
      net: 'trade-tracker',
    },
    {
      title: 'Tune',
      image: 'https://www.affluent.io/images/platforms/tune-logo.png',
      checked: false,
      net: 'tune',
    },
    {
      title: 'Webgains',
      image: 'https://www.affluent.io/images/platforms/webgains.png',
      checked: false,
      net: 'web-gains',
    },
  ];

  const [details, setDetails] = React.useState({
    name: '',
    email: '',
    pwd: '',
    re_pwd: '',
    org_name: '',
    org_img: '',
    brand_name: '',
    brand_image: '',
    brand_vertical: '',
    brand_domain: '',
    payment: keys.priceIdYearly,
  });

  const [detailsCheck, setDetailsCheck] = React.useState({
    name: '',
    email: '',
    pwd: '',
    re_pwd: '',
    org_name: '',
    // org_img: "",
    brand_name: '',
    // brand_image: "",
    brand_vertical: '',
    brand_domain: '',
    payment: '',
  });

  React.useState(() => {
    const info = JSON.parse(localStorage.getItem('details'));

    if (info !== null && info !== undefined) {
      setDetails({
        ...info,
      });
    }
  }, [details]);

  const changeDetails = (e) => {
    if (e.target.name === 'org_img' || e.target.name === 'brand_image') {
      const file = e.target.files[0];
      const newdata = {
        ...details,
        [e.target.name]: URL.createObjectURL(file),
      };
      setDetails(newdata);
      localStorage.setItem('details', JSON.stringify(newdata));

      return;
    }

    const newdata = {
      ...details,
      [e.target.name]: e.target.value,
    };

    setDetails(newdata);

    localStorage.setItem('details', JSON.stringify(newdata));
  };

  React.useEffect(() => {
    checkStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const open1 = ['name', 'email', 'pwd', 're_pwd'];
  const open2 = [
    'org_name',
    // 'prg_img',
  ];

  const open3 = [
    'brand_name',
    // 'brand_image',
    'brand_vertical',
    'brand_domain',
  ];

  const rename = {
    name: 'Name',
    email: 'Email',
    pwd: ' Password',
    re_pwd: 'Comfirm Password',
    org_name: 'Organization name',
    // org_img: "",
    brand_name: 'Brand Name',
    // brand_image: "",
    brand_vertical: 'Brand Vertical',
    brand_domain: 'Brand Domain',
    payment: '',
  };
  const checkValidation = () => {
    let flag = true;
    if (open === 0) {
      for (let i = 0; i < open1.length; i++) {
        let val = open1[i];

        if (details[val].length === 0) {
          detailsCheck[val] = `${rename[val]} required !`;
          setDetailsCheck({
            ...detailsCheck,
          });
          console.log(details[val].length, val);
          // return false;
          flag = false;
        } else {
          detailsCheck[val] = ``;
          setDetailsCheck({
            ...detailsCheck,
          });
        }
      }

      if (details.email.length !== 0 && !validator.isEmail(details.email)) {
        detailsCheck['email'] = `Invalid email !`;
        setDetailsCheck({
          ...detailsCheck,
        });
        flag = false;
      }

      if (
        details.pwd.length !== 0 &&
        details.re_pwd !== 0 &&
        details.pwd !== details.re_pwd
      ) {
        detailsCheck.re_pwd = `Confirm password does not match with password!`;
        setDetailsCheck({
          ...detailsCheck,
        });
        flag = false;
      }
    } else if (open === 1) {
      for (let i = 0; i < open2.length; i++) {
        let val = open2[i];

        if (details[val].length === 0) {
          detailsCheck[val] = `${rename[val]} required !`;
          setDetailsCheck({
            ...detailsCheck,
          });
          console.log(details[val].length, val);
          // return false;
          flag = false;
        } else {
          detailsCheck[val] = ``;
          setDetailsCheck({
            ...detailsCheck,
          });
        }
      }
    } else if (open === 2) {
      for (let i = 0; i < open3.length; i++) {
        let val = open3[i];

        if (details[val].length === 0) {
          detailsCheck[val] = `${rename[val]} required !`;
          setDetailsCheck({
            ...detailsCheck,
          });
          console.log(details[val].length, val);
          // return false;
          flag = false;
        } else {
          detailsCheck[val] = ``;
          setDetailsCheck({
            ...detailsCheck,
          });
        }
      }
    }
    return flag;
  };

  const checkStage = () => {
    // console.log(canShowDialogLeavingPage, 'canShowDialogLeavingPage')
    for (let i = 0; i < open1.length; i++) {
      let key = open1[i];
      if (details[key] === '') {
        localStorage.setItem('stage', 0);
        return;
      }
    }
    if (!checkValidation()) {
      localStorage.setItem('stage', 0);
      return;
    }
    localStorage.setItem('stage', 1);

    for (let i = 0; i < open2.length; i++) {
      let key = open2[i];
      if (details[key] === '') {
        localStorage.setItem('stage', 1);
        return;
      }
    }

    if (!checkValidation()) {
      localStorage.setItem('stage', 1);
      return;
    }

    localStorage.setItem('stage', 2);
    for (let i = 0; i < open3.length; i++) {
      let key = open3[i];
      if (details[key] === '') {
        localStorage.setItem('stage', 2);
        return;
      }
    }

    if (!checkValidation()) {
      localStorage.setItem('stage', 2);
      return;
    }
    localStorage.setItem('stage', 3);
  };

  const [currentNet, setCurrentNet] = useState('');
  const [currentNetImg, setCurrentNetImg] = useState('');

  // const [form, setForm] = useState("");
  const getForm = (form, Connect) => {
    switch (form) {
      case 'impact':
        return <Impact currentNetImg={currentNetImg} Connect={Connect} />;

      case 'admitad':
        return <Admitad currentNetImg={currentNetImg} Connect={Connect} />;

      case 'ad-traction':
        return <AdTraction currentNetImg={currentNetImg} Connect={Connect} />;

      case 'affiliate-future':
        return (
          <AffiliateFuture currentNetImg={currentNetImg} Connect={Connect} />
        );
      case 'avant-link':
        return <AvantLink currentNetImg={currentNetImg} Connect={Connect} />;
      case 'awin':
        return <Awin currentNetImg={currentNetImg} Connect={Connect} />;
      case 'commision-factory':
        return (
          <CommisionFactory currentNetImg={currentNetImg} Connect={Connect} />
        );
      case 'commision-junction':
        return (
          <CommisionJunction currentNetImg={currentNetImg} Connect={Connect} />
        );
      case 'daisy-con':
        return <DaisyCon currentNetImg={currentNetImg} Connect={Connect} />;
      case 'ever-flow':
        return <EverFlow currentNetImg={currentNetImg} Connect={Connect} />;
      case 'link-connector':
        return (
          <LinkConnector currentNetImg={currentNetImg} Connect={Connect} />
        );
      case 'odyssey':
        return <Odyssey currentNetImg={currentNetImg} Connect={Connect} />;
      case 'one-network-direct':
        return (
          <OneNetworkDirect currentNetImg={currentNetImg} Connect={Connect} />
        );
      case 'paid-on-results':
        return (
          <PaidOnResults currentNetImg={currentNetImg} Connect={Connect} />
        );
      case 'partnerize':
        return <Partnerize currentNetImg={currentNetImg} Connect={Connect} />;
      case 'partner-stack':
        return <PartnerStack currentNetImg={currentNetImg} Connect={Connect} />;
      case 'pepper-jam':
        return <PepperJam currentNetImg={currentNetImg} Connect={Connect} />;
      case 'rakuten-linkshare':
        return (
          <RakutenLinkshare currentNetImg={currentNetImg} Connect={Connect} />
        );
      case 'refersion':
        return <Refersion currentNetImg={currentNetImg} Connect={Connect} />;
      case 'share-a-sale':
        return <ShareASale currentNetImg={currentNetImg} Connect={Connect} />;
      case 'trade-tracker':
        return <TradeTracker currentNetImg={currentNetImg} Connect={Connect} />;
      case 'trade-doubler':
        return <TradeDoubler currentNetImg={currentNetImg} Connect={Connect} />;
      case 'tune':
        return <Tune currentNetImg={currentNetImg} Connect={Connect} />;
      case 'web-gains':
        return <WebGains currentNetImg={currentNetImg} Connect={Connect} />;
      default:
        return 'affiliate-future';
    }
  };
  const [networkCollection, setNetworkCollection] = useState([]);

  const Connect = (obj) => {
    const net = networkCollection;
    net.push(obj);
    setNetworkCollection(net);
    setCurrentNet('');
    console.log(obj, 'obj');
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={currentNet.length === 0 ? false : true}
        onClose={() => setCurrentNet('')}>
        <div
          className="right-drawer"
          style={{
            width: '700px',
          }}>
          <ThemeProvider theme={theme}>
            {getForm(currentNet, Connect)}
          </ThemeProvider>
        </div>
      </SwipeableDrawer>

      <div className="onboarding-invite">
        {/* <DialogLeavingPage
          showDialog={showDialogLeavingPage}
          setShowDialog={setCanShowDialogLeavingPage}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        /> */}
        <div className="top-bar">
          <div className="logo">
            <img alt="" src={process.env.PUBLIC_URL + '/assets/lift_fav.png'} />
          </div>
        </div>
        <div className={'tab-container'}>
          <ul>
            {data.map((d, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setOpen(i);
                  }}>
                  <OnBoardTab
                    open={open + 1}
                    tot={data.length}
                    active={i === open}
                    type_content={d.type_content}
                    icon={d.icon}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content-container">
          <h2 className={'header'}>Welcome to LIFT</h2>
          <p className="content">
            Please complete the following steps to continue
          </p>
        </div>
        <div className={'form-container'}>
          <ThemeProvider theme={theme}>
            {open === 0 ? (
              <div className={'form-type form-1'}>
                <TextField
                  required
                  error={detailsCheck.name === '' ? false : true}
                  size={'small'}
                  label="Name"
                  helperText={
                    detailsCheck.name === '' ? 'Your Name' : detailsCheck.name
                  }
                  value={details.name}
                  name={'name'}
                  style={{
                    width: '100%',
                    marginTop: '25px',
                  }}
                  onChange={changeDetails}
                />
                <TextField
                  required
                  error={detailsCheck.email === '' ? false : true}
                  size={'small'}
                  label="Email"
                  helperText={
                    detailsCheck.email === ''
                      ? 'Your Email'
                      : detailsCheck.email
                  }
                  value={details.email}
                  name={'email'}
                  style={{
                    width: '100%',
                    marginTop: '25px',
                  }}
                  onChange={changeDetails}
                />
                <TextField
                  required
                  error={detailsCheck.email === '' ? false : true}
                  size={'small'}
                  label="Position in company"
                  helperText={
                    detailsCheck.email === ''
                      ? 'Your Email'
                      : detailsCheck.email
                  }
                  value={details.email}
                  name={'email'}
                  style={{
                    width: '100%',
                    marginTop: '25px',
                  }}
                  onChange={changeDetails}
                />
                <TextField
                  required
                  error={detailsCheck.pwd === '' ? false : true}
                  size={'small'}
                  type="password"
                  label="Password"
                  helperText={
                    detailsCheck.pwd === '' ? 'Your Password' : detailsCheck.pwd
                  }
                  value={details.pwd}
                  name={'pwd'}
                  style={{
                    width: '100%',
                    marginTop: '25px',
                  }}
                  onChange={changeDetails}
                />
                <TextField
                  required
                  error={detailsCheck.re_pwd}
                  size={'small'}
                  type="password"
                  label="Confirm-Password"
                  helperText={
                    detailsCheck.re_pwd === ''
                      ? 'Should be the same as Password'
                      : detailsCheck.re_pwd
                  }
                  value={details.re_pwd}
                  name={'re_pwd'}
                  style={{
                    width: '100%',
                    marginTop: '25px',
                  }}
                  onChange={changeDetails}
                />
              </div>
            ) : (
              <></>
            )}

            {open === 1 ? (
              <div className={'form-type form-2'}>
                <Upload
                  label="Organization Image"
                  name={'org_img'}
                  setDetails={setDetails}
                  details={details}
                  error={''}
                />
                <TextField
                  required
                  error={detailsCheck.org_name === '' ? false : true}
                  size={'small'}
                  label="Organization Name"
                  helperText={
                    detailsCheck.org_name === ''
                      ? 'Your Organization Name'
                      : detailsCheck.org_name
                  }
                  value={details.org_name}
                  name={'org_name'}
                  style={{
                    width: '100%',
                    marginTop: '25px',
                  }}
                  onChange={changeDetails}
                />
              </div>
            ) : (
              <></>
            )}

            {open === 2 ? (
              <div className={'form-type form-3'}>
                <div className="net-list">
                  {networks.map((d, i) => {
                    return (
                      <div
                        onClick={() => {
                          setCurrentNet(d.net);
                          setCurrentNetImg(d.image);
                        }}
                        className="card">
                        <div className="selection">
                          {networkCollection.some((n) => n.net === d.net) ? (
                            <CheckCircleRoundedIcon
                              style={{ color: '#b51783' }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="image-holder">
                          <img alt="" src={d.image} />
                        </div>
                        <div className="title">{d.title}</div>
                      </div>
                    );
                  })}
                </div>
                {/* <Upload
                label="Brand Image"
                name={"brand_image"}
                setDetails={setDetails}
                details={details}
                error={""}
              /> */}
                {/* <TextField
                required
                error={detailsCheck.brand_name === "" ? false : true}
                size={"small"}
                label="Brand Name"
                helperText={
                  detailsCheck.brand_name === ""
                    ? "Your Brand Name"
                    : detailsCheck.brand_name
                }
                name={"brand_name"}
                value={details.brand_name}
                style={{
                  width: "100%",
                  marginTop: "25px",
                }}
                onChange={changeDetails}
              />
              <TextField
                required
                error={detailsCheck.brand_vertical === "" ? false : true}
                size="small"
                label="Brand Vertical"
                helperText={
                  detailsCheck.brand_name === ""
                    ? "Your Brand Vertical"
                    : detailsCheck.brand_name
                }
                name={"brand_vertical"}
                value={details.brand_vertical}
                style={{
                  width: "100%",
                  marginTop: "25px",
                }}
                onChange={changeDetails}
              />
              <TextField
                required
                error={detailsCheck.brand_domain === "" ? false : true}
                size="small"
                label="Brand Domain"
                helperText={
                  detailsCheck.brand_name === ""
                    ? "Your Brand Domain"
                    : detailsCheck.brand_name
                }
                name={"brand_domain"}
                value={details.brand_domain}
                style={{
                  width: "100%",
                  marginTop: "25px",
                }}
                onChange={changeDetails}
              /> */}
              </div>
            ) : (
              <></>
            )}

            {open === 3 ? (
              <div className={'form-type form-4'}>
                <Pricing changeDetails={changeDetails} details={details} />
                <Payment sub_click={sub_click} details={details} />
              </div>
            ) : (
              <></>
            )}
          </ThemeProvider>
        </div>
        <div className="control-section">
          <div className="controls">
            <div
              onClick={() => {
                if (open > 0) {
                  setOpen(open - 1);
                }
              }}
              className="back-control">
              <WestIcon size={30} /> <span>Back</span>
            </div>

            {!payment.payment_process ? (
              <div
                onClick={() => {
                  if (open <= 2) {
                    if (checkValidation()) {
                      setOpen(open + 1);
                    }
                  } else {
                    // console.log("click")
                    sub_click.current.click();
                  }
                }}
                className="button-control">
                {open === 3 ? 'Subscribe' : 'Next Step'}
              </div>
            ) : (
              <div className="button-control">
                <CircularProgress
                  style={{
                    color: '#fff',
                    height: '20px',
                    width: '20px',
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="footer">
          <p>All rights reserved | LT Partners</p>
        </div>
      </div>
    </>
  );
};

export default OnBoarding;
