import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import '../../components/preloader/preloader.scss';
import axios from 'axios';
import { Toastify } from '../../App';
import { actionLogTrack } from '../../lib/logs';

const AdminRedirect = () => {
  const super_token = JSON.parse(localStorage.getItem('super_token'));
  const super_admin = JSON.parse(localStorage.getItem('super_admin'));
  const searchParams = new URLSearchParams(window.location.search);
  const [isStateSet, setIsStateSet] = useState(false);
  const redirectToken = searchParams.get('redirectToken');
  const adminId = searchParams.get('adminId');
  const adminType = searchParams.get('adminType');
  const navigate = useNavigate();

  const adminProfileRedirect = async () => {
    if (super_token && super_admin) {
      console.log('navigation');
      navigate(`/home`, {
        state: {
          email: super_admin.email,
          is_admin: super_admin.is_admin,
        },
      });
      return;
    }

    localStorage.clear();
    sessionStorage.setItem('register', 1);

    console.log(redirectToken, adminId, adminType);
    const res = await axios.get(
      `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/v2/user/${adminId}`,
      {
        headers: {
          Authorization: `JWT ${redirectToken}`,
        },
      }
    );
    if (adminType == 1) {
      if (res.data.is_admin === 0) {
        Toastify('error', 'Unauthorized Admin');
        return;
      }
      localStorage.setItem(
        'super_admin',
        JSON.stringify({
          id: adminId,
          name: res.data.name,
          email: res.data.email,
          user_hash: res.data.user_hash,
          brand: res.data.brand,
          brand_hash: res.data.brand_hash,
          image: res.data.image,
          track: res.data.track_usage,
          team: res.data.team,
          admin: res.data.admin_verified,
          weights: res.data.weights,
          fintech_brands: res.data.fintech_brands,
          brand_pub_mapping: res.data.brand_pub_mapping,
          brand_goals: res.data.brand_goals,
          brand_images: res.data.brand_images,
          notifications: res.data.notifications,
          mobile: res.data.mobile,
          meeting_link: res.data.meeting_link,
          position: res.data.position,
          mobile: res.data.mobile,
          meeting_link: res.data.meeting_link,
          position: res.data.position,
          is_admin: res.data.is_admin,
        })
      );

      localStorage.setItem(
        'super_token',
        JSON.stringify({
          token: redirectToken,
        })
      );

      const desc = {
        message: `User with email ${res.data.email} loggedIn`,
        status: res.status,
        method: res.config.method,
        url: res.config.url,
      };

      await actionLogTrack(JSON.stringify(desc), false, redirectToken);

      Toastify('success', 'Login Successfully');

      navigate('/home', {
        state: {
          email: res.data.email,
          is_admin: res.data.is_admin,
        },
      });
    } else {
      navigate('/waiting', {
        state: {
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          brand: res.data.brand,
          image: res.data.image,
          track: res.data.track_usage,
          team: res.data.team,
        },
      });
      Toastify('success', 'Login Successfully');
    }
  };
  useEffect(() => {
    setIsStateSet(true);
  }, []);

  useEffect(() => {
    if (isStateSet) adminProfileRedirect();
  }, [isStateSet]);

  return (
    <div className="preloader">
      <PuffLoader
        className="puff-loader-preloader"
        size={80}
        color="rgb(166, 0, 113)"
        speedMultiplier={0.8}
      />
    </div>
  );
};

export default AdminRedirect;
