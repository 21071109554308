import { Button, TextField } from "@mui/material";
import FormContainer from "../form-container";

import "./link-connector.styles.scss";
import { useState } from "react";
import { Toastify } from "../../../../App";

const LinkConnector = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    username: "",
    password: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.username.length === 0 || details.username === "") {
      return "Username required !!";
    } else if (details.password.length === 0 || details.password === "") {
      return "Password required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="link-connector-container">
        {" "}
        <TextField
          required
          size="small"
          label="User name"
          style={{
            margin: "10px 0",
          }}
          name="username"
          value={details.username}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Password"
          type="password"
          style={{
            margin: "10px 0",
          }}
          name="password"
          value={details.password}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "link-connector" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default LinkConnector;
