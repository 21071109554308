import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Auth from './pages/auth/auth.component';
import Home from './pages/home/home.component';
// import Landing from "./pages/landing/landing.component";
import './App.css';
import OnBoarding from './pages/onboarding';
import InviteOnBoarding from './pages/inviteboarding';
import PrivateRoute from './private/PrivateRouter';
import CheckPaymentRoute from './custom-routes/CheckPaymentRoute';
// import { useDispatch, useSelector } from "react-redux"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JoinOrg from './pages/join-organization';
import Create from './components/cms/page/create';
import PreviewContent from './components/cms/page/preview';
import PaymentRequired from './pages/payment-required';
import ViewContent from './components/cms/page/view';
import Edit from './components/cms/page/edit';
import SizeDialog from './components/size-dialog';
import PreLoader from './components/preloader';
import AdminRedirect from './pages/auth/admin-redirect';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { usePostHog } from 'posthog-js/react'
import ProtectedRoute from './utils/protected-route';

export const queryClient = new QueryClient();
export const Toastify = (type, msg) => {
  switch (type) {
    case 'success':
      toast.success(msg, {
        hideProgressBar: true,
        theme: 'colored',
        className: 'toast-success-container toast-style-ltp',
      });
      break;
    case 'warning':
      toast.warn(msg, {
        hideProgressBar: true,
        theme: 'colored',
        className: 'toast-wrong-container toast-style-ltp',
      });
      break;
    case 'error':
      toast.error(msg, {
        hideProgressBar: true,
        theme: 'colored',
        className: 'toast-wrong-container toast-style-ltp',
      });
      break;
    case 'info':
      toast.info(msg, {
        hideProgressBar: true,
        theme: 'colored',
        className: 'toast-success-container toast-style-ltp',
      });
      break;
    default:
      toast.info(msg, {
        hideProgressBar: true,
        theme: 'colored',
        className: 'toast-success-container toast-style-ltp',
      });
      break;
  }
};

function App() {
  // toast.configure({ hideProgressBar: true });
  // analytics

  // const posthog = usePostHog();

  // const identifyUser = (id, properties) => {
  //   // console.log('Identifying user:', id, properties);
  //   posthog.identify(id, properties);
  // };

  // const captureEvent = (eventName, properties) => {
  //   // console.log('Capturing event:', eventName, properties);
  //   posthog.capture(eventName, properties);
  // };

  // const user = JSON.parse(localStorage.getItem("super_admin"));
  // useEffect(() => {


  //   if (user) {

  //     identifyUser(user.id, {
  //       id: user.id,
  //       email: user.email,
  //       name: user.name,
  //       brand: user.brand,
  //       is_admin: user.is_admin,

  //       image: user.image,
  //       team: user.team,
  //       position: user.position,
  //       mobile: user.mobile,
  //       admin: user.admin,
  //     });

  //     // Track each item in localStorage as an event
  //     Object.keys(localStorage).forEach(key => {
  //       captureEvent('local_storage_item_admin', {
  //         key: user.id,
  //         value: localStorage.getItem(key)
  //       });
  //     });
  //   }
  // }, []);

  return (
    // <Landing />
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <PreLoader />
          <ToastContainer />
          <Routes>
            {/* <Route exact path="/testing" element={<Test />} /> */}
            <Route exact path="/" element={<Auth />} />
            <Route path="/home">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  </ProtectedRoute>
                }
              />
              <Route path="create">
                <Route index element={<Create />} />
                <Route path="preview" element={<PreviewContent />} />
              </Route>
              <Route
                path="page-view/:subdomain/:endpoint"
                element={<ViewContent />}
              />
              <Route
                path="page-edit/:page_id/:subdomain/:endpoint"
                element={<Edit />}
              />
            </Route>

            {/* <PrivateRoute path="/home" element={<Home />} /> */}
            <Route path="/testOnly" element={<OnBoarding />} />
            <Route path="/adminRedirect" element={<AdminRedirect />} />
            <Route path="/admin">
              <Route path="payment" element={<PaymentRequired />} />
              <Route
                path="onboard/:invite_token"
                element={<InviteOnBoarding />}
              />
            </Route>

            <Route path="/organization/change" element={<JoinOrg />} />
          </Routes>
        </BrowserRouter>
        <SizeDialog />
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </>
  );
}

export default App;
