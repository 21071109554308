import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  SwipeableDrawer,
  Tooltip,
} from '@mui/material';
import {
  Search,
  Add,
  Done,
  Pause,
  DeleteOutline,
  Refresh,
  Edit,
  Assessment,
  AssessmentOutlined,
  Workspaces,
  WorkspacesOutlined,
  People,
  PeopleOutline,
} from '@mui/icons-material';
import PasswordValidator from 'password-validator';
import validator from 'validator';
import Spinner from 'react-spinner-material';

import './users.styles.scss';

import AuthFormInput from '../auth-form-input/auth-form-input.componnt';
import BrandSelectInput from '../brand-select-input/brand-select-input.component';

import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import { debounce } from '../../utils/debounce';
import { useThrottledFn } from '../../utils/throttle';
import ReactSelectCompoment from '../select-input';
import { actionLogTrack } from '../../lib/logs';
import apiClient from '../../utils/axiosClient';

const Users = () => {
  const [toggle, setToggle] = useState('all-users');
  const [users, setUsers] = useState([]);
  const [usersBackup, setUsersBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [newUserDrawerOpen, setNewUserDrawerOpen] = useState(false);
  const [brandListData, setBrandListData] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [newUserSelectedBrand, setNewUserSelectedBrand] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [disableEmailEdit, setDisableEmailEdit] = useState(false);
  const [disablePasswordEdit, setDisablePasswordEdit] = useState(false);
  const [updatingUser, setUpdatingUser] = useState();
  const [userTrackingStatus, setuserTrackingStatus] = useState({});
  const [userTeamStatus, setUserTeamStatus] = useState({});
  const [userAssignedBrands, setUserAssignedBrands] = useState(null);

  const [loadList, setLoadList] = useState(true);

  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Format Date
  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    );
  };
  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current && super_token?.token) {
      get_ref.current = false;
      getUsers();
      listOfBrands();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to get all users
  const getUsers = () => {
    setToggle('all-users');
    apiClient
      .get(`/user/v1/admin/all`)
      .then((res) => {
        setLoadList(false);
        console.log(res.data, 'res.data');
        setUsers(res.data.response.sort(customSortBasedOnDate).reverse());
        setUsersBackup(res.data.response.sort(customSortBasedOnDate).reverse());

        let userTrackStat = {};
        for (let i = 0; i < res.data.response.length; i++)
          userTrackStat[res.data.response[i]['id']] =
            res.data.response[i]['track_usage'];
        setuserTrackingStatus(userTrackStat);

        let userTeamStat = {};
        for (let i = 0; i < res.data.response.length; i++)
          userTeamStat[res.data.response[i]['id']] =
            res.data.response[i]['team'];
        setUserTeamStatus(userTeamStat);
      })
      .catch((error) => {
        Toastify('error', error.response.data.message);
        console.log(error);
      });
  };

  // Function to sort users data based on their date
  const customSortBasedOnDate = (a, b) =>
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

  // Function to allow user
  const allowUser = (user) => {
    console.log(user, 'user');

    apiClient
      .post(`/user/v1/admin/verify/${user.id}`)
      .then(async (res) => {
        const desc = {
          message: `User with email: ${user.email} allowed !!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);

        Toastify('success', 'User allowed');

        getUsers();
      })
      .catch(async (error) => {
        console.log(error, 'error');
        if (error.response) {
          Toastify('error', error.response.data.message);
        } else {
          Toastify('error', 'Somthing went wrong !!');
        }

        console.log(super_token?.token, 'super_token?.token');
        await actionLogTrack(JSON.stringify(error), true, super_token?.token);
      });
  };

  // Function to put user on hold
  const putUserOnHold = (user) => {
    apiClient
      .put(`/user/v1/admin/hold/${user.id}`)
      .then(async (res) => {
        const desc = {
          message: `User with email: ${user.email} on hold !!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);
        Toastify('success', 'User holded');
        getUsers();
      })
      .catch(async (error) => {
        console.log(error);
        if (error.response) {
          Toastify('error', error.response.data.message);
        } else {
          Toastify('error', 'Somthing went wrong !!');
        }
        await actionLogTrack(JSON.stringify(error), true, super_token?.token);
      });
  };

  // fuction to delete user
  const deleteUser = (user) => {
    apiClient
      .delete(`/user/v1/admin/delete/${user.id}`)
      .then(async (res) => {
        const desc = {
          message: `User with email: ${user.email} deleted !!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);
        Toastify('success', 'User deleted');
        getUsers();
      })
      .catch(async (error) => {
        console.log(error);
        if (error.response) {
          Toastify('error', error.response.data.message);
        } else {
          Toastify('error', 'Somthing went wrong !!');
        }
        await actionLogTrack(JSON.stringify(error), true, super_token?.token);
      });
  };

  const throttleDeleteUser = useThrottledFn(deleteUser, 1000);

  // Function to update data
  const updateDataOnToggle = (toggleActionName) => {
    setToggle(toggleActionName);
    setPage(0);

    if (toggleActionName === 'all-users') {
      setUsers(usersBackup);
    } else if (toggleActionName === 'pending') {
      setUsers(usersBackup.filter((x) => x.admin_verified === 0));
    } else if (toggleActionName === 'allowed') {
      setUsers(usersBackup.filter((x) => x.admin_verified === 1));
    } else if (toggleActionName === 'on-hold') {
      setUsers(usersBackup.filter((x) => x.admin_verified === 2));
    } else {
      setUsers(usersBackup.filter((x) => x.admin_verified === 3));
    }
  };
  //super token
  const super_token = JSON.parse(localStorage.getItem('super_token'));
  // Function to get list of brands
  const listOfBrands = () => {
    apiClient
      .get(`/brand/v1/admin/brands/all`)
      .then((res) => {
        console.log(res, 'brand');
        let brandOptionsTag = [];
        let br = [];
        for (let i = 0; i < res.data.response.length; i++) {
          brandOptionsTag.push(res.data.response[i]['brand']);
          br.push(res.data.response[i]['brand']);
        }

        setBrandListData(br);
        setBrandsList(brandOptionsTag);
      })
      .catch((error) => {
        Toastify('error', error.response.data.message);
        console.error(error);
      });
  };

  // Function to handle submit
  const handleSubmit = () => {
    let passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(6)
      .is()
      .max(15)
      .has()
      .uppercase(1)
      .has()
      .lowercase(1)
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();

    if (name == null || name === '') {
      setNameError('Required!');
      setEmailError('');
      setPasswordError('');
      setConfirmPasswordError('');
      return;
    } else if (email == null || email === '') {
      setNameError('');
      setEmailError('Required!');
      setPasswordError('');
      setConfirmPasswordError('');
      return;
    } else if (password == null || password === '') {
      setNameError('');
      setEmailError('');
      setPasswordError('Required!');
      setConfirmPasswordError('');
      return;
    } else if (confirmPassword == null || confirmPassword === '') {
      setNameError('');
      setEmailError('');
      setPasswordError('');
      setConfirmPasswordError('Required!');
      return;
    } else if (!validator.isEmail(email)) {
      setNameError('');
      setEmailError('Invalid Email!');
      setPasswordError('');
      setConfirmPasswordError('');
      return;
    } else if (!passwordSchema.validate(password)) {
      setNameError('');
      setEmailError('');
      setPasswordError(
        'Password should be of length 6 - 10 and should be containing atleast 1 uppercase, 1 lowercase and 1 digit and should not have any spaces'
      );
      setConfirmPasswordError('');
      return;
    } else if (password !== confirmPassword) {
      setNameError('');
      setEmailError('');
      setPasswordError('');
      setConfirmPasswordError("Doesn't match password");
      return;
    } else {
      setShowLoading(true);

      setNameError('');
      setEmailError('');
      setPasswordError('');
      setConfirmPasswordError('');

      console.log(newUserSelectedBrand);
      console.log(brandListData[newUserSelectedBrand]);

      let brands = '';
      for (let i = 0; i < newUserSelectedBrand.length - 1; i++) {
        brands += newUserSelectedBrand[parseInt(i)] + ',';
      }
      brands += newUserSelectedBrand[parseInt(newUserSelectedBrand.length - 1)];

      console.log(brands);

      const requestBody = {
        name: name,
        email: email,
        password: password,
        brand: brands,
      };

      console.log(requestBody);
      apiClient
        .post('/user/v1/admin/user/add', requestBody)
        .then(async (res) => {
          console.log(res);
          setShowLoading(false);
          setNewUserDrawerOpen(false);
          getUsers();
          const desc = {
            message: `User with email: ${email} added !!`,
            status: res.status,
            method: res.config.method,
            url: res.config.url,
          };
          await actionLogTrack(JSON.stringify(desc), false, super_token?.token);
          Toastify('success', 'User added');
        })
        .catch(async (error) => {
          setShowLoading(false);
          setEmailError(error.response.data.message);
          console.log(error);
          if (error.response) {
            Toastify('error', error.response.data.message);
          } else {
            Toastify('error', 'Somthing went wrong !!');
          }
          await actionLogTrack(JSON.stringify(error), true, super_token?.token);
        });
    }
  };

  const getMeTobrand = () => {
    let selectedbrand = null;
    for (let child of listRef?.current?.children) {
      if (child.value === 'selected-brand') {
        // console.log(child);
        selectedbrand = child;
        break;
      }
    }

    if (selectedbrand != null) {
      // console.log(selectedbrand.scrollIntoView());
      selectedbrand.scrollIntoView();
    } else {
      listRef?.current?.children[0].scrollIntoView();
    }
  };

  const editUser = (user) => {
    if (user.brand.length > 0) {
      setUserAssignedBrands(user.brand.split(','));
    } else {
      setUserAssignedBrands([]);
    }
    setNewUserDrawerOpen(true);
    setDisableEmailEdit(true);
    setDisablePasswordEdit(true);
    setName(user.name);
    setEmail(user.email);
    // why ?
    setPassword('QWERTY123');
    setConfirmPassword('QWERTY123');
    setNewUserSelectedBrand([]);
    setUpdatingUser(user);

    // setTimeout(() => {
    //   getMeTobrand();
    // }, 500);
  };

  const changeDetec = () => {
    const user = usersBackup.find((user) => user.email === email);
  };
  const handleUpdate = () => {
    if (name == null || name === '') {
      setNameError('Required!');
      setEmailError('');
      setPasswordError('');
      setConfirmPasswordError('');
      return;
    } else {
      setNameError('');
      setEmailError('');
      setPasswordError('');
      setConfirmPasswordError('');
      setShowLoading(true);

      // why ?
      let brands = '';
      for (let i = 0; i < newUserSelectedBrand.length - 1; i++) {
        brands += newUserSelectedBrand[parseInt(i)] + ',';
      }
      brands += newUserSelectedBrand[parseInt(newUserSelectedBrand.length - 1)];

      console.log(newUserSelectedBrand, brandListData, 'brandListData');
      const requestBody = {
        name: name,
        brand: brands,
      };

      console.log(requestBody, 'requestBody');
      // return;
      // for team what should be the case ?
      apiClient
        .put(`/user/v1/admin/update/${updatingUser.id}`, requestBody)
        .then(async (res) => {
          console.log(res);
          setDisableEmailEdit(false);
          setDisablePasswordEdit(false);
          setShowLoading(false);
          setNewUserDrawerOpen(false);
          getUsers();

          const desc = {
            message: `User with email: ${
              updatingUser.email
            }  updated. updated ${JSON.stringify(requestBody)} !!`,
            status: res.status,
            method: res.config.method,
            url: res.config.url,
          };
          await actionLogTrack(JSON.stringify(desc), false, super_token?.token);
          Toastify('success', 'User updated');
        })
        .catch(async (error) => {
          setShowLoading(false);
          setEmailError(error.response.data.message);
          if (error.response) {
            Toastify('error', error.response.data.message);
          } else {
            Toastify('error', 'Somthing went wrong !!');
          }
          await actionLogTrack(JSON.stringify(error), true, super_token?.token);
        });
    }
  };

  // Function to handle search results
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < usersBackup.length; i++) {
      if (
        usersBackup[i]['name'].toLowerCase().includes(query.toLowerCase()) ||
        usersBackup[i]['email'].toLowerCase().includes(query.toLowerCase()) ||
        usersBackup[i]['brand'].toLowerCase().includes(query.toLowerCase())
      )
        results.push(usersBackup[i]);
    }

    setUsers(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));
  const handleMultipleSelectChange = (selectedItems) => {
    const indexes = [];
    for (let i = 0; i < selectedItems.length; i++) {
      indexes.push(selectedItems[i].value);
    }
    setNewUserSelectedBrand(indexes);

    console.log(indexes);
  };

  // handle change in tracking status of a user
  const handleTrackingChange = (user) => {
    apiClient
      .put(`/user/v1/admin/tracking/${user.id}`)
      .then(async (res) => {
        let uTS = userTrackingStatus;

        uTS[user.id] = uTS[user.id] === 1 ? 0 : 1;

        setuserTrackingStatus({ ...uTS });

        const desc = {
          message: `User with email: ${user.email} ${
            uTS[user.id] === 1 ? 'Tracking started' : ' Tracking stoped'
          } !!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);

        Toastify(
          'success',
          uTS[user.id] === 1 ? 'Tracking started' : ' Tracking stoped'
        );
      })
      .catch(async (error) => {
        Toastify('error', error.response.data.message);
        console.error(error);

        if (error.response) {
          Toastify('error', error.response.data.message);
        } else {
          Toastify('error', 'Somthing went wrong !!');
        }
        await actionLogTrack(JSON.stringify(error), true, super_token?.token);
      });
  };

  // handle change in team status of a user
  const handleTeamChange = (user) => {
    apiClient
      .put(`/user/v1/admin/team/${user.id}`)
      .then(async (res) => {
        let uTS = userTeamStatus;

        uTS[user.id] = uTS[user.id] === 1 ? 0 : 1;

        setUserTeamStatus({ ...uTS });

        const desc = {
          message: `User with email: ${user.email} ${
            uTS[user.id] === 1 ? 'Added to team' : ' Removed from team'
          } !!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);

        Toastify(
          'success',
          uTS[user.id] === 1 ? 'Added to team' : ' Removed from team'
        );
      })
      .catch(async (error) => {
        console.error(error);
        if (error.response) {
          Toastify('error', error.response.data.message);
        } else {
          Toastify('error', 'Somthing went wrong !!');
        }
        await actionLogTrack(JSON.stringify(error), true, super_token?.token);
      });
  };
  const listRef = useRef(null);

  const handleAdminAccessChange = (user) => {
    apiClient
      .put(`/user/v1/admin/level/${user.id}`)
      .then(async (res) => {
        const desc = {
          message: `Admin access ${
            user.is_admin ? 'removed' : 'provided'
          } to email: ${user.email}!!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);

        Toastify(
          'success',
          user.is_admin ? 'Add access removed' : 'Add access provided'
        );

        const updatedUsers = users.map((u) => {
          if (user.id === u.id) {
            return {
              ...u,
              is_admin: u.is_admin == 1 ? 0 : 1,
            };
          } else {
            return u;
          }
        });

        setUsers(updatedUsers);
        setUsersBackup(updatedUsers);
      })
      .catch(async (error) => {
        console.error(error);
        if (error.response) {
          Toastify('error', error.response.data.message);
        } else {
          Toastify('error', 'Somthing went wrong !!');
        }
        await actionLogTrack(JSON.stringify(error), true, super_token?.token);
      });
  };
  useEffect(() => {
    if (!newUserDrawerOpen) {
      console.log('change');
      setUserAssignedBrands(null);
    }
  }, [newUserDrawerOpen]);
  return (
    <div className="users">
      <SwipeableDrawer
        anchor="right"
        open={newUserDrawerOpen}
        onClose={() => setNewUserDrawerOpen(false)}>
        <div
          className="right-drawer"
          style={{
            width: '430px',
            padding: '30px',
          }}>
          <p
            className="title"
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontWeight: '600',
              fontSize: '1.2rem',
              marginTop: '0px',
            }}>
            {updatingUser ? 'Update User' : 'Add New User'}
          </p>
          <AuthFormInput
            label="Name"
            type="text"
            hint="Jane Doe"
            value={name}
            changeFunction={(event) => setName(event.target.value)}
            error={nameError}
          />
          <AuthFormInput
            label="Email"
            type="email"
            hint="jane@lt.partners"
            value={email}
            changeFunction={(event) => setEmail(event.target.value)}
            error={emailError}
            isDisabled={disableEmailEdit}
          />
          <AuthFormInput
            label="Password"
            type="password"
            hint="paS5w0rD"
            value={password}
            changeFunction={(event) => setPassword(event.target.value)}
            error={passwordError}
            isDisabled={disablePasswordEdit}
          />
          <AuthFormInput
            label="Confirm Password"
            type="password"
            hint="Same as password"
            value={confirmPassword}
            changeFunction={(event) => setConfirmPassword(event.target.value)}
            error={confirmPasswordError}
            isDisabled={disablePasswordEdit}
          />
          {/* Brands Dropdown */}
          <BrandSelectInput />
          <div className="brand-select">
            <p className="label">Select Brand</p>

            <ReactSelectCompoment
              setNewUserSelectedBrand={setNewUserSelectedBrand}
              dataDefaults={userAssignedBrands}
              dataVals={brandsList}
            />
          </div>
          <Button
            onClick={disableEmailEdit ? handleUpdate : handleSubmit}
            style={{
              backgroundColor: 'rgb(166, 0, 113)',
              border: '2px solid rgb(166, 0, 113)',
              color: 'white',
              fontFamily: '"Montserrat", sans-serif',
              padding: '8px 15px',
              fontSize: '0.7rem',
              float: 'right',
              marginTop: '35px',
            }}>
            {showLoading ? (
              <div className="loading">
                <Spinner
                  radius={23}
                  color={'white'}
                  stroke={3}
                  visible={true}
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                />
                <div
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginLeft: '20px',
                  }}>
                  Loading
                </div>
              </div>
            ) : disableEmailEdit ? (
              'Update'
            ) : (
              'Create'
            )}
          </Button>
        </div>
      </SwipeableDrawer>

      <div className="head">
        <div className="head-txt">Users</div>
        <Refresh className="head-refresh" onClick={getUsers} />
        <Button className="add-user-btn">
          <div
            className="add-user-content"
            onClick={() => {
              setName('');
              setEmail('');
              setPassword('');
              setConfirmPassword('');
              setNewUserSelectedBrand([]);
              setUpdatingUser();
              setNewUserDrawerOpen(true);
              setUserAssignedBrands([]);
            }}>
            <Add className="add-icon" />
            <p className="add-text">Add User</p>
          </div>
        </Button>
      </div>
      <div className="toggle-div">
        <div
          className="toggle-content"
          onClick={() => updateDataOnToggle('all-users')}>
          <p className={toggle === 'all-users' ? 'selected-toggle-text' : ''}>
            All Users
          </p>
          <div
            className={
              toggle === 'all-users' ? 'selected-toggle-bar' : ''
            }></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => updateDataOnToggle('pending')}>
          <p className={toggle === 'pending' ? 'selected-toggle-text' : ''}>
            Pending
          </p>
          <div
            className={toggle === 'pending' ? 'selected-toggle-bar' : ''}></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => updateDataOnToggle('allowed')}>
          <p className={toggle === 'allowed' ? 'selected-toggle-text' : ''}>
            Allowed
          </p>
          <div
            className={toggle === 'allowed' ? 'selected-toggle-bar' : ''}></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => updateDataOnToggle('on-hold')}>
          <p className={toggle === 'on-hold' ? 'selected-toggle-text' : ''}>
            On Hold
          </p>
          <div
            className={toggle === 'on-hold' ? 'selected-toggle-bar' : ''}></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => updateDataOnToggle('deleted')}>
          <p className={toggle === 'deleted' ? 'selected-toggle-text' : ''}>
            Deleted
          </p>
          <div
            className={toggle === 'deleted' ? 'selected-toggle-bar' : ''}></div>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Email
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Registered at
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Admin Status
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                    textAlign: 'center',
                  }}>
                  Actions
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                    textAlign: 'center',
                  }}>
                  Controls
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}>
                        <img
                          alt="fav"
                          className="avtar"
                          src={row.image}
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            height: '40px',
                            width: '40px',
                            objectFit: 'cover',
                            borderRadius: '5px',
                            marginRight: '15px',
                          }}
                        />
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}>
                          <div
                            style={{
                              fontWeight: '600',
                            }}>
                            {row.name}
                          </div>
                          <div
                            style={{
                              fontWeight: '500',
                              color: 'rgb(166, 0, 113)',
                              fontSize: '0.8rem',
                            }}>
                            {userTeamStatus[row.id] === 1
                              ? 'All Brands'
                              : `${row.brand.split(',').length} Brand(s)`}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.email}
                          </p>
                          <Tooltip
                            title={
                              row.verified === 0
                                ? 'Email not verified!'
                                : 'Email Verified!'
                            }
                            style={{
                              fontFamily: '"Montserrat", sans-serif',
                            }}>
                            <img
                              alt="status"
                              src={
                                row.verified === 0
                                  ? process.env.PUBLIC_URL + '/assets/close.png'
                                  : process.env.PUBLIC_URL +
                                    '/assets/correct.png'
                              }
                              height="18"
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                              }}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                          fontSize: '0.8rem',
                        }}>
                        {formatDate(row.created_at)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div
                          style={
                            row.admin_verified === 0
                              ? {
                                  color: 'rgb(252, 198, 3)',
                                  backgroundColor: 'rgba(252, 227, 3, 0.3)',
                                  border: '1px solid rgb(252, 198, 3)',
                                  borderRadius: '20px',
                                  textAlign: 'center',
                                  fontSize: '0.8rem',
                                  width: '75px',
                                  paddingTop: '3px',
                                  paddingBottom: '3px',
                                }
                              : row.admin_verified === 1
                              ? {
                                  color: 'rgb(39, 181, 36)',
                                  backgroundColor: 'rgba(71, 237, 52, 0.3)',
                                  border: '1px solid rgb(39, 181, 36)',
                                  borderRadius: '20px',
                                  textAlign: 'center',
                                  fontSize: '0.8rem',
                                  width: '75px',
                                  paddingTop: '3px',
                                  paddingBottom: '3px',
                                }
                              : row.admin_verified === 2
                              ? {
                                  color: 'rgb(36, 169, 181)',
                                  backgroundColor: 'rgba(36, 169, 181, 0.3)',
                                  border: '1px solid rgb(36, 169, 181)',
                                  borderRadius: '20px',
                                  textAlign: 'center',
                                  fontSize: '0.8rem',
                                  width: '75px',
                                  paddingTop: '3px',
                                  paddingBottom: '3px',
                                }
                              : {
                                  color: 'rgb(252, 3, 3)',
                                  backgroundColor: 'rgba(252, 3, 3, 0.3)',
                                  border: '1px solid rgb(252, 3, 3)',
                                  borderRadius: '20px',
                                  textAlign: 'center',
                                  fontSize: '0.8rem',
                                  width: '75px',
                                  paddingTop: '3px',
                                  paddingBottom: '3px',
                                }
                          }>
                          {/* <p> */}
                          {row.admin_verified === 0
                            ? 'Pending'
                            : row.admin_verified === 1
                            ? 'Allowed'
                            : row.admin_verified === 2
                            ? 'On Hold'
                            : 'Deleted'}
                          {/* </p> */}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="actions-div-user">
                          {row.admin_verified === 0 ||
                          row.admin_verified === 2 ||
                          row.admin_verified === 3 ? (
                            <Tooltip title="Allow">
                              <Done
                                onClick={() => allowUser(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(39, 181, 36)',
                                  backgroundColor: 'rgba(71, 237, 52, 0.3)',
                                  border: '1px solid rgb(39, 181, 36)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                          {row.admin_verified === 0 ||
                          row.admin_verified === 1 ||
                          row.admin_verified === 3 ? (
                            <Tooltip title="Hold">
                              <Pause
                                onClick={() => putUserOnHold(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(36, 169, 181)',
                                  backgroundColor: 'rgba(36, 169, 181, 0.3)',
                                  border: '1px solid rgb(36, 169, 181)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                          <Tooltip title="Edit">
                            <Edit
                              onClick={() => editUser(row)}
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(252, 198, 3)',
                                backgroundColor: 'rgba(252, 227, 3, 0.3)',
                                border: '1px solid rgb(252, 198, 3)',
                                padding: '3px',
                                fontSize: '15px',
                                marginLeft: '10px',
                                borderRadius: '5px',
                              }}
                            />
                          </Tooltip>
                          {row.admin_verified === 0 ||
                          row.admin_verified === 1 ||
                          row.admin_verified === 2 ? (
                            <Tooltip title="Delete">
                              <DeleteOutline
                                onClick={() => throttleDeleteUser(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(252, 3, 3)',
                                  backgroundColor: 'rgba(252, 3, 3, 0.3)',
                                  border: '1px solid rgb(252, 3, 3)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="actions-div-user">
                          <Tooltip
                            title={
                              userTrackingStatus[row.id] === 1
                                ? 'Stop Tracking'
                                : 'Start Tracking'
                            }>
                            {userTrackingStatus[row.id] === 1 ? (
                              <Assessment
                                onClick={() => handleTrackingChange(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(166, 0, 113)',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            ) : (
                              <AssessmentOutlined
                                onClick={() => handleTrackingChange(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(166, 0, 113)',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            )}
                          </Tooltip>
                          <Tooltip
                            title={
                              userTeamStatus[row.id] === 1
                                ? 'Remove from Team'
                                : 'Add to Team'
                            }>
                            {userTeamStatus[row.id] === 1 ? (
                              <Workspaces
                                onClick={() => handleTeamChange(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(166, 0, 113)',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            ) : (
                              <WorkspacesOutlined
                                onClick={() => handleTeamChange(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(166, 0, 113)',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            )}
                          </Tooltip>
                          {row.is_admin === 1 && (
                            <Tooltip title={'Admin'}>
                              <People
                                onClick={() => handleAdminAccessChange(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(166, 0, 113)',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          )}
                          {row.is_admin === 0 && (
                            <Tooltip title={'Not an Admin'}>
                              <PeopleOutline
                                onClick={() => handleAdminAccessChange(row)}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(166, 0, 113)',
                                  marginLeft: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return <TableSkeleton />;
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>

      <div className="foot">
        <p>All rights reserved | LT Partners</p>
      </div>
    </div>
  );
};

export default Users;
