import * as React from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";
export default function DatePickerComponent({ setNotification, Notification }) {
  React.useEffect(() => {
    console.log(Notification["readBy"], "readby");
  }, [Notification["readBy"]]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label="Read By Date *"
        renderInput={(params) => (
          <TextField required size="small" {...params} />
        )}
        value={new Date(Notification["readBy"])}
        onChange={(event) => {
          if (
            moment().format("MM/DD/YYYY") < moment(event).format("MM/DD/YYYY")
          ) {
            setNotification({
              ...Notification,
              [`readBy`]: moment(event).format("MM/DD/YYYY"),
            });
          }
        }}
      />
    </LocalizationProvider>
  );
}
