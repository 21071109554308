import { Button, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./refersion.styles.scss";
import { useState } from "react";
import { Toastify } from "../../../../App";
const Refersion = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    refersion_graphql_token: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (
      details.refersion_graphql_token.length === 0 ||
      details.refersion_graphql_token === ""
    ) {
      return "Refersion GraphQL Token required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="refersion-container">
        {" "}
        <TextField
          required
          size="small"
          label="Refersion GraphQL Token"
          style={{
            margin: "10px 0",
          }}
          name="refersion_graphql_token"
          value={details.refersion_graphql_token}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "refersion" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default Refersion;
