import React from "react";

import "./brand-select-input.styles.scss";

const BrandSelectInput = ({ label }) => {
  <div className="brand-select">
    <p className="label">{label}</p>
  </div>;
};

export default BrandSelectInput;
