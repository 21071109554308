import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const SizeDialog = () => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Dialog disableEscapeKeyDown open={windowSize.innerWidth < 600}>
      <DialogTitle
        style={{
          fontFamily: '"Montserrat", sans-serif',
          fontSize: "1.2rem",
          fontWeight: "700",
        }}
      >
        Alert!!!
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "0.9rem",
            // width: "400px",
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          <Typography>
            This applications is desktop view only. In order to use futher
            please resize or use desktop
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={theme}>
          <Button
            variant="contained"
            // style={{
            //   fontFamily: '"Montserrat", sans-serif',
            //   fontSize: "0.8rem",
            //   color: "rgb(166, 0, 113)",
            // }}
            onClick={() => {
              window.location.assign("https://lift.lt.partners");
            }}
          >
            Ok
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};

export default SizeDialog;
