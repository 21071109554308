import React, { useEffect, useRef, useState } from "react";

import "./payment-required.styles.scss";

import Pricing from "../../components/pricing";

import { createTheme, CircularProgress, Dialog } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Payment from "../../components/onboarding/payment";
import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import axios from "axios";
import { Toastify } from "../../App";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../utils/firebaseConfig";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const PaymentRequired = () => {
  const payment = useSelector((state) => state.payment);

  // const { invite_token } = useParams();
  const [invitation, setInvitation] = useState({
    payment: "",
    admin: {
      name: "",
      email: "",
      id: "",
      org_id: "",
    },
  });

  // payment

  const [showPaymentDialog, SetShowPaymentDialog] = useState(false);
  const sub_click = React.useRef();
  // const [paymentDetails, setPaymentDetails] = useState(null);

  // payment

  const { admin_profile } = useSelector((state) => state.admin);
  // get intivation
  const get_ref = useRef(true);
  useEffect(() => {
    setInvitation({
      ...invitation,
      admin: {
        name: admin_profile?.name,
        email: admin_profile?.email,
        id: admin_profile?.id,
        org_id: admin_profile?.organization?.id,
      },
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if (get_ref.current) {
      get_ref.current = false;
      logEvent(analytics, "onboading_page_visited");
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeDetails = (e) => {
    if (
      e.target.name === "re_pwd" ||
      e.target.name === "pwd" ||
      e.target.name === "payment"
    ) {
      const newdata = {
        ...invitation,
        [e.target.name]: e.target.value,
      };
      setInvitation(newdata);
      localStorage.setItem("invitationInfo", JSON.stringify(newdata));

      return;
    }
  };

  // const [onboarding, setOnboarding] = useState(false);
  // const onboard = async () => {
  //   try {
  //     setOnboarding(true);

  //     const respone_1 = await axios.post(
  //       "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/onboarding",
  //       {
  //         token: invite_token,
  //         admin_password: invitation.pwd,
  //         payment_model:
  //           paymentDetails.payment_model === ""
  //             ? null
  //             : paymentDetails.payment_model,
  //         subscription_id:
  //           paymentDetails.subscription_id === ""
  //             ? null
  //             : paymentDetails.subscription_id,
  //         customer_id:
  //           paymentDetails.customer_id === ""
  //             ? null
  //             : paymentDetails.customer_id,
  //       }
  //     );
  //     localStorage.clear();
  //     delete respone_1.data.details.password;

  //     localStorage.setItem(
  //       "admin_auth",
  //       JSON.stringify(respone_1.data.details)
  //     );
  //     setOnboarding(false);
  //     sessionStorage.setItem("register", 1);
  //     navigator("/home");
  //     Toastify("Success", "Success! Check your email for the invoice.");
  //   } catch (error) {
  //     setOnboarding(false);

  //     console.log(error, "error.response.data.message");
  //   }
  // };

  const pay_onbarod = () => {
    if (invitation.payment === "") {
      Toastify("error", "Please select your payment model!!");
      return;
    }
    SetShowPaymentDialog(true);
  };

  return (
    <div className="payment-required-container">
      <Dialog disableEscapeKeyDown open={showPaymentDialog}>
        <div className="payment-dialog-container">
          <div className="header">One Step to Lift Up</div>

          <Payment
            sub_click={sub_click}
            details={{ ...invitation.admin, ...invitation }}
          />
          <ThemeProvider theme={theme}>
            <div className="btn-container-payment">
              <div
                className={`button-control-close-pay ${
                  payment.payment_process ? "button-control-close-diable" : ""
                }`}
                onClick={() => {
                  if (!payment.payment_process) {
                    SetShowPaymentDialog(false);
                  }
                }}
              >
                Close
              </div>
              {!payment.payment_process ? (
                <div
                  className="button-control-pay"
                  onClick={() => {
                    sub_click.current.click();
                  }}
                >
                  {/* {open == 2 ? "Subscribe" : "Next Step"} */}
                  Pay
                </div>
              ) : (
                <div className="button-control-pay">
                  <CircularProgress
                    style={{
                      color: "#fff",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </div>
              )}
            </div>
          </ThemeProvider>
        </div>
        {/* </DialogContent> */}
      </Dialog>
      <div className="top-bar">
        <div className="logo">
          <img alt="" src={process.env.PUBLIC_URL + "/assets/lift_fav.png"} />
        </div>
      </div>

      <div className="content-container">
        <h2 className={"header"}>Welcome to LIFT</h2>
        <p className="content">
          Please complete the following steps to continue
        </p>
      </div>
      <div className={"form-container"}>
        <ThemeProvider theme={theme}>
          <div className={"form-type form-4"}>
            <Pricing
              changeDetails={changeDetails}
              details={{ ...invitation.admin, ...invitation }}
            />
          </div>
        </ThemeProvider>
      </div>
      <div className="control-section">
        <div className="controls">
          {!payment.payment_process ? (
            <div
              onClick={() => {
                pay_onbarod();
              }}
              className="button-control"
            >
              {/* {open == 2 ? "Subscribe" : "Next Step"} */}
              {"Next Step"}
            </div>
          ) : (
            <div className="button-control">
              <CircularProgress
                style={{
                  color: "#fff",
                  height: "20px",
                  width: "20px",
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="footer">
        <p>All rights reserved | LT Partners</p>
      </div>
    </div>
  );
};

export default PaymentRequired;
