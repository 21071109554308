import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  TextField,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';

import { Refresh, Add, Edit, Search, DeleteOutline } from '@mui/icons-material';
import axios from 'axios';
import './notification.styles.scss';
import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import { debounce } from '../../utils/debounce';
import moment from 'moment';
import DatePickerComponent from '../date-picker';
import { mergeData } from '../../utils/merge';
import apiClient from '../../utils/axiosClient';
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const NotificationsComponent = () => {
  const [notifications, setNotificaionts] = useState([]);
  const [teamsNoti, setTeamsNoti] = useState([]);
  const [notificationsBackup, setNotificaiontsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [showAddOrgDialog, setShowAddOrgDialog] = useState(false);
  const [loadList, setLoadList] = useState(true);
  // const []

  const [addNotification, setAddNotification] = useState({
    topic: '',
    [`subTopic`]: '',
    message: '',
    readBy: moment().add(1, 'days').format('MM/DD/YYYY'),
    checked: false,
  });
  const [hideAddOrgProgressDialog, setHideAddOrgProgressDialog] =
    useState(true);

  // add

  //edit
  const [editNotifications, setEditNotifications] = useState({
    topic: '',
    [`subTopic`]: '',
    message: '',
    readBy: moment().add(1, 'days').format('MM/DD/YYYY'),
    id: '',
  });

  const [showEditNotificationDialog, setShowEditNotificationDialog] =
    useState(false);

  const [hideEditOrgProgressDialog, setHideEditOrgProgressDialog] =
    useState(true);
  //edit

  //delete
  const [aboutToDeleteNotification, setAboutToDeleteNotification] =
    useState(null);
  const [hideDeleteOrgProgressDialog, setHideDeleteOrgProgressDialog] =
    useState(true);
  //delete

  const [searchText, setSearchText] = useState('');

  //super token
  const super_token = JSON.parse(localStorage.getItem('super_token'));

  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //react version 18 2x rendering
  const get_orgs_ref = useRef(true);
  useEffect(() => {
    if (get_orgs_ref.current && super_token?.token) {
      get_orgs_ref.current = false;
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Getting all notifications
  const getNotifications = async () => {
    try {
      const res1 = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/notification/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );

      const res2 = await apiClient.get(`/outreach/v1/admin/notification/get`);

      console.log(res1.data, res2.data);
      if (res1.status == 200 && res2.status === 200) {
        setTeamsNoti(res2.data);
        const finalData = res1.data;

        setLoadList(false);
        const sortedFinalData = finalData.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setNotificaionts(sortedFinalData);
        setNotificaiontsBackup(sortedFinalData);
        console.log(res1, res2, 'results if ');
      } else {
        console.log(res1, res2, 'results else ');
      }
    } catch (error) {
      setLoadList(false);
      Toastify('error', error);
      console.error(error);
    }
  };

  // Add Notification to db
  const addNotifications = async () => {
    try {
      if (
        addNotification.message === null ||
        addNotification.message.length === 0
      ) {
        Toastify('error', 'Message is required !!');
        return;
      } else if (
        addNotification.subTopic === null ||
        addNotification.subTopic.length === 0
      ) {
        Toastify('error', 'Sub-topic  is required !!');
        return;
      } else if (
        addNotification.topic === null ||
        addNotification.topic.length === 0
      ) {
        Toastify('error', 'Topic is required !!');
        return;
      } else if (
        addNotification.readBy === null ||
        addNotification.readBy.length === 0
      ) {
        Toastify('error', 'Read by is required !!');
        return;
      }

      setHideAddOrgProgressDialog(false);

      const res1 = await axios.post(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/notification/new`,
        {
          ...addNotification,
          readBy: moment(addNotification.readBy).format('DD/MM/YYYY'),
        },
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );

      let res2 = null;
      if (addNotification.checked) {
        res2 = await apiClient.post(
          `/outreach/v1/admin/notification/new`,
          {
            ...addNotification,
            readBy: moment(addNotification.readBy).format('DD/MM/YYYY'),
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );
      }
      console.log(res2, res1, 'result');
      if (
        res1.status == 200 &&
        (!addNotification.checked || res2.status == 200)
      ) {
        setHideAddOrgProgressDialog(true);
        setShowAddOrgDialog(false);
        setAddNotification({
          topic: '',
          [`subTopic`]: '',
          message: '',
        });
        getNotifications();
      }
    } catch (error) {
      Toastify('error', error);
      setHideAddOrgProgressDialog(true);
      setShowAddOrgDialog(false);
      setAddNotification({
        topic: '',
        [`subTopic`]: '',
        message: '',
      });
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  //handle add notification
  const handleAddNotification = async (e) => {
    setAddNotification({
      ...addNotification,
      [e.target.name]: e.target.value,
    });
  };

  // edit organization
  const editOrganization = (notification) => {
    setEditNotifications({
      topic: notification.topic,
      [`subTopic`]: notification[`sub_topic`],
      message: notification.message,
      ['readBy']: moment(notification[`read_by`]).format('MM/DD/YYYY'),
      id: notification.id,
    });
    setShowEditNotificationDialog(true);
  };

  // update organization
  const updateNotification = async () => {
    try {
      setHideEditOrgProgressDialog(false);

      const isTeam = teamsNoti.find(
        (team) =>
          team.topic === editNotifications.topic &&
          team.sub_topic === editNotifications.subTopic &&
          team.ready_by === editNotifications.readyBy
      );

      let res1 = null;
      if (isTeam !== undefined) {
        res1 = apiClient.post(
          `/outreach/v1/admin/notification/update/${isTeam.id}`,
          {
            ...editNotifications,
            readBy: moment(editNotifications.readBy).format('DD/MM/YYYY'),
            id: isTeam.id,
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );
      }
      console.log(editNotifications, 'editNotifications');
      const res2 = axios.post(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/notification/update/${editNotifications.id}`,
        {
          ...editNotifications,
          readBy: moment(editNotifications.readBy).format('DD/MM/YYYY'),
        },
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );
      Promise.all([res1, res2])
        .then((res) => {
          setHideEditOrgProgressDialog(true);
          setShowEditNotificationDialog(false);
          getNotifications();
        })
        .catch((err) => {
          console.log(err);
          Toastify('error', err.response.data.message);
          setHideEditOrgProgressDialog(true);
          setShowEditNotificationDialog(false);
        });
    } catch (error) {
      Toastify('error', error.response.data.message);
      setHideEditOrgProgressDialog(true);
      setShowEditNotificationDialog(false);
      console.log(error);
    }
  };
  const handleEditNotification = async (e) => {
    setEditNotifications({
      ...editNotifications,
      [e.target.name]: e.target.value,
    });
  };

  //Delete Organization
  const deleteNotification = async (noti) => {
    try {
      setHideDeleteOrgProgressDialog(false);
      const isTeam = teamsNoti.find(
        (team) =>
          team.topic === noti.topic &&
          team.sub_topic === noti.sub_topic &&
          team.ready_by === noti.ready_by
      );

      let res1 = null;
      if (isTeam !== undefined) {
        res1 = apiClient.delete(
          `outreach/v1/admin/notification/delete/${isTeam.id}`,
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );
      }

      const res2 = axios.delete(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/notification/delete/${noti.id}`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );

      Promise.all([res1, res2])
        .then((res) => {
          console.log(res, 'res');
          getNotifications();
          setHideDeleteOrgProgressDialog(true);
          setAboutToDeleteNotification(null);
        })
        .catch((error) => {
          setAboutToDeleteNotification(null);
          setHideDeleteOrgProgressDialog(true);
          Toastify('error', error.response.data.message);
          console.log(error);
        });
    } catch (error) {
      Toastify('error', error.response.data.message);
      setHideDeleteOrgProgressDialog(true);
      setAboutToDeleteNotification(null);
      console.log(error);
    }
  };

  // Search result
  const searchResults = (query) => {
    let results = [];

    for (let i = 0; i < notificationsBackup.length; i++) {
      if (
        notificationsBackup[i]['name']
          ?.toLowerCase()
          .includes(query.toLowerCase()) ||
        ('monthly'.includes(query.toLowerCase()) &&
          notificationsBackup[i]['payment_model'] === 1) ||
        ('yearly'.includes(query.toLowerCase()) &&
          notificationsBackup[i]['payment_model'] === 2)
      ) {
        results.push(notificationsBackup[i]);
      }
    }

    setNotificaionts(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));

  return (
    <div className="brands">
      {/* Add Notificaion Dialog */}
      <Dialog disableEscapeKeyDown open={showAddOrgDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Add Notification
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <TextField
                style={{
                  width: '100%',
                }}
                required
                size="small"
                label="Topic"
                value={addNotification.topic}
                name="topic"
                onChange={(event) => handleAddNotification(event)}
              />

              <TextField
                required
                size="small"
                label="Sub Topic"
                value={addNotification[`subTopic`]}
                name="subTopic"
                onChange={(event) => handleAddNotification(event)}
              />
              <DatePickerComponent
                setNotification={setAddNotification}
                Notification={addNotification}
              />
              <TextField
                style={{
                  width: '100%',
                }}
                required
                size="small"
                label="Message"
                value={addNotification.message}
                name="message"
                onChange={(event) => handleAddNotification(event)}
              />

              <FormControlLabel
                style={{
                  padding: '0px 10px',
                }}
                control={
                  <Checkbox
                    value={addNotification.checked}
                    onChange={() => {
                      setAddNotification({
                        ...addNotification,
                        checked: !addNotification.checked,
                      });
                    }}
                  />
                }
                label="Send it in LIFT Team as well"
              />
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (hideAddOrgProgressDialog) {
                addNotifications();
              }
            }}>
            <div>
              {hideAddOrgProgressDialog ? (
                <p>Add</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => setShowAddOrgDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Notifications Dialog */}
      <Dialog disableEscapeKeyDown open={showEditNotificationDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Edit Notification
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <TextField
                style={{
                  width: '100%',
                }}
                required
                size="small"
                label="Title Notifications"
                value={editNotifications.topic}
                name="topic"
                onChange={(event) => handleEditNotification(event)}
              />

              <TextField
                required
                size="small"
                label="Title Subtitle "
                value={editNotifications[`subTopic`]}
                name="subTopic"
                onChange={(event) => handleEditNotification(event)}
              />

              <DatePickerComponent
                setNotification={setEditNotifications}
                Notification={editNotifications}
              />

              <TextField
                style={{
                  width: '100%',
                }}
                required
                size="small"
                label="Message"
                value={editNotifications[`message`]}
                name="message"
                onChange={(event) => handleEditNotification(event)}
              />
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={updateNotification}>
            <div>
              {hideEditOrgProgressDialog ? (
                <p>Update</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => setShowEditNotificationDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Notifications */}
      <Dialog
        disableEscapeKeyDown
        open={aboutToDeleteNotification == null ? false : true}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Delete Notification
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <div>Are you sure you want to delete this notification?</div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => setAboutToDeleteNotification(null)}>
            Cancel
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (hideDeleteOrgProgressDialog) {
                deleteNotification(aboutToDeleteNotification);
              }
            }}>
            <div>
              {hideDeleteOrgProgressDialog ? (
                <p>Yes</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
        </DialogActions>
      </Dialog>

      <div className="head">
        <div className="head-txt">Notifications</div>
        <Refresh className="head-refresh" />
        <Button
          className="add-brand-btn"
          onClick={() => setShowAddOrgDialog(true)}>
          <div className="add-brand-content">
            <Add className="add-icon" />
            <p className="add-text">Add Notification</p>
          </div>
        </Button>
      </div>

      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">
              {notifications.length} Notifications{' '}
            </span>
          </p>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Notifications Topic
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Sub Topic
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Message
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Read By
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Created At
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Controls
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.topic}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row['sub_topic']}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.message}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {moment(row.read_by).format('DD/MM/YYYY')}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {moment(row.created_at).format('DD/MM/YYYY')}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        {teamsNoti.find((team) => team.topic === row.topic) &&
                        teamsNoti.find(
                          (team) => team.read_by === row.read_by
                        ) &&
                        teamsNoti.find(
                          (team) => team.sub_topic === row.sub_topic
                        ) ? (
                          <div
                            style={{
                              color: 'rgb(252, 198, 3)',
                              backgroundColor: 'rgba(252, 227, 3, 0.3)',
                              border: '1px solid rgb(252, 198, 3)',
                              borderRadius: '20px',
                              textAlign: 'center',
                              fontSize: '0.8rem',
                              width: '75px',
                              paddingTop: '3px',
                              paddingBottom: '3px',
                            }}>
                            All
                          </div>
                        ) : (
                          <div
                            style={{
                              color: 'rgb(36, 169, 181)',
                              backgroundColor: 'rgba(36, 169, 181, 0.3)',
                              border: '1px solid rgb(36, 169, 181)',
                              borderRadius: '20px',
                              textAlign: 'center',
                              fontSize: '0.8rem',
                              width: '75px',
                              paddingTop: '3px',
                              paddingBottom: '3px',
                            }}>
                            Public
                          </div>
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <Tooltip title="Edit">
                          <Edit
                            style={{
                              cursor: 'pointer',
                              color: 'rgb(252, 198, 3)',
                              backgroundColor: 'rgba(252, 227, 3, 0.3)',
                              border: '1px solid rgb(252, 198, 3)',
                              padding: '3px',
                              fontSize: '15px',
                              marginRight: '15px',
                              borderRadius: '5px',
                            }}
                            onClick={() => editOrganization(row)}
                          />
                        </Tooltip>

                        <Tooltip title="Delete">
                          <DeleteOutline
                            onClick={() => {
                              setAboutToDeleteNotification(row);
                              // deleteOrganization(row);
                            }}
                            style={{
                              cursor: 'pointer',
                              color: 'rgb(252, 3, 3)',
                              backgroundColor: 'rgba(252, 3, 3, 0.3)',
                              border: '1px solid rgb(252, 3, 3)',
                              padding: '3px',
                              fontSize: '15px',
                              marginRight: '15px',
                              borderRadius: '5px',
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return <TableSkeleton key={i} />;
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={notifications.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default NotificationsComponent;
