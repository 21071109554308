import React from "react";

import "./home.styles.scss";

const HomeComponent = () => (
  <div className="home-component">
    <iframe
    className="report"
      src="https://datastudio.google.com/embed/reporting/e1557321-7daf-4a8f-bd16-45d04022ecc7/page/p_ui1i42dsxc"
      title="User Analytics"
    />
  </div>
);

export default HomeComponent;
