import "./onboard-tab.styles.scss";

const OnBoardTab = (props) => {
  let { type_content, icon, active, open, tot } = props;
  return (
    <div className={`onboard-tab ${active ? "onboard-tab-active" : ""}`}>
      <div className={`${active ? "tab-icon-active" : "tab-icon"}`}>{icon}</div>
      <div className={`tab-info ${active ? "tab-info-active" : ""}`}>
        <p className="step-count">{`Step ${open}/${tot}`}</p>
        <div className="tab-name">{type_content}</div>
      </div>
    </div>
  );
};

export default OnBoardTab;
