import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import axios from 'axios';
import { FormHelperText } from '@mui/material';
import { Toastify } from '../../App';
import apiClient from '../../utils/axiosClient';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip({
  label,
  helperText,
  addBrandVertical,
  onChange,
}) {
  // const theme = useTheme();
  // const [personName, setPersonName] = React.useState([]);
  const [verticals, setVertical] = React.useState([]);
  const [selectedVerticals, setSelectedVerticals] =
    React.useState(addBrandVertical);
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  const super_token = JSON.parse(localStorage.getItem('super_token'));
  const admin_auth = JSON.parse(localStorage.getItem('admin_auth'));
  const getVerticalBrand = async () => {
    try {
      const res = await apiClient.get('/outreach/v1/admin/brand/verticals');
      // const res = await axios.get(
      //   'http://localhost:9090/v1/admin/brand/verticals'
      // );

      setVertical(res.data.response);
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    getVerticalBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="multi-input">
      <FormControl
        sx={{
          m: 1,
          width: '25ch',
          '& .css-qiwgdb': {
            padding: '9px',
          },
        }}>
        <InputLabel
          style={{
            top: '-6px',
          }}
          id="demo-multiple-chip-label">
          Verticals
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedVerticals}
          onChange={(e) => {
            setSelectedVerticals(e.target.value);
          }}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value, i) => (
                <div key={i}>{value} </div>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}>
          {verticals.map((name, i) => (
            <MenuItem
              key={i}
              value={name}
              onClick={() => {
                onChange(name);
              }}
              // style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
