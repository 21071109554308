const initialState = {
  payment_process: false,
  payment_model_process: false,
  coupon: null,
};

const paymentReducer = (state = initialState, action) => {
  //schedule request

  switch (action.type) {
    case "PAYMENT_REQUEST":
      state = {
        ...state,
        payment_process: true,
      };
      break;

    case "PAYMENT_SUCCESS":
      state = {
        ...state,
        payment_process: false,
      };
      break;
    case "PAYMENT_ERROR":
      state = {
        ...state,
        payment_process: false,
      };
      break;

    case "PAYMENT_MODEL_UPDATE_REQUEST":
      state = {
        ...state,
        payment_model_process: true,
      };
      break;

    case "PAYMENT_MODEL_UPDATE_SUCCESS":
      state = {
        ...state,
        payment_model_process: false,
      };
      break;
    case "PAYMENT_MODEL_UPDATE_FAIL":
      state = {
        ...state,
        payment_model_process: false,
      };
      break;
    case "SET_COUPON":
      state = {
        ...state,
        coupon: action.payload,
      };
      break;
    case "UNSET_COUPON":
      state = {
        ...state,
        coupon: null,
      };
      break;
    default:
      state = {
        ...state,
      };
  }

  return state;
};

export default paymentReducer;
