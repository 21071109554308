import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { onCMSChange } from "../../../../redux/action/cms.actions";
import "./editor.styles.scss";

const Editor = ({ width, content, id }) => {
  const dispatch = useDispatch();
  const cmsData = useSelector((state) => state.cmsData);

  const onEditorChange = (text) => {
    let contentList = cmsData.cms.blocks.filter((c) => {
      if (c.id === content.id) {
        c.text = text;
      }
      return c;
    });

    let cms = {
      ...cmsData.cms,
      blocks: [...contentList],
    };

    dispatch(onCMSChange(cms));
  };
  const ref = useRef(null);
  return (
    <div
      // onPaste={(e) => {
      //   console.log(e.clipboardData.getData("text/plain"), "paste ");
      //   onEditorChange(e.clipboardData.getData("text/plain"));
      // }}
      className="editor"
      style={{ width: width }}
      onClick={() => {
        ref.current.focus();
      }}
    >
      <ReactQuill
        id={id}
        ref={ref}
        className="editor-quill"
        theme="snow"
        value={content.text}
        onChange={(e) => {
          onEditorChange(e);
        }}
      />
    </div>
  );
};

export default Editor;
