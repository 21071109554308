import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Tooltip,
  createTheme,
  debounce,
} from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import './show-pub.styles.scss';
import { Toastify } from '../../../App';
import TableSkeleton from '../../skeleton-loader/table';
import { actionLogTrack } from '../../../lib/logs';
import apiClient from '../../../utils/axiosClient';
import axios from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const ShowPub = ({
  showEditBrandPubDialog,
  setShowEditBrandPubDialog,
  brandInfo,
  setBrandInfoChange,
  brandInfoChange,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [publishers, setPublishers] = useState([]);
  const [publishersBackup, setPublishersBackup] = useState([]);
  const super_token = JSON.parse(localStorage.getItem('super_token'));

  const getPublishers = async () => {
    try {
      console.log(brandInfo, 'brandInfo');
      const res = await apiClient.get(
        `/affcrm/v1/user/pub/all?brand=${brandInfo?.brand}`
      );

      let results = [];
      for (let i = 0; i < res.data.response.length; i++) {
        results.push({
          pub: res.data.response[i]?.Publisher,
          checked: !brandInfo?.publishers?.includes(
            res.data.response[i]?.Publisher
          ),
        });
      }
      console.log(brandInfo, 'brandInfo');
      setPublishers(results);
      setPublishersBackup(results);
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  useEffect(() => {
    getPublishers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandInfo]);
  const [notSelected, setNotSelcted] = useState([]);

  useEffect(() => {
    if (brandInfo?.publishers?.length > 0) {
      setNotSelcted(brandInfo?.publishers?.split(','));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandInfo?.publishers]);

  const [
    hideEditBrandWeightProgressDialog,
    setHideEditBrandWeightProgressDialog,
  ] = useState(true);
  const updatePublishers = async () => {
    try {
      console.log(notSelected.toString(), 'notSelected');
      setHideEditBrandWeightProgressDialog(false);
      const res = await apiClient.put(`/affcrm/v1/admin/brand/pub/update`, {
        brand: brandInfo?.brand,
        pub: notSelected.toString(),
      });

      // const res = await axios.put(
      //   `http://localhost:4002/v1/admin/brand/pub/update`,
      //   {
      //     brand: brandInfo?.brand,
      //     pub: notSelected.toString(),
      //   }
      // );

      if (res.status === 200) {
        setShowEditBrandPubDialog(false);
        setBrandInfoChange(!brandInfoChange);
        await actionLogTrack(super_token?.token);

        const desc = {
          message: `Publisher preferences updated, where brand ${
            brandInfo?.brand
          } not selected ${notSelected.toString()}!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);

        Toastify('success', 'Publisher preferences updated!');
        setNotSelcted([]);
      } else {
        Toastify('error', 'something went wrong');
      }
      setHideEditBrandWeightProgressDialog(true);
    } catch (error) {
      console.log(error);
      if (error.response) {
        Toastify('error', error.response.data.message);
      } else {
        Toastify('error', 'Somthing went wrong !!');
      }
      await actionLogTrack(JSON.stringify(error), true, super_token?.token);
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const editBrand = () => {};
  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //search
  const [searchText, setSearchText] = useState('');
  // Search results
  const searchResults = (query) => {
    const results = publishersBackup.filter((info) =>
      info?.pub?.toLowerCase()?.includes(query.toLowerCase())
    );

    setPublishers(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));

  return (
    <>
      <style></style>
      <div className="show-pub-container">
        <Dialog disableEscapeKeyDown open={showEditBrandPubDialog}>
          <DialogTitle
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1rem',
            }}>
            Edit Brand Publisher for{' '}
            <span
              style={{
                fontWeight: 'bold',
              }}>
              {brandInfo?.brand}
            </span>
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '1.1rem',
                fontWeight: '700',
              }}>
              {editBrand}
            </span>
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                paddingLeft: '5px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.9rem',
                width: '510px',
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}>
              <div
                className="secondary-div"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}>
                <div
                  className="input-container"
                  style={{
                    marginLeft: 'auto',
                    marginRight: '30px',
                    marginBottom: '10px',
                    width: '300px',
                    borderRadius: '100px',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0 0 10px 1px rgb(241, 241, 241)',
                  }}>
                  <Search
                    className="icon"
                    style={{
                      height: '20px',
                      color: 'white',
                      minWidth: '50px',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      cursor: 'pointer',
                    }}
                  />
                  <input
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      width: '100%',
                      padding: '10px',
                      height: '20px',
                      border: 'none',
                      borderBottomRightRadius: '100px',
                      borderTopRightRadius: '100px',
                    }}
                    className="input-field"
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                      searchOptimized(event.target.value);
                    }}
                  />
                </div>
              </div>
              <TableContainer sx={{ maxHeight: '80%' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                        }}>
                        Publisher Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                          paddingLeft: '50px',
                        }}
                        align="right">
                        Control
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {publishers.length !== 0 ? (
                    <TableBody>
                      {publishers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}>
                              <TableCell
                                style={{
                                  fontFamily: '"Montserrat", sans-serif',
                                  fontWeight: '500',
                                }}>
                                <div>
                                  <p
                                    style={{
                                      display: 'inline-block',
                                      verticalAlign: 'middle',
                                      marginRight: '5px',
                                    }}>
                                    {row.pub}
                                  </p>
                                </div>
                              </TableCell>

                              <TableCell>
                                <div
                                  className="actions-div"
                                  style={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                  }}>
                                  <Tooltip title="Edit">
                                    <Checkbox
                                      style={{
                                        cursor: 'pointer',
                                        color: 'rgb(166, 0, 113)',
                                        fontSize: '15px',
                                      }}
                                      checked={row.checked}
                                      onClick={() => {
                                        let res1 = publishersBackup;
                                        let res2 = publishers;
                                        res1[index] = {
                                          pub: res2[index].pub,

                                          checked: !row.checked,
                                        };
                                        res2[index] = {
                                          pub: res2[index].pub,
                                          checked: !row.checked,
                                        };

                                        let nSelected = notSelected;
                                        if (row.checked) {
                                          nSelected.push(res2[index].pub);
                                        } else {
                                          let idx = nSelected.indexOf(
                                            res2[index].pub
                                          );
                                          if (idx > -1) {
                                            nSelected.splice(idx, 1);
                                          }
                                        }

                                        setNotSelcted(nSelected);
                                        setPublishersBackup([...res1]);
                                        setPublishers([...res2]);
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : (
                    <></>
                  )}
                </Table>
                {publishers.length === 0 ? (
                  [1, 2, 3, 4, 5].map((d, i) => {
                    return (
                      <Fragment key={i}>
                        <TableSkeleton />
                      </Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
              </TableContainer>
              <div className="spacer"></div>
              <Paper style={{ width: '100%' }}>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 100, 200]}
                  component="div"
                  count={publishers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{}}
                  sx={{
                    marginTop: '10px',
                    fontFamily: '"Montserrat", sans-serif',
                  }}
                />
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.8rem',
                color: 'rgb(166, 0, 113)',
              }}
              onClick={() => {
                updatePublishers();
              }}>
              <div>
                {hideEditBrandWeightProgressDialog ? (
                  <p>Update</p>
                ) : (
                  <ThemeProvider theme={theme}>
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </ThemeProvider>
                )}
              </div>
            </Button>
            <Button
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.8rem',
                color: 'rgb(166, 0, 113)',
              }}
              onClick={() => setShowEditBrandPubDialog(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ShowPub;
