import { Toastify } from "../../App";
export const saveCms = (cms) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "CMS_SAVE_LOAD",
      });
      /// function running
      let res = true;
      if (res) {
        dispatch({
          type: "CMS_SAVE_SUCCESS",
          payload: { cms: cms },
        });
      } else {
        dispatch({
          type: "CMS_SAVE_FAIL",
        });
      }
    } catch (error) {
      dispatch({
        type: "CMS_SAVE_FAIL",
      });
    }
  };
};

export const onCMSChange = (cms) => {
  return async (dispatch) => {
    try {
      sessionStorage.setItem("preview", JSON.stringify(cms));
      dispatch({
        type: "CMS_CHANGE",
        payload: { ...cms },
      });
    } catch (error) {
      Toastify('error', error.response?.data?.message);
      console.log(error);
    }
  };
};

export const onCMSChangeEdit = (cms) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "CMS_CHANGE",
        payload: { ...cms },
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const onCMSInit = (cms) => {
  return async (dispatch) => {
    try {
      const cms = JSON.parse(sessionStorage.getItem("preview"));

      if (cms === null || cms === undefined) {
        return;
      }
      console.log({ ...cms }, "cms init1");
      dispatch({
        type: "CMS_CHANGE",
        payload: { ...cms },
      });
    } catch (error) {}
  };
};
