import React, { useState, useRef, useEffect } from "react";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import "./img-crop.style.scss";
import { Box, Slider, Switch, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { pink } from "@mui/material/colors";
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImgCrop = ({ previewCanvasRef, imgInput }) => {
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);

  function onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0], "event.target.files[0]");
      setCrop(undefined);
      const img = URL.createObjectURL(event.target.files[0]);
      setImgSrc(img);
    }
  }

  useEffect(() => {
    if (imgInput) {
      onSelectFile(imgInput);
    }
  }, [imgInput]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }
  const inputRef = useRef(null);

  const CusSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  return (
    <div className="crop-control-container">
      <div className="crop-controls">
        <input
          ref={inputRef}
          style={{ display: "none", width: "0", height: "0" }}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
        <div
          className="btn-container"
          onClick={() => {
            inputRef?.current?.click();
          }}
        >
          Select
        </div>

        <Box sx={{ m: 3, display: "flex" }}>
          <Typography
            style={{
              fontWeight: "600",
              marginRight: "10px",
              marginTop: "0.35em",
            }}
            gutterBottom
          >
            Aspect
          </Typography>
          <CusSwitch
            style={{
              cursor: "pointer",
              color: "rgb(166, 0, 113)",
            }}
            checked={!aspect}
            onClick={handleToggleAspectClick}
          />
        </Box>
        <Box sx={{ m: 3, display: "flex" }}>
          <Typography
            gutterBottom
            style={{ fontWeight: "600", marginRight: "10px" }}
          >
            Scale
          </Typography>
          <Slider
            id="rotate-input"
            onChange={(e) => setScale(e.target.value)}
            style={{
              cursor: "pointer",
              color: "rgb(166, 0, 113)",
              width: "150px",
            }}
            aria-label="Temperature"
            defaultValue={1}
            valueLabelDisplay="auto"
            step={0.1}
            min={0.09}
            max={10}
          />
        </Box>
        <Box sx={{ m: 3, display: "flex" }}>
          <Typography
            gutterBottom
            style={{ fontWeight: "600", marginRight: "10px" }}
          >
            Rotate
          </Typography>
          <Slider
            id="rotate-input"
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
            style={{
              cursor: "pointer",
              color: "rgb(166, 0, 113)",
              width: "150px",
            }}
            aria-label="Temperature"
            defaultValue={0}
            valueLabelDisplay="auto"
            step={1}
            min={-180}
            max={180}
          />
        </Box>
      </div>
      {imgSrc ? (
        <ReactCrop
          style={{
            width: "90%",
            height: "auto",
            margin: "10px 0 ",
            padding: "10px",
            borderRadius: "5px",
          }}
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            className="has-img"
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      ) : (
        <div className="no-img"></div>
      )}
      {!!completedCrop && (
        <>
          <canvas
            ref={previewCanvasRef}
            style={{
              display: "none",
              margin: "10px 0 ",
              padding: "10px",
              border: "1px solid black",
              borderRadius: "5px",
              objectFit: "contain",
              width: "90%",
              height: "300px",
              // width: completedCrop.width,
              // height: completedCrop.height,
            }}
          />
        </>
      )}
    </div>
  );
};

export default ImgCrop;
