import { Button, ButtonGroup, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./rakuten-linkshare.styles.scss";
import { timeZone } from "../../const/timeZone";
const RakutenLinkshare = () => {
  const time = timeZone;
  return (
    <FormContainer>
      <div className="rakuten-linkshare-container">
        {" "}
        <TextField
          required
          size="small"
          label="Username"
          style={{
            margin: "10px 0",
          }}
          // value={addOrgName}
          // onChange={(event) => setAddOrgName(event.target.value)}
        />
        <TextField
          required
          size="small"
          label="Password"
          type="password"
          style={{
            margin: "10px 0",
          }}
          // value={addOrgName}
          // onChange={(event) => setAddOrgName(event.target.value)}
        />
        <TextField
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "10px 0",
          }}
          // value={inviteType}
          onChange={(e) => {
            // console.log(e.target.value, "e.target.value");
            // setInviteType(e.target.value);
          }}
        >
          {/* <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>User</MenuItem> */}
          {/* {console.log(orgs, "orgs")} */}
          <MenuItem
          // onClick={() => {
          //   setInviteOrgData(null);
          // }}
          // value={0}
          >
            Please select a timezone..
          </MenuItem>
          {time.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  // setInviteOrgData(o);
                }}
                value={i + 1}
              >
                {t.time}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          required
          size="small"
          label="MID"
          style={{
            margin: "10px 0",
          }}
          // value={addOrgName}
          // onChange={(event) => setAddOrgName(event.target.value)}
        />
        <div className="number-and-option">
          <TextField
            required
            size="small"
            label="Network Commission Rate (Optional)"
            type="number"
            style={{
              width: "100%",
              margin: "10px 0",
            }}
            // value={addOrgName}
            // onChange={(event) => setAddOrgName(event.target.value)}
          />
          <div style={{ fontSize: "1rem", fontWeight: "500" }}> Of </div>
          <ButtonGroup
            style={{
              width: "100%",
            }}
          >
            <Button>Commision</Button>
            <Button variant="contained">Revenue</Button>
          </ButtonGroup>
        </div>
        <TextField
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "10px 0",
          }}
          // value={addOrgName}
          // onChange={(event) => setAddOrgName(event.target.value)}
        />
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          // value={addOrgName}
          // onChange={(event) => setAddOrgName(event.target.value)}
        />
      </div>
      <Button
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default RakutenLinkshare;
