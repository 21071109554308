import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Refresh, Search, Edit } from '@mui/icons-material';
import axios from 'axios';
import { actionLogTrack } from '../../lib/logs';
import './brand-weights.styles.scss';
import { useSelector } from 'react-redux';
import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import { debounce } from '../../utils/debounce';
import { useThrottledFn } from '../../utils/throttle';
import apiClient from '../../utils/axiosClient';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const BrandWeights = () => {
  const [brandWeights, setBrandWeights] = useState([]);
  const [brandWeightsBackup, setBrandWeightsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [showEditBrandWeightDialog, setShowEditBrandWeightDialog] =
    useState(false);
  const [editBrandId, setEditBrandId] = useState();
  const [editBrand, setEditBrand] = useState();
  const [editRevenue, setEditRevenue] = useState();
  const [editAOV, setEditAOV] = useState();
  const [editClicks, setEditClicks] = useState();
  const [editConvRate, setEditConvRate] = useState();
  const [editNewVExisting, setEditNewVExisting] = useState();
  const [editECPC, setEditECPC] = useState();
  const [editConversions, setEditConversions] = useState();
  const [editNewUser, setEditNewUser] = useState();

  const [
    hideEditBrandWeightProgressDialog,
    setHideEditBrandWeightProgressDialog,
  ] = useState(true);

  const { admin_auth, superAdmin } = useSelector((state) => state.admin);

  //search
  const [searchText, setSearchText] = useState('');

  //super token

  const super_token = JSON.parse(localStorage.getItem('super_token'));
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current) {
      get_ref.current = false;
      getBrandWeights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getting ll brand weights
  const [listLoad, setListLoad] = useState(true);
  const getBrandWeights = () => {
    if (superAdmin) {
      apiClient
        .get(`/brand/v1/admin/brand/weights`)
        .then((res) => {
          setListLoad(false);
          setBrandWeights(res.data.response.sort(customSort).reverse());
          setBrandWeightsBackup(res.data.response.sort(customSort).reverse());
        })
        .catch((err) => {
          Toastify('error', err.response.data.message);
          console.error(err);
        });
    } else {
      axios
        .get(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/brand/weights/get?admin_id=${admin_auth.id}`,
          {
            headers: {
              Authorization: `JWT ${admin_auth?.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data, 'res');
          setListLoad(false);
          setBrandWeights(response.data.sort(customSort).reverse());
          setBrandWeightsBackup(response.data.sort(customSort).reverse());
        })
        .catch((err) => {
          Toastify('error', err.response.data.message);
          console.error(err);
        });
    }
  };

  const throttleGetBrandWeights = useThrottledFn(getBrandWeights, 1000);

  const customSort = (a, b) => a.brand_id - b.brand_id;

  const totalWeight = (row) =>
    parseFloat(row.revenue) +
    parseFloat(row.aov) +
    parseFloat(row.click) +
    parseFloat(row.conv_rate) +
    parseFloat(row.new_v_existing) +
    parseFloat(row.ecpc) +
    parseFloat(row.conversions) +
    parseFloat(row.new_user);

  // Assigning vlues to text fields
  const editBrandWeight = (row) => {
    setEditBrandId(row.brand_id);
    setEditBrand(row.brand);
    setEditRevenue(row.revenue);
    setEditAOV(row.aov);
    setEditClicks(row.click);
    setEditConvRate(row.conv_rate);
    setEditNewVExisting(row.new_v_existing);
    setEditECPC(row.ecpc);
    setEditConversions(row.conversions);
    setEditNewUser(row.new_user);

    setShowEditBrandWeightDialog(true);
  };

  const changeDetected = () => {
    const brand = brandWeightsBackup.find((b) => b.brand_id === editBrandId);

    const changes = [];
    if (brand) {
      if (brand.revenue != editRevenue) {
        changes.push(`${brand.revenue} => ${editRevenue} (revenue)`);
      }
      if (brand.aov != editAOV) {
        changes.push(`${brand.aov} => ${editAOV} (aov)`);
      }
      if (brand.click != editClicks) {
        changes.push(`${brand.click} => ${editClicks} (conv_rate)`);
      }
      if (brand.conv_rate != editConvRate) {
        changes.push(`${brand.conv_rate} => ${editConvRate} (conv_rate)`);
      }
      if (brand.new_v_existing != editNewVExisting) {
        changes.push(
          `${brand.new_v_existing} => ${editNewVExisting} (new_v_existing)`
        );
      }
      if (brand.ecpc != editECPC) {
        changes.push(`${brand.ecpc} => ${editECPC} (ecpc)`);
      }
      if (brand.conversions != editConversions) {
        changes.push(
          `${brand.conversions} => ${editConversions} (conversions)`
        );
      }
      if (brand.new_user != editNewUser) {
        changes.push(`${brand.new_user} => ${editNewUser} (new_user)`);
      }
    }

    return changes;
  };

  const updateBrandWeight = () => {
    if (
      typeof editRevenue === 'undefined' ||
      editRevenue === '' ||
      isNaN(parseFloat(editRevenue))
    ) {
      Toastify(
        'warning',
        'Revenue Wt. should be decimal and should not be empty.'
      );
      // window.alert("Revenue Wt. should be decimal and should not be empty.");
    } else if (
      typeof editAOV === 'undefined' ||
      editAOV === '' ||
      isNaN(parseFloat(editAOV))
    ) {
      Toastify('warning', 'AOV Wt. should be decimal and should not be empty.');
      // window.alert("AOV Wt. should be decimal and should not be empty.");
    } else if (
      typeof editClicks === 'undefined' ||
      editClicks === '' ||
      isNaN(parseFloat(editClicks))
    ) {
      Toastify(
        'warning',
        'Clicks Wt. should be decimal and should not be empty.'
      );
      // window.alert("Clicks Wt. should be decimal and should not be empty.");
    } else if (
      typeof editConvRate === 'undefined' ||
      editConvRate === '' ||
      isNaN(parseFloat(editConvRate))
    ) {
      Toastify(
        'warning',
        'Conversion Rate Wt. should be decimal and should not be empty.'
      );
      // window.alert(
      //   "Conversion Rate Wt. should be decimal and should not be empty."
      // );
    } else if (
      typeof editNewVExisting === 'undefined' ||
      editNewVExisting === '' ||
      isNaN(parseFloat(editNewVExisting))
    ) {
      Toastify(
        'warning',
        'New v/s Existing Wt. should be decimal and should not be empty.'
      );
      // window.alert(
      //   "New v/s Existing Wt. should be decimal and should not be empty."
      // );
    } else if (
      typeof editECPC === 'undefined' ||
      editECPC === '' ||
      isNaN(parseFloat(editECPC))
    ) {
      Toastify(
        'warning',
        'eCPC Wt. should be decimal and should not be empty.'
      );
      // window.alert("eCPC Wt. should be decimal and should not be empty.");
    } else if (
      typeof editConversions === 'undefined' ||
      editConversions === '' ||
      isNaN(parseFloat(editConversions))
    ) {
      Toastify(
        'warning',
        'Conversions Wt. should be decimal and should not be empty.'
      );
      // window.alert(
      //   "Conversions Wt. should be decimal and should not be empty."
      // );
    } else if (
      typeof editNewUser === 'undefined' ||
      editNewUser === '' ||
      isNaN(parseFloat(editNewUser))
    ) {
      Toastify(
        'warning',
        'New User % should be decimal and should not be empty.'
      );
      // window.alert("New User % should be decimal and should not be empty.");
    } else {
      setHideEditBrandWeightProgressDialog(false);

      let requestBody = {
        revenue: parseFloat(editRevenue),
        aov: parseFloat(editAOV),
        click: parseFloat(editClicks),
        conv_rate: parseFloat(editConvRate),
        new_v_existing: parseFloat(editNewVExisting),
        ecpc: parseFloat(editECPC),
        conversions: parseFloat(editConversions),
        new_user: parseFloat(editNewUser),
      };

      const changesVars = changeDetected();
      console.log(changesVars, 'changesVars');

      if (superAdmin) {
        apiClient
          .put(`/brand/v1/admin/brand/weights/${editBrandId}`, requestBody, {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          })
          .then((res) => {
            const desc = {
              message: `Brand weight ${changesVars.toString()} updated for ${editBrand}`,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };
            actionLogTrack(JSON.stringify(desc), false, super_token?.token);
            Toastify(
              'success',
              `Brand Weight ${changesVars.toString()} updated`
            );
            setHideEditBrandWeightProgressDialog(true);
            setShowEditBrandWeightDialog(false);
            getBrandWeights();
          })
          .catch((error) => {
            setHideEditBrandWeightProgressDialog(true);
            console.error(error);
            Toastify('error', error.response.data.message);
            if (error.response) {
              Toastify('error', error.response.data.message);
            } else {
              Toastify('error', 'Somthing went wrong !!');
            }
            actionLogTrack(JSON.stringify(error), true, super_token?.token);
          });
      } else {
        requestBody = {
          admin_id: admin_auth.id,
          organization_id: admin_auth.organization,
          id: editBrandId,
          ...requestBody,
        };

        axios
          .post(
            `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/brand/weights/update`,
            requestBody,
            {
              headers: {
                Authorization: `JWT ${admin_auth.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => {
            Toastify('success', 'Brand Weight updated');
            setHideEditBrandWeightProgressDialog(true);
            setShowEditBrandWeightDialog(false);
            getBrandWeights();

            const desc = {
              message: `Brand Weight ${changesVars.toString()} updated for ${editBrand}`,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };
            actionLogTrack(JSON.stringify(desc), false, super_token?.token);
          })
          .catch((error) => {
            setHideEditBrandWeightProgressDialog(true);
            console.error(error);
            Toastify('error', error.response.data.message);
            if (error.response) {
              Toastify('error', error.response.data.message);
            } else {
              Toastify('error', 'Somthing went wrong !!');
            }
            actionLogTrack(JSON.stringify(error), true, super_token?.token);
          });
      }
    }
  };

  // Search results
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < brandWeightsBackup.length; i++) {
      if (
        brandWeightsBackup[i]['brand']
          .toLowerCase()
          .includes(query.toLowerCase())
      )
        results.push(brandWeightsBackup[i]);
    }

    setBrandWeights(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));
  const admin_data = JSON.parse(localStorage.getItem('admin_auth'));

  return (
    <div className="br-weights">
      <Dialog disableEscapeKeyDown open={showEditBrandWeightDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1rem',
          }}>
          Edit Brand Weights -{' '}
          <span
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1.1rem',
              fontWeight: '700',
            }}>
            {editBrand}
          </span>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <p>
                All weights should be decimal. Weights range is{' '}
                <b> 0.0 to 1.0</b>
              </p>
              <TextField
                required
                size="small"
                label="Revenue Wt."
                value={editRevenue}
                onChange={(event) => setEditRevenue(event.target.value)}
              />
              <TextField
                required
                size="small"
                label="AOV Wt."
                value={editAOV}
                onChange={(event) => setEditAOV(event.target.value)}
              />
              <TextField
                required
                size="small"
                label="Clicks Wt."
                value={editClicks}
                onChange={(event) => setEditClicks(event.target.value)}
              />
              <TextField
                required
                size="small"
                label="Conversion Rate Wt."
                value={editConvRate}
                onChange={(event) => setEditConvRate(event.target.value)}
              />
              <TextField
                required
                size="small"
                label="New v/s Existing Wt."
                value={editNewVExisting}
                onChange={(event) => setEditNewVExisting(event.target.value)}
              />
              <TextField
                required
                size="small"
                label="eCPC Weight"
                value={editECPC}
                onChange={(event) => setEditECPC(event.target.value)}
              />
              <TextField
                required
                size="small"
                label="Conversions Wt."
                value={editConversions}
                onChange={(event) => setEditConversions(event.target.value)}
              />
              <TextField
                required
                size="small"
                label="New user % Wt."
                value={editNewUser}
                onChange={(event) => setEditNewUser(event.target.value)}
              />
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (hideEditBrandWeightProgressDialog) {
                updateBrandWeight();
              }
            }}>
            <div>
              {hideEditBrandWeightProgressDialog ? (
                <p>Update</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => setShowEditBrandWeightDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div className="head">
        <div className="head-txt">Brand Weights</div>
        <Refresh className="head-refresh" onClick={throttleGetBrandWeights} />
        {admin_data ? (
          <div className="btn-control-link">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}>
              <Button className="add-user-btn">
                <a
                  rel="noreferrer"
                  href={`https://app.lift.lt.partners/testload?_id=${
                    admin_data.user
                  }&token=${admin_data?.token}&onboard=${1}`}
                  target={'_blank'}>
                  <div
                    style={{
                      backgroundColor: '#a60071',
                      color: 'white',
                      padding: '0px 30px',
                      borderRadius: '5px',
                    }}
                    className="add-user-content">
                    {/* <Add className="add-icon" /> */}
                    <p
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        fontSize: '0.7rem',
                      }}
                      className="add-text">
                      Go to LIFT
                    </p>
                  </div>
                </a>
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="secondary-div">
        <div className="secondary-content">
          <p className="selected-text">{brandWeights.length} Brands</p>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              console.log(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Brand
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Total Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Revenue Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  AOV Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Clicks Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Conv. Rate Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  New v/s Exist. Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  eCPC Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Conversions Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  New User % Wt.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brandWeights
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.brand}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={
                          totalWeight(row) !== 1
                            ? {
                                fontFamily: '"Montserrat", sans-serif',
                                fontWeight: '700',
                                backgroundColor: 'red',
                                color: 'white',
                              }
                            : {
                                fontFamily: '"Montserrat", sans-serif',
                                fontWeight: '700',
                                backgroundColor: 'rgb(58, 201, 97)',
                                color: 'white',
                              }
                        }>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {totalWeight(row).toFixed(2)}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.revenue}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.aov}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.click}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.conv_rate}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.new_v_existing}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.ecpc}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.conversions}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.new_user}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="actions-div">
                          <Tooltip title="Edit">
                            <Edit
                              onClick={() => editBrandWeight(row)}
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(252, 198, 3)',
                                backgroundColor: 'rgba(252, 227, 3, 0.3)',
                                border: '1px solid rgb(252, 198, 3)',
                                padding: '3px',
                                fontSize: '15px',
                                marginLeft: '10px',
                                borderRadius: '5px',
                              }}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {listLoad ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={brandWeights.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default BrandWeights;
