import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function ReactSelectCompoment({
  dataVals,
  dataDefaults,
  setNewUserSelectedBrand,
}) {
  const colourStyles = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: "white",
        borderColor: isFocused ? "#a60071" : styles.borderColor, // Change '#a60071' to your #desia60071 color
        boxShadow: isFocused ? "0 0 0 1px #a60071" : styles.boxShadow, // Change '#a60071' to your #desia60071 color
        "&:hover": {
          borderColor: isFocused ? "#a60071" : styles.borderColor, // Change '#a60071' to your #desia60071 color
        },
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "undefined"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? "pink"
            ? "white"
            : "black"
          : "rgb(166, 0, 113)",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "blue"
            : undefined,
        },
        ":hover": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "rgb(255 200 237)"
            : undefined,
          color: "rgb(166, 0, 113)",
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "rgb(255 200 237)",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "rgb(166, 0, 113)",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "rgb(166, 0, 113)",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
  };

  const [options, setOptions] = useState([]);

  // useEffect(() => {}, [dataDefaults]);

  useEffect(() => {
    let res = dataVals?.map((val) => ({ label: val, value: val }));

    setOptions(res);
  }, [dataVals, dataDefaults]);

  const handleChange = (selectedOption) => {
    selectedOption = selectedOption.map((o) => o.value);
    setNewUserSelectedBrand(selectedOption);
  };

  // aasd;
  return dataDefaults ? (
    <div style={{ width: "100%" }}>
      <Select
        onChange={handleChange}
        closeMenuOnSelect={false}
        defaultValue={
          dataDefaults.length == 0
            ? options
            : dataDefaults.map((val) => ({ label: val, value: val }))
        }
        options={options}
        isMulti
        label="Single select"
        styles={colourStyles}
      />
    </div>
  ) : (
    <></>
  );
}
