import { Add, CoPresentOutlined, Search } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import { useRef, useState } from 'react';
import AffluentImports from '../affluent-imports/affluent-imports';
import GAImports from '../ga-imports/ga-import';
import DailyActualsImport from '../daily-actuals-import/daily-actuals-import';
import './import-section.style.scss';

const ImportSection = ({ state }) => {
  const childRef = useRef();

  const handleAddClick = () => {
    if (toggle === 'affluent-import') {
      childRef.current.addAffluentTrigger();
    } else if (toggle === 'daily-actuals-import') {
      childRef.current.addAffluentTrigger();
    } else if (toggle === 'ga-import') {
      childRef.current.addGATrigger();
    }
  };

  const handleAddClickGAImport = () => {
    if (toggle === 'ga-import') {
      childRef.current.addGAImportTrigger();
    }
  }
  const [searchText, setSearchText] = useState('');
  const [toggle, setToggle] = useState('affluent-import');
  const updateDataOnToggle = (toggleActionName) => {
    setSearchText('');
    setToggle(toggleActionName);
  };

  return (
    <div className="import-body">
      <div className="head">
        <div className="head-txt">
          {toggle === 'affluent-import'
            ? 'Affluent Import'
            : toggle === 'daily-actuals-import'
              ? 'Daily Actuals Import'
              : 'GA Import'}
        </div>
        {/* {toggle === "Brands" || toggle === "Events" ? (
        <Button className="add-btn" onClick={handleAddClick}>
          <div className="add-content">
            <Add className="add-icon" />
            <p className="add-text">
              {toggle === "Brands" ? "Add Brand" : "Update Events"}
            </p>
          </div>
        </Button>
      ) : (
        <div></div>
      )} */}
        {toggle === 'affluent-import' && (
          <Button className="add-btn" onClick={handleAddClick}>
            <div className="add-content">
              <Add className="add-icon" />
              <p className="add-text">Trigger Import Affluent</p>
            </div>
          </Button>
        )}
        {toggle === 'daily-actuals-import' && (
          <Button className="add-btn" onClick={handleAddClick}>
            <div className="add-content">
              <Add className="add-icon" />
              <p className="add-text">Trigger Import Actuals</p>
            </div>
          </Button>
        )}
        {toggle === 'ga-import' && (
          <div className='button-container'>

            <Button className="add-btn" onClick={handleAddClick}>
              <div className="add-content">
                <Add className="add-icon" />
                <p className="add-text">Select Views and Properties</p>
              </div>
            </Button>
            <Button className="add-btn" onClick={handleAddClickGAImport}>
              <div className="add-content">
                <Add className="add-icon" />
                <p className="add-text">Trigger Import GA</p>
              </div>
            </Button>
          </div>
        )}
      </div>

      <div className="toggle-div">
        <div
          className="toggle-content"
          onClick={() => updateDataOnToggle('affluent-import')}>
          <p
            className={
              toggle === 'affluent-import' ? 'selected-toggle-text' : ''
            }>
            Affluent Import
          </p>
          <div
            className={
              toggle === 'affluent-import' ? 'selected-toggle-bar' : ''
            }></div>
        </div>

        <div
          className="toggle-content"
          onClick={() => updateDataOnToggle('ga-import')}>
          <p className={toggle === 'ga-import' ? 'selected-toggle-text' : ''}>
            GA Import
          </p>
          <div
            className={
              toggle === 'ga-import' ? 'selected-toggle-bar' : ''
            }></div>
        </div>
      </div>
      <Divider />

      {toggle === 'affluent-import' ? (
        <AffluentImports
          childRef={childRef}
          searchText={searchText}
          state={state}
        />
      ) : toggle == 'daily-actuals-import' ? (
        <DailyActualsImport childRef={childRef} searchText={searchText} />
      ) : (
        <GAImports childRef={childRef} searchText={searchText} />
      )}
    </div>
  );
};

export default ImportSection;
