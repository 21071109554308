import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  TextField,
  MenuItem,
  CircularProgress,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
  Select,
  Autocomplete,
  CancelOutlinedIcon,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Refresh,
  Add,
  Edit,
  Search,
  AddPhotoAlternateOutlined,
  DeleteOutline,
  TurnSlightRightOutlined,
} from "@mui/icons-material";
import axios from "axios";
import moment from "moment";

import './brands.styles.scss';
import { useSelector } from 'react-redux';
import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import MultipleSelectChip from '../multiple-input';
import isValidDomain from 'is-valid-domain';
import { debounce } from '../../utils/debounce';
import { useThrottledFn } from '../../utils/throttle';
import isEmail from 'validator/lib/isEmail';
import { actionLogTrack } from '../../lib/logs';
import { parseISO } from 'date-fns';
import apiClient from '../../utils/axiosClient';

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const types = [
  {
    value: "New",
    label: "New",
  },
  {
    value: "Takeover",
    label: "Takeover",
  },
];

// const demoID = [
//   1,
//   2,
//   3,
//   4,
//   5
// ];
const demoID = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

const demoID2 = [
  { value: 119, label: '119' },
  { value: 120, label: '120' },
  { value: 121, label: '121' },
  { value: 122, label: '122' },
  { value: 123, label: '123' },
  { value: 124, label: '124' },
];

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [usersSearched, setUsersSearched] = useState([]);
  const [brandsBackup, setBrandsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const inputFile = useRef(null);
  const [showAddBrandDialog, setShowAddBrandDialog] = useState(false);
  const [addBrandImageRaw, setAddBrandImageRaw] = useState(null);
  const [addBrandImage, setAddBrandImage] = useState(null);
  const [addBrandName, setAddBrandName] = useState("");
  const [addAlias, setAddAlias] = useState("");
  const [addBrandDate, setAddBrandDate] = useState(new Date());
  const [addBrandVertical, setAddBrandVertical] = useState([]);
  const [addBrandType, setAddBrandType] = useState("");
  const [addBrandDomain, setAddBrandDomain] = useState("");
  const [hideAddBrandProgressDialog, setHideAddBrandProgressDialog] =
    useState(true);

  const editInputFile = useRef(null);
  const [editBrandData, setEditBrandData] = useState();
  const [showEditBrandDialog, setShowEditBrandDialog] = useState(false);
  const [editBrandImageRaw, setEditBrandImageRaw] = useState(null);
  const [editBrandImage, setEditBrandImage] = useState(null);
  const [editBrandImageChange, setEditBrandImageChange] = useState(0);
  const [editBrandName, setEditBrandName] = useState();
  const [editAlias, setEditAlias] = useState();
  const [editBrandDate, setEditBrandDate] = useState();
  const [editBrandVertical, setEditBrandVertical] = useState([]);
  const [editBrandVerticalChange, setEditBrandVerticalChange] = useState();
  const [editBrandType, setEditBrandType] = useState("");
  const [editBrandDomain, setEditBrandDomain] = useState();
  const [editBrandDomainChange, setEditBrandDomainChange] = useState();
  const [hideEditBrandProgressDialog, setHideEditBrandProgressDialog] =
    useState(true);

  const [editSOWLink, setEditSOWLink] = useState(null);
  const [editMSALink, setEditMSALink] = useState(null);
  const [editContractTerms, setEditContractTerms] = useState(null);
  const [editDirectorID, setEditDirectorID] = useState(null);
  const [editManagerID, setEditManagerID] = useState(null);
  const [editAssociateID1, setEditAssociateID1] = useState(null);
  const [editAssociateID2, setEditAssociateID2] = useState(null);
  const [editOpsID, setEditOpsID] = useState(null);
  const [editDateJoined, setEditDateJoined] = useState(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  const { admin_auth, superAdmin } = useSelector((state) => state.admin);

  //search
  const [searchText, setSearchText] = useState('');
  const [searchTextUser, setSearchTextUser] = useState('');
  const [searchedBrands, setSearchedBrands] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  console.log(searchQuery, 'searchQuery');

  const date = new Date(0);
  useEffect(() => {
    setEditDateJoined(date.toISOString());
  }, []);
  useEffect(() => {
    console.log(editDateJoined, 'editDateJoined');
  }, [editDateJoined]);

  // Format Date
  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  };

  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current) {
      get_ref.current = false;
      getBrands();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //super token
  const super_token = JSON.parse(localStorage.getItem("super_token"));

  const [loadList, setLoadList] = useState(true);
  // Getting all brands
  const getBrands = () => {
    if (superAdmin) {
      console.log('superadmin');
      apiClient
        .get(`/brand/v1/admin/brands/all`)
        // axios
        //   .get('http://localhost:4004/v1/admin/brands/all')
        .then((res) => {
          console.log(res.data.response, 'res.data.response');
          setLoadList(false);
          if (!searchQuery.trim()) {
            setBrands(res.data.response.sort(customSortBasedOnDate).reverse());
            setBrandsBackup(
              res.data.response.sort(customSortBasedOnDate).reverse()
            );
          } else {
            const filteredBrands = res.data.response.filter((brand) =>
              searchedBrands.includes(brand.id)
            );
            console.log(filteredBrands, 'filteredBRands');
            setBrands(filteredBrands.sort(customSortBasedOnDate).reverse());
            // setBrandsBackup(filteredBrands.sort(customSortBasedOnDate).reverse());
          }
        })
        .catch((err) => {
          Toastify("error", err.response.data.message);
          console.error(err);
        });
    } else {
      axios
        .get(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/brand/get?admin_id=${admin_auth?.id}`,
          {
            headers: {
              Authorization: `JWT ${admin_auth?.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data, "response.data");
          setLoadList(false);
          setBrands(response.data.sort(customSortBasedOnDate).reverse());
          setBrandsBackup(response.data.sort(customSortBasedOnDate).reverse());
        })
        .catch((err) => {
          Toastify("error", err.response.data.message);
          console.error(err);
        });
    }
  };

  const throttleGetBrands = useThrottledFn(getBrands, 1000);

  const customSortBasedOnDate = (a, b) => {
    if (superAdmin) {
      return new Date(a.launch).getTime() - new Date(b.launch).getTime();
    } else {
      return new Date(a.launch).getTime() - new Date(b.launch).getTime();
    }
  };

  const resetAddBrand = () => {
    setAddBrandImageRaw(null);
    setAddBrandImage(null);
    setAddBrandName("");
    setAddAlias("");
    setAddBrandDate(new Date());
    setAddBrandVertical([]);
    setAddBrandType("");
    setAddBrandDomain("");
    setHideAddBrandProgressDialog(true);
    setShowAddBrandDialog(false);
  };
  // Add brand to db
  const addBrand = () => {
    if (
      typeof addBrandName === "undefined" ||
      addBrandName === null ||
      addBrandName === ""
    ) {
      Toastify("warning", "Name of brand is required!");
    } else if (addAlias && addAlias.length > 0 && !isEmail(addAlias)) {
      Toastify("warning", "Alias must be an email!!");
    } else if (
      typeof addBrandDate === "undefined" ||
      addBrandDate === null ||
      addBrandDate === ""
    ) {
      Toastify("warning", "Date of program launch is required!");
    } else if (
      typeof addBrandVertical === "undefined" ||
      addBrandVertical === null ||
      addBrandVertical.length === 0
    ) {
      Toastify("warning", "Vertical of brand is required!");
    } else if (
      typeof addBrandType === "undefined" ||
      addBrandType === null ||
      addBrandType === ""
    ) {
      Toastify("warning", "Program type of brand is required!");
    } else if (
      typeof addBrandDomain === "undefined" ||
      addBrandDomain === null ||
      addBrandDomain === ""
    ) {
      Toastify("warning", "Domain of brand is required!");
    } else if (
      !isValidDomain(addBrandDomain) ||
      addBrandDomain.split(":")[0] === "http" ||
      addBrandDomain.split(":")[0] === "https"
    ) {
      Toastify(
        "warning",
        "Domain of brand should be of format eg: domain_name.com"
      );
    } else {
      setHideAddBrandProgressDialog(false);
      if (addBrandImage === null) {
        let verticals = addBrandVertical;
        verticals.join(",");
        let formData = new FormData();
        formData.append("brand", addBrandName);
        formData.append(
          "launch",
          moment(addBrandDate).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append("vertical", verticals);
        formData.append("type", addBrandType);
        formData.append("domain", addBrandDomain);
        formData.append("url", null);
        formData.append("alias", addAlias);

        apiClient
          .post(`/brand/v1/admin/add/brand`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // axios
          //   .post(`http://localhost:4004/v1/admin/add/brand`, formData, {
          //     headers: {
          //       'Content-Type': 'multipart/form-data',
          //     },
          //   })
          .then(async (res) => {
            console.log(res, 'from brand');
            setHideAddBrandProgressDialog(true);
            setShowAddBrandDialog(false);
            setAddBrandName("");
            setAddBrandDate(new Date());
            setAddBrandVertical([]);
            setAddBrandType("");
            setAddBrandDomain();
            getBrands("");

            const desc = {
              message: `
               Brand with name ${addBrandName} added successfully!
               brand: ${addBrandName}
               launch: ${addBrandDate}
               vertical: ${verticals}
               type: ${addBrandType}
               url: false 
               alias: ${addAlias} 
              `,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };
            await actionLogTrack(
              JSON.stringify(desc),
              false,
              super_token?.token
            );
            Toastify("success", "Brand added successfully!");
          })
          .catch(async (error) => {
            console.error(error, 'from brand');
            if (error.response) {
              Toastify("error", error.response.data.message);
            } else {
              Toastify("error", "Somthing went wrong !!");
            }
            await actionLogTrack(
              JSON.stringify(error),
              true,
              super_token?.token
            );
          });
      } else {
        let verticals = addBrandVertical;
        verticals.join(",");
        let formData = new FormData();
        formData.append("brand", addBrandName);
        formData.append(
          "launch",
          moment(addBrandDate).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append("vertical", verticals);
        formData.append("type", addBrandType);
        formData.append("domain", addBrandDomain);
        formData.append("url", addBrandImageRaw);
        formData.append("alias", addAlias);

        // apiClient
        //   .post(`/brand/v1/admin/add/brand`, formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   })
        axios
          .post(
            `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/admin/add/brand`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `JWT ${super_token?.token}`,
              },
            }
          )
          .then(async (res) => {
            setHideAddBrandProgressDialog(true);
            setShowAddBrandDialog(false);
            setAddBrandImage(null);
            setAddBrandName("");
            setAddBrandDate(new Date());
            setAddBrandVertical([]);
            setAddBrandType("");
            setAddBrandDomain("");
            setAddAlias("");
            getBrands("");

            const desc = {
              message: `
               Brand with name ${addBrandName} added successfully!
               brand: ${addBrandName}
               launch: ${addBrandDate}
               vertical: ${verticals}
               type: ${addBrandType}
               url: 'true' 
               alias: ${addAlias} 
              `,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };
            await actionLogTrack(
              JSON.stringify(desc),
              false,
              super_token?.token
            );
            Toastify("success", "Brand added successfully!");
          })
          .catch(async (error) => {
            console.error(error);
            if (error.response) {
              Toastify("error", error.response.data.message);
            } else {
              Toastify("error", "Somthing went wrong !!");
            }
            await actionLogTrack(
              JSON.stringify(error),
              true,
              super_token?.token
            );
          });
      }
    }
  };

  // delete brand
  const deleteBrand = (id, brandName) => {
    apiClient
      .delete(`/brand/v1/admin/brand/${id}`)
      // axios
      //   .delete(`http://localhost:4004/v1/admin/brand/${id}`)
      .then(async (res) => {
        const desc = {
          message: `Brand with name ${brandName} deleted succesfully!`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        await actionLogTrack(JSON.stringify(desc), false, super_token?.token);
        Toastify("success", "Brand deleted succesfully!");
        getBrands();
      })
      .catch(async (error) => {
        console.error(error);
        if (error.response) {
          Toastify("error", error.response.data.message);
        } else {
          Toastify("error", "Somthing went wrong !!");
        }
        await actionLogTrack(JSON.stringify(error), true, super_token?.token);
      });
  };

  const throttleDeleteBrand = useThrottledFn(deleteBrand, 1000);
  // edit brand
  const editBrand = (brand) => {
    setEditBrandData(brand);
    setEditBrandImage(superAdmin ? brand.url : brand.image);
    setEditBrandName(superAdmin ? brand.brand : brand.name);
    setEditBrandDate(superAdmin ? brand.launch : brand.created_at);

    setEditBrandVertical(brand.vertical.split(","));
    setEditBrandType(brand.type);
    setEditBrandDomain(brand.domain);
    setShowEditBrandDialog(true);
    setEditAlias(brand.alias);

    setEditSOWLink(brand.sow_link);
    setEditMSALink(brand.msa_link);
    setEditContractTerms(brand.contract_terms);
    setEditDirectorID(brand.director_id);
    setEditManagerID(brand.manager_id);
    setEditAssociateID1(brand.associate_1_id);
    setEditAssociateID2(brand.associate_2_id);
    setEditOpsID(brand.ops_id);
    setEditDateJoined(brand.date_joined);
  };

  const changeDetect = () => {
    const brand = brandsBackup.find((b) => b.id == editBrandData.id);
    const changes = [];
    console.log(brand.brand, editBrandName, "brand.brand !== editBrandName");
    if (editBrandData) {
      if (brand.domain !== editBrandDomain) {
        changes.push(`${brand.domain} => ${editBrandDomain} (domain)`);
      }
      if (brand.alias !== editAlias) {
        changes.push(`${brand.alias} => ${editAlias} (alias)`);
      }
      if (brand.brand !== editBrandName) {
        changes.push(`${brand.brand} => ${editBrandName} (brand)`);
      }
      if (brand.launch !== editBrandDate) {
        changes.push(`${brand.launch} => ${editBrandDate} (launch)`);
      }
      if (brand.vertical !== editBrandVertical) {
        changes.push(`${brand.vertical} => ${editBrandVertical} (vertical)`);
      }
      if (editBrandImageRaw !== null) {
        changes.push("image");
      }
      if (brand.type !== editBrandType) {
        changes.push(
          `${brand.type} => ${editBrandType} ${editBrandType} (type)`
        );
      }

      if (brand.sow_link !== editSOWLink) {
        changes.push(`${brand.sow_link} => ${editSOWLink} (sow)`);
      }
      if (brand.msa_link !== editMSALink) {
        changes.push(`${brand.msa_link} => ${editMSALink} (msa)`);
      }
      if (brand.contract_terms !== editContractTerms) {
        changes.push(
          `${brand.contract_terms} => ${editContractTerms} (contractTerms)`
        );
      }
      if (brand.director_id !== editDirectorID) {
        changes.push(`${brand.director_id} => ${editDirectorID} (directorID)`);
      }
      if (brand.manager_id !== editManagerID) {
        changes.push(`${brand.manager_id} => ${editManagerID} (managerID)`);
      }
      if (brand.associate_1_id !== editAssociateID1) {
        changes.push(
          `${brand.associate_1_id} => ${editAssociateID1} (associateID1)`
        );
      }
      if (brand.associate_2_id !== editAssociateID2) {
        changes.push(
          `${brand.associate_2_id} => ${editAssociateID2} (associateID2)`
        );
      }
      if (brand.ops_id !== editOpsID) {
        changes.push(`${brand.ops_id} => ${editOpsID} (opsId)`);
      }
      if (brand.date_joined !== editDateJoined) {
        changes.push(`${brand.date_joined} => ${editDateJoined} (dateJoined)`);
      }
    }

    return changes;
  };

  // update brand
  const updateBrand = () => {
    if (
      !isValidDomain(editBrandDomain) ||
      editBrandDomain.split(":")[0] === "http" ||
      editBrandDomain.split(":")[0] === "https"
    ) {
      Toastify(
        "warning",
        "Domain of brand should be of format eg: domain_name.com"
      );
    } else if (editAlias && editAlias.length > 0 && !isEmail(editAlias)) {
      Toastify("warning", "Alias must be an email!!");
    } else if (
      typeof editBrandName === "undefined" ||
      editBrandName === null ||
      editBrandName === ""
    ) {
      Toastify("warning", "Name of brand is required!");
      // window.alert("");
    } else if (
      typeof editBrandDate === "undefined" ||
      editBrandDate === null ||
      editBrandDate === ""
    ) {
      Toastify("warning", "Date of program launch is required!");
      // window.alert("Date of program launch is required!");
    } else if (
      typeof editBrandVertical === "undefined" ||
      editBrandVertical === null ||
      editBrandVertical.length === 0
    ) {
      Toastify("warning", "Vertical of brand is required!");
      // window.alert("Vertical of brand is required!");
    } else if (
      superAdmin &&
      (typeof editBrandType === "undefined" ||
        editBrandType === null ||
        editBrandType === "")
    ) {
      Toastify("warning", "Program type of brand is required!");
      // window.alert("Program type of brand is required!");
    } else if (editBrandImageRaw === null) {
      const changeVar = changeDetect();
      console.log(changeVar, "changevar");

      setHideEditBrandProgressDialog(false);

      if (superAdmin) {
        let formData = new FormData();
        formData.append("brand", editBrandName);
        formData.append(
          "launch",
          moment(editBrandDate).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append('vertical', editBrandVertical);
        formData.append('type', editBrandType);
        formData.append('domain', editBrandDomain);
        formData.append('url', null);
        // formData.append('alias', editAlias);
        // formData.append('sowLink', editSOWLink);
        // formData.append('msaLink', editMSALink);
        // formData.append('contractTerms', editContractTerms);
        // formData.append('directorId', editDirectorID);
        // formData.append('managerId', editManagerID);
        // formData.append('associateId1', editAssociateID1);
        // formData.append('associateId2', editAssociateID2);
        // formData.append('opsId', editOpsID);
        if (editAlias !== null) {
          formData.append('alias', editAlias);
        }
        if (editSOWLink !== null) {
          formData.append('sowLink', editSOWLink);
        }
        if (editMSALink !== null) {
          formData.append('msaLink', editMSALink);
        }
        if (editContractTerms !== null) {
          formData.append('contractTerms', editContractTerms);
        }
        if (editDirectorID !== null) {
          formData.append('directorId', editDirectorID);
        }
        if (editManagerID !== null) {
          formData.append('managerId', editManagerID);
        }
        if (editAssociateID1 !== null) {
          formData.append('associateId1', editAssociateID1);
        }
        if (editAssociateID2 !== null) {
          formData.append('associateId2', editAssociateID2);
        }
        if (editOpsID !== null) {
          formData.append('opsId', editOpsID);
        }

        // formData.append(
        //   'dateJoined',
        //   moment(editDateJoined).format('YYYY-MM-DD HH:mm:ss')
        // );
        if (editDateJoined !== null) {
          formData.append(
            'dateJoined',
            moment(editDateJoined).format('YYYY-MM-DD HH:mm:ss')
          );
        }

        let filteredFormData = new FormData();
        for (const [key, value] of formData.entries()) {
          if (value) {
            filteredFormData.append(key, value);
          }
        }
        console.log(filteredFormData, 'filteredFormData');
        console.log(editBrandData, 'editBrandData.id');
        apiClient
          .put(
            `/brand/v1/admin/brand/update/${editBrandData.id}`,
            filteredFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          // axios
          //   .put(
          //     `http://localhost:4004/v1/admin/brand/update/${editBrandData.id}`,
          //     filteredFormData,
          //     {
          //       headers: {
          //         'Content-Type': 'multipart/form-data',
          //       },
          //     }
          //   )
          .then(async (res) => {
            const desc = {
              message: `Updated ${changeVar.toString()} for brand ${editBrandName}!`,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };

            await actionLogTrack(
              JSON.stringify(desc),
              false,
              super_token?.token
            );
            console.log('formData:', formData);
            Toastify('success', 'Brand updated!');
            setHideEditBrandProgressDialog(true);
            setShowEditBrandDialog(false);
            setShowAdditionalFields(false);
            setEditBrandImage();
            setEditBrandName();
            setEditBrandDate();
            setEditBrandVertical([]);
            setEditBrandType();
            setEditBrandDomain();
            setEditBrandData();
            setEditAlias();
            setEditSOWLink();
            setEditMSALink();
            setEditContractTerms();
            setEditDirectorID();
            setEditManagerID();
            setEditAssociateID1();
            setEditAssociateID2();
            setEditOpsID();
            setEditDateJoined();
            getBrands();
            // if (searchedBrands.length === 0) {
            //   getBrands();
            // }
          })
          .catch(async (error) => {
            console.error(error);
            console.log('api1');
            if (error.response) {
              Toastify("error", error.response.data.message);
            } else {
              Toastify("error", "Somthing went wrong !!");
            }
            await actionLogTrack(
              JSON.stringify(error),
              true,
              super_token?.token
            );
          });
      } else {
        let formData = new FormData();
        formData.append("admin_id", admin_auth.id);
        formData.append("id", editBrandData.id);
        formData.append("brand_vertical", editBrandVertical);
        formData.append("brand_vertical_change", editBrandVerticalChange);
        formData.append("brand_domain", editBrandDomain);
        formData.append("brand_domain_change", editBrandDomainChange);
        formData.append("brand_image_change", 0);
        formData.append("brand_image_file", null);

        axios
          .post(
            `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/brand/update`,
            formData,
            {
              headers: {
                Authorization: `JWT ${admin_auth?.token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (res) => {
            const desc = {
              message: `Updated ${changeVar.toString()} for brand ${editBrandName}!`,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };
            await actionLogTrack(
              JSON.stringify(desc),
              false,
              super_token?.token
            );

            Toastify("success", "Brand updated");
            setHideEditBrandProgressDialog(true);
            setShowEditBrandDialog(false);
            setEditBrandImage();
            setEditBrandName();
            setEditBrandDate();
            setEditBrandVertical([]);
            setEditBrandType();
            setEditBrandDomain();
            setEditBrandData();
            setEditAlias();
            getBrands();
            // if (searchedBrands.length === 0) {
            //   getBrands();
            // }
          })
          .catch(async (error) => {
            console.error(error);
            if (error.response) {
              Toastify("error", error.response.data.message);
            } else {
              Toastify("error", "Somthing went wrong !!");
            }
            await actionLogTrack(
              JSON.stringify(error),
              true,
              super_token?.token
            );
          });
      }
    } else {
      const changeVar = changeDetect();
      console.log(changeVar, "changevar");

      setHideEditBrandProgressDialog(false);

      if (superAdmin) {
        let formData = new FormData();
        formData.append("brand", editBrandName);
        formData.append(
          "launch",
          moment(editBrandDate).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append('vertical', editBrandVertical);
        formData.append('type', editBrandType);
        formData.append('domain', editBrandDomain);
        formData.append('url', editBrandImageRaw);
        // formData.append('sowLink', editSOWLink)
        // formData.append('msaLink', editMSALink)
        // formData.append('contractTerms', editContractTerms)
        // formData.append('directorId', editDirectorID)
        // formData.append('managerId', editManagerID)
        // formData.append('associateId1', editAssociateID1)
        // formData.append('associateId2', editAssociateID2)
        // formData.append('opsId', editOpsID);
        if (editSOWLink !== null) {
          formData.append('sowLink', editSOWLink);
        }
        if (editMSALink !== null) {
          formData.append('msaLink', editMSALink);
        }
        if (editContractTerms !== null) {
          formData.append('contractTerms', editContractTerms);
        }
        if (editDirectorID !== null) {
          formData.append('directorId', editDirectorID);
        }
        if (editManagerID !== null) {
          formData.append('managerId', editManagerID);
        }
        if (editAssociateID1 !== null) {
          formData.append('associateId1', editAssociateID1);
        }
        if (editAssociateID2 !== null) {
          formData.append('associateId2', editAssociateID2);
        }
        if (editOpsID !== null) {
          formData.append('opsId', editOpsID);
        }
        // formData.append(
        //   'dateJoined',
        //   moment(editDateJoined).format('YYYY-MM-DD')
        // );

        console.log(editDateJoined, 'editDateJoined');
        if (editDateJoined !== null) {
          formData.append(
            'date_joined',
            moment(editDateJoined).format('YYYY-MM-DD HH:mm:ss')
          );
        }
        // let filteredData = formData.filter(e => e);

        let filteredFormData = new FormData();
        for (const [key, value] of formData.entries()) {
          if (value) {
            filteredFormData.append(key, value);
          }
        }

        apiClient
          .put(
            `/brand/v1/admin/brand/update/${editBrandData.id}`,
            filteredFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          // axios
          //   .put(
          //     `http://localhost:4004/v1/admin/brand/update/${editBrandData.id}`,
          //     filteredFormData,
          //     {
          //       headers: {
          //         'Content-Type': 'multipart/form-data',
          //       },
          //     }
          //   )
          .then(async (res) => {
            const desc = {
              message: `Updated ${changeVar.toString()} for brand ${editBrandName}!`,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };

            await actionLogTrack(
              JSON.stringify(desc),
              false,
              super_token?.token
            );
            console.log('formData:', formData);
            Toastify('success', 'Brand updated');
            setHideEditBrandProgressDialog(true);
            setShowEditBrandDialog(false);
            setShowAdditionalFields(false);
            setEditBrandImageRaw(null);
            setEditBrandImage();
            setEditBrandName();
            setEditBrandDate();
            setEditBrandVertical([]);
            setEditBrandType();
            setEditBrandDomain();

            setEditSOWLink();
            setEditMSALink();
            setEditContractTerms();
            setEditDirectorID();
            setEditManagerID();
            setEditAssociateID1();
            setEditAssociateID2();
            setEditOpsID();
            setEditDateJoined();
            setEditBrandData();
            getBrands();
            // if (searchedBrands.length === 0) {
            //   getBrands();
            // }
          })
          .catch(async (error) => {
            console.error(error);
            console.log('api2');
            if (error.response) {
              Toastify("error", error.response.data.message);
            } else {
              Toastify("error", "Somthing went wrong !!");
            }
            await actionLogTrack(
              JSON.stringify(error),
              true,
              super_token?.token
            );
          });
      } else {
        let formData = new FormData();
        formData.append("admin_id", admin_auth.id);
        formData.append("id", editBrandData.id);
        formData.append("brand_vertical", editBrandVertical);
        formData.append("brand_vertical_change", editBrandVerticalChange);
        formData.append("brand_domain", editBrandDomain);
        formData.append("brand_domain_change", editBrandDomainChange);
        formData.append("brand_image_change", editBrandImageChange);
        formData.append("brand_image_file", editBrandImageRaw);

        axios
          .post(
            `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/brand/update`,
            formData,
            {
              headers: {
                Authorization: `JWT ${admin_auth?.token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (res) => {
            const desc = {
              message: `Updated ${changeVar.toString()} for brand ${editBrandName}!`,
              status: res.status,
              method: res.config.method,
              url: res.config.url,
            };
            await actionLogTrack(
              JSON.stringify(desc),
              false,
              super_token?.token
            );
            Toastify("success", "Brand updated");
            setHideEditBrandProgressDialog(true);
            setShowEditBrandDialog(false);
            setEditBrandImage();
            setEditBrandName();
            setEditBrandDate();
            setEditBrandVertical([]);
            setEditBrandType();
            setEditBrandDomain();
            setEditBrandData();
            getBrands();
            // if (searchedBrands.length === 0) {
            //   getBrands();
            // }
          })
          .catch(async (error) => {
            console.error(error);
            if (error.response) {
              Toastify("error", error.response.data.message);
            } else {
              Toastify("error", "Somthing went wrong !!");
            }
            await actionLogTrack(
              JSON.stringify(error),
              true,
              super_token?.token
            );
          });
      }
    }
  };

  // Search result
  const searchResults = (query) => {
    setSearchQuery(query);
    let results = [];

    for (let i = 0; i < brandsBackup.length; i++) {
      if (superAdmin) {
        if (
          brandsBackup[i]["brand"]
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          brandsBackup[i]["vertical"]
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          brandsBackup[i]["type"].toLowerCase().includes(query.toLowerCase()) ||
          brandsBackup[i]["domain"].toLowerCase().includes(query.toLowerCase())
        ) {
          results.push(brandsBackup[i]);
        }
      } else {
        if (
          brandsBackup[i]["name"].toLowerCase().includes(query.toLowerCase()) ||
          brandsBackup[i]["vertical"]
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          brandsBackup[i]["domain"].toLowerCase().includes(query.toLowerCase())
        ) {
          results.push(brandsBackup[i]);
        }
      }
    }
    let resultID = [];
    results.forEach((item) => {
      resultID.push(item.id);
    });
    setSearchedBrands(resultID);
    if (!query.trim()) {
      console.log('empty');
    }
    setBrands(results);
  };

  const searchResultsUsers = (query) => {
    let userSearchResult = [];
    console.log(allUsers, 'allUsers');

    for (let i = 0; i < allUsers.length; i++) {
      if (allUsers[i]['name'].toLowerCase().includes(query.toLowerCase())) {
        userSearchResult.push(allUsers[i]);
      }
    }

    setUsersSearched(userSearchResult);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));
  const searchUserOptimized = useCallback(debounce(searchResultsUsers, 600));
  const admin_data = JSON.parse(localStorage.getItem('admin_auth'));
  const additionalFieldsRef = useRef(null);
  const dialogContentRef = useRef(null);

  const handleSwitchClick = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  useEffect(() => {
    if (additionalFieldsRef.current && showAdditionalFields) {
      additionalFieldsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [showAdditionalFields]);

  const getAllUsers = () => {
    const res = apiClient
      .get(`/user/v1/admin/all`)
      .then((response) => {
        console.log(response.data, 'response.data');
        setAllUsers(response.data.response);
        setLoadList(false);
      })
      .catch((err) => {
        Toastify('error', err.response.data.message);
        console.error(err);
      });
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    console.log('editManagerID:', editManagerID);
  }, [editManagerID]);

  return (
    <div className="brands">
      {/* Add brand Dialog */}
      <Dialog disableEscapeKeyDown open={showAddBrandDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Add Brand
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div
                style={{
                  height: "200px",
                  width: "500px",
                  marginBottom: "25px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => inputFile.current.click()}
              >
                {addBrandImage === null ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlined />
                    <p>Upload Image</p>
                    <input
                      type="file"
                      ref={inputFile}
                      style={{
                        display: "none",
                      }}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          setAddBrandImageRaw(event.target.files[0]);
                          setAddBrandImage(
                            URL.createObjectURL(event.target.files[0])
                          );
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: "200px",
                      width: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src={addBrandImage}
                      alt="Brand"
                      height="200"
                    />
                  </div>
                )}
              </div>
              <TextField
                required
                size="small"
                label="Name"
                helperText="Eg. - LT Partners"
                value={addBrandName}
                onChange={(event) => setAddBrandName(event.target.value)}
              />
              <TextField
                size="small"
                label="Alias"
                helperText="Eg. - lift@lt.partners"
                value={addAlias}
                onChange={(event) => setAddAlias(event.target.value)}
              />
              <div className="lauch-date-style">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    sx={{
                      "& .css-1x5jdmq": { padding: "9px" },
                    }}
                    label="Launch Date"
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        helperText="Program Start Date"
                        {...params}
                      />
                    )}
                    value={addBrandDate}
                    onChange={(event) => {
                      console.log(event, "event");
                      setAddBrandDate(event);
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* <TextField
                required
                size="small"
                label="Vertical"
                helperText="Eg. - Marketing"
                value={addBrandVertical}
                onChange={(event) => setAddBrandVertical(event.target.value)}
              /> */}

              <MultipleSelectChip
                label="Vertical"
                helperText="Eg. - Fashion, Beauty"
                addBrandVertical={addBrandVertical}
                onChange={(vertical) => {
                  let verticals = addBrandVertical;
                  if (!verticals.includes(vertical)) {
                    //checking weather array contain the id
                    verticals.push(vertical); //adding to array because value doesnt exists
                  } else {
                    verticals.splice(verticals.indexOf(vertical), 1); //deleting
                  }

                  // verticals
                  console.log(addBrandVertical, "addBrandVertical");
                  setAddBrandVertical(verticals);
                }}
              />

              <TextField
                select
                label="Type"
                size="small"
                value={addBrandType}
                onChange={(event) => setAddBrandType(event.target.value)}
                helperText="Program Type"
              >
                {console.log(types, "types")}
                {types.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                size="small"
                label="Domain"
                helperText="Eg. - lt.partners"
                value={addBrandDomain}
                onChange={(event) => setAddBrandDomain(event.target.value)}
              />
              <div>Make sure the brand name matches with Affluent!</div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (hideAddBrandProgressDialog) {
                addBrand();
              }
            }}
          >
            <div>
              {hideAddBrandProgressDialog ? (
                <p>Add</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => resetAddBrand()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Brand Dialog */}
      <Dialog
        disableEscapeKeyDown
        open={showEditBrandDialog}
        ref={dialogContentRef}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Edit Brand
        </DialogTitle>
        <DialogContent ref={dialogContentRef}>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div
                style={{
                  height: "200px",
                  width: "500px",
                  marginBottom: "25px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => editInputFile.current.click()}
              >
                <div
                  style={{
                    height: "200px",
                    width: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={editBrandImage}
                    alt="Brand"
                    height="200"
                  />
                  <input
                    type="file"
                    ref={editInputFile}
                    style={{
                      display: "none",
                    }}
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        if (!superAdmin) {
                          setEditBrandImageChange(1);
                        }
                        setEditBrandImageRaw(event.target.files[0]);
                        setEditBrandImage(
                          URL.createObjectURL(event.target.files[0])
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <TextField
                disabled={!superAdmin ? true : false}
                required
                size="small"
                label="Name"
                helperText="Eg. - LT Partners"
                value={editBrandName}
                onChange={(event) => setEditBrandName(event.target.value)}
              />
              <TextField
                disabled={!superAdmin ? true : false}
                size="small"
                label="Alias"
                helperText="Eg. - lift@lt.partners"
                value={editAlias}
                onChange={(event) => setEditAlias(event.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  sx={{
                    "& .css-1x5jdmq": { padding: "9px" },
                  }}
                  label="Launch Date"
                  inputFormat="MM/dd/yyyy"
                  // disabled={!superAdmin ? true : false}
                  renderInput={(params) => (
                    <TextField
                      required
                      size="small"
                      helperText="Program Start Date"
                      {...params}
                    />
                  )}
                  value={new Date(editBrandDate)}
                  onChange={(event) => {
                    setEditBrandDate(event);
                    // console.log(event)
                    setEditDateJoined(event);
                  }}
                />
              </LocalizationProvider>

              <MultipleSelectChip
                label="Vertical"
                helperText="Eg. - Fashion, Beauty"
                addBrandVertical={editBrandVertical}
                onChange={(vertical) => {
                  let verticals = editBrandVertical;
                  if (!verticals.includes(vertical)) {
                    //checking weather array contain the id
                    verticals.push(vertical); //adding to array because value doesnt exists
                  } else {
                    verticals.splice(verticals.indexOf(vertical), 1); //deleting
                  }

                  // verticals

                  setEditBrandVertical(verticals);
                  setEditBrandVerticalChange(1);
                  // setEditBrandChange({
                  //   ...editBrandChange,
                  //   brand_vertical_change: 1,
                  // });
                }}
              />

              {superAdmin ? (
                <TextField
                  select
                  label="Type"
                  size="small"
                  value={editBrandType}
                  onChange={(event) => setEditBrandType(event.target.value)}
                  helperText="Program Type"
                >
                  {types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <></>
              )}
              <TextField
                required
                size="small"
                label="Domain"
                helperText="Eg. - lt.partners"
                value={editBrandDomain}
                onChange={(event) => {
                  if (!superAdmin) {
                    setEditBrandDomainChange(1);
                  }
                  setEditBrandDomain(event.target.value);
                }}
              />
            </ThemeProvider>
          </Box>

          {/* Additional Fields */}
          <ThemeProvider theme={theme}>
            <FormGroup style={{ marginRight: '8.5rem' }}>
              <FormControlLabel
                control={<Switch onClick={handleSwitchClick} />}
                label="Additional Brand Details"
              />
            </FormGroup>
          </ThemeProvider>
          {showAdditionalFields && (
            <Box
              ref={additionalFieldsRef}
              component="form"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.9rem',
                width: '510px',
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: '1.2rem',
                    fontWeight: '700',
                  }}>
                  Additional Brand Details
                </p>
                <div>
                  <TextField
                    size="small"
                    label="SOW Link"
                    helperText="SOW Link"
                    style={{
                      margin: '8px',
                      width: '25ch',
                    }}
                    value={editSOWLink ? editSOWLink : ''}
                    onChange={(event) => setEditSOWLink(event.target.value)}
                  />
                  <TextField
                    size="small"
                    label="MSA Link"
                    helperText="MSA Link"
                    style={{
                      margin: '8px',
                      width: '25ch',
                    }}
                    value={editMSALink ? editMSALink : ''}
                    onChange={(event) => setEditMSALink(event.target.value)}
                  />
                  <TextField
                    size="small"
                    className="contract-terms-field"
                    multiline
                    rows={3}
                    label="Contract Terms"
                    helperText="Contract Terms"
                    value={editContractTerms ? editContractTerms : ''}
                    style={{
                      margin: '8px',
                      // width: '100%'
                      width: '30.8rem',
                    }}
                    onChange={(event) =>
                      setEditContractTerms(event.target.value)
                    }
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}>
                    <Autocomplete
                      options={allUsers}
                      getOptionLabel={(option) => option.name}
                      value={
                        allUsers.find((user) => user.id === editDirectorID) ||
                        null
                      }
                      onChange={(event, newValue) => {
                        setEditDirectorID(newValue ? newValue.id : '');
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <img src={option.image} className="userImage" />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Director"
                          size="small"
                          value={editDirectorID ? editDirectorID : ''}
                          onChange={(event) =>
                            setEditDirectorID(event.target.value)
                          }
                          helperText="Director"
                          // style={{
                          //   margin: '8px',
                          //   width: '25ch',
                          // }}
                        />
                      )}
                    />

                    <Autocomplete
                      options={allUsers}
                      getOptionLabel={(option) => option.name}
                      value={
                        allUsers.find((user) => user.id === editManagerID) ||
                        null
                      }
                      onChange={(event, newValue) => {
                        setEditManagerID(newValue ? newValue.id : '');
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <img src={option.image} className="userImage" />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Manager"
                          size="small"
                          value={editManagerID ? editManagerID : ''}
                          onChange={(event) =>
                            setEditManagerID(event.target.value)
                          }
                          helperText="Manager"
                          style={{
                            margin: '8px',
                            width: '25ch',
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      options={allUsers}
                      getOptionLabel={(option) => option.name}
                      value={
                        allUsers.find((user) => user.id === editAssociateID1) ||
                        null
                      }
                      onChange={(event, newValue) => {
                        setEditAssociateID1(newValue ? newValue.id : '');
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <img src={option.image} className="userImage" />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Associate 1"
                          size="small"
                          value={editAssociateID1 ? editAssociateID1 : ''}
                          onChange={(event) =>
                            setEditAssociateID1(event.target.value)
                          }
                          helperText="Associate 1"
                          style={{
                            margin: '8px',
                            width: '25ch',
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      options={allUsers}
                      getOptionLabel={(option) => option.name}
                      value={
                        allUsers.find((user) => user.id === editAssociateID2) ||
                        null
                      }
                      onChange={(event, newValue) => {
                        setEditAssociateID2(newValue ? newValue.id : '');
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <img src={option.image} className="userImage" />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Associate 2"
                          size="small"
                          value={editAssociateID2 ? editAssociateID2 : ''}
                          onChange={(event) =>
                            setEditAssociateID2(event.target.value)
                          }
                          helperText="Associate 2"
                          style={{
                            margin: '8px',
                            width: '25ch',
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      options={allUsers}
                      getOptionLabel={(option) => option.name}
                      value={
                        allUsers.find((user) => user.id === editOpsID) || null
                      }
                      onChange={(event, newValue) => {
                        setEditOpsID(newValue ? newValue.id : '');
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <img src={option.image} className="userImage" />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root': { height: '3.5rem' },
                          }}
                          {...params}
                          label="Operations Manager"
                          size="small"
                          value={editOpsID ? editOpsID : ''}
                          onChange={(event) => setEditOpsID(event.target.value)}
                          helperText="Operations Manager"
                          style={{
                            margin: '8px',
                            width: '25ch',
                          }}
                        />
                      )}
                    />

                    {/* </div> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        sx={{
                          '& .css-1x5jdmq': { padding: '9px' },
                        }}
                        label="Date Joined"
                        inputFormat="yyyy/MM/dd"
                        // disabled={!superAdmin ? true : false}

                        renderInput={(params) => (
                          <TextField
                            required
                            size="small"
                            helperText="Date Joined"
                            {...params}
                            // style={{
                            //   height: "1rem",
                            //   width: "13.7rem"
                            // }}
                          />
                        )}
                        value={new Date(editDateJoined)}
                        // value={editDateJoined}
                        onChange={(event) => {
                          setEditDateJoined(event);
                          console.log(editDateJoined, 'editDateJoined');
                        }}
                        // onChange={(newDate) => {
                        //   const formattedDate = newDate.toISOString(); // Convert to ISO string format
                        //   setEditDateJoined(formattedDate);
                        //   console.log(formattedDate);
                        // }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </ThemeProvider>
            </Box>
          )}
          <ThemeProvider theme={theme}>
            <div style={{ marginLeft: '1rem' }}>
              Make sure the brand name matches with Affluent!
            </div>
          </ThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (hideEditBrandProgressDialog) {
                updateBrand();
              }
            }}
          >
            <div>
              {hideEditBrandProgressDialog ? (
                <p>Update</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              setShowEditBrandDialog(false);
              setShowAdditionalFields(false);
              setHideEditBrandProgressDialog(true);
            }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div className="head">
        <div className="head-txt">Brands</div>
        <Refresh className="head-refresh" onClick={throttleGetBrands} />
        {superAdmin ? (
          <Button
            className="add-brand-btn"
            onClick={() => setShowAddBrandDialog(true)}
          >
            <div className="add-brand-content">
              <Add className="add-icon" />
              <p className="add-text">Add Brand</p>
            </div>
          </Button>
        ) : (
          <></>
        )}
        {admin_data ? (
          <div className="btn-control-link">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Button className="add-user-btn">
                <a
                  rel="noreferrer"
                  href={`https://app.lift.lt.partners/testload?_id=${
                    admin_data.user
                  }&token=${admin_data?.token}&onboard=${1}`}
                  target={"_blank"}
                >
                  <div
                    style={{
                      backgroundColor: "#a60071",
                      color: "white",
                      padding: "0px 30px",
                      borderRadius: "5px",
                    }}
                    className="add-user-content"
                  >
                    {/* <Add className="add-icon" /> */}
                    <p
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        fontSize: "0.7rem",
                      }}
                      className="add-text"
                    >
                      Go to LIFT
                    </p>
                  </div>
                </a>
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="secondary-div">
        <div className="secondary-content">
          {!searchQuery.trim() ? (
            <p>
              <span className="selected-text">{brands.length} Brands </span>
              {/* <span>( Brand name should be the same as in Affluent! )</span> */}
            </p>
          ) : (
            <p>
              <span className="selected-text">
                {brandsBackup.length} Brands{' '}
              </span>
              <span style={{ margin: '0px 27px' }} className="selected-text">
                {brands.length} Results{' '}
              </span>
              {/* <span>( Brand name should be the same as in Affluent! )</span> */}
            </p>
          )}
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              // console.log('seaching');
              // Toastify('default', "Searching")
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: "calc(100% - 60px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Brand
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Alias
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Launch Date
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Vertical
                </TableCell>
                {superAdmin ? (
                  <TableCell
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      color: "grey",
                      // paddingLeft: "50px",
                    }}
                  >
                    Type
                  </TableCell>
                ) : (
                  <></>
                )}
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Domain
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brands
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}
                      >
                        <img
                          alt="brand-img"
                          className="avtar"
                          src={superAdmin ? row.image_url : row.image}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            height: "60px",
                            width: "120px",
                            objectFit: "contain",
                            borderRadius: "5px",
                            marginRight: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(`https://${row.domain}`);
                          }}
                        />
                        {/* <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {row.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "500",
                              color: "rgb(166, 0, 113)",
                              fontSize: "0.8rem",
                            }}
                          >
                            {row.brand.length > 10
                              ? row.brand.substr(0, 9) + "..."
                              : row.brand}
                          </div>
                        </div> */}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {superAdmin ? row.brand : row.name}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {superAdmin
                              ? row.alias && row.alias.length > 0
                                ? row.alias
                                : "Not Set"
                              : row.name}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {formatDate(
                              superAdmin ? row.launch : row.created_at
                            )}
                          </p>
                        </div>
                      </TableCell>
                      {/* <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip
                          title={`${
                            row.vertical.split != null
                              ? (row.vertical.split(",").length > 3
                                ? row.vertical
                                : "")
                              : ''
                          }`}
                          placement="top"
                        >
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.vertical.split(",").length > 3
                              ? `${row.vertical.split(",")[0]}, ${
                                  row.vertical.split(",")[1]
                                } & more`
                              : row.vertical.split(",").length > 2
                              ? `${row.vertical.split(",")[0]},${
                                  row.vertical.split(",")[1]
                                } & ${row.vertical.split(",")[2]}`
                              : row.vertical.split(",").length > 1
                              ? `${row.vertical.split(",")[0]} & ${
                                  row.vertical.split(",")[1]
                                }`
                              : row.vertical}
                          </p>
                        </Tooltip>
                      </TableCell> */}
                      {superAdmin ? (
                        <TableCell
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            fontWeight: "500",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                                marginRight: "5px",
                              }}
                            >
                              {row.type}
                            </p>
                          </div>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(`https://${row.domain}`);
                            }}
                          >
                            {row.domain}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <Tooltip title="Visit">
                          <TurnSlightRightOutlined
                            style={{
                              cursor: "pointer",
                              color: "rgb(36, 169, 181)",
                              backgroundColor: "rgba(36, 169, 181, 0.3)",
                              border: "1px solid rgb(36, 169, 181)",
                              padding: "3px",
                              fontSize: "15px",
                              marginRight: "15px",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              window.open(`https://${row.domain}`);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Edit">
                          <Edit
                            style={{
                              cursor: "pointer",
                              color: "rgb(252, 198, 3)",
                              backgroundColor: "rgba(252, 227, 3, 0.3)",
                              border: "1px solid rgb(252, 198, 3)",
                              padding: "3px",
                              fontSize: "15px",
                              marginRight: "15px",
                              borderRadius: "5px",
                            }}
                            onClick={() => editBrand(row)}
                          />
                        </Tooltip>
                        {superAdmin ? (
                          <Tooltip title="Delete">
                            <DeleteOutline
                              style={{
                                cursor: "pointer",
                                color: "rgb(252, 3, 3)",
                                backgroundColor: "rgba(252, 3, 3, 0.3)",
                                border: "1px solid rgb(252, 3, 3)",
                                padding: "3px",
                                fontSize: "15px",
                                marginRight: "15px",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                throttleDeleteBrand(
                                  row.id,
                                  superAdmin ? row.brand : row.name
                                )
                              }
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={brands.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: "10px",
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default Brands;
