import { useCallback, useState } from "react";

// Custom hook for throttling a function
export function useThrottledFn(fn, delay) {
  const [throttleTimeout, setThrottleTimeout] = useState(null);

  const throttledFn = useCallback(
    (...args) => {
      if (!throttleTimeout) {
        // Call the original function with the arguments
        fn(...args);

        // Set the throttle timeout to prevent rapid clicks
        setThrottleTimeout(
          setTimeout(() => {
            setThrottleTimeout(null);
          }, delay)
        );
      }
    },
    [fn, throttleTimeout, delay]
  );

  return throttledFn;
}
