import axios from "axios";

const AxiosDev = (token) => {
  return axios.create({
    baseURL: "https://ltpautomatedpublisherscorecard.uc.r.appspot.com",
    withCredentials: token ? false : true,
    headers: {
      Authorization: token ? `JWT ${token}` : "",
    },
  });
};

export default AxiosDev;
