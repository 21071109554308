import { Button, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./share-a-sale.styles.scss";
import { useState } from "react";
import { Toastify } from "../../../../App";
const ShareASale = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    api_token: "",
    api_secrect: "",
    merchent_id: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.api_token.length === 0 || details.api_token === "") {
      return "API Token required !!";
    } else if (details.api_secrect.length === 0 || details.api_secrect === "") {
      return "API Secrect required !!";
    } else if (details.merchent_id.length === 0 || details.merchent_id === "") {
      return "Merchent ID required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="share-a-sale-container">
        {" "}
        <TextField
          required
          size="small"
          label="API Token"
          helperText="Auth Token"
          style={{
            margin: "10px 0",
          }}
          name="api_token"
          value={details.api_token}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="API Secret"
          type="password"
          helperText="Auth Token"
          style={{
            margin: "10px 0",
          }}
          name="api_secrect"
          value={details.api_secrect}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Merchant Id"
          helperText="Eg. - LT Partners"
          style={{
            margin: "10px 0",
          }}
          name="merchent_id"
          value={details.merchent_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          helperText="Eg. - LT Partners"
          style={{
            margin: "10px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }

          Connect({ ...details, net: "share-a-sale" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default ShareASale;
