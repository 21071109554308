import classes from "./navItem.module.scss";

const NavItem1 = (props) => {
  return (
    <>
      <div className={`${classes.container}`}>
        <button>
          <span>{props.text}</span>
        </button>
      </div>
    </>
  );
};

export default NavItem1;
