import { Button, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./admitad.styles.scss";
import { useState } from "react";
import { Toastify } from "../../../../App";
const Admitad = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    client_id: "",
    api_secrect: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.client_id.length === 0 || details.client_id === "") {
      return " Client ID  required !!";
    } else if (details.api_secrect.length === 0 || details.api_secrect === "") {
      return "API Secrect required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="admitad-container">
        {" "}
        <TextField
          required
          size="small"
          label="Client ID"
          style={{
            margin: "10px 0",
          }}
          name="client_id"
          value={details.client_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="API Secrect"
          style={{
            margin: "10px 0",
          }}
          type="password"
          name="api_secrect"
          value={details.api_secrect}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        style={{
          width: "120px",
        }}
        variant="contained"
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "admitad" });
        }}
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default Admitad;
