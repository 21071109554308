import { Button, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";

import "./tune.styles.scss";

import { useState } from "react";
import { money } from "../../const/currency";
import { Toastify } from "../../../../App";
const Tune = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    api_key: "",
    network_id: "",
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };
  const currency = money;
  const checkPass = () => {
    if (details.api_key.length === 0 || details.api_key === "") {
      return "API Key required !!";
    } else if (details.network_id.length === 0 || details.network_id === "") {
      return "Network ID required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="tune-container">
        {" "}
        <TextField
          required
          size="small"
          label="API key"
          style={{
            margin: "10px 0",
          }}
          name="api_key"
          value={details.api_key}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Network ID"
          style={{
            margin: "10px 0",
          }}
          name="network_id"
          value={details.network_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "10px 0",
          }}
        >
          <MenuItem>Please select a currency..</MenuItem>
          {currency.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: t.option,
                    },
                  });
                }}
                value={i + 1}
              >
                {t.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="nick_name "
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "tune" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default Tune;
