import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  TextField,
  MenuItem,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import DatePicker from "./DatePicker";
import { Refresh, Add, Search, DeleteOutline } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";

import "./coupons.styles.scss";
import { Toastify } from "../../App";
import TableSkeleton from "../skeleton-loader/table";
import dayjs from "dayjs";
import { debounce } from "../../utils/debounce";
import { useThrottledFn } from "../../utils/throttle";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const Coupons = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [showaddCouponDialog, setShowaddCouponDialog] = useState(false);
  // add
  const [couponInfo, setCouponInfo] = useState({
    coupon: "",
    name: "",
    percentOff: 0,
    duration: 1,
    durationInMonths: 0,
    // hasMaxRedemptions: 0,
    maxRedemptions: 0,
    // hasRedeemBy: 1,
    redeemBy: null,
    date: null,
  });

  const handleAddChange = (e) => {
    if (e?.target === undefined || e?.target === null) {
      const m = e.$M + 1 < 10 ? `0${e.$M + 1}` : e.$M + 1;
      let str1 = `${e.$y}-${m}-${e.$D}`;
      let str2 = `${e.$D}/${m}/${e.$y}`;

      console.log(dayjs(str1));
      const change = {
        ...couponInfo,
        redeemBy: dayjs(str1),
        date: str2,
      };
      setCouponInfo(change);
      return;
    }
    const change = {
      ...couponInfo,
      [e.target.name]: e.target.value,
    };

    setCouponInfo(change);
  };
  const [hideaddCouponProgressDialog, setHideaddCouponProgressDialog] =
    useState(true);

  // const [value, setValue] = React.useState(dayjs("2022-04-17"));
  // add

  const [searchText, setSearchText] = useState("");

  //super_token
  const super_token = JSON.parse(localStorage.getItem("super_token"));

  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current && super_token?.token) {
      get_ref.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadList, setLoadList] = useState(true);

  // Add brand to db
  const addCoupon = async () => {
    console.log(couponInfo.percentOff <= 100 && couponInfo.percentOff >= 5);
    if (
      typeof couponInfo.name === "undefined" ||
      couponInfo.name === null ||
      couponInfo.name === ""
    ) {
      Toastify("warning", "Name of coupon is required!");
    } else if (
      typeof couponInfo.coupon === "undefined" ||
      couponInfo.coupon === null ||
      couponInfo.coupon === ""
    ) {
      Toastify("warning", "Coupon is required!");
    } else if (
      typeof couponInfo.percentOff === "undefined" ||
      couponInfo.percentOff === null ||
      couponInfo.percentOff === 0
    ) {
      Toastify("warning", "Percentage offer is required!");
    } else if (!(couponInfo.percentOff <= 100 && couponInfo.percentOff >= 5)) {
      Toastify("warning", "Percentage offer must be in between 5% to 100%");
    } else {
      setHideaddCouponProgressDialog(false);
      try {
        // console.log(couponInfo.date, "couponInfo.date ");
        Toastify('info', couponInfo.date);
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/coupon/new`,
          {
            ...couponInfo,
            durationInMonths:
              couponInfo.duration === 2 ? couponInfo.durationInMonths : null,
            hasMaxRedemptions: couponInfo.maxRedemptions !== 0 ? 1 : 0,
            maxRedemptions:
              couponInfo.maxRedemptions !== 0
                ? couponInfo.maxRedemptions
                : null,
            hasRedeemBy: couponInfo.date != null ? 1 : 0,
            redeemBy: couponInfo.date != null ? couponInfo.date : null,
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        if (res.status === 200) {
          Toastify("success", "Coupon added !!");
          getCoupons();
          setHideaddCouponProgressDialog(true);
          setShowaddCouponDialog(false);
          setCouponInfo({
            coupon: "",
            name: "",
            percentOff: 0,
            duration: 1,
            durationInMonths: 0,
            hasMaxRedemptions: 0,
            maxRedemptions: 0,
            hasRedeemBy: 1,
            redeemBy: null,
            date: null,
          });
        } else {
          Toastify("error", "Something went wrong!!");
        }
      } catch (error) {
        Toastify('error', error.response.data.message);
        console.log(error);
      }
    }
  };

  // delete brand
  const deleteCoupons = (id) => {
    axios
      .delete(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/coupon/delete/${id}`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "delete brand");
        getCoupons();
        Toastify("success", "Coupon deleted");
      })
      .catch((err) => {
        Toastify('error', err.response.data.message);
        console.error(err)
      });
  };

  const throttleDeleteCoupons = useThrottledFn(deleteCoupons, 1000);
  //get coupons
  const [coupons, setCoupons] = useState([]);
  const [couponsBackup, setCouponsBackup] = useState([]);

  const getCoupons = async () => {
    try {
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/coupon/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );

      setLoadList(false);
      setCoupons(res.data.sort(customSortBasedOnDate).reverse());
      setCouponsBackup(res.data.sort(customSortBasedOnDate).reverse());
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  const customSortBasedOnDate = (a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };
  // Search result
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < couponsBackup.length; i++) {
      if (
        couponsBackup[i]["name"].toLowerCase().includes(query.toLowerCase()) ||
        couponsBackup[i]["coupon"].toLowerCase().includes(query.toLowerCase())
      )
        results.push(couponsBackup[i]);
    }

    setCoupons(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));
  useEffect(() => {
    getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const durations = [
    {
      type: 1,
      name: "Once",
    },
    { type: 2, name: "Repeating" },
    { type: 3, name: "Forever" },
  ];

  return (
    <div className="coupons-container">
      {/* Add brand Dialog */}
      <Dialog disableEscapeKeyDown open={showaddCouponDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Add Coupon
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div className="full-width-input">
                <TextField
                  style={{ width: "100%" }}
                  name="coupon"
                  required
                  size="small"
                  label="Coupon"
                  helperText="LTPCOUPON"
                  value={couponInfo.coupon}
                  onChange={(event) => handleAddChange(event)}
                />
                <TextField
                  required
                  style={{ width: "100%" }}
                  name="name"
                  size="small"
                  label="Name"
                  helperText="Eg. - LTP Coupon"
                  value={couponInfo.name}
                  onChange={(event) => handleAddChange(event)}
                />
                <TextField
                  style={{ width: "100%" }}
                  required
                  type="number"
                  size="small"
                  name="percentOff"
                  label="Offer %"
                  helperText="Eg. 20"
                  value={couponInfo.percentOff}
                  onChange={(event) => {
                    if (
                      event.target.value <= 99.99 &&
                      event.target.value >= 0
                    ) {
                      handleAddChange(event);
                    }
                  }}
                />
              </div>

              <TextField
                select
                size="small"
                label="Duration"
                name="duration"
                helperText="Eg. - LTP Coupon"
                value={couponInfo.duration}
                onChange={(event) => handleAddChange(event)}
              >
                {durations.map((duration) => (
                  <MenuItem key={duration.type} value={duration.type}>
                    {duration.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                type="number"
                size="small"
                disabled={couponInfo.duration !== 2}
                name="durationInMonths"
                label="Duration in month"
                helperText="Eg. - LTP Coupon"
                value={couponInfo.durationInMonths}
                onChange={(event) => handleAddChange(event)}
              />

              <TextField
                type="number"
                size="small"
                label="Max Redemption"
                helperText="Eg. - LTP Coupon"
                name="maxRedemptions"
                value={couponInfo.maxRedemptions}
                onChange={(event) => handleAddChange(event)}
              />

              <DatePicker
                setValue={handleAddChange}
                value={couponInfo.redeemBy}
                label={"Redeem By"}
              />
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (hideaddCouponProgressDialog) {
                addCoupon();
              }
            }}
          >
            <div>
              {hideaddCouponProgressDialog ? (
                <p>Add</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              setShowaddCouponDialog(false);
              setHideaddCouponProgressDialog(true);
              setCouponInfo({
                coupon: "",
                name: "",
                percentOff: 0,
                duration: 1,
                durationInMonths: 0,
                hasMaxRedemptions: 0,
                maxRedemptions: 0,
                hasRedeemBy: 1,
                redeemBy: null,
                date: null,
              });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div className="head">
        <div className="head-txt">Coupons</div>
        <Refresh className="head-refresh" />
        <Button
          className="add-brand-btn"
          onClick={() => {
            setShowaddCouponDialog(true);
          }}
        >
          <div className="add-brand-content">
            <Add className="add-icon" />
            <p className="add-text">Add Coupons</p>
          </div>
        </Button>
      </div>

      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">{coupons.length} Coupons </span>
          </p>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: "calc(100% - 60px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Coupons
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  % Offer
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Duration
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Redemption count
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Redeem By
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.coupon}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.name}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.percent_off}%
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.duration_in_months} Months
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {row.max_redemptions === null ||
                            row.max_redemptions === undefined ||
                            row.max_redemptions.length === 0
                              ? "Not Set"
                              : row.max_redemptions}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.redeem_by === null ||
                            row.redeem_by === undefined ||
                            row.redeem_by.length === 0
                              ? "Not Set"
                              : moment(row.redeem_by).format("MM/DD/YYYY")}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <Tooltip title="Delete">
                          <DeleteOutline
                            style={{
                              cursor: "pointer",
                              color: "rgb(252, 3, 3)",
                              backgroundColor: "rgba(252, 3, 3, 0.3)",
                              border: "1px solid rgb(252, 3, 3)",
                              padding: "3px",
                              fontSize: "15px",
                              marginRight: "15px",
                              borderRadius: "5px",
                            }}
                            onClick={() => throttleDeleteCoupons(row.id)}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={coupons.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: "10px",
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default Coupons;
