import {
  AdminPanelSettings,
  PersonOutline,
  ScaleOutlined,
  Search,
} from '@mui/icons-material';

import { useSelector } from 'react-redux';
import './overview.style.scss';
import ApiDashboard from '../logs-components/api-dashboard/api-dashboard';
import LogsTable from './_components/logs-table';
import { useState } from 'react';

const options = [
  // {
  //   container: "organization",
  //   option: "Organization",
  //   icon: <GridViewRoundedIcon />,
  // },

  {
    container: 'brands-up',
    option: 'Brands',
    icon: <ScaleOutlined />,
  },
  {
    container: 'br-wt-up',
    option: 'Brand Weights',

    icon: <ScaleOutlined />,
  },
  // {
  //   container: "admins",
  //   option: "Admins",
  //   icon: <AdminPanelSettings />,
  // },

  {
    container: 'admin-user',
    option: 'Users',
    icon: <PersonOutline />,
  },
  // {
  //   container: "admin-profile",
  //   option: "Profile",
  //   icon: <ManageAccountsOutlined />,
  // },
  // {
  //   container: "cms",
  //   option: "CMS",
  //   icon: <HistoryEdu />,
  // },
  {
    container: 'payments',
    option: 'Payments',
    icon: <AdminPanelSettings />,
  },
];
const   OverView = ({ setContainer }) => {
  const { superAdmin } = useSelector((state) => state.admin);
  const [toggleDash, setToggleDash] = useState(true);
  const [searchText, setSearchText] = useState('');

  return (
    <div className="container-overview">
      {superAdmin && (
        <div className="toggle-div-container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}>
            <div
              className={`toggle-div  ${toggleDash ? 'active-toggle' : ''}`}
              onClick={() => setToggleDash(true)}>
              <p
                style={{
                  fontSize: '14px',
                  padding: '0 5px',
                }}>
                Dashboard
              </p>
            </div>
            <div
              className={`toggle-div  ${!toggleDash ? 'active-toggle' : ''}`}
              onClick={() => setToggleDash(false)}>
              <p
                style={{
                  fontSize: '14px',
                  padding: '0 5px',
                }}>
                Activity Log
              </p>
            </div>
          </div>

          {!toggleDash && (
            <div className="input-container-search">
              <Search className="icon" />
              <input
                className="input-field"
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  // searchOptimized(event.target.value);
                }}
              />
            </div>
          )}
        </div>
      )}
      {toggleDash ? (
        <div className="inner-container">
          <div className="heading">
            <div className="head-title">
              <img
                alt="lift"
                src={process.env.PUBLIC_URL + '/assets/lift.png'}
              />
              <h1>Welcome to LIFT Admin Panel</h1>
            </div>
            <p>Partnership Performance Analytics SaaS Platform</p>
          </div>
          <div className="body">
            <h2>Quick Access</h2>
            <div
              className="quick-access"
              style={{
                justifyContent: 'center',
                gridTemplateColumns: superAdmin
                  ? '24% 24% 24% 24%'
                  : '24% 24% 24%',
              }}>
              {options.map((o, i) => {
                if (
                  !superAdmin &&
                  (o.container === 'organization' ||
                    o.container === 'admins' ||
                    o.container === 'cms' ||
                    o.container === 'payments')
                ) {
                  return <></>;
                } else if (superAdmin && o.container === 'admin-profile') {
                  return <></>;
                }
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setContainer(o.container);
                    }}
                    className="quick-grid">
                    <div className="content-box">
                      {o.icon}
                      <p>{o.option}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="inner-container"
          style={{
            height: '80vh',
          }}>
          <LogsTable searchText={searchText} />
        </div>
      )}
      <div className="footer">
        <div className="content"></div>
      </div>
    </div>
  );
};

export default OverView;
