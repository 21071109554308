import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBqKG7yTlIu9ct2s_k1b0H6qe3qfV4cf_E',
  authDomain: 'ltpautomatedpublisherscorecard.firebaseapp.com',
  projectId: 'ltpautomatedpublisherscorecard',
  storageBucket: 'ltpautomatedpublisherscorecard.appspot.com',
  messagingSenderId: '733755540125',
  appId: '1:733755540125:web:78050227021d6004330a74',
};

const app = initializeApp(firebaseConfig);
export const authFirebase = getAuth(app);
