import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/partials/footer';
import NavBar from '../../components/partials/NavBarComponents/NavBar';
import './preview.styles.scss';
import './preview.styles.scss';
import { Toastify } from '../../../../App';
import apiClient from '../../../../utils/axiosClient';

const ViewContent = () => {
  const [previewContent, setPreviewContent] = useState(null);
  const { subdomain, endpoint } = useParams();
  const navigate = useNavigate();
  const getContents = async () => {
    try {
      const super_token = JSON.parse(localStorage.getItem('super_token'));
      if (super_token?.token == null) {
        navigate('/404');
        return;
      }
      const page_id = `${endpoint}@&${subdomain}`;

      const res = await apiClient.get(
        `/outreach/v1/pages/${page_id}`
      );
      setPreviewContent(res.data);
      console.log(res, 'result');
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, 'errror');
    }
  };
  useEffect(() => {
    getContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function readingTime() {
    let text = '';
    for (let i = 0; i < previewContent?.blocks.length; i++) {
      text += previewContent?.blocks[i].text;
    }
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    return time;
  }

  return (
    <div className="preview-container">
      <NavBar />

      <div className="hero-section">
        <div className="background-image">
          <img alt="hero-img" src={previewContent?.hero} />
          <div className="overlay"></div>
          <div className="content">
            <h1>{previewContent?.title}</h1>
            <hr />
            <p className="para">{previewContent?.subtitle}</p>
            <p> Jan/ 1/ 23</p>
            <p>{readingTime()} min read</p>
          </div>
        </div>
      </div>
      <div className="content-section">
        {previewContent?.blocks?.map((block) => {
          console.log(block, 'block');
          return (
            <div
              className={`content-block ${
                block.type === 3
                  ? 'content-block-direction2'
                  : block.type === 4
                  ? 'content-block-direction1'
                  : 'content-block-normal'
              }`}>
              {block?.image ? (
                <div className="img-container">
                  <img alt="block-img" src={block?.image} />{' '}
                </div>
              ) : (
                <></>
              )}
              {block?.image ? <div style={{ width: '30px' }}></div> : <></>}
              {block?.text ? (
                <div
                  className="block"
                  dangerouslySetInnerHTML={{ __html: block?.text }}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default ViewContent;
