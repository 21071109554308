import React from 'react';

import './drawer-item.styles.scss';

const DrawerItem = ({
  name,
  clickEventName,
  icon,
  clickEvent,
  selected,
  forLogout,
}) => (
  <div
    className={selected === clickEventName ? 'selected' : 'item'}
    onClick={clickEvent}>
    <div className="brick"></div>
    <div className="img">{icon}</div>
    {!forLogout && <div className="spacer">{name}</div>}
  </div>
);

export default DrawerItem;
