import { Button, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./commision-factory.styles.scss";
import { money } from "../../const/currency";
import { useState } from "react";
import { Toastify } from "../../../../App";

const CommisionFactory = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    api_key: "",
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    if (e.target.name === "ncr_val" || e.target.name === "ncr_type") {
      console.log("click");
      setDetails({
        ...details,
        network_commision_rate: {
          ...details.network_commision_rate,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const currency = money;
  const checkPass = () => {
    if (details.api_key.length === 0 || details.api_key === "") {
      return "API Key required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="commision-factory-container">
        {" "}
        <TextField
          required
          size="small"
          label="API Key"
          style={{
            margin: "10px 0",
          }}
          name="api_key"
          value={details.api_key}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "10px 0",
          }}
        >
          <MenuItem>Choose type of account</MenuItem>
          {currency.map((a, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: a.option,
                    },
                  });
                }}
                value={i + 1}
              >
                {a.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "impact" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default CommisionFactory;
