const initialState = {
  cms: {
    hero: null,
    title: "",
    subtitle: "",
    blocks: [
      {
        id: 1,
        type: 2,
        text: null,
        image: null,
      },
    ],
    meta_data: {
      // title: "",
      // desc: "",
      // keywords: [],
      endpoint: "",
      subdomain: "",
    },
  },
  load: false,
};

const cmsReducer = (state = initialState, action) => {
  //schedule request

  switch (action.type) {
    case "CMS_SAVE_LOAD":
      state = {
        ...state,
        load: true,
      };
      break;

    case "CMS_SAVE_SUCCESS":
      state = {
        ...state,
        load: false,
        cms: action?.payload?.cms,
      };
      break;

    case "CMS_SAVE_FAIL":
      state = {
        ...state,
        load: false,
      };
      break;

    case "CMS_CHANGE":
      state = {
        ...state,
        cms: { ...action.payload },
      };
      break;
    default:
      state = {
        ...state,
      };
  }

  return state;
};

export default cmsReducer;
