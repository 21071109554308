import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import { Toastify } from '../../../../App';
import apiClient from '../../../../utils/axiosClient';

function descendingComparator(a, b, orderBy) {
  console.log(a, b, orderBy);
  if (orderBy === 'time') {
    if (
      new Date(b[`created_at`]).getTime() < new Date(a[`created_at`]).getTime()
    ) {
      return -1;
    }
    if (
      new Date(b[`created_at`]).getTime() > new Date(a[`created_at`]).getTime()
    ) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator, orderBy) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name_email',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'status',
    numeric: false,
    center: 'center',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'time',
    numeric: false,
    center: 'center',
    disablePadding: false,
    label: 'Time',
  },

  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'Log message',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    console.log('click', property);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell?.center ? 'center' : headCell.numeric ? 'right' : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              minWidth: '250px',
            }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}>
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div">
          {numSelected} Admin Action Logs
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div">
          Admin Action Logs
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function LogsTable({ searchText }) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('time');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const super_token = JSON.parse(localStorage.getItem('super_token'));
  const [filterdData, setFilteredData] = React.useState([]);
  const [filterdDataBackup, setFilteredDataBackup] = React.useState([]);

  const isJsonParse = (screen) => {
    try {
      JSON.parse(screen);
      return true;
    } catch (error) {
      return false;
    }
  };
  const rawLogToJSON = (logs) => {
    let screen = logs.replace('[INTERNAL] ', '');
    screen = isJsonParse(screen) ? JSON.parse(screen) : screen;
    screen = {
      ...screen,
      payload: isJsonParse(screen.payload)
        ? JSON.parse(screen.payload)
        : screen.payload,
    };
    return screen;
  };

  const getLogsData = async () => {
    try {
      const res = await apiClient.get(`/user/v1/admin/log/all`);
      console.log(res.data, 'result');
      const filterDataFinal = res.data
        .map((d) => {
          return {
            ...d,
            name_email: `${d.name} (${d.email})`,
            log: rawLogToJSON(d.log),
          };
        })
        .sort(
          (a, b) =>
            new Date(b[`created_at`]).getTime() -
            new Date(a[`created_at`]).getTime()
        );
      setFilteredData(filterDataFinal);

      setFilteredDataBackup(filterDataFinal);
    } catch (error) {
      Toastify('error', 'Error on logs');
      console.log(error, 'error');
    }
  };

  React.useEffect(() => {
    getLogsData();
  }, []);

  React.useEffect(() => {
    console.log(filterdData, 'filterdData');
  }, [filterdData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filterdData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filterdData.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filterdData, getComparator(order, orderBy), orderBy).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filterdData.length]
  );

  const statusCodeColor = (statusCode) => {
    const status = parseInt(statusCode);
    if (status >= 200 && status <= 299) {
      return 'green';
    } else if (status >= 400 && status <= 499) {
      return 'yellow';
    } else if (status >= 500 && status <= 500) {
      return 'red';
    } else {
      return 'black';
    }
  };

  React.useEffect(() => {
    console.log(filterdDataBackup, 'filterData');
    const res = [];
    // name + email
    for (let i = 0; i < filterdDataBackup.length; i++) {
      if (filterdDataBackup[i]?.name_email?.includes(searchText)) {
        res.push(filterdDataBackup[i]);
      }
    }
    // payload
    for (let i = 0; i < filterdDataBackup.length; i++) {
      const payload = filterdDataBackup[i].log.payload;
      if (
        payload?.message?.includes(searchText) ||
        payload?.status?.toString()?.includes(searchText)
      ) {
        res.push(filterdDataBackup[i]);
      }
    }

    setFilteredData([...res]);
  }, [searchText]);

  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    );
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '100%', mb: 2, height: '85vh', position: 'relative' }}>
        <TableContainer sx={{ maxHeight: '75vh' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader
            aria-label="sticky table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filterdData.length}
            />
            <TableBody
              style={{
                height: '400px',
                overflowY: 'scroll',
              }}>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell
                      style={{
                        padding: 0,
                      }}></TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none">
                      <div
                        style={{
                          margin: '0 auto',
                        }}>
                        {searchText.length > 0 ? (
                          row.name_email
                            .toString()
                            .split(new RegExp(`(${searchText})`, 'i'))
                            .map((part) =>
                              part.toLowerCase() ===
                              searchText.toLowerCase() ? (
                                <span
                                  key={index}
                                  style={{
                                    color: '#a60071',
                                    backgroundColor: `#a6007173`,
                                  }}>
                                  {part}
                                </span>
                              ) : (
                                <span key={index}>{part}</span>
                              )
                            )
                        ) : (
                          <span>{row.name_email}</span>
                        )}
                      </div>
                    </TableCell>

                    <TableCell align="center">
                      {row?.log?.payload?.status && (
                        <div
                          style={{
                            color: 'rgb(39, 181, 36)',
                            backgroundColor: 'rgba(71, 237, 52, 0.3)',
                            border: '1px solid rgb(39, 181, 36)',
                            borderRadius: '20px',
                            textAlign: 'center',
                            fontSize: '0.8rem',
                            width: '75px',
                            margin: '0 auto',
                          }}>
                          <p
                            style={{
                              margin: '5px',
                              color: statusCodeColor(row?.log?.payload?.status),
                              fontWeight: 500,
                            }}>
                            {searchText.length > 0 ? (
                              row?.log?.payload?.status
                                .toString()
                                .split(new RegExp(`(${searchText})`, 'i'))
                                .map((part) =>
                                  part.toLowerCase() ===
                                  searchText.toLowerCase() ? (
                                    <span
                                      key={index}
                                      style={{
                                        color: '#a60071',
                                        backgroundColor: `#a6007173`,
                                      }}>
                                      {part}
                                    </span>
                                  ) : (
                                    <span key={index}>{part}</span>
                                  )
                                )
                            ) : (
                              <span>{row?.log?.payload?.status}</span>
                            )}
                          </p>
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(row.created_at)}
                    </TableCell>

                    <TableCell align="left">
                      {searchText.length > 0 ? (
                        row?.log?.payload?.message
                          .split(new RegExp(`(${searchText})`, 'i'))
                          .map((part) =>
                            part.toLowerCase() === searchText.toLowerCase() ? (
                              <span
                                key={index}
                                style={{
                                  color: '#a60071',
                                  backgroundColor: `#a6007173`,
                                }}>
                                {part}
                              </span>
                            ) : (
                              <span key={index}>{part}.</span>
                            )
                          )
                      ) : (
                        <span>{row?.log?.payload?.message}.</span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{
            position: 'absolute',
            bottom: 10,
            width: '100%',
          }}
          rowsPerPageOptions={[25, 50, 100, 500]}
          component="div"
          count={filterdData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
