import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'API point logs',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: '/admin/login',
      data: [1, 2, 3, 4, 5, 5, 7, 10, 1, 20, 4, 11],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: '/admin/verify',
      data: [
        2,
        2 + 1,
        3 + 1,
        4 + 1,
        5 + 1,
        5 + 1,
        7 + 1,
        10 + 1,
        1 + 1,
        20,
        4,
        11,
      ],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const SimpleLineChart = ({ data: data_test }) => {
  console.log(data_test, 'data');

  useEffect(() => {
    const datasets = [];

    for (const dt of data_test) {
      datasets.push({
        label: dt.url,
        data: [1, 2, 3, 4, 5, 5, 7, 10, 1, 20, 4, 11],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      });
    }
  }, [data_test]);
  return <Line options={options} data={data} />;
};

export default SimpleLineChart;
