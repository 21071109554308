import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  ThemeProvider,
  createTheme,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  HomeOutlined,
  PersonOutline,
  LogoutOutlined,
  CabinOutlined,
  ScaleOutlined,
  CorporateFare,
  AdminPanelSettings,
  HistoryEdu,
  AccountTree,
  SelectAll,
  NotificationsActive,
  Assessment
} from '@mui/icons-material';


import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MailOutline from '@mui/icons-material/MailOutlineOutlined';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import DiscountIcon from '@mui/icons-material/Discount';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './home.styles.scss';

import DrawerItem from '../../components/drawer-item/drawer-item.component';
import HomeComponent from '../../components/home/home.component';
import CRMUserAnalytics from '../../components/crm-user-analytics/crm-user-analytics.component'
import Users from '../../components/users/users.component';
import AdminProfile from '../../components/admin-profile';
import Admins from '../../components/admins';
import Payments from '../../components/payments';
import Brands from '../../components/brands/brands.component';
import BrandsUp from '../../components/brands-up';
import BrandWeights from '../../components/brand-weights/brand-weights.component';
import BrandWeightsUP from '../../components/brand-weights-up';
import Organization from '../../components/organization';
import NotificationsTeamsComponent from '../../components/notification-teams';
import NotificationsPublicComponent from '../../components/notification-public';
import CMS from '../../components/cms';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_admin_profile,
  logout_admin,
} from '../../redux/action/auth.action';
import AdminUsers from '../../components/admin-users';
import { Toastify } from '../../App';
import { getAnalytics, logEvent } from 'firebase/analytics';
import OverView from '../../components/overview/overview.component';
import Coupons from '../../components/coupons';
import PublisherMapping from '../../components/publisher-mapping';
import MatrixComponent from '../../components/matrix';
import ImportSection from '../../components/import-sections';
import { actionLogTrack } from '../../lib/logs';
import { usePostHog } from 'posthog-js/react'


const drawerWidth = 300;

/*
Container Index(es)
1 - Home
2 - Users
*/
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const Home = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { admin_auth, superAdmin, admin_profile } = useSelector(
    (state) => state.admin
  );
  const super_admin = JSON.parse(localStorage.getItem('super_admin'));
  const [container, setContainer] = useState('');
  const [showContactUsDialog, setShowContactUsDialog] = useState(false);
  const [contactUsText, setContactUsText] = useState('');
  const [showContactUsLoader, setShowContactUsLoader] = useState(false);
  const [contactUsErrorText, setContactUsErrorText] = useState('');
  const [showContactUsDone, setShowContactUsDone] = useState(false);

  //remvoing presistant login
  useEffect(() => {
    function clearStorage() {
      // let session = sessionStorage.getItem('register');
      // console.log(session, 'session');
      // if (session == null) {
      //   localStorage.clear();
      // }
      // sessionStorage.setItem('register', 1);
    }
    clearStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // running multiple times need some fix
  useEffect(() => {
    // setContainer(superAdmin ? "organization" : "admin-user");
    setContainer('overview');
    // return () => {
    //   setContainer("");
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAdmin]);

  useEffect(() => {
    dispatch(get_admin_profile(admin_auth));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();

  //doubt
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'home_page_visited');
  });

  const handleLogOut = () => {
    const token = JSON.parse(localStorage.getItem('super_token'))?.token;

    const desc = {
      message: `User with email ${super_admin.email} logged out`,
      status: 200,
      method: '',
      url: '',
    };

    actionLogTrack(JSON.stringify(desc), false, token);
    if (superAdmin) {
      setContainer('');
    }
    navigate('/', {
      state: {
        email: null,
        password: null,
        action: 'login',
      },
    });
    posthog.reset(true);
    dispatch(logout_admin());
    Toastify('success', 'Logout Successfully');
    localStorage.clear();
  };

  const sendQuery = () => {
    if (contactUsText === '') {
      setContactUsErrorText('Message is required!');
    } else {
      setContactUsErrorText('');
      setShowContactUsLoader(true);
      axios
        .post(
          'https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/query',
          {
            id: admin_profile.id,
            query: contactUsText,
          },
          {
            headers: {
              Authorization: `JWT ${admin_auth.token}`,
            },
          }
        )
        .then((_) => {
          setShowContactUsLoader(false);
          setContactUsText('');
          setContactUsErrorText('');
          setShowContactUsDone(true);
        });
    }
  };

  const Item = () => {
    switch (container) {
      case 'overview':
        return <OverView setContainer={setContainer} />;

      case 'home':
        return <HomeComponent />;
      
      case 'crm-user-analytics':
        return <CRMUserAnalytics />;

      case 'users':
        return <AdminUsers />;

      case 'users-internal':
        return <Users />;

      case 'admin-user':

        return <AdminUsers />;

      case 'admins':
        return <Admins />;

      case 'brands':

        return <Brands />;
      case 'coupons':
        return <Coupons />;

      case 'br-wt':

        return <BrandWeights />;

      case 'pub-map':
        return <PublisherMapping />;

      case 'goal-matrix':
        return <MatrixComponent />;

      case 'organization':
        return <Organization />;

      case 'brands-up':
        return <BrandsUp />;

      case 'br-wt-up':
        return <BrandWeightsUP />;

      case 'admin-profile':
        return <AdminProfile />;

      case 'payments':
        return <Payments />;

      case 'notification-public':
        return <NotificationsPublicComponent />;

      case 'notification-teams':
        return <NotificationsTeamsComponent />;

      case 'imports':
        return <ImportSection />;

      case 'cms':
        return <CMS />;

      default:
        return '';
    }
  };

  return (
    <div>
      {superAdmin ? (
        <Box className="home" sx={{ display: 'flex', border: 'none' }}>
          {/* Drawer START */}
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: 'white',
                borderRight: '1px solid rgba(0, 0, 0, 0.05)',
              },
            }}
            className="drawer"
            variant="persistent"
            anchor="left"
            open={true}>
            <div className="logo-container">
              <img
                className="logo-drawer"
                src={process.env.PUBLIC_URL + '/assets/lift.png'}
                alt="Logo"
                onClick={() => {
                  window.open('https://lift.lt.partners');
                }}
              />
              {!superAdmin ? (
                <img
                  className="logo-drawer"
                  src={
                    admin_profile?.organization?.image
                      ? admin_profile?.organization?.image
                      : process.env.PUBLIC_URL + '/assets/lift.png'
                  }
                  alt="Logo"
                  onClick={() => {
                    // window.open("https://lift.lt.partners");
                    setContainer('admin-profile');
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <Divider style={{ opacity: '50%' }} />
            <div style={{ overflow: 'hidden auto', height: '100%' }}>
              <DrawerItem
                className="item"
                name="Overview"
                clickEventName="overview"
                icon={<GridViewRoundedIcon />}
                clickEvent={() => setContainer('overview')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Organization"
                clickEventName="organization"
                icon={<CorporateFare />}
                clickEvent={() => setContainer('organization')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Brands"
                clickEventName="brands-up"
                icon={<CabinOutlined />}
                clickEvent={() => setContainer('brands-up')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Brand Weights"
                clickEventName="br-wt-up"
                icon={<ScaleOutlined />}
                clickEvent={() => setContainer('br-wt-up')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Admins"
                clickEventName="admins"
                icon={<AdminPanelSettings />}
                clickEvent={() => setContainer('admins')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Users"
                clickEventName="admin-user"
                icon={<PersonOutline />}
                clickEvent={() => setContainer('admin-user')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Coupons"
                clickEventName="coupons"
                icon={<DiscountIcon />}
                clickEvent={() => setContainer('coupons')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="CMS"
                clickEventName="cms"
                icon={<HistoryEdu />}
                clickEvent={() => setContainer('cms')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Payments"
                clickEventName="payments"
                icon={<PaidOutlinedIcon />}
                clickEvent={() => setContainer('payments')}
                selected={container}
              />

              <DrawerItem
                className="item"
                name="Notification Public"
                clickEventName="notification-public"
                icon={<NotificationsActive />}
                clickEvent={() => setContainer('notification-public')}
                selected={container}
              />

              <div className="drawer-type">
                <div className="line-start"></div>
                <div className="text">Internal</div>
                <div className="line"></div>
              </div>
              <DrawerItem
                className="item"
                name="Home"
                clickEventName="home"
                icon={<HomeOutlined />}
                clickEvent={() => setContainer('home')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="CRM User Analytics"
                clickEventName="crm-user-analytics"
                icon={<QueryStatsIcon />}
                clickEvent={() => setContainer('crm-user-analytics')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Users"
                clickEventName="users-internal"
                icon={<PersonOutline />}
                clickEvent={() => setContainer('users-internal')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Brands"
                clickEventName="brands"
                icon={<CabinOutlined />}
                clickEvent={() => setContainer('brands')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Brand Weights"
                clickEventName="br-wt"
                icon={<ScaleOutlined />}
                clickEvent={() => setContainer('br-wt')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Pub Mapping"
                clickEventName="pub-map"
                icon={<AccountTree />}
                clickEvent={() => setContainer('pub-map')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Goal Matrix"
                clickEventName="goal-matrix"
                icon={<SelectAll />}
                clickEvent={() => setContainer('goal-matrix')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Notification Teams"
                clickEventName="notification-teams"
                icon={<NotificationsActive />}
                clickEvent={() => setContainer('notification-teams')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Imports"
                clickEventName="imports"
                icon={<Assessment />}
                clickEvent={() => setContainer('imports')}
                selected={container}
              />
            </div>
            {/* Footer */}
            {superAdmin ? (
              <div className="drawer-foot">
                <Divider style={{ opacity: '50%' }} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '15px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <img
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '40px',
                        margin: '7px auto',
                        objectFit: 'contain',
                      }}
                      src={super_admin?.image}
                    />
                    <div
                      style={{
                        marginLeft: 10,
                      }}>
                      {super_admin?.name}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      handleLogOut();
                    }}>
                    <Button
                      style={{
                        fontFamily: '"Montserrat", sans-serif',
                        color: 'rgb(166, 0, 113)',
                      }}>
                      <LogoutOutlined
                        style={{
                          fontSize: '2rem',
                        }}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Drawer>
          {/* Drawer END */}

          <div className="main">
            {Item()}
            {/* {container === "home" ? (
              <HomeComponent />
            ) : container === "users" ? (
              <Users />
            ) : container === "brands" ? (
              <Brands />
            ) : (
              <BrandWeights />
            )} */}
          </div>
        </Box>
      ) : (
        <Box className="home" sx={{ display: 'flex', border: 'none' }}>
          <Dialog disableEscapeKeyDown open={showContactUsDialog}>
            <DialogTitle
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '1.2rem',
                fontWeight: '700',
              }}>
              What do you need help with?
            </DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop: '10px',
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.9rem',
                  width: '380px',
                }}>
                <ThemeProvider theme={theme}>
                  {showContactUsDone ? (
                    'Message Sent!'
                  ) : (
                    <TextField
                      fullWidth
                      multiline
                      minRows={5}
                      value={contactUsText}
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          setContactUsErrorText('');
                        else setContactUsErrorText('Message is required!');
                        setContactUsText(e.target.value);
                      }}
                      label="Send us a message"
                      error={!(contactUsErrorText === '')}
                      helperText={contactUsErrorText}
                    />
                  )}
                </ThemeProvider>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.8rem',
                  color: 'rgb(166, 0, 113)',
                  marginBottom: '18px',
                }}
                onClick={() => setShowContactUsDialog(false)}>
                Cancel
              </Button>
              <Button
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.8rem',
                  color: 'white',
                  backgroundColor: 'rgb(166, 0, 113)',
                  marginBottom: '18px',
                  marginRight: '22px',
                }}
                onClick={() => {
                  if (showContactUsDone) {
                    setShowContactUsDone(false);
                    setShowContactUsDialog(false);
                  } else sendQuery();
                }}>
                {showContactUsLoader ? (
                  <ThemeProvider theme={theme}>
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </ThemeProvider>
                ) : showContactUsDone ? (
                  'OK'
                ) : (
                  'Send'
                )}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Drawer START */}
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: 'white',
                borderRight: '1px solid rgba(0, 0, 0, 0.05)',
              },
            }}
            className="drawer"
            variant="persistent"
            anchor="left"
            open={true}>
            <div className="logo-container">
              <img
                className="logo-drawer "
                src={process.env.PUBLIC_URL + '/assets/lift.png'}
                alt="Logo"
                onClick={() => {
                  window.open('https://lift.lt.partners');
                }}
              />
              {!superAdmin ? (
                <img
                  className="logo-drawer "
                  src={
                    admin_profile?.organization?.image
                      ? admin_profile?.organization?.image
                      : process.env.PUBLIC_URL + '/assets/lift.png'
                  }
                  alt="Logo"
                  onClick={() => {
                    // window.open("https://lift.lt.partners");
                    setContainer('admin-profile');
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <Divider style={{ opacity: '50%' }} />
            <div style={{ overflow: 'hidden auto' }}>
              <DrawerItem
                className="item"
                name="Overview"
                clickEventName="overview"
                icon={<GridViewRoundedIcon />}
                clickEvent={() => setContainer('overview')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Users"
                clickEventName="admin-user"
                icon={<PersonOutline />}
                clickEvent={() => setContainer('admin-user')}
                selected={container}
              />

              <DrawerItem
                className="item"
                name="Brands"
                clickEventName="brands"
                icon={<CabinOutlined />}
                clickEvent={() => setContainer('brands')}
                selected={container}
              />
              <DrawerItem
                className="item"
                name="Brand Weights"
                clickEventName="br-wt"
                icon={<ScaleOutlined />}
                clickEvent={() => setContainer('br-wt')}
                selected={container}
              />

              <DrawerItem
                className="item"
                name="Profile"
                clickEventName="admin-profile"
                icon={<ManageAccountsOutlinedIcon />}
                clickEvent={() => setContainer('admin-profile')}
                selected={container}
              />

              <DrawerItem
                className="item"
                name="Contact Us"
                clickEventName="email"
                icon={<MailOutline />}
                clickEvent={() => setShowContactUsDialog(true)}
                selected={container}
              />

              {/* Footer */}
              {/* <div className="drawer-foot" onClick={handleLogOut}>
              <Divider style={{ opacity: "50%" }} />
              <DrawerItem
                className="item"
                name="Log Out"
                clickEventName="users"
                icon={<LogoutOutlined />}
              />
            </div> */}
            </div>
          </Drawer>
          {/* Drawer END */}

          <div className="main">
            {Item()}
            {/* {container === "home" ? (
              <HomeComponent />
            ) : container === "users" ? (
              <Users />
            ) : container === "brands" ? (
              <Brands />
            ) : (
              <BrandWeights />
            )} */}
          </div>
        </Box>
      )}
    </div>
  );
};

export default Home;
