import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import StepOne from '../../components/step-one/step-one';
import StepSec from '../../components/step-sec/step-sec';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './create.styles.scss';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { onCMSInit } from '../../../../redux/action/cms.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Toastify } from '../../../../App';
import styled from '@emotion/styled';
import { pink } from '@mui/material/colors';
import apiClient from '../../../../utils/axiosClient';

const Create = () => {
  const super_token = JSON.parse(localStorage.getItem('super_token'));
  const cmsData = useSelector((state) => state.cmsData);

  const { state } = useLocation();
  const [loaderPublish, setLoaderPublish] = useState(false);
  const [loaderDraft, setLoaderDraft] = useState(false);
  const [publicBlog, setPublicBlog] = useState(false);
  const navigate = useNavigate();

  const uploadImgs = async (blogs, pageId, hero) => {
    try {
      console.log(blogs, pageId, hero, 'print data');
      const file = await fetch(hero)
        .then((r) => r.blob())
        .then((blobFile) => {
          return new File([blobFile], 'fileName.png', {
            type: blobFile.type,
          });
        });

      let formData = new FormData();
      formData.append('cms_image', file);
      formData.append('page', pageId);

      const result = await apiClient.post(
        '/outreach/v1/cms/image/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(result.data, 'hero data');

      const promises = await blogs.map(async (b, i) => {
        if (b.image != null) {
          const file = await fetch(b.image)
            .then((r) => r.blob())
            .then((blobFile) => {
              return new File([blobFile], 'fileName.png', {
                type: blobFile.type,
              });
            });
          let formData = new FormData();
          formData.append('cms_image', file);
          formData.append('page', pageId);

          const res = await apiClient.post(
            '/outreach/v1/cms/image/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          return {
            ...b,
            image: res.data.image,
          };
        } else {
          return b;
        }
      });
      const res = await Promise.all(promises);
      return { blogs: res, hero: result.data.image };
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, 'error');
    }
  };

  const checkEndPointSubdomainCheck = async (cmsData, type) => {
    try {
      const res = await apiClient.get(
        type === 'publish'
          ? `/outreach/v1/admin/cms/get/published`
          : `/outreach/v1/admin/cms/get/draft`
      );

      let endpoint = res.data.response.find(
        (v) => v.endpoint === cmsData.cms.meta_data.endpoint
      );

      let subdomain = res.data.response.find(
        (v) => v.subdomain === cmsData.cms.meta_data.subdomain
      );
      console.log(res.data);
      console.log(subdomain, endpoint);
      if (
        (endpoint !== undefined && endpoint !== null) ||
        (subdomain !== undefined && subdomain !== null)
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  const blogAction = async (type) => {
    if (
      typeof cmsData.cms.title === 'undefined' ||
      cmsData.cms.title === null ||
      cmsData.cms.title === ''
    ) {
      Toastify('warning', 'Title is required');
      return;
    } else if (
      typeof cmsData.cms.subtitle === 'undefined' ||
      cmsData.cms.subtitle === null ||
      cmsData.cms.subtitle === ''
    ) {
      Toastify('warning', 'Subtitle is required');
      return;
    } else if (
      typeof cmsData.cms.subtitle === 'undefined' ||
      cmsData.cms.subtitle === null ||
      cmsData.cms.subtitle === ''
    ) {
      Toastify('warning', 'Subtitle is required');
      return;
    } else if (
      typeof cmsData.cms.meta_data.endpoint === 'undefined' ||
      cmsData.cms.meta_data.endpoint === null ||
      cmsData.cms.meta_data.endpoint === ''
    ) {
      Toastify('warning', 'Endpoint is required');
      return;
    } else if (
      typeof cmsData.cms.meta_data.subdomain === 'undefined' ||
      cmsData.cms.meta_data.subdomain === null ||
      cmsData.cms.meta_data.subdomain === ''
    ) {
      Toastify('warning', 'Subdomain is required');
      return;
    } else {
      try {
        const check = await checkEndPointSubdomainCheck(cmsData, type);

        if (check) {
          Toastify('warning', 'Endpoint or subdomain already exits!!');
          return;
        }

        if (type === 'publish') {
          setLoaderPublish(true);
        } else {
          setLoaderDraft(true);
        }

        const pageId = localStorage.getItem('pageId');

        const content = JSON.parse(sessionStorage.getItem('preview'));

        //upload block images
        const { blogs, hero } = await uploadImgs(
          content.blocks,
          pageId,
          content.hero
        );

        //upload hero
        // const hero = await uploadHero(content.hero, pageId);
        // console.log(hero, "hero");
        //get all imgs
        const imgs = blogs.map((b) => b.image);

        const post = {
          page: pageId,
          publish: type === 'publish' ? 1 : 0,
          data: {
            hero: hero,
            title: content.title,
            subtitle: content.subtitle,
            blocks: blogs,
            metadata: {
              endpoint: content.meta_data.endpoint,
              subdomain: content.meta_data.subdomain,
            },
            images: [...imgs, hero],
          },
          public: publicBlog ? 1 : 0,
        };

        //publish page
        const res = await apiClient.post('/outreach/cms/upload', post);
        if (res.status === 200) {
          if (type === 'publish') {
            setLoaderPublish(false);
          } else {
            setLoaderDraft(false);
          }
          Toastify(
            'success',
            type === 'publish' ? 'Page Published !!' : 'Page saved as draft'
          );

          console.log('publish redirect', state);
          localStorage.removeItem('pageId');

          const url = `/home/page-view/${cmsData.cms.meta_data.subdomain}/${cmsData.cms.meta_data.endpoint}`;
          window.open(url, '_blank', 'noopener,noreferrer');
          window.close();
        }
      } catch (error) {
        if (type === 'publish') {
          setLoaderPublish(false);
        } else {
          setLoaderDraft(false);
        }
        Toastify('error', error.response.data.message);
        console.log(error);
      }
    }
  };

  const toTop = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (super_token?.token == null) {
      navigate('/404');
      return;
    }
    dispatch(onCMSInit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CusSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
  }));

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   window.addEventListener("unload", handleTabClosing);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //     window.removeEventListener("unload", handleTabClosing);
  //   };
  // });

  return (
    // <ThemeProvider theme={theme}>
    <div className="create-container">
      <div className="top-preview" ref={toTop}>
        <div className="options">
          <Button
            onClick={() => {
              blogAction('publish');
            }}
            className="add-brand-btn">
            {!loaderPublish ? (
              <div className="add-brand-content">
                <p className="add-text">Publish</p>
              </div>
            ) : (
              <CircularProgress style={{ color: '#a60071' }} />
            )}
          </Button>
          <Button
            onClick={() => {
              blogAction('draft');
            }}
            className="add-brand-btn">
            {!loaderDraft ? (
              <div className="add-brand-content">
                <p className="add-text">Save as draft</p>
              </div>
            ) : (
              <CircularProgress style={{ color: '#a60071' }} />
            )}
          </Button>

          <FormControlLabel
            control={
              <CusSwitch
                style={{
                  cursor: 'pointer',
                  color: 'rgb(166, 0, 113)',
                }}
                onClick={() => {
                  setPublicBlog(!publicBlog);
                }}
                checked={publicBlog}
              />
            }
            label={
              <div style={{ color: 'rgb(166, 0, 113)' }}>
                {publicBlog ? 'Public' : 'Private'}
              </div>
            }
          />
        </div>
        <div className="options">
          <Link to="/home/create/preview" target={'_blank'}>
            <VisibilityIcon style={{ fontSize: '30px', color: '#a60071' }} />
          </Link>
        </div>
      </div>
      <StepOne cmsData={cmsData} />
      <StepSec />

      <div className="botton-create-page">
        <div
          onClick={() => {
            toTop.current.scrollIntoView({ behavior: 'smooth' });
          }}
          style={{
            border: '1px solid rgb(166, 0, 113)',
            padding: '0',
            borderRadius: '50px',
            margin: '20px 10px',
            width: '50px',
            height: '50px',
          }}>
          <ArrowDropUpIcon
            style={{ fontSize: '50px', color: 'rgb(166, 0, 113)' }}
          />
        </div>
      </div>
    </div>
    // </ThemeProvider>
  );
};

export default Create;
