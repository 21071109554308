import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  auth_check,
  super_admin as superAdminFunc,
} from '../redux/action/auth.action';
import PreLoader from '../components/preloader';

export default function PrivateRoute({ children }) {
  const auth = JSON.parse(localStorage.getItem('admin_auth'));
  const super_admin = JSON.parse(localStorage.getItem('super_admin'));
  const super_token = JSON.parse(localStorage.getItem('super_token'));

  const dispatch = useDispatch();
  const { admin_auth } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("admin", superAdmin);
    if (super_admin && super_token) {
      sessionStorage.setItem('register', 1);
      dispatch(superAdminFunc(super_admin && super_token));
    } else if (auth) {
      dispatch(auth_check(auth));
    } else {
      navigate(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (super_token && super_admin) || auth ? children : <PreLoader />;
}
