import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  PAYMENT_REQUEST,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
  CouponSet,
  UnSetCoupon,
} from '../../../../redux/action/payment.action';
import axios from 'axios';
import { Toastify } from '../../../../App';
import './checkout-form.scss';
import { keys } from '../../../../lib/consts/keys';
import { Close } from '@mui/icons-material';

function CheckoutForm(props) {
  // get token
  const { invite_token } = useParams();
  const admin_auth = JSON.parse(localStorage.getItem('admin_auth'));
  const dispatch = useDispatch();
  // collect data from the user

  const navigator = useNavigate();
  const [name, setName] = useState(
    props?.details?.name ? props?.details?.name : admin_auth.name
  );
  const [email, setEmail] = useState(
    props?.details?.email ? props?.details?.email : admin_auth.email
  );
  const [errorMessage, setErrorMessage] = useState({
    cvc: '',
    number: '',
    name: '',
    date: '',
  });

  // const [email, setEmail] = useState(props?.details?.email);

  const stripe = useStripe();
  const elements = useElements();

  const createSubscription = async () => {
    const cardElementContainer1 = document
      .querySelector('#stripe-element-1')
      .classList.contains('StripeElement--empty');
    const cardElementContainer2 = document
      .querySelector('#stripe-element-2')
      .classList.contains('StripeElement--empty');
    const cardElementContainer3 = document
      .querySelector('#stripe-element-3')
      .classList.contains('StripeElement--empty');

    if (
      cardElementContainer1 ||
      cardElementContainer2 ||
      cardElementContainer3
    ) {
      // Toastify("error","Required Field!")
      if (cardElementContainer1) {
        setErrorMessage({
          ...errorMessage,
          number: 'Required Field!',
        });
      } else if (cardElementContainer2) {
        setErrorMessage({
          ...errorMessage,
          cvc: 'Required Field!',
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          date: 'Required Field!',
        });
      }

      return;
    }

    try {
      console.log('payment');
      dispatch(PAYMENT_REQUEST());
      // create a payment method

      // console.log(get);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
          email,
        },
      });

      console.log(paymentMethod, 'paymentMethod');
      // call the backend to create subscription
      //"https://stripe-ltp-api.onrender.com/create-sub",
      const info = {
        payment_method: paymentMethod?.paymentMethod?.id,
        name: name,
        email: email,
        price_id: props?.details?.payment,
        has_coupon: payment?.coupon?.coupon?.length !== 0 ? 1 : 0,
        coupon: payment?.coupon?.coupon?.coupon,
      };

      console.log(
        {
          payment_method: paymentMethod?.paymentMethod?.id,
          name: name,
          email: email,
          price_id: props?.details?.payment,
          has_coupon:
            payment?.coupon?.coupon?.coupon !== undefined &&
            payment?.coupon?.coupon?.coupon !== null
              ? 1
              : 0,
          coupon: payment?.coupon?.coupon?.coupon,
        },
        'testing'
      );
      const response = await axios.post(
        'https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/subscription/create',
        {
          payment_method: paymentMethod?.paymentMethod?.id,
          name: name,
          email: email,
          price_id: props?.details?.payment,
          has_coupon:
            payment?.coupon?.coupon?.coupon !== undefined &&
            payment?.coupon?.coupon?.coupon !== null
              ? 1
              : 0,
          coupon: payment?.coupon?.coupon?.coupon,
        }
      );

      const confirmPayment = await stripe?.confirmCardPayment(
        response.data.client_secret
      );
      console.log(confirmPayment, 'confirmPayment');
      console.log('stage 3');
      if (confirmPayment?.error) {
        dispatch(PAYMENT_ERROR());

        Toastify('error', confirmPayment.error.message);
      } else {
        if (
          invite_token !== '' &&
          invite_token !== null &&
          invite_token !== undefined
        ) {
          const respone_1 = await axios.post(
            'https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/onboarding',
            {
              token: invite_token,
              admin_password: props?.details?.pwd,
              payment_model:
                props?.details?.payment === keys.priceIdMonthly ? 1 : 2,
              subscription_id: response.data.subscription_id,
              customer_id: response.data.customer_id,
              coupon: payment?.coupon?.coupon?.coupon,
            }
          );
          console.log(respone_1, 'respone_1');

          //send user id also
          localStorage.clear();
          delete respone_1.data.details.password;
          dispatch(UnSetCoupon());
          localStorage.setItem(
            'admin_auth',
            JSON.stringify(respone_1.data.details)
          );
        } else {
          const respone_1 = await axios.post(
            'https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/payment/update',
            {
              admin_id: props?.details?.id,
              organization_id: props?.details?.org_id,
              payment_model:
                props?.details?.payment === keys.priceIdMonthly ? 1 : 2,
              subscription_id: response.data.subscription_id,
              customer_id: response.data.customer_id,
              coupon: payment?.coupon?.coupon?.coupon,
            },
            {
              headers: {
                Authorization: `JWT ${admin_auth?.token}`,
              },
            }
          );
          console.log(respone_1, 'rerespone_1s4');
          dispatch(UnSetCoupon());
          localStorage.removeItem('invitationInfo');
          localStorage.removeItem('payment');
        }

        console.log(confirmPayment, 'confirmPayment');

        dispatch(PAYMENT_SUCCESS());
        sessionStorage.setItem('register', 1);
        navigator('/home');
        Toastify('Success', 'Success! Check your email for the invoice.');
      }
    } catch (error) {
      Toastify('error', error?.response?.data?.message);
      console.log(error);
    }
  };

  const styleIt = {
    // iconStyle: "solid",
    // showIcon: true,
    style: {
      base: {
        iconColor: '#00000099',
        color: '#000',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '1em',
        fontWeight: 400,
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#00000099',
        },
        '::placeholder': {
          color: '#00000099',
        },
        border: '1px solid red',
      },
      invalid: {
        iconColor: 'red',
        color: 'red',
      },
    },
    showIcon: true,
  };

  const changeFocus = (isComplete, type) => {
    if (type === 'number' && isComplete) {
      const input = document.querySelector('#stripe-element-2 input');
      input.focus();
    } else if (type === 'cvc' && isComplete) {
      const input = document.querySelector('#stripe-element-3 input');
      input.focus();
    }
  };

  const checkCoupon = async () => {
    try {
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/coupon/verify/${coupon}`
      );
      console.log(res.data, 'checkcoupon');
      if (!res.data.exists) {
        setCouponErr(true);
        setCouponMsg('');
        // CouponSet
        UnSetCoupon();
        setCoupon('');
      } else {
        setCouponErr(false);
        setCoupon('');
        dispatch(CouponSet(res.data.coupon));
        setCouponMsg('Coupon added');
      }
    } catch (error) {
      console.log();
    }
  };

  const [coupon, setCoupon] = useState('');
  const [couponErr, setCouponErr] = useState(false);
  const [couponMsg, setCouponMsg] = useState('');
  const payment = useSelector((state) => state.payment);
  const [cardHolderName, setCardHolderName] = useState('');
  useEffect(() => {
    let invitationInfo = JSON.parse(localStorage.getItem('invitationInfo'));
    setCardHolderName(invitationInfo?.admin?.name);
  }, []);
  return (
    <div className="payment-ltp-container grid gap-4 m-auto">
      <div className="payment-grid">
        <div
          className="payment-input"
          style={{
            marginRight: '20px',
          }}>
          <label>Card Number</label>
          <CardNumberElement
            id="stripe-element-1"
            options={styleIt}
            onChange={(e) => {
              if (e.error) {
                setErrorMessage({
                  ...errorMessage,
                  number: e.error.message,
                });
              } else {
                setErrorMessage({
                  ...errorMessage,
                  number: '',
                });
              }

              changeFocus(e.complete === true, 'number');
            }}
            className="cardNumber-ltp"
          />

          {errorMessage.number.length !== 0 ? (
            <div className="error-payment">{errorMessage.number}</div>
          ) : (
            <div style={{ margin: '20px 0' }}></div>
          )}
        </div>

        <div className={`payment-input `}>
          <label>CVC</label>
          <CardCvcElement
            id="stripe-element-2"
            onChange={(e) => {
              if (e.error) {
                setErrorMessage({
                  ...errorMessage,
                  cvc: e.error.message,
                });
              } else {
                setErrorMessage({
                  ...errorMessage,
                  cvc: '',
                });
              }

              changeFocus(e.complete === true, 'cvc');
            }}
            options={styleIt}
            className={`cardCvc-ltp`}
          />

          {errorMessage.cvc.length !== 0 ? (
            <div className="error-payment">{errorMessage.cvc}</div>
          ) : (
            <div style={{ margin: '20px 0' }}></div>
          )}
        </div>

        <div
          className="payment-input"
          style={{
            marginRight: '20px',
          }}>
          <label>Card Holder Name</label>
          <input
            // style={{
            //   ...styleIt,
            // }}
            placeholder="Eg: James Rod (Optional)"
            id="card-owner-name-lpt"
            onChange={(e) => {
              if (e.error) {
                setErrorMessage({
                  ...errorMessage,
                  name: e.error.message,
                });
              } else {
                setCardHolderName(e.target.value);
                setErrorMessage({
                  ...errorMessage,
                  name: '',
                });
              }
            }}
            value={cardHolderName}></input>

          {errorMessage.name.length !== 0 ? (
            <div className="error-payment">{errorMessage.name}</div>
          ) : (
            <div style={{ margin: '20px 0' }}></div>
          )}
        </div>

        <div className="payment-input">
          <label> Expiry Date</label>
          <CardExpiryElement
            id="stripe-element-3"
            onChange={(e) => {
              if (e.error) {
                setErrorMessage({
                  ...errorMessage,
                  date: e.error.message,
                });
              } else {
                setErrorMessage({
                  ...errorMessage,
                  date: '',
                });
              }
            }}
            options={styleIt}
            className="cardCvc-ltp"
          />
          {errorMessage.date.length !== 0 ? (
            <div className="error-payment">{errorMessage.date}</div>
          ) : (
            <div style={{ margin: '20px 0' }}></div>
          )}
        </div>
      </div>
      {payment.coupon === null || payment.coupon === undefined ? (
        <div className="payment-input">
          <div id="coupon-lpt" style={{ display: 'flex' }}>
            <input
              className="input-coupon"
              value={coupon}
              onChange={(e) => {
                setCoupon(e.target.value);
                console.log(coupon, 'coupon');
              }}
            />
            {payment?.coupon === null || payment?.coupon === undefined ? (
              <div
                className="payment-input"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}>
                <div
                  onClick={() => {
                    checkCoupon();
                  }}
                  className="button-control-pay"
                  style={{ width: '100%' }}>
                  Apply
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <label style={{ fontSize: '.9rem' }} className="coupon-label">
            Hint: COUPON25%
          </label>
          {couponErr ? (
            <div className="error-payment">Coupon Invalid</div>
          ) : (
            <div className="success-payment">{couponMsg}</div>
          )}
        </div>
      ) : (
        <></>
      )}

      {payment.coupon !== null && payment.coupon !== undefined ? (
        <div className="coupon-success">
          <p>{payment?.coupon?.coupon?.coupon} Applied Successfully </p>

          <Close
            onClick={() => {
              setCoupon('');
              setCouponMsg('');
              dispatch(UnSetCoupon());
              props.setShowCost(props.org_cost);
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ) : (
        <div></div>
      )}
      <button
        style={{ visibility: 'hidden' }}
        ref={props?.sub_click}
        onClick={() => {
          console.log(invite_token, 'invite_token');
          createSubscription();
        }}
        disabled={!stripe}>
        Subscribe
      </button>
    </div>
  );
}

export default CheckoutForm;
