import React, { useEffect, useState } from 'react';
import { Paper, Grid, AppBar, Toolbar } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import './auth.styles.scss';

import Login from '../../components/login/login.component';
import ForgotPassword from '../../components/forgot-password';
import ForgotPasswordChange from '../../components/forgot-password-change';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

const Auth = () => {
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userAction, setUserAction] = useState('login');
  const [email, setEmail] = useState('');

  useEffect(() => {
    setUserAction(location.state === null ? 'login' : location.state.action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const imgTitle = [
    'Top Performers',
    'Performance Comparison',
    'Publisher Scorecard',
    'Paid Placement',
    'Network v/s Google Analytics Comparison',
    'Monthly KPIs',
  ];

  const imgDesc = [
    'Get a detailed list of top revenue, return and traffic driving publishers and channels for your brands. Also see the top converting publishers and channels.',
    "Get the ability to compare revenue, commission , RoAS and more for a previous period or previous year. That's not all you can also fiter the data based on publishers and publisher groups.",
    'Did you know we have a LTP Score! The score that tells it all. Want to know which publisher would be the best for your needs based on historic data? Publisher scorecard is the way to go.',
    'Want to know how your paid placement performed? Have a look at paid placement lift report for detailed analysis of your placement.',
    'Want to know if your revenue from Affiliate Network and Google Analytics adds up? have a look at Netwirk v/s GA Comparison report for detailed analysis.',
    'Monthly KPIs or Monthly Key Parametres Index will give you the YoY growthon different KPIs',
  ];

  const prevImage = () => {
    if (currentIndex <= 0) return;
    else setCurrentIndex(currentIndex - 1);
  };

  const nextImage = () => {
    if (currentIndex >= 5) return;
    else setCurrentIndex(currentIndex + 1);
  };
  const navigator = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'auth_page_visited');

    const admin_auth = JSON.parse(localStorage.getItem('admin_auth'));
    const super_token = localStorage.getItem('super_token');
    const super_admin = JSON.parse(localStorage.getItem('super_admin'));
    // console.log(superAdmin, "testing ");
    if (super_token && super_admin) {
      navigator('/home', {
        state: {
          email: super_admin.email,
          is_admin: super_admin.is_admin,
        },
      });
    } else {
      if (
        // superAdmin ||
        admin_auth !== null &&
        admin_auth !== undefined &&
        admin_auth !== ''
      ) {
        navigator('/home');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="auth">
      <div className="web">
        <img
          className="bgnd-img-top"
          alt="top"
          align="left"
          src={process.env.PUBLIC_URL + '/assets/auth/img1.webp'}
          height="34%"
        />
        <img
          className="bgnd-img-middle"
          alt="middle"
          align="right"
          src={process.env.PUBLIC_URL + '/assets/auth/img2.webp'}
          height="34%"
        />
        <img
          className="bgnd-img-bottom"
          alt="bottom"
          src={process.env.PUBLIC_URL + '/assets/auth/img3.webp'}
          height="33%"
        />
        <div className="carbon"></div>
        <Paper className="card">
          <Grid container spacing={0} justify="center">
            <Grid className="left-item" item xs={6}>
              {userAction === 'login' ? (
                <Login changeUserAction={(ac) => setUserAction(ac)} />
              ) : userAction === 'forgot-pwd' ? (
                <ForgotPassword
                  changeUserAction={(ac) => setUserAction(ac)}
                  changeEmail={(e) => setEmail(e)}
                />
              ) : (
                <ForgotPasswordChange
                  changeUserAction={(ac) => setUserAction(ac)}
                  email={email}
                />
              )}
            </Grid>
            <Grid className="right-item" item xs={6}>
              <div className="content">
                {/* <img
                  className="dash-image"
                  alt="Dashboard detail"
                  src={imgs[currentIndex]}
                /> */}
                <svg
                  width="429"
                  height="281"
                  // width="899"
                  // height="571"
                  viewBox="0 0 899 571"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg-container">
                  <g
                    id="undraw_analysis_re_w2vd 1"
                    clipPath="url(#clip0_1405_70)">
                    <path
                      id="Vector"
                      d="M828.975 558.002H816.367L810.367 460H828.975V558.002Z"
                      fill="#A0616A"
                    />
                    <path
                      id="Vector_2"
                      d="M807.362 554.399H831.676V569.709H792.053C792.053 567.698 792.449 565.707 793.218 563.85C793.987 561.993 795.115 560.305 796.537 558.883C797.958 557.462 799.646 556.334 801.503 555.565C803.361 554.795 805.351 554.399 807.362 554.399Z"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_3"
                      d="M891.874 544.746L880.291 549.724L830.577 457.415L847.673 450.068L891.874 544.746Z"
                      fill="#A0616A"
                    />
                    <path
                      id="Vector_4"
                      d="M870.595 549.97L892.934 540.37L898.978 554.435L862.574 570.08C861.78 568.233 861.358 566.248 861.332 564.237C861.305 562.227 861.675 560.231 862.419 558.364C863.164 556.497 864.27 554.794 865.672 553.354C867.075 551.914 868.748 550.764 870.595 549.97Z"
                      fill="#2F2E41"
                    />
                    <g id="card_2">
                      <path
                        id="Vector_5"
                        d="M708.257 129.682H489.822C488.096 129.68 486.441 128.993 485.221 127.773C484 126.552 483.314 124.897 483.312 123.171V6.51015C483.314 4.78416 484 3.12942 485.221 1.90897C486.441 0.688507 488.096 0.00199028 489.822 0H708.257C709.983 0.00199028 711.637 0.688507 712.858 1.90897C714.078 3.12943 714.765 4.78416 714.767 6.51015V123.171C714.765 124.897 714.078 126.552 712.858 127.773C711.637 128.993 709.983 129.68 708.257 129.682V129.682Z"
                        fill="#E6E6E6"
                      />
                      <path
                        id="Vector_6"
                        d="M527.129 81.637C540.394 81.637 551.148 70.8835 551.148 57.6184C551.148 44.3533 540.394 33.5998 527.129 33.5998C513.864 33.5998 503.11 44.3533 503.11 57.6184C503.11 70.8835 513.864 81.637 527.129 81.637Z"
                        fill="white"
                      />
                      <path
                        id="Vector_7"
                        d="M681.791 36.1117H583.725C582.751 36.1117 581.817 35.7248 581.128 35.0361C580.44 34.3474 580.053 33.4133 580.053 32.4393C580.053 31.4653 580.44 30.5312 581.128 29.8425C581.817 29.1538 582.751 28.7669 583.725 28.7669H681.791C682.765 28.7669 683.699 29.1538 684.388 29.8425C685.076 30.5312 685.463 31.4653 685.463 32.4393C685.463 33.4133 685.076 34.3474 684.388 35.0361C683.699 35.7248 682.765 36.1117 681.791 36.1117V36.1117Z"
                        fill="white"
                      />
                      <path
                        id="Vector_8"
                        d="M630.453 52.9002H583.725C582.751 52.9002 581.817 52.5133 581.128 51.8246C580.44 51.1358 580.053 50.2017 580.053 49.2278C580.053 48.2538 580.44 47.3197 581.128 46.631C581.817 45.9422 582.751 45.5553 583.725 45.5553H630.453C631.427 45.5553 632.361 45.9422 633.05 46.631C633.738 47.3197 634.125 48.2538 634.125 49.2278C634.125 50.2017 633.738 51.1358 633.05 51.8246C632.361 52.5133 631.427 52.9002 630.453 52.9002V52.9002Z"
                        fill="white"
                      />
                      <path
                        id="Vector_9"
                        d="M663.363 69.6812H583.725C582.751 69.6812 581.817 69.2942 581.128 68.6055C580.44 67.9168 580.053 66.9827 580.053 66.0087C580.053 65.0347 580.44 64.1006 581.128 63.4119C581.817 62.7232 582.751 62.3363 583.725 62.3363H663.363C664.337 62.3363 665.271 62.7232 665.96 63.4119C666.649 64.1006 667.036 65.0347 667.036 66.0087C667.036 66.9827 666.649 67.9168 665.96 68.6055C665.271 69.2942 664.337 69.6812 663.363 69.6812V69.6812Z"
                        fill="white"
                      />
                      <path
                        id="Vector_10"
                        d="M681.791 86.4622H583.725C582.751 86.4622 581.817 86.0752 581.128 85.3865C580.44 84.6978 580.053 83.7637 580.053 82.7897C580.053 81.8157 580.44 80.8816 581.128 80.1929C581.817 79.5042 582.751 79.1173 583.725 79.1173H681.791C682.765 79.1173 683.699 79.5042 684.388 80.1929C685.076 80.8816 685.463 81.8157 685.463 82.7897C685.463 83.7637 685.076 84.6978 684.388 85.3865C683.699 86.0752 682.765 86.4622 681.791 86.4622V86.4622Z"
                        fill="white"
                      />
                      <path
                        id="Vector_11"
                        d="M678.72 116.822H662.483C661.596 116.824 660.718 116.65 659.898 116.312C659.079 115.974 658.334 115.477 657.707 114.851C657.079 114.225 656.582 113.481 656.242 112.662C655.902 111.843 655.727 110.965 655.727 110.079C655.727 109.192 655.902 108.314 656.242 107.495C656.582 106.676 657.079 105.933 657.707 105.306C658.334 104.68 659.079 104.183 659.898 103.845C660.718 103.507 661.596 103.334 662.483 103.335H678.72C680.508 103.335 682.224 104.046 683.488 105.31C684.753 106.575 685.463 108.29 685.463 110.079C685.463 111.867 684.753 113.582 683.488 114.847C682.224 116.112 680.508 116.822 678.72 116.822V116.822Z"
                        fill="white"
                      />
                    </g>
                    <g id="card_1">
                      <path
                        id="Vector_12"
                        d="M383.373 140.547H230.856C229.651 140.546 228.496 140.066 227.643 139.214C226.791 138.362 226.312 137.207 226.31 136.001V54.5455C226.312 53.3404 226.791 52.185 227.643 51.3329C228.496 50.4807 229.651 50.0014 230.856 50H383.373C384.578 50.0014 385.733 50.4807 386.585 51.3329C387.437 52.185 387.917 53.3404 387.918 54.5455V136.001C387.917 137.207 387.437 138.362 386.585 139.214C385.733 140.066 384.578 140.546 383.373 140.547V140.547Z"
                        fill="#F2F2F2"
                      />
                      <path
                        id="Vector_13"
                        d="M256.905 107.001C266.167 107.001 273.675 99.4926 273.675 90.2306C273.675 80.9686 266.167 73.4602 256.905 73.4602C247.643 73.4602 240.134 80.9686 240.134 90.2306C240.134 99.4926 247.643 107.001 256.905 107.001Z"
                        fill="white"
                      />
                      <path
                        id="Vector_14"
                        d="M364.894 75.2141H296.422C295.741 75.2141 295.089 74.944 294.608 74.4631C294.127 73.9822 293.857 73.33 293.857 72.6499C293.857 71.9699 294.127 71.3177 294.608 70.8368C295.089 70.3559 295.741 70.0858 296.422 70.0858H364.894C365.574 70.0858 366.226 70.3559 366.707 70.8368C367.188 71.3177 367.458 71.9699 367.458 72.6499C367.458 73.33 367.188 73.9822 366.707 74.4631C366.226 74.944 365.574 75.2141 364.894 75.2141V75.2141Z"
                        fill="white"
                      />
                      <path
                        id="Vector_15"
                        d="M329.048 86.9362H296.422C295.742 86.9349 295.091 86.6642 294.612 86.1834C294.132 85.7027 293.862 85.0512 293.862 84.3721C293.862 83.6929 294.132 83.0414 294.612 82.5607C295.091 82.08 295.742 81.8092 296.422 81.8079H329.048C329.727 81.8092 330.378 82.08 330.858 82.5607C331.338 83.0414 331.607 83.6929 331.607 84.3721C331.607 85.0512 331.338 85.7027 330.858 86.1834C330.378 86.6642 329.727 86.9349 329.048 86.9362V86.9362Z"
                        fill="white"
                      />
                      <path
                        id="Vector_16"
                        d="M352.027 98.6531H296.422C295.742 98.6518 295.091 98.3811 294.612 97.9003C294.132 97.4196 293.862 96.7681 293.862 96.089C293.862 95.4098 294.132 94.7583 294.612 94.2776C295.091 93.7969 295.742 93.5261 296.422 93.5248H352.027C352.706 93.5261 353.357 93.7969 353.837 94.2776C354.317 94.7583 354.586 95.4098 354.586 96.089C354.586 96.7681 354.317 97.4196 353.837 97.9003C353.357 98.3811 352.706 98.6518 352.027 98.6531V98.6531Z"
                        fill="white"
                      />
                      <path
                        id="Vector_17"
                        d="M364.894 110.37H296.422C295.742 110.369 295.091 110.098 294.612 109.617C294.132 109.136 293.862 108.485 293.862 107.806C293.862 107.127 294.132 106.475 294.612 105.994C295.091 105.514 295.742 105.243 296.422 105.242H364.894C365.231 105.241 365.565 105.307 365.876 105.435C366.188 105.564 366.471 105.753 366.71 105.991C366.949 106.229 367.138 106.512 367.267 106.823C367.396 107.135 367.463 107.469 367.463 107.806C367.463 108.143 367.396 108.477 367.267 108.788C367.138 109.1 366.949 109.383 366.71 109.621C366.471 109.859 366.188 110.048 365.876 110.176C365.565 110.305 365.231 110.371 364.894 110.37V110.37Z"
                        fill="white"
                      />
                    </g>
                    <path
                      id="Vector_18"
                      d="M362.749 131.568H351.412C350.163 131.568 348.966 131.072 348.083 130.189C347.2 129.306 346.703 128.108 346.703 126.86C346.703 125.611 347.2 124.413 348.083 123.53C348.966 122.647 350.163 122.151 351.412 122.151H362.749C363.998 122.151 365.196 122.647 366.079 123.53C366.962 124.413 367.458 125.611 367.458 126.86C367.458 128.108 366.962 129.306 366.079 130.189C365.196 131.072 363.998 131.568 362.749 131.568Z"
                      fill="white"
                    />
                    <g id="card_main">
                      <path
                        id="Vector_19"
                        d="M644.267 266.682H359.067C356.813 266.679 354.653 265.783 353.059 264.189C351.466 262.596 350.569 260.435 350.567 258.182V105.862C350.569 103.609 351.466 101.448 353.059 99.8548C354.653 98.2613 356.813 97.3649 359.067 97.3623H644.267C646.52 97.3649 648.681 98.2613 650.274 99.8548C651.868 101.448 652.764 103.609 652.767 105.862V258.182C652.764 260.435 651.868 262.596 650.274 264.189C648.681 265.783 646.52 266.679 644.267 266.682Z"
                        fill="#A60071"
                      />
                      <path
                        id="Vector_20"
                        d="M407.777 203.952C425.096 203.952 439.137 189.912 439.137 172.592C439.137 155.272 425.096 141.232 407.777 141.232C390.457 141.232 376.417 155.272 376.417 172.592C376.417 189.912 390.457 203.952 407.777 203.952Z"
                        fill="white"
                      />
                      <path
                        id="Vector_21"
                        d="M609.712 144.512H481.672C480.4 144.512 479.18 144.007 478.281 143.107C477.382 142.208 476.877 140.988 476.877 139.717C476.877 138.445 477.382 137.226 478.281 136.326C479.18 135.427 480.4 134.922 481.672 134.922H609.712C610.341 134.922 610.965 135.046 611.547 135.287C612.128 135.528 612.657 135.881 613.102 136.326C613.547 136.772 613.901 137.3 614.142 137.882C614.383 138.464 614.507 139.087 614.507 139.717C614.507 140.346 614.383 140.97 614.142 141.552C613.901 142.133 613.547 142.662 613.102 143.107C612.657 143.553 612.128 143.906 611.547 144.147C610.965 144.388 610.341 144.512 609.712 144.512V144.512Z"
                        fill="white"
                      />
                      <path
                        id="Vector_22"
                        d="M542.682 166.432H481.672C480.4 166.432 479.18 165.926 478.281 165.027C477.382 164.128 476.877 162.908 476.877 161.637C476.877 160.365 477.382 159.145 478.281 158.246C479.18 157.347 480.4 156.842 481.672 156.842H542.682C543.954 156.842 545.173 157.347 546.072 158.246C546.972 159.145 547.477 160.365 547.477 161.637C547.477 162.908 546.972 164.128 546.072 165.027C545.173 165.926 543.954 166.432 542.682 166.432V166.432Z"
                        fill="white"
                      />
                      <path
                        id="Vector_23"
                        d="M585.652 188.342H481.672C480.4 188.342 479.18 187.837 478.281 186.937C477.382 186.038 476.877 184.819 476.877 183.547C476.877 182.275 477.382 181.056 478.281 180.156C479.18 179.257 480.4 178.752 481.672 178.752H585.652C586.923 178.752 588.143 179.257 589.042 180.156C589.941 181.056 590.447 182.275 590.447 183.547C590.447 184.819 589.941 186.038 589.042 186.937C588.143 187.837 586.923 188.342 585.652 188.342V188.342Z"
                        fill="white"
                      />
                      <path
                        id="Vector_24"
                        d="M609.712 210.252H481.672C480.4 210.252 479.18 209.747 478.281 208.848C477.382 207.948 476.877 206.729 476.877 205.457C476.877 204.185 477.382 202.966 478.281 202.067C479.18 201.167 480.4 200.662 481.672 200.662H609.712C610.983 200.662 612.203 201.167 613.102 202.067C614.001 202.966 614.507 204.185 614.507 205.457C614.507 206.729 614.001 207.948 613.102 208.848C612.203 209.747 610.983 210.252 609.712 210.252V210.252Z"
                        fill="white"
                      />
                      <path
                        id="Vector_25"
                        d="M605.702 249.892H584.502C583.345 249.893 582.199 249.666 581.129 249.224C580.06 248.782 579.088 248.134 578.27 247.316C577.451 246.498 576.802 245.527 576.359 244.458C575.916 243.39 575.688 242.244 575.688 241.087C575.688 239.93 575.916 238.784 576.359 237.715C576.802 236.647 577.451 235.675 578.27 234.858C579.088 234.04 580.06 233.392 581.129 232.95C582.199 232.508 583.345 232.281 584.502 232.282H605.702C606.859 232.281 608.005 232.508 609.074 232.95C610.143 233.392 611.115 234.04 611.934 234.858C612.752 235.675 613.402 236.647 613.845 237.715C614.288 238.784 614.516 239.93 614.516 241.087C614.516 242.244 614.288 243.39 613.845 244.458C613.402 245.527 612.752 246.498 611.934 247.316C611.115 248.134 610.143 248.782 609.074 249.224C608.005 249.666 606.859 249.893 605.702 249.892Z"
                        fill="white"
                      />
                    </g>
                    <path
                      id="Vector_26"
                      d="M893.727 460.152C893.727 460.152 868.737 332.732 864.797 321.992C862.627 316.122 861.197 311.852 861.197 311.852L787.757 316.052L787.157 321.992L777.257 471.012C777.257 471.012 780.757 461.542 783.897 459.802C787.037 458.062 786.707 470.992 786.707 470.992"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_27"
                      d="M788.32 316H852.599C850.794 309.541 852.599 303.555 855.177 298.962C856.98 295.736 859.247 292.792 861.907 290.227L862.349 289.832L884.808 284.852L886.016 284.589C886.016 284.589 886.194 243.783 871.751 238.184C863.815 235.149 856.359 230.985 849.613 225.82C848.949 225.296 848.135 224.998 847.29 224.969L815.762 223.815C814.792 223.782 813.843 224.101 813.091 224.713C807.878 228.904 791.302 237.318 782.389 241.719C780.824 242.491 779.616 243.836 779.016 245.475C778.417 247.114 778.472 248.921 779.17 250.521C782.729 258.693 788.776 272.36 789.412 272.36C790.318 272.36 788.32 316 788.32 316Z"
                      fill="#A60071"
                    />
                    <path
                      id="Vector_28"
                      d="M831.899 249.818L862.108 327.933C862.108 327.933 841.684 332.642 821.985 308.567L814.876 293.05L798.86 233.912L831.899 249.818Z"
                      fill="#F6F8FB"
                    />
                    <path
                      id="Vector_29"
                      d="M839.918 208C862.562 208 880.918 189.644 880.918 167C880.918 144.356 862.562 126 839.918 126C817.274 126 798.918 144.356 798.918 167C798.918 189.644 817.274 208 839.918 208Z"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_30"
                      d="M813.91 304.369C815.032 305.774 816.577 306.781 818.316 307.24C820.055 307.698 821.895 307.585 823.565 306.916L845.529 315.511L852.848 298.965L828.099 295.769C827.618 294.68 826.911 293.707 826.025 292.914C825.138 292.121 824.093 291.526 822.958 291.169C821.824 290.812 820.626 290.701 819.445 290.843C818.264 290.985 817.127 291.378 816.11 291.994C815.093 292.611 814.219 293.437 813.547 294.418C812.874 295.399 812.418 296.513 812.21 297.684C812.002 298.855 812.046 300.057 812.339 301.209C812.632 302.362 813.168 303.439 813.91 304.369V304.369Z"
                      fill="#A0616A"
                    />
                    <path
                      id="Vector_31"
                      d="M833.701 217.883C848.193 217.883 859.94 206.136 859.94 191.645C859.94 177.153 848.193 165.406 833.701 165.406C819.21 165.406 807.462 177.153 807.462 191.645C807.462 206.136 819.21 217.883 833.701 217.883Z"
                      fill="#A0616A"
                    />
                    <path
                      id="Vector_32"
                      d="M870.601 174.266C856.647 181.863 840.941 185.658 825.058 185.269C818.975 185.112 812.272 184.005 808.419 179.294C804.072 173.979 805.16 165.674 809.353 160.237C813.547 154.799 820.107 151.734 826.652 149.655C832.844 147.689 839.478 146.422 845.828 147.797C849.179 148.522 852.335 149.961 855.08 152.015C857.825 154.069 860.096 156.69 861.738 159.7C863.38 162.71 864.354 166.039 864.595 169.459C864.835 172.879 864.337 176.311 863.133 179.521"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_33"
                      d="M868.918 161C881.621 161 891.918 150.703 891.918 138C891.918 125.297 881.621 115 868.918 115C856.216 115 845.918 125.297 845.918 138C845.918 150.703 856.216 161 868.918 161Z"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_34"
                      d="M877.156 273.746L886.008 284.589L887.812 314.017C887.812 314.017 890.136 329.003 873.005 325.394C855.874 321.785 833.827 314.767 833.827 314.767C833.827 314.767 845.559 297.762 840.323 292.891L864.881 300.836C864.881 300.836 869.032 299.93 865.779 296.321C862.527 292.713 861.954 286.207 861.954 286.207L877.156 273.746Z"
                      fill="#A60071"
                    />
                    <path
                      id="Vector_35"
                      d="M831.702 299.226C829.997 299.798 828.153 299.805 826.444 299.247C824.734 298.689 823.25 297.595 822.21 296.127L799.096 291.431L801.925 273.562L824.449 284.304C825.443 283.65 826.565 283.216 827.74 283.031C828.915 282.846 830.116 282.913 831.262 283.228C832.409 283.544 833.476 284.1 834.39 284.86C835.305 285.621 836.047 286.567 836.567 287.637C837.087 288.707 837.373 289.875 837.406 291.064C837.439 292.253 837.217 293.435 836.757 294.532C836.297 295.629 835.608 296.615 834.736 297.424C833.864 298.233 832.83 298.848 831.702 299.226V299.226Z"
                      fill="#A0616A"
                    />
                    <path
                      id="Vector_36"
                      d="M801.197 257.905C801.197 257.905 797.186 263.058 792.496 264.325C787.805 265.592 790.801 268.606 790.801 268.606L815.741 275.254C808.7 276.506 809.33 297.155 809.33 297.155C809.33 297.155 787.002 291.09 770.653 284.828C754.305 278.565 764.387 267.237 764.387 267.237L781.868 243.495L795.186 239.189L801.197 257.905Z"
                      fill="#A60071"
                    />
                    <path
                      id="Vector_37"
                      d="M36.427 538.404L48.46 543.575L74.133 499.623L56.374 491.99L36.427 538.404Z"
                      fill="#FFB8B8"
                    />
                    <path
                      id="Vector_38"
                      d="M66.8644 564.722L29.0462 548.469L35.3258 533.858L59.0788 544.066C62.8091 545.669 65.7498 548.688 67.2539 552.46C68.758 556.231 68.7024 560.445 67.0993 564.176L66.8644 564.722L66.8644 564.722Z"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_39"
                      d="M119.429 553.213H132.527L138.76 502.695H119.429V553.213Z"
                      fill="#FFB8B8"
                    />
                    <path
                      id="Vector_40"
                      d="M157.786 565.375H116.623V549.471H142.476C146.537 549.471 150.431 551.084 153.302 553.955C156.173 556.826 157.786 560.72 157.786 564.78V565.375L157.786 565.375Z"
                      fill="#2F2E41"
                    />
                    <g id="Group" opacity="0.1">
                      <path
                        id="Vector_41"
                        d="M39.6967 167.792L40.4367 167.952C40.2206 167.804 39.9549 167.746 39.6967 167.792V167.792Z"
                        fill="url(#paint0_linear_1405_70)"
                      />
                    </g>
                    <path
                      id="Vector_42"
                      d="M141.549 507.976L111.423 509.17C104.068 504.226 81.2341 387.418 81.2341 387.418C81.39 388.322 78.3773 503.353 78.3773 503.353H45.9654L30.0414 323.974L47.6171 331.05C47.6171 331.05 128.098 324.524 131.848 329.168C132.638 330.684 141.549 507.976 141.549 507.976Z"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_43"
                      d="M73.7013 196.883C88.1926 196.883 99.9401 185.136 99.9401 170.645C99.9401 156.153 88.1926 144.406 73.7013 144.406C59.21 144.406 47.4625 156.153 47.4625 170.645C47.4625 185.136 59.21 196.883 73.7013 196.883Z"
                      fill="#FFB8B8"
                    />
                    <path
                      id="Vector_44"
                      d="M118.163 377.778C117.032 376.291 116.41 374.479 116.389 372.611C116.369 370.742 116.95 368.917 118.048 367.406L114.966 343.098L133.492 339.926L130.428 365.669C131.402 366.429 132.203 367.388 132.777 368.482C133.351 369.577 133.685 370.781 133.757 372.014C133.828 373.248 133.636 374.483 133.193 375.636C132.75 376.789 132.066 377.835 131.186 378.703C130.307 379.571 129.253 380.242 128.094 380.67C126.935 381.099 125.698 381.275 124.465 381.187C123.233 381.1 122.033 380.75 120.946 380.163C119.859 379.575 118.91 378.762 118.163 377.778H118.163Z"
                      fill="#FFB8B8"
                    />
                    <path
                      id="Vector_45"
                      d="M77.6765 153.045L78.2549 153.17C78.0849 153.057 77.8781 153.012 77.6765 153.045V153.045Z"
                      fill="#E1E7EF"
                    />
                    <path
                      id="Vector_46"
                      d="M65.2759 160.81C70.9885 162.058 76.8018 163.248 82.6363 162.86C88.4708 162.472 94.4214 160.312 98.2101 155.858C101.999 151.404 103.047 144.411 99.6839 139.627C95.6853 133.939 87.667 133.257 80.7155 133.113L58.8113 132.659C54.4834 132.569 49.9422 132.527 46.1092 134.539C39.4499 138.034 37.1511 146.523 37.2842 154.042C37.4604 161.233 39.1896 168.3 42.3533 174.76C45.517 181.22 50.0403 186.919 55.6131 191.467C58.5775 193.853 63.458 195.667 65.7176 192.605C66.7028 191.27 57.0831 175.906 57.0553 174.248C56.894 164.604 66.3651 168.532 66.2038 158.889"
                      fill="#2F2E41"
                    />
                    <path
                      id="Vector_47"
                      d="M39.9895 330.592C64.9583 350.119 92.5191 359.962 123.718 356.21C110.541 309.946 106.633 264.032 119.344 218.746L93.1006 206.875L57.485 205L36.0448 211.306C33.0288 212.194 30.3884 214.049 28.5301 216.585C26.6718 219.121 25.6988 222.198 25.7608 225.341C26.5179 263.962 30.0667 300.105 39.9895 330.592Z"
                      fill="#E6E6E6"
                    />
                    <path
                      id="Vector_48"
                      d="M118.719 359.959L134.965 361.834L131.161 241.199C130.991 235.808 129.272 230.581 126.21 226.141C123.149 221.702 118.873 218.238 113.895 216.164L108.097 213.748L109.659 270.295L118.719 359.959Z"
                      fill="#E6E6E6"
                    />
                    <path
                      id="Vector_49"
                      d="M39.0867 351.822C37.4596 350.904 36.1715 349.486 35.4132 347.779C34.6549 346.071 34.4669 344.165 34.8768 342.342L22.4264 321.238L38.1846 310.995L45.5585 335.848C46.7539 336.16 47.8689 336.724 48.8291 337.502C49.7892 338.28 50.5725 339.254 51.1265 340.358C51.6806 341.462 51.9928 342.672 52.0423 343.907C52.0917 345.142 51.8773 346.373 51.4134 347.518C50.9494 348.663 50.2466 349.696 49.3517 350.548C48.4569 351.4 47.3906 352.052 46.224 352.459C45.0575 352.867 43.8174 353.021 42.5867 352.911C41.3559 352.801 40.1627 352.43 39.0867 351.822V351.822Z"
                      fill="#FFB8B8"
                    />
                    <path
                      id="Vector_50"
                      d="M32.4915 342.464L47.7018 331.246L31.2418 282.48L37.1778 210.936C24.2832 214.989 10.4378 225.875 8.15733 239.198L0 286.854L32.4915 342.464Z"
                      fill="#E6E6E6"
                    />
                    <path
                      id="Vector_51"
                      opacity="0.2"
                      d="M38.925 270.993L32.23 297.771L47.422 335.77L35.835 299.831L38.925 270.993Z"
                      fill="black"
                    />
                    <path
                      id="Vector_52"
                      opacity="0.2"
                      d="M105.236 284.875L110.404 342.256L101.653 285.275L105.236 284.875Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1405_70"
                      x1="40.0667"
                      y1="167.992"
                      x2="40.0667"
                      y2="167.812"
                      gradientUnits="userSpaceOnUse">
                      <stop stopColor="#808080" stopOpacity="0.25" />
                      <stop
                        offset="0.54"
                        stopColor="#808080"
                        stopOpacity="0.12"
                      />
                      <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
                    </linearGradient>
                    <clipPath id="clip0_1405_70">
                      <rect width="898.978" height="570.08" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="title">{imgTitle[currentIndex]}</p>
                <p className="desc">{imgDesc[currentIndex]}</p>

                {/* Controls */}
                <Grid
                  container
                  className="controls-grid"
                  spacing={0}
                  justifyContent="center">
                  <Grid item xs={2} className="arrow-grid">
                    <ArrowBackIos className="arrow" onClick={prevImage} />
                  </Grid>
                  <Grid item xs={8}>
                    <div className="pointers">
                      <div
                        className={
                          currentIndex === 0
                            ? 'dot-point-selected'
                            : 'dot-point'
                        }
                        onClick={() => setCurrentIndex(0)}></div>
                      <div
                        className={
                          currentIndex === 1
                            ? 'dot-point-selected'
                            : 'dot-point'
                        }
                        onClick={() => setCurrentIndex(1)}></div>
                      <div
                        className={
                          currentIndex === 2
                            ? 'dot-point-selected'
                            : 'dot-point'
                        }
                        onClick={() => setCurrentIndex(2)}></div>
                      <div
                        className={
                          currentIndex === 3
                            ? 'dot-point-selected'
                            : 'dot-point'
                        }
                        onClick={() => setCurrentIndex(3)}></div>
                      <div
                        className={
                          currentIndex === 4
                            ? 'dot-point-selected'
                            : 'dot-point'
                        }
                        onClick={() => setCurrentIndex(4)}></div>
                      <div
                        className={
                          currentIndex === 5
                            ? 'dot-point-selected'
                            : 'dot-point'
                        }
                        onClick={() => setCurrentIndex(5)}></div>
                    </div>
                  </Grid>
                  <Grid item xs={2} className="arrow-grid">
                    <ArrowForwardIos className="arrow" onClick={nextImage} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className="mobile">
        {/* Top Section */}

        <AppBar position="static" className="appbar">
          <Link to="/">
            <Toolbar variant="regular" className="top">
              <img
                className="logo"
                alt="Logo"
                src={process.env.PUBLIC_URL + '/assets/lift.png'}
              />
            </Toolbar>
          </Link>
        </AppBar>

        <Login />

        <div className="foot">
          <p>We recommend using this application in your Laptop or Tablet</p>
        </div>
      </div>
    </div>
  );
};

export default Auth;
