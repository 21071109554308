import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toastify } from "../../App";

const JoinOrg = () => {
  const [showInviteOrg, setShowInviteOrg] = useState(true);
  const [org, setOrg] = useState(null);
  const [query] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    GetInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetInvitation = async () => {
    try {
      const token = query.get("token");
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/org/change?token=${token}`
      );

      setOrg(res.data?.organization);
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  const accectInvitation = async () => {
    try {
      const token = query.get("token");
      const res = await axios.post(
        "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/org/change/accept",
        {
          token: token,
        }
      );

      console.log(res, "accecpt");

      navigate("/auth");
    } catch (error) {
      Toastify('error', error.response?.data?.message);
      console.log(error);
    }
  };

  const declineInvitation = async () => {
    try {
      const token = query.get("token");
      await axios.post(
        "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/user/org/change/decline",
        {
          token: token,
        }
      );
      setShowInviteOrg(false);
    } catch (error) {
      Toastify('error', error.response?.data?.message);
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog disableEscapeKeyDown open={showInviteOrg}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Organization Invited
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <p>This is a invitation to join organization : {org?.name}</p>
            <img
              alt=""
              style={{
                width: "200px",
                height: "250px",
              }}
              src={org?.image}
            />

            <p>Do you want to join ?</p>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              accectInvitation();
            }}
          >
            <div>
              {/* {!send_invitation ? ( */}
              <p>Accept</p>
              {/* ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )} */}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              declineInvitation();
            }}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
      {showInviteOrg ? (
        <></>
      ) : (
        <div>
          <h1> You have decliened the invitation</h1>
        </div>
      )}
    </div>
  );
};

export default JoinOrg;
