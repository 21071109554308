
import { useAuthContext } from "../provider/AuthProvider";
import React from "react";
import { useNavigate } from "react-router-dom";
import PreLoader from "../../components/preloader";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { authUser, loading } = useAuthContext();

  if (loading) {
    return <PreLoader />;
  }

  if (!authUser) {
    // navigate("/auth");
  }

  return <>{children}</>;
};

export default ProtectedRoute;
