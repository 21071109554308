import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function TableSkeleton({ width }) {
  return (
    <Skeleton
      sx={{ bgcolor: "grey.100" }}
      variant="rounded"
      style={{
        width: `${width ? width : 96}%`,
        display: "inline-block",
        height: "60px",
        padding: "16px",
        borderBottom: "1px solid #fff",
      }}
    />
  );
}
