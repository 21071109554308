import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  ThemeProvider,
  createTheme,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Search, Add, DeleteOutline, Refresh, Mail } from "@mui/icons-material";
import axios from "axios";

import validator from "validator";

import "./admin-user.styles.scss";

import AxiosDev from "../../helper/axiosDev";
import { useDispatch, useSelector } from "react-redux";
import { Toastify } from "../../App";
import { Box } from "@mui/system";
import { send_invitation_user } from "../../redux/action/super_admin.action";
import TableSkeleton from "../skeleton-loader/table";
import { debounce } from "../../utils/debounce";
import { useThrottledFn } from "../../utils/throttle";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const AdminUsers = () => {
  const [toggle, setToggle] = useState("all-admins");
  const [admins, setAdmins] = useState([]);
  const [adminBackup, setAdminsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState("");

  //
  const super_token = JSON.parse(localStorage.getItem("super_token"));
  const admin_data = JSON.parse(localStorage.getItem("admin_auth"));

  //invite
  const [inviteOrgdata, setInviteOrgData] = useState();
  const [inviteType, setInviteType] = useState(0);
  const [inviteOrgPersonName, setInviteOrgPersonName] = useState();
  const [inviteOrgPersonEmail, setInviteOrgPersonEmail] = useState();
  const [showInviteOrgDialog, setShowInviteOrgDialog] = useState(false);

  const [orgs, setOrgs] = useState([]);
  //invite

  //redux
  const { admin_auth, superAdmin } = useSelector((state) => state.admin);

  const { send_invitation } = useSelector((state) => state.super_admin);
  const dispatch = useDispatch();
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Format Date
  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds()
    );
  };
  const [loadList, setLoadList] = useState(true);

  const get_ref = useRef(true);
  useEffect(() => {
    getAdmins();
    if (get_ref.current && super_token?.token) {
      get_ref.current = false;

      if (superAdmin) {
        getOrgs();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin_auth === null || admin_auth === undefined]);

  // Function to get all users
  const getAdmins = () => {
    setToggle("all-admins");
    if (!superAdmin) {
      AxiosDev(admin_auth?.token)
        .get(`/public/admin/user/get?admin_id=${admin_auth?.id}`)
        .then((response) => {
          console.log("testing", loadList);
          setLoadList(false);
          setAdmins(response.data.sort(customSortBasedOnDate).reverse());
          setAdminsBackup(response.data.sort(customSortBasedOnDate).reverse());

          let userTrackStat = {};
          for (let i = 0; i < response.data.length; i++)
            userTrackStat[response.data[i]["id"]] =
              response.data[i]["track_usage"];

          let userTeamStat = {};
          for (let i = 0; i < response.data.length; i++)
            userTeamStat[response.data[i]["id"]] = response.data[i]["team"];
        })
        .catch((err) => {
          Toastify("error", err.response.data.message);
          console.log(err, "error");
        });
    } else {
      axios
        .get(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/get`,
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((response) => {
          console.log("testing", loadList);
          setLoadList(false);
          setAdmins(response.data.sort(customSortBasedOnDate).reverse());
          setAdminsBackup(response.data.sort(customSortBasedOnDate).reverse());

          let userTrackStat = {};
          for (let i = 0; i < response.data.length; i++)
            userTrackStat[response.data[i]["id"]] =
              response.data[i]["track_usage"];

          let userTeamStat = {};
          for (let i = 0; i < response.data.length; i++)
            userTeamStat[response.data[i]["id"]] = response.data[i]["team"];
        })
        .catch((err) => {
          Toastify("error", err.response.data.message);
          console.log(err, "error");
        });
    }
  };

  const throttleGetAdmin = useThrottledFn(getAdmins, 1000);
  // Function to sort users data based on their date
  const customSortBasedOnDate = (a, b) =>
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

  // fuction to delete admin
  const deleteUser = (admin) => {
    if (!superAdmin) {
      axios
        .delete(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/delete?admin_id=${admin_auth.id}&user_id=${admin.id}`,

          {
            headers: {
              Authorization: `JWT ${admin_auth?.token}`,
            },
          }
        )
        .then((_) => {
          getAdmins();
          Toastify("success", "User Deleted");
        })
        .catch((error) => {
          Toastify("error", error.response.data.message);
          console.error(error);
        });
    } else {
      axios
        .delete(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/delete?user_id=${admin.id}`,
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((_) => {
          getAdmins();
          Toastify("success", "User Deleted");
        })
        .catch((error) => {
          Toastify("error", error.response.data.message);
          console.error(error);
        });
    }
  };

  const throttleDeleteUser = useThrottledFn(deleteUser, 1000);
  //function to delete invited admin

  const deleteInvitedUser = (admin) => {
    if (!superAdmin) {
      //

      axios
        .delete(
          admin.invite_type === "non-existing"
            ? `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/invite/delete?admin_id=${admin_auth.id}&invite_id=${admin.id}`
            : `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/org/change/delete?admin_id=${admin_auth.id}&token=${admin.token}`,
          {
            headers: {
              Authorization: `JWT ${admin_auth?.token}`,
            },
          }
        )
        .then((_) => {
          getInvitedAdmins();
          Toastify("success", "Invited User Deleted");
        })
        .catch((error) => {
          Toastify("error", error.response.data.message);
          console.error(error);
        });
    } else {
      axios
        .delete(
          admin.invite_type === "non-existing"
            ? `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/invite/delete?user_invite_id=${admin.id}`
            : `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/org/change/delete?token=${admin.token}`,
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((_) => {
          getInvitedAdmins();
          Toastify("success", "Invited User Deleted");
        })
        .catch((error) => {
          Toastify("error", error.response.data.message);
          console.error(error)
        });
    }
  };

  const throttleDeleteInvitedUser = useThrottledFn(deleteInvitedUser, 1000);
  //function to re send invitation to admin
  //send invitation
  const sendInvitation = async () => {
    try {
      if (
        typeof inviteOrgPersonName === "undefined" ||
        inviteOrgPersonName === null ||
        inviteOrgPersonName === ""
      ) {
        Toastify("warning", "Name of Admin is required!");
      } else if (
        superAdmin &&
        (typeof inviteOrgdata === "undefined" ||
          inviteOrgdata === null ||
          inviteOrgdata === "")
      ) {
        Toastify("warning", "Organization not selected!");
      } else if (
        typeof inviteOrgPersonEmail === "undefined" ||
        inviteOrgPersonEmail === null ||
        inviteOrgPersonEmail === ""
      ) {
        Toastify("warning", "Email of Admin is required!");
      } else if (!validator.isEmail(inviteOrgPersonEmail)) {
        Toastify("warning", "Enter a valid email");
      } else {
        let reqBody;
        if (superAdmin) {
          reqBody = {
            organization: inviteOrgdata.id,
            name: inviteOrgPersonName,
            email: inviteOrgPersonEmail,
          };
        } else {
          reqBody = {
            admin_id: admin_auth.id,
            name: inviteOrgPersonName,
            email: inviteOrgPersonEmail,
          };
        }

        const adminDetails = {
          superAdmin: superAdmin,
          admin: { ...admin_auth },
        };
        dispatch(
          send_invitation_user(
            reqBody,

            setShowInviteOrgDialog,
            setInviteType,
            setInviteOrgData,
            setInviteOrgPersonEmail,
            setInviteOrgPersonName,
            toggle === "invited-admins" ? getInvitedAdmins : "",
            adminDetails,
            super_token
          )
        );
      }
    } catch (error) {
      Toastify("error", error.response.data.message);
      console.log(error, "sendinvitation");
    }
  };

  const sendReInvitation = async (admin) => {
    try {
      if (
        typeof admin === "undefined" ||
        typeof admin.name === "undefined" ||
        admin.name === null ||
        admin.name === ""
      ) {
        Toastify("warning", "Name of Admin is required!");
      } else if (
        typeof admin === "undefined" ||
        typeof admin.email === "undefined" ||
        admin.email === null ||
        admin.email === ""
      ) {
        Toastify("warning", "Email of Admin is required!");
      } else if (!validator.isEmail(admin.email)) {
        Toastify("warning", "Enter a valid email");
      } else {
        let reqBody;

        if (superAdmin) {
          reqBody = {
            user_invite_id: admin.id,
          };
        } else {
          reqBody = {
            admin_id: admin_auth.id,
            user_invite_id: admin.id,
          };
        }
        let res;

        if (superAdmin) {
          res = await axios.post(
            admin.invite_type === "non-existing"
              ? `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/invite/resend`
              : `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/org/change/resend?token=${admin.token}`,
            reqBody,
            {
              headers: {
                Authorization: `JWT ${super_token?.token}`,
              },
            }
          );
        } else {
          //

          res = await axios.post(
            admin.invite_type === "non-existing"
              ? `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/invite/resend`
              : `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/org/change/resend?admin_id=${admin_auth.id}&token=${admin.token}`,
            reqBody,
            {
              headers: {
                Authorization: `JWT ${admin_auth.token}`,
              },
            }
          );
        }

        if (res.status === 200) {
          Toastify("success", "Invite Sent !");
        }
      }
    } catch (error) {
      Toastify("error", error.response.data.message);
      console.log(error, "sendinvitation");
    }
  };

  const throttleSendReInvitation = useThrottledFn(sendReInvitation, 1000);
  // Function to update data
  const updateDataOnToggle = (toggleActionName) => {
    setToggle(toggleActionName);
    setPage(0);

    if (toggleActionName === "all-admins") {
      setAdmins(adminBackup);
    } else if (toggleActionName === "invited-admins") {
      // setAdmins(adminBackup.filter((x) => x.admin_verified === 0));
      getInvitedAdmins();
    }
  };

  const getInvitedAdmins = async () => {
    try {
      if (!superAdmin) {
        // https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/invite/get?admin_id=10

        const response = await axios.get(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/user/invite/get?admin_id=${admin_auth.id}`,
          {
            headers: {
              Authorization: `JWT ${admin_auth?.token}`,
            },
          }
        );

        setAdmins(response.data.sort(customSortBasedOnDate).reverse());
        setAdminsBackup(response.data.sort(customSortBasedOnDate).reverse());
      } else {
        const response = await axios.get(
          "https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/user/invite/get",
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        setAdmins(response.data.sort(customSortBasedOnDate).reverse());
        setAdminsBackup(response.data.sort(customSortBasedOnDate).reverse());
      }
    } catch (error) {
      Toastify("error", error?.response?.data?.message);
      console.log(error);
    }
  };

  // Function to handle search results
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < adminBackup.length; i++) {
      if (
        adminBackup[i]["name"].toLowerCase().includes(query.toLowerCase()) ||
        adminBackup[i]["email"].toLowerCase().includes(query.toLowerCase())
      ) {
        results.push(adminBackup[i]);
      }
    }

    setAdmins(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));

  //org
  const getOrgs = () => {
    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((response) => {
        setOrgs(response.data.sort(customSortBasedOnDate).reverse());
      })
      .catch((err) => {
        Toastify("error", err.response.data.message);
        console.error(err);
      });
  };

  return (
    <div className="admins">
      <Dialog disableEscapeKeyDown open={showInviteOrgDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Invite User
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              {superAdmin ? (
                <TextField
                  select
                  required
                  size="small"
                  label="Type"
                  helperText="Eg. Admin "
                  value={inviteType}
                  onChange={(e) => {
                    setInviteType(e.target.value);
                  }}
                >
                  {/* <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>User</MenuItem> */}
                  {/* {console.log(orgs, "orgs")} */}
                  <MenuItem
                    onClick={() => {
                      setInviteOrgData(null);
                    }}
                    value={0}
                  >
                    Choose Organization
                  </MenuItem>
                  {orgs.map((o, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          setInviteOrgData(o);
                        }}
                        value={i + 1}
                      >
                        {o.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : (
                <></>
              )}

              <TextField
                required
                size="small"
                label="Name"
                helperText="Eg. - Ross "
                value={inviteOrgPersonName}
                onChange={(event) => {
                  setInviteOrgPersonName(event.target.value);
                }}
              />
              <TextField
                required
                size="small"
                label="Email"
                type={"email"}
                helperText="Eg. - ross@lt.partners"
                value={inviteOrgPersonEmail}
                onChange={(event) => {
                  setInviteOrgPersonEmail(event.target.value);
                }}
              />

              {/* <div>Make sure the brand name matches with Affluent!</div> */}
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!send_invitation) {
                sendInvitation();
              }
            }}
          >
            <div>
              {!send_invitation ? (
                <p>Send</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => setShowInviteOrgDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="head">
        <div className="head-txt">Users</div>
        <Refresh
          className="head-refresh"
          onClick={() => {
            throttleGetAdmin();
          }}
        />
        {superAdmin ? (
          <div className="btn-control-link">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Button className="add-user-btn">
                <div
                  className="add-user-content"
                  onClick={() => setShowInviteOrgDialog(true)}
                >
                  <Add className="add-icon" />
                  <p className="add-text">Invite User</p>
                </div>
              </Button>
              {admin_data ? (
                <Button className="add-user-btn">
                  <a
                    rel="noreferrer"
                    href={`https://app.lift.lt.partners/testload?_id=${
                      admin_data?.user
                    }&token=${admin_data?.token}&onboard=${1}`}
                    target={"_blank"}
                  >
                    <div className="add-user-content">
                      <Add className="add-icon" />
                      <p className="add-text">Go to LIFT</p>
                    </div>
                  </a>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (

          <div className="btn-control-link">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {admin_data ? (
                <Button className="add-user-btn">
                  <a
                    rel="noreferrer"
                    href={`https://app.lift.lt.partners/testload?_id=${
                      admin_data?.user
                    }&token=${admin_data?.token}&onboard=${1}`}
                    target={"_blank"}
                  >
                    <div className="add-user-content">
                      {/* <Add className="add-icon" /> */}
                      <p className="add-text">Go to LIFT</p>
                    </div>
                  </a>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="toggle-div">
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle("all-admins");
            getAdmins();
          }}
        >
          <p className={toggle === "all-admins" ? "selected-toggle-text" : ""}>
            Active Users
          </p>
          <div
            className={toggle === "all-admins" ? "selected-toggle-bar" : ""}
          ></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle("invited-admins");
            getInvitedAdmins();
          }}
        >
          <p
            className={
              toggle === "invited-admins" ? "selected-toggle-text" : ""
            }
          >
            Invited Users
          </p>
          <div
            className={toggle === "invited-admins" ? "selected-toggle-bar" : ""}
          ></div>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: "calc(100% - 60px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {superAdmin ? (
                  <TableCell
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      color: "grey",
                    }}
                  >
                    Organization
                  </TableCell>
                ) : (
                  <></>
                )}
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Registered at
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  Controls
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {superAdmin ? (
                        <TableCell
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                          }}
                        >
                          <img
                            alt="fav"
                            className="avtar"
                            src={
                              row?.organization?.image
                                ? row?.organization?.image
                                : "https://storage.googleapis.com/ltp-static-data/ltp-analytics-cient/ltp_inverted.png"
                            }
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              height: "60px",
                              width: "120px",
                              objectFit: "contain",
                              borderRadius: "5px",
                              marginRight: "15px",
                              cursor: "pointer",
                            }}
                          />
                        </TableCell>
                      ) : (
                        <></>
                      )}
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}
                      >
                        <img
                          alt="fav"
                          className="avtar"
                          src={
                            row?.image
                              ? row?.image
                              : "https://storage.googleapis.com/ltp-static-data/ltp-analytics-cient/ltp_inverted.png"
                          }
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            height: "40px",
                            width: "40px",
                            objectFit: "cover",
                            borderRadius: "5px",
                            marginRight: "15px",
                          }}
                        />
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {row.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "500",
                              color: "rgb(166, 0, 113)",
                              fontSize: "0.8rem",
                            }}
                          >
                            {/* {userTeamStatus[row.id] === 1
                              ? "All Brands"
                              : `${row.organization.split(",").length} Brand(s)`} */}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.email}
                          </p>
                          {toggle !== "invited-admins" ? (
                            <Tooltip
                              title={
                                row.verified === 0
                                  ? "Email not verified!"
                                  : "Email Verified!"
                              }
                              style={{
                                fontFamily: '"Montserrat", sans-serif',
                              }}
                            >
                              <img
                                alt="status"
                                src={
                                  row.verified === 0
                                    ? process.env.PUBLIC_URL +
                                      "/assets/close.png"
                                    : process.env.PUBLIC_URL +
                                      "/assets/correct.png"
                                }
                                height="18"
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                          fontSize: "0.8rem",
                        }}
                      >
                        {formatDate(row.created_at)}
                      </TableCell>
                      <TableCell>
                        <div className="actions-div-admin-user">
                          {/* {row.admin_verified === 0 ||
                          row.admin_verified === 2 ||
                          row.admin_verified === 3 ? (
                            <Tooltip title="Allow">
                              <Done
                                onClick={() => allowUser(row)}
                                style={{
                                  cursor: "pointer",
                                  color: "rgb(39, 181, 36)",
                                  backgroundColor: "rgba(71, 237, 52, 0.3)",
                                  border: "1px solid rgb(39, 181, 36)",
                                  padding: "3px",
                                  fontSize: "15px",
                                  marginLeft: "15px",
                                  borderRadius: "5px",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )} */}

                          <Tooltip title="Delete">
                            <DeleteOutline
                              onClick={() => {
                                if (toggle === "invited-admins") {
                                  throttleDeleteInvitedUser(row);
                                } else {
                                  throttleDeleteUser(row);
                                }
                              }}
                              style={{
                                cursor: "pointer",
                                color: "rgb(252, 3, 3)",
                                backgroundColor: "rgba(252, 3, 3, 0.3)",
                                border: "1px solid rgb(252, 3, 3)",
                                padding: "3px",
                                fontSize: "15px",
                                marginLeft: "15px",
                                borderRadius: "5px",
                              }}
                            />
                          </Tooltip>

                          {toggle === "invited-admins" ? (
                            <Tooltip title="Resend">
                              <Mail
                                onClick={() => {
                                  throttleSendReInvitation(row);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "rgb(36, 169, 181)",
                                  backgroundColor: "rgba(36, 169, 181, 0.3)",
                                  border: "1px solid rgb(36, 169, 181)",
                                  padding: "3px",
                                  fontSize: "15px",
                                  marginLeft: "15px",
                                  borderRadius: "5px",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={admins.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: "10px",
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>

      <div className="foot">
        <p>All rights reserved | LT Partners</p>
      </div>
    </div>
  );
};

export default AdminUsers;
