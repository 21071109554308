import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./date-picker.style.scss";

export default function DatePickerValue({ disabled, label, value, setValue }) {
  return (
    <div className="date-picker-custom">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className="date-picker-style"
          disabled={disabled}
          format="MM/DD/YYYY"
          label={label}
          value={value}
          onChange={(newValue) => setValue(newValue)}
        />
      </LocalizationProvider>
    </div>
  );
}
