import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useImperativeHandle,
} from 'react';
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  CircularProgress,
  Checkbox,
  TextField,
  FormControlLabel,
  Autocomplete,
} from '@mui/material';

import axios from 'axios';

import './ga-imports.styles.scss';

import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useThrottledFn } from '../../utils/throttle';
import { actionLogTrack } from '../../lib/logs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import apiClient from '../../utils/axiosClient';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const GaImports = ({ childRef }) => {
  const [gaBrands, setGaBrands] = useState([]);
  const [gaBrandBackup, setGaBrandBackup] = useState([]);
  const [viewIds, setViewIds] = useState([]);
  const [propertyIds, setPropertyIds] = useState([]);

  const [properties, setProperties] = useState([]);
  const [views, setViews] = useState([]);

  const [getAllLogs, setGetAllLogs] = useState({});

  const [selectedData, setSelectedData] = useState(null);

  const [selected, setSelected] = useState({
    ga_brand: { idx: -1, id: -1, isHovered: false },
    property: { data: [], id: -1, isHovered: false },
    view: { data: [], id: -1, isHovered: false },
  });

  const [page, setPage] = useState(0);
  const [pageLog, setPageLog] = useState(0);
  const [pageLogHistory, setPageLogHistory] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowsPerPageLog, setRowsPerPageLog] = useState(25);
  const [rowsPerPageLogHistory, setRowsPerPageLogHistory] = useState(25);
  const [showTriggerBackFill, setShowTriggerBackFill] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTrigger, setLoadingTrigger] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [propertiesForGATrigger, setPropertiesForGATrigger] = useState('');

  //super token

  const super_token = JSON.parse(localStorage.getItem('super_token'));
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangePageLog = (_, newPage) => {
    setPageLog(newPage);
  };

  const handleChangePageLogHistory = (_, newPage) => {
    setPageLogHistory(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeRowsPerPageLog = (event) => {
    setRowsPerPageLog(+event.target.value);
    setPageLog(0);
  };

  const handleChangeRowsPerPageLogHistory = (event) => {
    setRowsPerPageLog(+event.target.value);
    setPageLogHistory(0);
  };

  const get_ref = useRef(true);

  useEffect(() => {
    if (get_ref.current) {
      get_ref.current = false;
      geGaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getting ll brand weights
  const [listLoad, setListLoad] = useState(true);
  // console.log(views, 'views')

  const geGaData = () => {
    apiClient
      .get(`/affcrm/v1/admin/ga/properties`)
      .then((res) => {
        setListLoad(false);

        const gaData = [];

        for (let j = 0; j < res?.data.response?.length; j++) {
          const row = res?.data.response[j];
          const propertiesNew = [];
          for (let i = 0; i < row?.properties?.length; i++) {
            propertiesNew.push({
              ...row.properties[i],
              select:
                row.properties[i].views.length > 0
                  ? row.properties[i].views.find((v) => v.selected === 0) ===
                  undefined
                  : !!row.properties[i].selected,
              indeterminate:
                row.properties[i].views.length > 0 &&
                row.properties[i].views.some((v) => v.selected === 1) &&
                row.properties[i].views.some((v) => v.selected === 0)
            });
          }

          gaData.push({
            ...row,
            select: !propertiesNew.find((p) => p.select === false),
            indeterminate:
              propertiesNew.some((p) => p.indeterminate === true) ||
              (propertiesNew.some((p) => p.select === true) &&
                propertiesNew.some((p) => p.select === false)),
          });
          console.log(propertiesNew, 'propertyNew')
          console.log(gaData, 'gaNew')
        }
        setGaBrands([...gaData]);
        setGaBrandBackup([...gaData]);
      })
      .catch((err) => {
        Toastify('error', err);
        console.error(err);
      });
  };

  const throttleGetBrandWeights = useThrottledFn(geGaData, 1000);

  const customSort = (a, b) => new Date(b.created_at) - new Date(a.created_at);

  const [showlogs, setShowLogs] = useState(null);

  const explandRow = (idx) => {
    if (showlogs === idx) {
      setShowLogs(null);
      return;
    }
    setShowLogs(idx);
  };

  const [logsDetails, setLogsDetails] = useState([]);
  const [logsDetailsBackup, setLogsDetailsBackUp] = useState([]);

  // TriggerGAImportBox
  const [showTriggerGAImport, setShowTriggerGAImport] = useState(false);

  useImperativeHandle(childRef, () => ({
    addGATrigger() {
      setShowTriggerBackFill(true);
    },
    addGAImportTrigger() {
      setShowTriggerGAImport(true);
    },
    searchResultTrigger(query) {
      // searchResults(query);
    },
  }));

  const [logType, setLogType] = useState(0);

  const Filter = (type) => {
    console.log(type, 'type');
    if (type !== 0) {
      const res = logsDetailsBackup.filter((d) => d.type === type);
      setLogsDetails(res);
    } else {
      setLogsDetails([...logsDetailsBackup]);
    }
    setLogType(type);
  };

  useEffect(() => {
    console.log(gaBrandBackup, 'gaBrandBackup');
  }, [gaBrandBackup, gaBrands]);

  function makeApiRequest(params, type) {
    if (type === 'view') {
      console.log('params for view');
      return apiClient.put(`/affcrm/v1/admin/ga/properties/${params}`);
    } else {
      console.log('params for property');
      return apiClient.put(`/affcrm/v1/admin/ga4/properties/${params}`);
    }
  }

  // Create a function to handle the queue
  function executeQueue(queue, type) {
    if (queue.length === 0) {
      Toastify('success', `Updated succesfully ${type}!!`);
      setLoading(false);
      setViewIds([]);
      setPropertyIds([]);
      geGaData();
      return Promise.resolve();
    }

    const params = queue.shift();

    return makeApiRequest(params, type)
      .then((res) => {
        // console.log(`Success for params:`, params);
        Toastify('success', `Success for params: ${params}`);

        const desc = {
          message: `GA save data for param: ${params}`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        actionLogTrack(JSON.stringify(desc), false, super_token?.token);
        return executeQueue(queue, type);
      })
      .catch((error) => {
        console.error(`Error for params:`, params, error);
        Toastify('error', `Unable to Update  ${params}`);

        actionLogTrack(JSON.stringify(error), true, super_token?.token);

        return executeQueue(queue, type);
      });
  }

  const saveData = () => {
    try {
      if (viewIds.length > 0 || propertyIds.length > 0) {
        if (viewIds.length) {
          // console.log("executing viewsId");
          Toastify('info', 'Executing ViewsID');
          executeQueue([...viewIds], 'view');
        }
        if (propertyIds.length) {
          // console.log("executing property");
          Toastify('info', 'Executing Property');
          executeQueue([...propertyIds], 'property');
        }
      } else {
        setLoading(false);
        Toastify('error', 'No change in views Id!!');
      }
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, 'error');
    }
  };

  const triggerRefreash = async () => {
    try {
      await axios.get(
        `https://ga-account-fetch-y3lajdmf3q-uc.a.run.app`,

        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    } finally {
      setTimeout(async () => {
        setLoadingTrigger(false);
        Toastify('success', 'Triggered successfully!');
      }, [30000]);
    }
  };

  const searchBrands = (query) => {
    let res = [];
    for (let i = 0; i < gaBrandBackup.length; i++) {
      if (
        gaBrandBackup[i].account_name
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        res.push(gaBrandBackup[i]);
      }
    }
    setGaBrands([...res]);
  };

  const searchProperty = (query) => {
    let res = [];
    for (let i = 0; i < properties.length; i++) {
      if (
        properties[i]?.Property_Name?.toLowerCase()?.includes(
          query?.toLowerCase()
        )
      ) {
        res.push(properties[i]);
      }
    }
    setSelected({
      ...selected,
      property: { data: [...res], id: selected.property.id },
    });
  };

  const searchView = (query) => {
    let res = [];
    for (let i = 0; i < views.length; i++) {
      if (views[i]?.view_name?.toLowerCase()?.includes(query?.toLowerCase())) {
        res.push(views[i]);
      }
    }
    setSelected({
      ...selected,
      view: { data: [...res], id: selected.view.id },
    });
  };

  useEffect(() => {
    console.log(propertyIds, 'propertyIds');
  }, [propertyIds]);

  const getGaLogs = async () => {
    try {
      const res = await apiClient.get(`/affcrm/v1/admin/ga/data/log/all`);

      const sortedData = res.data.response.sort((a, b) => {
        const dateComparison = new Date(b.Date) - new Date(a.Date);
        if (dateComparison !== 0) {
          return dateComparison;
        }

        return (
          new Date(`1970-01-01T${b.Time}`) - new Date(`1970-01-01T${a.Time}`)
        );
      });

      const groupedLogs = {};

      sortedData.forEach((row) => {
        const { UUID, ...rest } = row;
        if (!groupedLogs[UUID]) {
          groupedLogs[UUID] = [];
        }
        groupedLogs[UUID].push({ UUID, ...rest });

        // Sort logs for this UUID by most recent first
        groupedLogs[UUID].sort((a, b) => {
          const dateComparison = new Date(b.Date) - new Date(a.Date);
          if (dateComparison !== 0) {
            return dateComparison;
          }

          return (
            new Date(`1970-01-01T${b.Time}`) - new Date(`1970-01-01T${a.Time}`)
          );
        });
      });

      setGetAllLogs(groupedLogs);
      // console.log(groupedLogs, "Grouped Logs");
    } catch (err) {
      Toastify('error', err);
      console.error(err);
    }
  };

  // getGaLogsTable();

  useEffect(() => {
    getGaLogs();
  }, []);

  const [propertiesAll, setPropertiesAll] = useState([]);

  const getAllProperties = () => {
    apiClient
      .get(`/affcrm/v1/admin/ga/properties`)
      .then((res) => {
        setListLoad(false);
        const propertiesNamesAll = []; // to store all the property names

        for (let j = 0; j < res?.data.response?.length; j++) {
          const row = res?.data.response[j];
          for (let i = 0; i < row?.properties?.length; i++) {
            // propertiesNamesAll.push(row.properties[i].property_name);
            propertiesNamesAll.push({
              index: propertiesNamesAll.length, // Storing the index as the key
              name: row.properties[i].property_name, // Storing the property name as the value
            });
          }
        }

        // propertiesNamesAll.sort((a, b) => {
        //   return a.name.localeCompare(b.name);
        // }); //in case it needs to be sorted

        setPropertiesAll(propertiesNamesAll);
      })
      .catch((err) => {
        Toastify('error', err);
        console.error(err);
      });
  };

  useEffect(() => {
    getAllProperties();
  }, []);

  const [selectedUUID, setSelectedUUID] = useState(null);

  const handleOnClick = (UUID) => {
    // console.log(uuid);
    openModal(UUID);
  };

  const openModal = (UUID) => {
    const data = getAllLogs[UUID];
    // console.log(data);
    setSelectedData(data);
    setSelectedUUID(UUID);
    setIsModalOpen(true);
    // console.log(selectedData, "Selected Data");
    // console.log(selectedUUID);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUUID(null);
    setSelectedData(null);
  };

  const [selectedDataLength, setelectedDataLength] = useState(0);

  useEffect(() => {
    // console.log(selectedData, "Selected Data");
    if (selectedData != null) {
      setelectedDataLength(selectedData.length);
    }
    // console.log(selectedDataLength);
  }, [selectedData]);

  useEffect(() => {
    // console.log(selectedUUID);
  }, [selectedUUID]);

  const formatDate = (dateData) => {
    let date = new Date(dateData);
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' '
    );
  };

  const formatTime = (time) => {
    let date = new Date(time);
    return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
  };

  // truncate text wrt letters
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  const [trigger, setTrigger] = useState({
    checked: false,
    // import_start: dayjs(new Date(new Date() - 60 * 24 * 60 * 60 * 1000)),
    // email: '',
    // import_type: 1,
  });

  console.log(propertiesAll);

  console.log(selected.view, 'seleviwe')
  console.log(selected.property.data, 'seleprops')


  return (
    <div className="br-weights">
      <Dialog disableEscapeKeyDown open={showTriggerGAImport}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Trigger GA Import
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <Autocomplete
                options={propertiesAll}
                getOptionLabel={(option) => option.name}
                value={
                  propertiesAll.find(
                    (option) => option.name === propertiesForGATrigger
                  ) || null
                }
                onChange={(event, newValue) => {
                  setPropertiesForGATrigger(newValue ? newValue.name : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="GA Properties"
                    size="large"
                    value={propertiesForGATrigger}
                    onChange={(event) =>
                      setPropertiesForGATrigger(event.target.value)
                    }
                    helperText="GA Properties"
                    style={{
                      margin: '8px',
                      width: '25ch',
                    }}
                  />
                )}
              />
              <div className="lauch-date-style">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    sx={{
                      '& .css-1x5jdmq': { padding: '9px' },
                    }}
                    label="Start Date"
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        helperText="Start Date"
                        {...params}
                      />
                    )}
                  // value={addBrandDate}
                  // onChange={(event) => {
                  //   console.log(event, 'event');
                  //   setAddBrandDate(event);
                  // }}
                  />
                </LocalizationProvider>
              </div>
              <div className="lauch-date-style">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    sx={{
                      '& .css-1x5jdmq': { padding: '9px' },
                    }}
                    label="End Date"
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        helperText="End Date"
                        {...params}
                      />
                    )}
                  // value={addBrandDate}
                  // onChange={(event) => {
                  //   console.log(event, 'event');
                  //   setAddBrandDate(event);
                  // }}
                  />
                </LocalizationProvider>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <FormControlLabel
                  label="Receive Mail"
                  style={{
                    padding: '0 8px',
                  }}
                  control={
                    <Checkbox
                      checked={trigger.checked}
                      onChange={() => {
                        setTrigger({
                          ...trigger,
                          checked: !trigger.checked,
                        });
                      }}
                    />
                  }
                />

                {trigger.checked && (
                  <TextField
                    style={{
                      width: '97%',
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        top: '4px',
                      },
                      '& .MuiInputBase-root': {
                        padding: '7px',
                      },
                    }}
                    required
                    size="small"
                    label="Email"
                  // value={trigger.email}
                  // onChange={(e) => {
                  //   setTrigger({
                  //     ...trigger,
                  //     email: e.target.value,
                  //   });
                  // }}
                  />
                )}
              </div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}>
            Trigger
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => setShowTriggerGAImport(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableEscapeKeyDown
        open={showTriggerBackFill}
        sx={{
          '& .MuiPaper-root': { minWidth: '1100px', maxWidth: '1100px' },
        }}>
        <ThemeProvider theme={theme}>
          <DialogTitle
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {!loadingTrigger ? (
              <Button
                onClick={async () => {
                  triggerRefreash();
                  setLoadingTrigger(true);
                }}
                variant="contained">
                Refresh Data
              </Button>
            ) : (
              <Button variant="contained">
                <CircularProgress
                  style={{
                    height: '20px',
                    width: '20px',
                    color: '#ffff',
                  }}
                />
              </Button>
            )}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0',
              overflow: 'hidden',
            }}>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                paddingBottom: '10px',
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.9rem',

                '& .MuiTextField-root': { m: 1, width: '800px' },
              }}>
              <div className="multi-menu-table">
                <div
                  className="menu-col-container"
                  style={{
                    borderRight: '1px solid #dadce0',
                  }}>
                  <div className="menu-header">
                    <span>Brand</span>
                    <input
                      className="search-header"
                      placeholder="search brands..."
                      onChange={(e) => searchBrands(e.target.value)}
                    />
                  </div>
                  <div
                    className="menu-body"
                    onMouseOver={() =>
                      setSelected({
                        ...selected,
                        ga_brand: {
                          ...selected.ga_brand,
                          isHovered: true,
                        },
                        property: {
                          ...selected.property,
                          isHovered: false,
                        },
                        view: {
                          ...selected.view,
                          isHovered: false,
                        },
                      })
                    }
                    onMouseOut={() => {
                      setSelected({
                        ...selected,
                        ga_brand: {
                          ...selected.ga_brand,
                          isHovered: false,
                        },
                        property: {
                          ...selected.property,
                          isHovered: false,
                        },
                        view: {
                          ...selected.view,
                          isHovered: false,
                        },
                      });
                    }}>
                    {gaBrands.map((row, i) => (
                      <div
                        key={row.account_id}
                        className={`menu-row ${row.account_id === selected.ga_brand.id && 'selected'
                          }`}
                        onClick={() => {
                          setProperties(row.properties);
                          const propertiesNew = [];

                          for (let i = 0; i < row.properties.length; i++) {
                            propertiesNew.push({
                              ...row.properties[i],
                              select:
                                row.properties[i].views.length > 0
                                  ? row.properties[i].views.find(
                                    (v) => v.selected === 0
                                  ) === undefined
                                  : !!row.properties[i].selected,
                              indeterminate:
                                row.properties[i].views.length > 0 &&
                                row.properties[i].views.some((v) => v.selected === 1) &&
                                row.properties[i].views.some((v) => v.selected === 0)
                            });
                          }

                          setSelected({
                            view: { data: [], id: -1 },
                            property: { data: [...propertiesNew], id: -1 },
                            ga_brand: {
                              id: row.account_id,
                              data: [...gaBrands],
                            },
                          });
                          setProperties(propertiesNew);
                          setViews([]);
                        }}>
                        <div className="content-container">
                          <Tooltip
                            sx={{ fontSize: '2rem' }}
                            title={
                              row.account_name && row.account_name.length > 20
                                ? row.account_name
                                : ''
                            }
                            placement="top">
                            <p className="brand">
                              {!selected.ga_brand.isHovered
                                ? row.account_name &&
                                  row.account_name.length > 15
                                  ? row.account_name.substring(0, 15) + '...'
                                  : row.account_name
                                : row.account_name}
                            </p>
                          </Tooltip>
                          <p className="id">{row.account_id}</p>
                        </div>
                        <Checkbox
                          // onClick={() => {
                          //   setSelected({
                          //     view: { data: [], id: -1 },
                          //     property: { data: [...row.properties], id: -1 },
                          //     ga_brand: { idx: i, id: row.account_id },
                          //   });
                          // }}
                          checked={row.select}
                          // icon={<IndeterminateCheckBoxIcon style={{ color: "rgb(166, 0, 113)" }} />}
                          indeterminate={row.indeterminate}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="menu-col-container"
                  style={{
                    borderRight: '1px solid #dadce0',
                  }}>
                  <div
                    className="menu-header"
                    style={{
                      borderLeft: '1px solid #dadce0',
                    }}>
                    <span>Property</span>
                    <input
                      className="search-header"
                      placeholder="search property..."
                      onChange={(e) => searchProperty(e.target.value)}
                    />
                  </div>
                  <div
                    className="menu-body"
                    onMouseOver={() =>
                      setSelected({
                        ...selected,
                        property: {
                          ...selected.property,
                          isHovered: true,
                        },
                        ga_brand: {
                          ...selected.ga_brand,
                          isHovered: false,
                        },
                        view: {
                          ...selected.view,
                          isHovered: false,
                        },
                      })
                    }
                    onMouseOut={() => {
                      setSelected({
                        ...selected,
                        property: {
                          ...selected.property,
                          isHovered: false,
                        },
                        ga_brand: {
                          ...selected.ga_brand,
                          isHovered: false,
                        },
                        view: {
                          ...selected.view,
                          isHovered: false,
                        },
                      });
                    }}>
                    {selected.property.data?.map((row, i) => (
                      <div
                        className={`menu-row ${row.property_id === selected.property.id
                          ? 'selected'
                          : ''
                          }`}
                        onClick={() => {
                          if (!row.views?.length) {
                            const updatePropertyData =
                              selected.property.data.map((p) => {
                                if (p.property_id === row.property_id) {
                                  return {
                                    ...p,
                                    select: !row?.select,
                                    // indeterminate: !row?.indeterminate,
                                  };
                                }
                                return p;
                              });

                            setSelected({
                              ...selected,
                              property: {
                                ...selected.property,
                                data: updatePropertyData,
                              },
                              view: { data: [], id: -1, isHovered: false },
                            });

                            const propertyIdx = propertyIds.findIndex(
                              (v) => v === row.property_id
                            );
                            if (propertyIdx === -1) {
                              propertyIds.push(row.property_id);
                              setPropertyIds([...propertyIds]);
                            } else {
                              propertyIds.splice(propertyIdx, 1);
                              setPropertyIds([...propertyIds]);
                            }

                            return;
                          }
                          setSelected({
                            ...selected,
                            view: {
                              // data: [...row.views], 
                              data: [...row.views.filter(v => v.view_id !== null && v.view_name !== null)],
                              id: -1
                            },
                            property: {
                              ...selected.property,
                              id: row.property_id,
                            },
                          });

                          // setViews([...row.views]);
                          setViews([...row.views.filter(v => v.view_id !== null && v.view_name !== null)]);
                        }}>
                        <div className="content-container">
                          <Tooltip
                            sx={{ fontSize: '2rem' }}
                            title={
                              row.property_name && row.property_name.length > 15
                                ? row.property_name
                                : ''
                            }
                            placement="top">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <p className="brand">
                                {!selected.property.isHovered
                                  ? row.property_name &&
                                    row.property_name.length > 15
                                    ? row.property_name.substring(0, 15) + '...'
                                    : row.property_name
                                  : row.property_name}
                              </p>
                            </div>
                          </Tooltip>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            {row.views.length > 0 ? (
                              <span className="ga4-or-ua">UA</span>
                            ) : (
                              <span className="ga4-or-ua">GA4</span>
                            )}
                            <p className="id">{row.property_id}</p>{' '}
                          </div>
                        </div>
                        <Checkbox
                          onClick={(e) => {
                            // e.stopPropagation();
                            console.log('click')
                            if (row.views.length) return;

                            selected.property.data[i].select =
                              selected.property.data[i].select === false ? true : false;

                            const allViewsSelected = selected.property.data[i].select;
                            console.log(allViewsSelected, 'seleprops2')
                            console.log(selected.property.data[i], 'seleprops2data')
                            console.log(selected.property.data[i].views, 'seleprops2dataviews')
                            const updatedViews = selected.property.data[i].views.map(view => ({
                              ...view,
                              selected: allViewsSelected ? 1 : 0
                            }));
                            console.log(updatedViews, 'seleUpdated')

                            const updatePropertyData =
                              selected.property.data.map((p) => {
                                if (p.property_id === row.property_id) {
                                  return {
                                    ...p,
                                    // select: !row?.select,
                                    select: allViewsSelected,
                                    // indeterminate: !row?.indeterminate,
                                    indeterminate: false,
                                    views: updatedViews
                                  };
                                }
                                return p;
                              });

                            console.log(updatePropertyData, 'updatePropertyData')
                            setSelected((prevSelected) => ({
                              ...prevSelected,
                              property: {
                                ...prevSelected.property,
                                data: updatePropertyData,
                              },
                              view: {
                                ...prevSelected.view,
                                data: allViewsSelected ? [...updatedViews] : [],
                                id: allViewsSelected ? row.property_id : -1,
                              },
                            }));
                            
                            console.log(selected, 'selected')
                            const propertyIdx = propertyIds.findIndex(
                              (v) => v === row.property_id
                            );
                            if (propertyIdx === -1) {
                              propertyIds.push(row.property_id);
                              setPropertyIds([...propertyIds]);
                            } else {
                              propertyIds.splice(propertyIdx, 1);
                              setPropertyIds([...propertyIds]);
                            }

                            setViews(updatedViews);
                          }}
                          // disabled={row.views.length}
                          checked={row?.select}
                          // icon={<IndeterminateCheckBoxIcon style={{ color: "rgb(166, 0, 113)" }} />}
                          indeterminate={row?.indeterminate}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="menu-col-container">
                  <div
                    className="menu-header"
                    style={{
                      borderLeft: '1px solid #dadce0',
                      width: '480px',
                    }}>
                    <span>View</span>
                    <input
                      className="search-header"
                      style={{
                        marginRight: 20,
                      }}
                      placeholder="search view..."
                      onChange={(e) => searchView(e.target.value)}
                    />
                  </div>
                  <div
                    className="menu-body"
                    onMouseOver={() =>
                      setSelected({
                        ...selected,
                        view: {
                          ...selected.view,
                          isHovered: true,
                        },
                        property: {
                          ...selected.property,
                          isHovered: false,
                        },
                        ga_brand: {
                          ...selected.ga_brand,
                          isHovered: false,
                        },
                      })
                    }
                    onMouseOut={() => {
                      setSelected({
                        ...selected,
                        view: {
                          ...selected.view,
                          isHovered: false,
                        },
                        property: {
                          ...selected.property,
                          isHovered: false,
                        },
                        ga_brand: {
                          ...selected.ga_brand,
                          isHovered: false,
                        },
                      });
                    }}>
                    {selected?.view?.data?.map((row, i) => (
                      <div
                        className={`menu-row 
                      `}
                        onClick={() => {
                          // if (selected.view.id !== i) {
                          //   setSelected({
                          //     ...selected,
                          //     view: { id: row.view_id, data: [] },
                          //   });
                          // }
                        }}>
                        <div className="content-container">
                          <Tooltip
                            sx={{ fontSize: '2rem' }}
                            title={
                              row.view_name && row.view_name.length > 15
                                ? row.view_name
                                : ''
                            }
                            placement="top">
                            <p className="brand">
                              {!selected?.view?.isHovered
                                ? row.view_name && row.view_name.length > 15
                                  ? row.view_name.substring(0, 15) + '...'
                                  : row.view_name.substring(0, 25)
                                : row.view_name.substring(0, 25)}
                            </p>
                          </Tooltip>
                          <p className="id">{row.view_id}</p>
                        </div>
                        <Checkbox
                          onClick={() => {
                            selected.view.data[i].selected =
                              selected.view.data[i].selected === 1 ? 0 : 1;

                            const idx1 = selected.property.data.findIndex(
                              (p) => p.property_id === selected.property.id
                            );
                            // console.log("Index of selected property:", idx1);

                            const anyViewSelected = selected.property.data[idx1].views.some((v) => v.selected === 1);
                            const anyViewUnselected = selected.property.data[idx1].views.some((v) => v.selected === 0);
                            // console.log("Is any view selected?", anyViewSelected);
                            // console.log("Is any view unselected?", anyViewUnselected);

                            // const flag1 =
                            //   selected.property.data[idx1].views.find(
                            //     (v) => v.selected === 0
                            //   ) === undefined;
                            const flag1 = anyViewSelected && anyViewUnselected;
                            // console.log("Indeterminate flag:", flag1);

                            // selected.property.data[idx1].select = flag1;
                            selected.property.data[idx1] = {
                              ...selected.property.data[idx1],
                              select: anyViewSelected && !anyViewUnselected,
                              indeterminate: flag1,
                            };
                            // console.log("Updated property data:", selected.property.data[idx1]);

                            const idx2 = gaBrands.findIndex(
                              (p) => p.account_id === selected.ga_brand.id
                            );

                            // const flag2 =
                            //   selected.property.data.find(
                            //     (v) => v.select === false
                            //   ) === undefined;

                            const anyPropertySelected = selected.property.data.some((v) => v.select === true);
                            const anyPropertyUnselected = selected.property.data.some((v) => v.select === false);
                            const anyPropertyIndeterminate = selected.property.data.some((v) => v.indeterminate === true)

                            const flag2 = (anyPropertySelected && anyPropertyUnselected) || anyPropertyIndeterminate;


                            // gaBrands[idx2].select = flag2;
                            gaBrands[idx2] = {
                              ...gaBrands[idx2],
                              select: anyPropertySelected && !anyPropertyUnselected,
                              indeterminate: flag2,
                            };

                            setGaBrands([...gaBrands]);

                            setSelected({
                              ...selected,
                              view: {
                                id: row.view_id,
                                data: [...selected.view.data],
                              },
                            });

                            const viewIdx = viewIds.findIndex(
                              (v) => v === row.view_id
                            );
                            if (viewIdx === -1) {
                              viewIds.push(row.view_id);
                              setViewIds([...viewIds]);
                            } else {
                              viewIds.splice(viewIdx, 1);
                              setViewIds([...viewIds]);
                            }
                          }}
                          checked={row.selected}
                        // icon={<IndeterminateCheckBoxIcon />}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Box>
          </DialogContent>

          <DialogActions>
            <ThemeProvider theme={theme}>
              {!loading ? (
                <Button
                  disabled={loadingTrigger || loading}
                  onClick={async () => {
                    if (viewIds.length > 0 || propertyIds.length > 0) {
                      saveData();
                      setLoading(true);
                    } else {
                      setLoading(false);
                      Toastify('error', 'No change in views Id!!');
                    }
                  }}
                  variant="contained">
                  Save
                </Button>
              ) : (
                <CircularProgress
                  style={{
                    height: '20px',
                    width: '20px',
                    margin: '0 10px',
                  }}
                />
              )}

              <Button
                disabled={loadingTrigger || loading}
                variant="contained"
                onClick={() => {
                  geGaData();
                  setViews([]);
                  setProperties([]);

                  setSelected({
                    property: { id: -1, data: [] },
                    view: { id: -1, data: [] },
                    ga_brand: { id: -1, data: [] },
                  });

                  setShowTriggerBackFill(false);
                  setViewIds([]);
                  setLoading(false);
                }}>
                Cancel
              </Button>
            </ThemeProvider>
          </DialogActions>
        </ThemeProvider>
      </Dialog>

      <div className="secondary-div">
        <div className="secondary-content">
          <p className="selected-text">
            {gaBrands.length} GA Brand Imports and{' '}
            {Object.keys(getAllLogs).length} GA Logs
          </p>
        </div>
      </div>

      {/* content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Date
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Time
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // textAlign: "center",
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {' '}
                    Log type
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {' '}
                    Logs
                  </span>
                </TableCell>
                {/* <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    UUID
                  </span>
                </TableCell> */}
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {' '}
                    Log History
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(getAllLogs)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((logsForUUID) => {
                  // Retrieve the first log for each UUID

                  const firstLog = logsForUUID[0];
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={firstLog.UUID}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p>{formatDate(firstLog.Date)}</p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p>{firstLog.Time}</p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                          }}>
                          <p
                            style={
                              firstLog.Log_Type === 'INFO'
                                ? {
                                  color: 'rgb(39, 181, 36)',
                                  backgroundColor: 'rgba(71, 237, 52, 0.3)',
                                  border: '1px solid rgb(39, 181, 36)',
                                  borderRadius: '20px',
                                  textAlign: 'center',
                                  fontSize: '0.8rem',
                                  width: '75px',
                                  padding: '3px',
                                }
                                : {
                                  color: 'rgb(252, 3, 3)',
                                  backgroundColor: 'rgba(252, 3, 3, 0.3)',
                                  border: '1px solid rgb(252, 3, 3)',
                                  borderRadius: '20px',
                                  textAlign: 'center',
                                  fontSize: '0.8rem',
                                  width: '75px',
                                  padding: '3px',
                                }
                            }>
                            {firstLog.Log_Type === 'INFO' ? 'INFO' : 'ERROR'}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          // className="actions-div"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            // maxWidth: "24rem",
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            // paddingLeft: "5rem",
                            cursor: 'pointer',
                          }}
                          title={firstLog.Log}>
                          {truncateText(firstLog.Log, 50)}
                        </div>
                      </TableCell>

                      {/* <TableCell>
                        <div
                          // className="actions-div"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                            width: "70%",
                          }}
                        >
                          {firstLog.UUID}
                        </div>
                      </TableCell> */}

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0 10px',
                          }}>
                          <SummarizeIcon
                            key={firstLog.UUID}
                            onClick={() => handleOnClick(firstLog.UUID)}
                            style={{
                              color: 'rgb(166, 0, 113)',
                              cursor: 'pointer',
                              // marginRight: 'auto',
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {listLoad ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={Object.keys(getAllLogs).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>

      {/* For Log History Modal */}
      <Dialog
        disableEscapeKeyDown
        open={isModalOpen == true}
        style={{}}
        maxWidth="md"
        fullWidth>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '20px',
          }}>
          <span
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1.5rem',
              fontWeight: '700',
            }}>
            {`All Logs for UUID ${selectedUUID}`}
          </span>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            open={openModal}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '800px',
              '& .MuiTextField-root': { m: 1, width: '710px' },
            }}>
            <ThemeProvider theme={theme}>
              <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                        }}>
                        Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                        }}>
                        Time
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                          textAlign: 'center',
                        }}>
                        Log type
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        Log
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <>
                    {/* Modal table body for log */}
                    <TableBody>
                      {selectedData && selectedData.length > 0 ? (
                        selectedData
                          .slice(
                            pageLogHistory * rowsPerPageLogHistory,
                            pageLogHistory * rowsPerPageLogHistory +
                            rowsPerPageLogHistory
                          )
                          .map((log, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={`${log.UUID}-${index}`}>
                              <TableCell
                                style={{
                                  fontFamily: '"Montserrat", sans-serif',
                                  fontWeight: '500',
                                }}>
                                <div>
                                  <p>{formatDate(log.Date)}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  fontFamily: '"Montserrat", sans-serif',
                                  fontWeight: '500',
                                }}>
                                <div>
                                  <p>{log.Time}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  fontFamily: '"Montserrat", sans-serif',
                                  fontWeight: '500',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                  }}>
                                  <p
                                    style={
                                      log.Log_Type === 'INFO'
                                        ? {
                                          color: 'rgb(39, 181, 36)',
                                          backgroundColor:
                                            'rgba(71, 237, 52, 0.3)',
                                          border:
                                            '1px solid rgb(39, 181, 36)',
                                          borderRadius: '20px',
                                          textAlign: 'center',
                                          fontSize: '0.8rem',
                                          width: '75px',
                                          padding: '3px',
                                        }
                                        : {
                                          color: 'rgb(252, 3, 3)',
                                          backgroundColor:
                                            'rgba(252, 3, 3, 0.3)',
                                          border: '1px solid rgb(252, 3, 3)',
                                          borderRadius: '20px',
                                          textAlign: 'center',
                                          fontSize: '0.8rem',
                                          width: '75px',
                                          padding: '3px',
                                        }
                                    }>
                                    {log.Log_Type === 'INFO' ? 'INFO' : 'ERROR'}
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  fontFamily: '"Montserrat", sans-serif',
                                  fontWeight: '500',
                                  textAlign: 'center',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '0 10px',
                                    cursor: 'pointer',
                                  }}
                                  title={log.Log}>
                                  {truncateText(log.Log, 35)}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            No Log History
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                </Table>
                {listLoad ? (
                  [1, 2, 3, 4].map((d, i) => {
                    return (
                      <Fragment key={i}>
                        <TableSkeleton />
                      </Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
              </TableContainer>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <div className="spacer"></div>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            component="div"
            count={selectedDataLength}
            rowsPerPage={rowsPerPageLogHistory}
            page={pageLogHistory}
            onPageChange={handleChangePageLogHistory}
            onRowsPerPageChange={handleChangeRowsPerPageLogHistory}
            style={{}}
            sx={{
              marginTop: '10px',
              fontFamily: '"Montserrat", sans-serif',
            }}
          />
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
              marginTop: '10px',
            }}
            onClick={() => {
              closeModal();
            }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GaImports;
