import axios from "axios";
import { Toastify } from "../../App";

export const super_admin = (admin_auth) => {
  return async (dispatch) => {
    try {
      if (admin_auth) {
        dispatch({
          type: "SUPERADMIN_SUCESS",
        });
      } else {
        dispatch({
          type: "SUPERADMIN_ERROR",
        });
      }
    } catch (error) {
      dispatch({
        type: "SUPERADMIN_ERROR",
      });
    }
  };
};

export const auth_check = (admin_auth) => {
  return async (dispatch) => {
    try {
      if (admin_auth != null) {
        dispatch({
          type: "ADMIN_AUTH_SUCESS",
          payload: {
            admin_auth: admin_auth,
          },
        });
      } else {
        dispatch({
          type: "ADMIN_AUTH_ERROR",
        });
      }
    } catch (error) {
      dispatch({
        type: "ADMIN_AUTH_ERROR",
      });
    }
  };
};

export const logout_admin = () => {
  return async (dispatch) => {
    try {
      sessionStorage.removeItem("register");
      dispatch({
        type: "ADMIN_LOGOUT",
      });
    } catch (error) {
      Toastify('error', error.response?.data?.message);
      console.log(error, "logout error");
    }
  };
};

export const get_admin_profile = (admin) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "GET_ADMIN_PROFILE_EXE",
      });
      console.log(admin.id, "res");
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/get/${admin.id}`,
        {
          headers: {
            Authorization: `JWT ${admin.token}`,
          },
        }
      );

      // console.log(res.data, "admin data");
      if (res.status === 200) {
        dispatch({
          type: "GET_ADMIN_PROFILE_SUCCESS",
          payload: { admin_profile: res.data },
        });

        // Toastify("success", "Invite Sent !");
      } else {
        dispatch({
          type: "GET_ADMIN_PROFILE_ERROR",
        });
      }
    } catch (error) {
      dispatch({
        type: "GET_ADMIN_PROFILE_ERROR",
      });

      Toastify("error", error.response?.data?.message);
    }
  };
};
