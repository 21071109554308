export const money = [
  { val: "USD", option: "USD - United States Dollar" },
  { val: "EUR", option: "EUR - Euro" },
  { val: "GBP", option: "GBP - Great British Pound" },
  { val: "CAD", option: "CAD - Canadian Dollar" },
  { val: "AUD", option: "AUD - Australian Dollar" },
  { val: "AED", option: "AED - United Arab Emirates Dirham" },
  { val: "BGN", option: "BGN - Bulgarian Lev" },
  { val: "BRL", option: "BRL - Brazilian Real" },
  { val: "CAD", option: "CAD - Canadian Dollar" },
  { val: "CHF", option: "CHF - Swiss Franc" },
  { val: "CLP", option: "CLP - Chilean Peso" },
  { val: "CNY", option: "CNY - Chinese Yuan" },
  { val: "COP", option: "COP - Colombian Peso" },
  { val: "CZK", option: "CZK - Czech Koruna" },
  { val: "DKK", option: "DKK - Danish Krone" },
  { val: "EGP", option: "EGP - Egyptian Pound" },
  { val: "HKD", option: "HKD - Hong Kong Dollar" },
  { val: "HRK", option: "HRK - Croatian Kuna" },
  { val: "HUF", option: "HUF - Hungarian Forint" },
  { val: "IDR", option: "IDR - Indonesian Rupiah" },
  { val: "ILS", option: "ILS - Israeli New Sheqel" },
  { val: "INR", option: "INR - Indian Rupee" },
  { val: "JPY", option: "JPY - Japanese Yen" },
  { val: "KRW", option: "KRW - South Korean Won" },
  { val: "KZT", option: "KZT - Kazakhstani Tenge" },
  { val: "MXN", option: "MXN - Mexican Peso" },
  { val: "MYR", option: "MYR - Malaysian Ringgit" },
  { val: "NGN", option: "NGN - Nigerian Naira" },
  { val: "NOK", option: "NOK - Norwegian Krone" },
  { val: "NZD", option: "NZD - New Zealand Dollar" },
  { val: "PEN", option: "PEN - Peruvian Nuevo Sol" },
  { val: "PHP", option: "PHP - Philippine Peso" },
  { val: "PLN", option: "PLN - Polish Zloty" },
  { val: "QAR", option: "QAR - Qatari Rial" },
  { val: "RMB", option: "RMB - Renminbi" },
  { val: "RON", option: "RON - Romanian Leu" },
  { val: "RUB", option: "RUB - Russian Ruble" },
  { val: "SAR", option: "SAR - Saudi Riyal" },
  { val: "SEK", option: "SEK - Swedish Krona" },
  { val: "SGD", option: "SGD - Singapore Dollar" },
  { val: "THB", option: "THB - Thai Baht" },
  { val: "TRY", option: "TRY - Turkish Lira" },
  { val: "TWD", option: "TWD - New Taiwan Dollar" },
  { val: "TZS", option: "TZS - Tanzanian Shilling" },
  { val: "VND", option: "VND - Vietnamese Dong" },
  { val: "ZAR", option: "ZAR - South African Rand" },
];
