import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  TextField,
  MenuItem,
  CircularProgress,
  Tooltip,
} from '@mui/material';

import {
  Refresh,
  Add,
  Edit,
  Search,
  AddPhotoAlternateOutlined,
  DeleteOutline,
  TurnSlightRightOutlined,
} from '@mui/icons-material';
import axios from 'axios';

import './brands-up.styles.scss';
import { Toastify } from '../../App';
import { useSelector } from 'react-redux';
import TableSkeleton from '../skeleton-loader/table';
import MultipleSelectChip from '../multiple-input';
import isValidDomain from 'is-valid-domain';
import { debounce } from '../../utils/debounce';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [brandsBackup, setBrandsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const inputFile = useRef(null);
  const [showAddBrandDialog, setShowAddBrandDialog] = useState(false);
  // add
  const [addBrandImageRaw, setAddBrandImageRaw] = useState(null);
  const [addBrandImage, setAddBrandImage] = useState(null);
  const [addBrandName, setAddBrandName] = useState();
  const [addBrandVertical, setAddBrandVertical] = useState([]);
  const [addBrandDomain, setAddBrandDomain] = useState();
  const [addBrandOrg, setAddBrandOrg] = useState('');
  const [hideAddBrandProgressDialog, setHideAddBrandProgressDialog] =
    useState(true);

  // add

  //edit
  const editInputFile = useRef(null);
  const [editBrandData, setEditBrandData] = useState();
  const [showEditBrandDialog, setShowEditBrandDialog] = useState(false);
  const [editBrandImageRaw, setEditBrandImageRaw] = useState(null);
  const [editBrandImage, setEditBrandImage] = useState(null);
  const [editBrandName, setEditBrandName] = useState();
  const [editBrandVertical, setEditBrandVertical] = useState();
  const [editBrandDomain, setEditBrandDomain] = useState();
  const [editBrandOrg, setEditBrandOrg] = useState();

  // delete edit

  const [showDeleteBrandDialog, setShowDeleteBrandDialog] = useState(false);
  const [deleteBrandId, setDeleteBrandId] = useState(null);
  const [hideDeleteBrandProgressDialog, setHideDeleteBrandProgressDialog] =
    useState(true);
  // delete edit

  const defaultEditChange = {
    brand_name_change: 0,
    brand_vertical_change: 0,
    brand_domain_change: 0,
    brand_image_change: 0,
    brand_organization_change: 0,
  };
  const [editBrandChange, setEditBrandChange] = useState({
    brand_name_change: 0,
    brand_vertical_change: 0,
    brand_domain_change: 0,
    brand_image_change: 0,
    brand_organization_change: 0,
  });
  const [hideEditBrandProgressDialog, setHideEditBrandProgressDialog] =
    useState(true);
  //edit

  const [searchText, setSearchText] = useState('');

  //super_token
  const super_token = JSON.parse(localStorage.getItem('super_token'));

  // admin state
  const { superAdmin } = useSelector((state) => state.admin);

  // Format Date
  // const formatDate = (timeStamp) => {
  //   let date = new Date(timeStamp);
  //   return (
  //     date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
  //   );
  // };

  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current && super_token?.token) {
      get_ref.current = false;
      getBrands();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadList, setLoadList] = useState(true);
  // Getting all brands
  const getBrands = () => {
    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/brand/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((response) => {
        setLoadList(false);

        setBrands(response.data.sort(customSortBasedOnDate).reverse());
        setBrandsBackup(response.data.sort(customSortBasedOnDate).reverse());
      })
      .catch((err) => {
        Toastify('error', err.response.data.message);
        console.error(err)
      });
  };

  const customSortBasedOnDate = (a, b) => {
    // return new Date(a.launch).getTime() - new Date(b.launch).getTime();
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  // function isValidDomain(str) {
  //   // Regex to check valid
  //   // Domain Name
  //   let regex = new RegExp(
  //     /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
  //   );

  //   // if str
  //   // is empty return false
  //   if (str == null) {
  //     return false;
  //   }

  //   // Return true if the str
  //   // matched the ReGex
  //   if (regex.test(str) == true) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // Add brand to db
  const addBrand = () => {
    if (
      typeof addBrandName === 'undefined' ||
      addBrandName === null ||
      addBrandName === ''
    ) {
      Toastify('warning', 'Name of brand is required!');
    } else if (
      typeof addBrandVertical === 'undefined' ||
      addBrandVertical === null ||
      addBrandVertical.lengh === 0
    ) {
      Toastify('warning', 'Vertical of brand is required!');
    } else if (
      typeof addBrandOrg === 'undefined' ||
      addBrandOrg === null ||
      addBrandOrg === ''
    ) {
      Toastify('warning', 'Organization of brand is required!');
    } else if (
      typeof addBrandDomain === 'undefined' ||
      addBrandDomain === null ||
      addBrandDomain === ''
    ) {
      Toastify('warning', 'Domain of brand is required!');
    } else if (
      !isValidDomain(addBrandDomain) ||
      addBrandDomain.split(':')[0] === 'http' ||
      addBrandDomain.split(':')[0] === 'https'
    ) {
      Toastify(
        'warning',
        'Domain of brand should be of format eg: domain_name.com'
      );
    } else {
      setHideAddBrandProgressDialog(false);
      if (addBrandImage === null) {
        let verticals = addBrandVertical;
        verticals = verticals.join(',');

        let formData = new FormData();
        formData.append('brand_name', addBrandName);
        formData.append('brand_vertical', verticals);
        formData.append('brand_domain', addBrandDomain);
        formData.append('organization', addBrandOrg);
        formData.append('brand_image', null);
        formData.append('brand_image_file', null);

        axios
          .post(
            `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/brand/new`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `JWT ${super_token?.token}`,
              },
            }
          )
          .then((_) => {
            Toastify('success', 'Brand created');
            setHideAddBrandProgressDialog(true);
            setShowAddBrandDialog(false);
            setAddBrandName();
            setAddBrandImage(null);
            setAddBrandImageRaw(null);
            setAddBrandOrg();
            setAddBrandVertical([]);
            setAddBrandDomain();
            getBrands();
          })
          .catch((err) => {
            Toastify('error', err.response.data.message);
            console.error(err)
          });
      } else {
        let verticals = addBrandVertical;
        verticals = verticals.join(',');

        let formData = new FormData();
        formData.append('brand_name', addBrandName);
        formData.append('brand_vertical', verticals);
        formData.append('brand_domain', addBrandDomain);
        formData.append('organization', addBrandOrg);
        formData.append('brand_image', 1);
        formData.append('brand_image_file', addBrandImageRaw);

        axios
          .post(
            `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/brand/new`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `JWT ${super_token?.token}`,
              },
            }
          )
          .then((_) => {
            Toastify('success', 'Brand created');
            setHideAddBrandProgressDialog(true);
            setShowAddBrandDialog(false);
            setAddBrandImage(null);
            setAddBrandImageRaw(null);
            setAddBrandName();
            setAddBrandOrg();
            setAddBrandVertical();
            setAddBrandDomain();
            getBrands();
          })
          .catch((err) => {
            Toastify('error', err.response.data.message);
            console.error(err, 'error is ')
          });
      }
    }
  };

  // delete brand
  const deleteBrand = (id) => {
    axios
      .delete(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/brand/delete?brand_id=${id}`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((res) => {
        getBrands();
        setShowDeleteBrandDialog(false);
        setHideDeleteBrandProgressDialog(true);
        Toastify('success', 'Brand deleted');
      })
      .catch((err) => {
        Toastify('error', err.response.data.message);
        console.error(err)
      });
  };

  // edit brand
  const editBrand = (brand) => {
    setEditBrandData(brand);
    setEditBrandImage(brand.image);
    setEditBrandName(brand.name);
    setEditBrandVertical(brand.vertical.split(','));
    setEditBrandDomain(brand.domain);
    setEditBrandOrg(brand?.organization?.id);
    setShowEditBrandDialog(true);
  };

  // update brand
  const updateBrand = () => {
    if (
      typeof editBrandName === 'undefined' ||
      editBrandName === null ||
      editBrandName === ''
    ) {
      Toastify('warning', 'Name of brand is required!');
    } else if (
      typeof editBrandVertical === 'undefined' ||
      editBrandVertical === null ||
      editBrandVertical.length === 0
    ) {
      Toastify('warning', 'Vertical of brand is required!');
    } else if (
      typeof editBrandDomain === 'undefined' ||
      editBrandDomain === null ||
      editBrandDomain === ''
    ) {
      Toastify('warning', 'Domain of brand is required!');
    } else if (
      !isValidDomain(editBrandDomain) ||
      editBrandDomain.split(':')[0] === 'http' ||
      editBrandDomain.split(':')[0] === 'https'
    ) {
      console.log(editBrandDomain);
      Toastify(
        'warning',
        'Domain of brand should be of format eg: domain_name.com'
      );
    } else if (
      typeof editBrandOrg === 'undefined' ||
      editBrandOrg === null ||
      editBrandOrg === ''
    ) {
      Toastify('warning', 'Organization of brand is required!');
    } else if (editBrandImageRaw === null) {
      setHideEditBrandProgressDialog(false);

      let verticals = editBrandVertical;
      verticals = verticals.join(',');
      let formData = new FormData();
      formData.append('id', editBrandData.id);
      formData.append('brand_name', editBrandName);
      formData.append('brand_vertical', verticals);
      formData.append('brand_domain', editBrandDomain);
      formData.append('brand_image_file', null);
      formData.append('brand_organization', editBrandOrg);

      formData.append('brand_name_change', editBrandChange.brand_name_change);
      formData.append(
        'brand_vertical_change',
        editBrandChange.brand_vertical_change
      );
      formData.append(
        'brand_domain_change',
        editBrandChange.brand_domain_change
      );
      formData.append('brand_image_change', 0);
      formData.append(
        'brand_organization_change',
        editBrandChange.brand_organization_change
      );

      axios
        .post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/brand/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((d) => {
          Toastify('success', 'Brand updated');
          setHideEditBrandProgressDialog(true);
          setShowEditBrandDialog(false);
          setEditBrandImage(null);
          setEditBrandImageRaw(null);
          setEditBrandName();
          setEditBrandVertical();
          setEditBrandDomain();
          setEditBrandData();
          setEditBrandChange(defaultEditChange);
          getBrands();
        })
        .catch((err) => {
          Toastify('error', err.response.data.message);
          console.error(err, 'edit error')
        });
    } else {
      setHideEditBrandProgressDialog(false);

      let verticals = editBrandVertical;
      verticals = verticals.join(',');

      let formData = new FormData();
      formData.append('id', editBrandData.id);
      formData.append('brand_name', editBrandName);
      formData.append('brand_vertical', verticals);
      formData.append('brand_domain', editBrandDomain);
      formData.append('brand_image_file', editBrandImageRaw);
      formData.append('brand_organization', editBrandOrg);

      formData.append('brand_name_change', editBrandChange.brand_name_change);
      formData.append(
        'brand_vertical_change',
        editBrandChange.brand_vertical_change
      );
      formData.append(
        'brand_domain_change',
        editBrandChange.brand_domain_change
      );
      formData.append('brand_image_change', editBrandChange.brand_image_change);
      formData.append(
        'brand_organization_change',
        editBrandChange.brand_organization_change
      );

      axios
        .post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/brand/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((d) => {
          Toastify('success', 'Brand updated');
          setHideEditBrandProgressDialog(true);
          setShowEditBrandDialog(false);
          setEditBrandImage(null);
          setEditBrandImageRaw(null);
          setEditBrandName();
          setEditBrandVertical();
          setEditBrandDomain();
          setEditBrandData();
          setEditBrandChange(defaultEditChange);
          getBrands();
        })
        .catch((err) => {
          Toastify('error', err.response.data.message);
          console.error(err, 'edit error')
        });
    }
  };

  //get organizations
  const [orgs, setOrgs] = useState([]);
  // const [orgsBackup, setOrgsBackup] = useState([]);

  // const orgNameIs = (id) => {
  //     const orgIs = orgsBackup.find(data => data.id === id);
  //     if (orgIs === null || orgIs === undefined) {
  //         return "Dummy Name"
  //     } else {
  //         return orgIs.name;
  //     }

  // }
  const get_org_ref = useRef(true);
  //sorting
  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (get_org_ref.current && super_token?.token) {
      get_org_ref.current = false;
      getOrgs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrgs = () => {
    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((response) => {
        setOrgs(response.data.sort(compare));
        // setOrgsBackup(response.data.sort(compare));
      })
      .catch((err) => {
        Toastify('error', err.response.data.message);
        console.error(err)
      });
  };

  // Search result
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < brandsBackup.length; i++) {
      if (
        brandsBackup[i]['name'].toLowerCase().includes(query.toLowerCase()) ||
        brandsBackup[i]['vertical']
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        brandsBackup[i]['domain'].toLowerCase().includes(query.toLowerCase()) ||
        (brandsBackup[i]['organization'] !== null &&
          brandsBackup[i]['organization'] !== undefined &&
          brandsBackup[i]['organization']?.name
            .toLowerCase()
            .includes(query.toLowerCase()))
      )
        results.push(brandsBackup[i]);
    }

    setBrands(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));
  return (
    <div className="brands">
      {/* Add brand Dialog */}
      <Dialog disableEscapeKeyDown open={showAddBrandDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Add Brand
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <div
                style={{
                  height: '200px',
                  width: '500px',
                  marginBottom: '25px',
                  borderRadius: '5px',
                  border: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => inputFile.current.click()}>
                {addBrandImage === null ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <AddPhotoAlternateOutlined />
                    <p>Upload Image</p>
                    <input
                      type="file"
                      ref={inputFile}
                      style={{
                        display: 'none',
                      }}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          setAddBrandImageRaw(event.target.files[0]);
                          setAddBrandImage(
                            URL.createObjectURL(event.target.files[0])
                          );
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: '200px',
                      width: '500px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <img src={addBrandImage} alt="Brand" height="200" />
                  </div>
                )}
              </div>
              <TextField
                required
                size="small"
                label="Brand's Name"
                helperText="Eg. - LT Partners"
                value={addBrandName}
                onChange={(event) => setAddBrandName(event.target.value)}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label="Launch Date"
                                    inputFormat="MM/dd/yyyy"
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            size="small"
                                            helperText="Program Start Date"
                                            {...params}
                                        />
                                    )}
                                    value={addBrandDate}
                                    onChange={(event) => setAddBrandDate(event)}
                                />
                            </LocalizationProvider> */}
              {/* <TextField
                required
                size="small"
                label="Vertical"
                helperText="Eg. - Marketing"
                value={addBrandVertical}
                onChange={(event) => setAddBrandVertical(event.target.value)}
              /> */}
              {/* <TextField
                                select
                                label="Type"
                                size="small"
                                value={addBrandType}
                                onChange={(event) => setAddBrandType(event.target.value)}
                                helperText="Program Type"
                            >
                                {types.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField> */}

              <MultipleSelectChip
                label="Vertical"
                helperText="Eg. - Fashion, Beauty"
                addBrandVertical={addBrandVertical}
                onChange={(vertical) => {
                  let verticals = addBrandVertical;
                  if (!verticals.includes(vertical)) {
                    //checking weather array contain the id
                    verticals.push(vertical); //adding to array because value doesnt exists
                  } else {
                    verticals.splice(verticals.indexOf(vertical), 1); //deleting
                  }

                  setAddBrandVertical(verticals);
                }}
              />
              <TextField
                required
                size="small"
                label="Domain"
                helperText="Eg. - lt.partners"
                value={addBrandDomain}
                onChange={(event) => setAddBrandDomain(event.target.value)}
              />

              <TextField
                select
                label="Organization"
                size="small"
                value={addBrandOrg}
                onChange={(event) => setAddBrandOrg(event.target.value)}
                helperText="Organization">
                {orgs?.map((option) => {
                  return (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (hideAddBrandProgressDialog) {
                addBrand();
              }
            }}>
            <div>
              {hideAddBrandProgressDialog ? (
                <p>Add</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => setShowAddBrandDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Brand Dialog */}
      <Dialog disableEscapeKeyDown open={showEditBrandDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Edit Brand
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <div
                style={{
                  height: '200px',
                  width: '500px',
                  marginBottom: '25px',
                  borderRadius: '5px',
                  border: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => editInputFile.current.click()}>
                <div
                  style={{
                    height: '200px',
                    width: '500px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <img src={editBrandImage} alt="Brand" height="200" />
                  <input
                    type="file"
                    ref={editInputFile}
                    style={{
                      display: 'none',
                    }}
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        setEditBrandImageRaw(event.target.files[0]);
                        setEditBrandChange({
                          ...editBrandChange,
                          brand_image_change: 1,
                        });
                        setEditBrandImage(
                          URL.createObjectURL(event.target.files[0])
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <TextField
                required
                size="small"
                label="Name"
                helperText="Eg. - LT Partners"
                value={editBrandName}
                onChange={(event) => {
                  setEditBrandChange({
                    ...editBrandChange,
                    brand_name_change: 1,
                  });
                  setEditBrandName(event.target.value);
                }}
              />

              <MultipleSelectChip
                label="Vertical"
                helperText="Eg. - Fashion, Beauty"
                addBrandVertical={editBrandVertical}
                onChange={(vertical) => {
                  let verticals = editBrandVertical;
                  if (!verticals.includes(vertical)) {
                    //checking weather array contain the id
                    verticals.push(vertical); //adding to array because value doesnt exists
                  } else {
                    verticals.splice(verticals.indexOf(vertical), 1); //deleting
                  }

                  // verticals
                  setEditBrandVertical(verticals);

                  setEditBrandChange({
                    ...editBrandChange,
                    brand_vertical_change: 1,
                  });
                }}
              />
              {/* <TextField
                required
                size="small"
                label="Vertical"
                helperText="Eg. - Marketing"
                value={editBrandVertical}
                onChange={(event) => {
                  setEditBrandChange({
                    ...editBrandChange,
                    brand_vertical_change: 1,
                  });
                  setEditBrandVertical(event.target.value);
                }}
              /> */}

              <TextField
                required
                size="small"
                label="Domain"
                helperText="Eg. - lt.partners"
                value={editBrandDomain}
                onChange={(event) => {
                  setEditBrandChange({
                    ...editBrandChange,
                    brand_domain_change: 1,
                  });
                  setEditBrandDomain(event.target.value);
                }}
              />
              {/* <TextField
                                required
                                size="small"
                                label="Organization"
                                helperText="Eg. - LT Partners"
                                value={editBrandOrg}
                                onChange={(event) => {
                                    setEditBrandChange({
                                        ...editBrandChange,
                                        brand_organization_change: 1
                                    })
                                    setEditBrandOrg(event.target.value)
                                }}
                            /> */}

              <TextField
                select
                label="Organization"
                size="small"
                value={editBrandOrg}
                onChange={(event) => {
                  setEditBrandChange({
                    ...editBrandChange,
                    brand_organization_change: 1,
                  });

                  setEditBrandOrg(event.target.value);
                }}
                helperText="Eg. - LT Partners">
                {orgs.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <div>Make sure the brand name matches with Affluent!</div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (hideEditBrandProgressDialog) {
                updateBrand();
              }
            }}>
            <div>
              {hideEditBrandProgressDialog ? (
                <p>Update</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              setShowEditBrandDialog(false);
              setHideEditBrandProgressDialog(true);
              }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Brand Dialog */}
      <Dialog disableEscapeKeyDown open={showDeleteBrandDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1.2rem',
            fontWeight: '700',
          }}>
          Delete Brand
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <div>
                Deleting this brand will remove all data related to it. Are you
                sure you want to delete this brand?!
              </div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (hideDeleteBrandProgressDialog) {
                setHideDeleteBrandProgressDialog(false);
                deleteBrand(deleteBrandId);
              }
            }}>
            <div>
              {hideDeleteBrandProgressDialog ? (
                <p>Delete</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              setShowDeleteBrandDialog(false);
              }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className="head">
        <div className="head-txt">Brands</div>
        <Refresh className="head-refresh" />
        <Button
          className="add-brand-btn"
          onClick={() => {
            setShowAddBrandDialog(true);
          }}>
          <div className="add-brand-content">
            <Add className="add-icon" />
            <p className="add-text">Add Brand</p>
          </div>
        </Button>
      </div>

      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">{brands.length} Brands </span>
            {/* <span>( Brand name should be the same as in Affluent! )</span> */}
          </p>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);

              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Brand
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Organization
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Vertical
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Domain
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brands
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}>
                        <img
                          alt="fav"
                          className="avtar"
                          src={row.image}
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            height: '60px',
                            width: '120px',
                            objectFit: 'contain',
                            borderRadius: '5px',
                            marginRight: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            window.open(`https://${row.domain}`);
                          }}
                        />
                        {/* <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {row.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "500",
                              color: "rgb(166, 0, 113)",
                              fontSize: "0.8rem",
                            }}
                          >
                            {row.brand.length > 10
                              ? row.brand.substr(0, 9) + "..."
                              : row.brand}
                          </div>
                        </div> */}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.name}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row?.organization === null ||
                            row?.organization === undefined
                              ? 'Dummy Test'
                              : row?.organization?.name}
                          </p>
                        </div>
                      </TableCell>
                      {/* <TableCell
                                                style={{
                                                    fontFamily: '"Montserrat", sans-serif',
                                                    fontWeight: "500",
                                                }}
                                            >
                                                <div>
                                                    <p
                                                        style={{
                                                            display: "inline-block",
                                                            verticalAlign: "middle",
                                                            marginRight: "5px",
                                                        }}
                                                    >
                                                        {formatDate(row.launch)}
                                                    </p>
                                                </div>
                                            </TableCell> */}
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                          cursor: 'pointer',
                        }}>
                        <Tooltip
                          style={{
                            cursor: 'pointer',
                          }}
                          title={`${
                            row.vertical.split(',').length > 3
                              ? row.vertical
                              : ''
                          }`}
                          placement="top">
                          {/* <div> */}
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.vertical.split(',').length > 3
                              ? `${row.vertical.split(',')[0]}, ${
                                  row.vertical.split(',')[1]
                                } & more`
                              : row.vertical.split(',').length > 2
                              ? `${row.vertical.split(',')[0]},${
                                  row.vertical.split(',')[1]
                                } & ${row.vertical.split(',')[2]}`
                              : row.vertical.split(',').length > 1
                              ? `${row.vertical.split(',')[0]} & ${
                                  row.vertical.split(',')[1]
                                }`
                              : row.vertical}
                          </p>
                          {/* </div> */}
                        </Tooltip>
                      </TableCell>
                      {/* <TableCell
                                                style={{
                                                    fontFamily: '"Montserrat", sans-serif',
                                                    fontWeight: "500",
                                                }}
                                            >
                                                <div>
                                                    <p
                                                        style={{
                                                            display: "inline-block",
                                                            verticalAlign: "middle",
                                                            marginRight: "5px",
                                                        }}
                                                    >
                                                        {row.type}
                                                    </p>
                                                </div>
                                            </TableCell> */}
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              window.open(`https://${row.domain}`);
                            }}>
                            {row.domain}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <Tooltip title="Visit">
                          <TurnSlightRightOutlined
                            style={{
                              cursor: 'pointer',
                              color: 'rgb(36, 169, 181)',
                              backgroundColor: 'rgba(36, 169, 181, 0.3)',
                              border: '1px solid rgb(36, 169, 181)',
                              padding: '3px',
                              fontSize: '15px',
                              marginRight: '15px',
                              borderRadius: '5px',
                            }}
                            onClick={() => {
                              window.open(`https://${row.domain}`);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Edit">
                          <Edit
                            style={{
                              cursor: 'pointer',
                              color: 'rgb(252, 198, 3)',
                              backgroundColor: 'rgba(252, 227, 3, 0.3)',
                              border: '1px solid rgb(252, 198, 3)',
                              padding: '3px',
                              fontSize: '15px',
                              marginRight: '15px',
                              borderRadius: '5px',
                            }}
                            onClick={() => editBrand(row)}
                          />
                        </Tooltip>
                        {superAdmin ? (
                          <Tooltip title="Delete">
                            <DeleteOutline
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(252, 3, 3)',
                                backgroundColor: 'rgba(252, 3, 3, 0.3)',
                                border: '1px solid rgb(252, 3, 3)',
                                padding: '3px',
                                fontSize: '15px',
                                marginRight: '15px',
                                borderRadius: '5px',
                              }}
                              onClick={() => {
                                setShowDeleteBrandDialog(true);
                                setDeleteBrandId(row.id);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={brands.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default Brands;
