import React, { useEffect, useRef, useState } from 'react';
import {
  createTheme,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  ThemeProvider,
  Button,
  CircularProgress,
  TextField,
  DialogActions,
  InputAdornment,
  IconButton,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PasswordValidator from 'password-validator';
import axios from 'axios';

import './admin-profile.styles.scss';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Toastify } from '../../App';
import { logout_admin } from '../../redux/action/auth.action';
import {
  CouponSet,
  PaymentModelUpdate,
  UnSetCoupon,
} from '../../redux/action/payment.action';
import Pricing from '../pricing';
import { keys } from '../../lib/consts/keys';
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const ProfileComponent = () => {
  const { admin_auth, superAdmin, admin_profile } = useSelector(
    (state) => state.admin
  );

  const { payment_model_process } = useSelector((state) => state.payment);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [details, setDetails] = useState({
    payment:
      admin_profile?.organization?.payment_model == '2'
        ? keys.priceIdYearly
        : admin_profile?.organization?.payment_model == '1'
        ? keys.priceIdMonthly
        : '',
  });

  const changeDetails = (e) => {
    setDetails({
      ...details,
      payment: e.target.value,
    });
  };

  const [passwordUpdate, setPasswordUpdate] = useState({
    current: 'password',
    new: 'password',
    confirm: 'password',
  });

  const handlePassword = (type) => {
    passwordUpdate[type] =
      passwordUpdate[type] === 'text' ? 'password' : 'text';
    setPasswordUpdate({
      ...passwordUpdate,
    });
  };

  const fileInput = useRef();

  // Update password
  const updatePassword = () => {
    let passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(6)
      .is()
      .has()
      .uppercase(1)
      .has()
      .lowercase(1)
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();

    if (currentPassword === '') {
      setCurrentPasswordError('Required!');
      setNewPasswordError('');
      setConfirmNewPasswordError('');
    } else if (newPassword === '') {
      setCurrentPasswordError('');
      setNewPasswordError('Required!');
      setConfirmNewPasswordError('');
    } else if (confirmNewPassword === '') {
      setCurrentPasswordError('');
      setNewPasswordError('');
      setConfirmNewPasswordError('Required!');
    } else if (!passwordSchema.validate(newPassword)) {
      setCurrentPasswordError('');
      setNewPasswordError("Password doesn't satisfy all conditions!");
      setConfirmNewPasswordError('');
    } else if (confirmNewPassword !== newPassword) {
      setCurrentPasswordError('');
      setNewPasswordError('');
      setConfirmNewPasswordError('Should be same as new password!');
    } else {
      setShowLoading(true);
      let requestBody = {
        id: admin_profile.id,
        current: currentPassword,
        new: newPassword,
      };
      const user = JSON.parse(localStorage.getItem('admin_auth'));

      console.log(user, 'user gatat');
      axios
        .post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/password/reset`,
          requestBody,
          {
            headers: {
              Authorization: `JWT ${user.token}`,
            },
          }
        )
        .then((response) => {
          Toastify('success', 'Password updated');
          setCurrentPassword('');
          setNewPassword('');
          setConfirmNewPassword('');
          setShowLoading(false);
          setShowPasswordDialog(false);
        })
        .catch((err) => {
          console.error(err.response.data.message);
          // setCurrentPassword(err.response.data.message);
          setCurrentPasswordError(err.response.data.message);
          setNewPassword('');
          setConfirmNewPassword('');
          setShowLoading(false);
        });
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //logout
  const handleLogOut = () => {
    sessionStorage.removeItem('register');
    localStorage.clear();
    navigate('/', {
      state: {
        email: null,
        password: null,
        action: 'login',
      },
    });
    dispatch(logout_admin());
    Toastify('success', 'Logout Successfully');
  };
  const [toggle, setToggle] = useState('details');

  // update payment model
  const couponRun = async () => {
    if (payment.coupon) {
      const res = await axios.post(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/payment/coupon/apply`,
        {
          admin_id: admin_auth.id,
          coupon: payment?.coupon?.coupon?.coupon,
        },
        {
          headers: {
            Authorization: `JWT ${admin_auth.token}`,
          },
        }
      );
      console.log(res.data, 'coupon run ');
      return res;
    }

    return null;
  };

  const UpdatePaymentModel = async () => {
    const pricing =
      admin_profile?.organization?.payment_model == '2'
        ? keys.priceIdYearly
        : keys.priceIdMonthly;

    if (details.payment == pricing) {
      await couponRun();
      setCouponErr(false);
      setCouponMsg('');
      setCoupon('');
      Toastify('success', 'Updated Payment!');
      setShowUpdateModel(false);
      dispatch(UnSetCoupon());
      return;
    }
    // await couponRun();
    await dispatch(
      PaymentModelUpdate(
        {
          admin_id: admin_auth.id,
          price_id: details.payment,
          model_id: details.payment == keys.priceIdYearly ? 2 : 1,
          // coupon: payment?.coupon,
        },
        admin_auth,
        setShowUpdateModel
      )
    );

    setCouponErr(false);
    setCouponMsg('');
    setCoupon('');
    dispatch(UnSetCoupon());
  };

  useEffect(() => {
    setDetails({
      ...details,
      payment:
        admin_profile?.organization?.payment_model == '2'
          ? keys.priceIdYearly
          : admin_profile?.organization?.payment_model == '1'
          ? keys.priceIdMonthly
          : '',
    });
  }, [admin_profile?.organization?.payment_model]);

  useEffect(() => {
    dispatch(UnSetCoupon());
  }, []);
  const payment = useSelector((state) => state.payment);

  const [coupon, setCoupon] = useState('');
  const [couponErr, setCouponErr] = useState(false);
  const [couponMsg, setCouponMsg] = useState('');
  const admin_data = JSON.parse(localStorage.getItem('admin_auth'));
  const checkCoupon = async () => {
    try {
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/coupon/verify/${coupon}`
      );
      console.log(res.data, 'checkcoupon');
      if (!res.data.exists) {
        setCouponErr(true);
        setCouponMsg('');
        // CouponSet
        UnSetCoupon();
      } else {
        setCouponErr(false);

        dispatch(CouponSet(res.data.coupon));
        setCouponMsg('Coupon added');
      }
    } catch (error) {
      console.log();
    }
  };

  return (
    <div className="admin-profile">
      <div className="admins">
        <div className="head">
          <div className="head-txt">Profile</div>
          {admin_data ? (
            <div className="btn-control-link">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}>
                <Button className="add-user-btn">
                  <a
                    href={`https://app.lift.lt.partners/testload?_id=${
                      admin_data.user
                    }&token=${admin_data?.token}&onboard=${1}`}
                    target={'_blank'}>
                    <div className="add-user-content">
                      {/* <Add className="add-icon" /> */}
                      <p className="add-text">Go to LIFT</p>
                    </div>
                  </a>
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="toggle-div">
          <div
            className="toggle-content"
            onClick={() => {
              setToggle('details');
            }}>
            <p className={toggle === 'details' ? 'selected-toggle-text' : ''}>
              Details
            </p>
            <div
              className={
                toggle === 'details' ? 'selected-toggle-bar' : ''
              }></div>
          </div>
        </div>
        <Divider />
        <Paper className="paper">
          {/* Update Password Dialog */}
          <Dialog disableEscapeKeyDown open={showPasswordDialog}>
            <DialogTitle
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '1.2rem',
                fontWeight: '700',
              }}>
              Update Password
            </DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.9rem',
                  width: '510px',
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}>
                <ThemeProvider theme={theme}>
                  <div>
                    Please make sure your password satisfies the following
                    conditions :
                    <ul>
                      <li>Min. Length - 6</li>
                      <li>Max. Length - 15</li>
                      <li>Atleast 1 UpperCase</li>
                      <li>Atleast 1 LowerCase</li>
                      <li>Atleast 1 Digit</li>
                      <li>No Space</li>
                    </ul>
                  </div>
                  <TextField
                    required
                    type={passwordUpdate.current}
                    error={currentPasswordError === '' ? false : true}
                    size="small"
                    label="Current Password"
                    helperText={
                      currentPasswordError === ''
                        ? 'Your current password'
                        : currentPasswordError
                    }
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    style={{
                      width: '100%',
                    }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handlePassword('current');
                            }}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {passwordUpdate.current === 'password' ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    required
                    type={passwordUpdate.new}
                    error={newPasswordError === '' ? false : true}
                    size="small"
                    label="New Password"
                    helperText={
                      newPasswordError === '' ? 'paSSw0rD' : newPasswordError
                    }
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{
                      width: '100%',
                    }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handlePassword('new');
                            }}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {passwordUpdate.new === 'password' ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    type={passwordUpdate.confirm}
                    required
                    error={confirmNewPasswordError === '' ? false : true}
                    size="small"
                    label="Confirm Password"
                    helperText={
                      confirmNewPasswordError === ''
                        ? 'Same as New Password'
                        : confirmNewPasswordError
                    }
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    style={{
                      width: '100%',
                    }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handlePassword('confirm');
                            }}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {passwordUpdate.confirm === 'password' ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </ThemeProvider>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.8rem',
                  color: 'rgb(166, 0, 113)',
                }}
                onClick={() => {
                  if (!showLoading) {
                    updatePassword();
                  }
                }}>
                {' '}
                {showLoading ? (
                  <ThemeProvider theme={theme}>
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </ThemeProvider>
                ) : (
                  'Apply'
                )}
              </Button>
              <Button
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.8rem',
                  color: 'rgb(166, 0, 113)',
                }}
                onClick={() => {
                  setCurrentPassword('');
                  setNewPassword('');
                  setConfirmNewPassword('');
                  setCurrentPasswordError('');
                  setNewPasswordError('');
                  setConfirmNewPasswordError('');
                }}>
                Reset
              </Button>
              <Button
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.8rem',
                  color: 'rgb(166, 0, 113)',
                }}
                onClick={() => {
                  setShowPasswordDialog(false);
                }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog disableEscapeKeyDown open={showUpdateModel}>
            <DialogTitle
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '1.2rem',
                fontWeight: '700',
              }}>
              Update Payment
            </DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.9rem',
                  width: '510px',
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}>
                <ThemeProvider theme={theme}>
                  <div>
                    This options will change the payment model of the
                    subscription :<ul></ul>
                  </div>
                  {details.payment.length != 0 ? (
                    <Pricing changeDetails={changeDetails} details={details} />
                  ) : (
                    'Wait'
                  )}
                  {/* <div className="coupon-control" style={{ display: "flex" }}>
                    {payment.coupon == null ? (
                      <div
                        className="payment-input"
                        style={{
                          marginRight: "20px",
                        }}
                      >
                        <label>Apply Coupon Code</label>
                        <input
                          id="card-owner-name-lpt"
                          value={coupon}
                          onChange={(e) => {
                            setCoupon(e.target.value);
                          }}
                        />

                        {couponErr ? (
                          <div className="error-payment">Coupon Invalid</div>
                        ) : (
                          <div className="success-payment">{couponMsg}</div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {payment?.coupon == null ? (
                      <div
                        className="payment-input"
                        style={{
                          marginLeft: "auto",
                          paddingTop: "25px",
                        }}
                      >
                        <div
                          onClick={() => {
                            checkCoupon();
                          }}
                          className="button-control-pay"
                          style={{ width: "auto" }}
                        >
                          Apply
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {payment.coupon != null ? (
                      <div className="coupon-success">
                        {coupon} Applied Successfully
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div> */}
                </ThemeProvider>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.8rem',
                  color: 'rgb(166, 0, 113)',
                }}
                onClick={() => {
                  if (!payment_model_process) {
                    UpdatePaymentModel();
                  }
                }}>
                {' '}
                {payment_model_process ? (
                  <ThemeProvider theme={theme}>
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </ThemeProvider>
                ) : (
                  'Apply'
                )}
              </Button>

              <Button
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '0.8rem',
                  color: 'rgb(166, 0, 113)',
                }}
                onClick={() => {
                  const change = {
                    ...details,
                    payment:
                      admin_profile?.organization?.payment_model === '2'
                        ? keys.priceIdYearly
                        : admin_profile?.organization?.payment_model === '1'
                        ? keys.priceIdMonthly
                        : '',
                  };
                  setDetails(change);
                  setShowUpdateModel(false);
                  setCouponErr(false);
                  setCouponMsg('');
                  setCoupon('');
                  dispatch(UnSetCoupon());
                }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <div className="user">
            <div className="details">
              <img
                className="avtar"
                alt="user"
                src={admin_profile?.organization?.image}
              />
              <div className="info">
                <div className="info-item">
                  Name:{' '}
                  <span className="info-value">{admin_profile?.name}</span>
                </div>
                <div className="info-item">
                  Email:{' '}
                  <span className="info-value">{admin_profile?.email}</span>
                </div>
                <div className="info-item">
                  Organization:
                  <span className="info-value">
                    {admin_profile?.organization?.name}
                  </span>
                </div>
                {admin_profile?.organization?.payment_required == 0 ? (
                  <></>
                ) : (
                  <div className="info-item">
                    Subscription Model:
                    <span className="info-value">
                      {admin_profile?.organization?.payment_model === '1'
                        ? 'Monthly'
                        : 'Yearly'}
                    </span>
                  </div>
                )}
                {/* </div> */}
              </div>
            </div>
            <div className="options">
              <Button
                className="option-btn"
                onClick={() => setShowPasswordDialog(true)}>
                Update Password
              </Button>

              {admin_profile?.organization?.payment_required == 0 ? (
                <></>
              ) : (
                <Button
                  // disabled
                  className="option-btn"
                  onClick={() => setShowUpdateModel(true)}>
                  Update payment details
                </Button>
              )}

              <Button
                // disabled
                className="option-btn-red"
                onClick={() => handleLogOut()}>
                Logout
              </Button>
            </div>
          </div>

          <div className="Updates">
            <div className="title">Updates</div>
            <div className="updates-lists">
              <ul>
                <li>All new dock structure for easy and ordered navigation.</li>
                <li>
                  New Performance Comparison Report with improved UI, granular
                  range, and more control.
                </li>
                <li>
                  New Executive Dashboard for birds-eye view of your brand's
                  performance
                </li>
                <li>
                  New Paid Placement Report with improved UI and better
                  placement selection control
                </li>
                <li>Several bug fixes for UI, data precision, and structure</li>
              </ul>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ProfileComponent;
