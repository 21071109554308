import { Button, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./avant-link.styles.scss";
import { money } from "../../const/currency";
import { useState } from "react";
import { Toastify } from "../../../../App";
const AvantLink = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    api_keys: "",
    parent_merchant_id: "",
    merchant_id: "",
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const currency = money;
  const checkPass = () => {
    if (details.api_keys.length === 0 || details.api_keys === "") {
      return "API Keys  required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    } else if (details.merchant_id.length === 0 || details.merchant_id === "") {
      return "Merchant ID required !!";
    } else if (
      details.parent_merchant_id.length === 0 ||
      details.parent_merchant_id === ""
    ) {
      return "Parent Merchant Id required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="avant-link-container">
        {" "}
        <TextField
          required
          size="small"
          label="API Key"
          style={{
            margin: "10px 0",
          }}
          name="api_keys"
          value={details.api_keys}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Parent Merchant ID"
          style={{
            margin: "10px 0",
          }}
          name="parent_merchant_id"
          value={details.parent_merchant_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Merchant ID"
          style={{
            margin: "10px 0",
          }}
          name="merchant_id"
          value={details.merchant_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "10px 0",
          }}
        >
          <MenuItem>Choose Currency</MenuItem>
          {currency.map((a, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: a.option,
                    },
                  });
                }}
                value={i + 1}
              >
                {a.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="merchant_id"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "avant-link" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default AvantLink;
