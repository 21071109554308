import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import FormContainer from "../form-container";
import { ExpandMoreSharp } from "@mui/icons-material";
import "./commision-junction.styles.scss";
import { Toastify } from "../../../../App";

import { useState } from "react";
const CommisionJunction = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    api_key: "",
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    if (e.target.name === "ncr_val" || e.target.name === "ncr_type") {
      console.log("click");
      setDetails({
        ...details,
        network_commision_rate: {
          ...details.network_commision_rate,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.api_key.length === 0 || details.api_key === "") {
      return "API Key required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    }
    return "";
  };

  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="commision-factory-container">
        {" "}
        <TextField
          required
          size="small"
          label="Username"
          style={{
            margin: "10px 0",
          }}
          name="api_key"
          value={details.api_key}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Password"
          style={{
            margin: "10px 0",
          }}
          type="password"
          name="api_key"
          value={details.api_key}
          onChange={(event) => onDetailChange(event)}
        />
        <Accordion style={{ margin: "15px 0" }}>
          <AccordionSummary expandIcon={<ExpandMoreSharp />}>
            <span className="accordion-title">
              Placement Configuration (Advance)
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Please contact support if you've enabled and configured placements
              in Affluent and need to change the configuration.
            </p>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">Include placement data?</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={gilad}
                      // onChange={handleChange}
                      name="gilad"
                    />
                  }
                  label="Yes (Default)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={jason}
                      // onChange={handleChange}
                      name="jason"
                    />
                  }
                  label="No"
                />
              </FormGroup>
            </FormControl>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">
                What date should placement fees be recorded?
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={gilad}
                      // onChange={handleChange}
                      name="gilad"
                    />
                  }
                  label="Split evenly over each day of the event (Default)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={jason}
                      // onChange={handleChange}
                      name="jason"
                    />
                  }
                  label="Start of the event"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={jason}
                      // onChange={handleChange}
                      name="jason"
                    />
                  }
                  label="End of the event"
                />
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "impact" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default CommisionJunction;
