import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";

import "./login.styles.scss";

import AuthFormInput from "../auth-form-input/auth-form-input.componnt";
import { Toastify } from "../../App";
import { actionLogTrack } from "../../lib/logs";
import { signInWithEmailAndPassword } from "firebase/auth";
import { authFirebase } from "../../utils/firebase.config";
import Spinner from "react-spinner-material";
import { useAuthContext } from "../../utils/provider/AuthProvider";

const Login = ({ changeUserAction }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false)

  const { loginUser } = useAuthContext()

  const handleSubmit = async () => {

    setShowLoading(true)
    try {
      await loginUser();
      const response = await axios.post(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/v2/users/login`,
        {
          email,
          password,
        }
      );

      localStorage.clear();
      sessionStorage.setItem("register", 1);

      if (response.data.verified === 1) {
        const res = await axios.get(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/v2/user/${response.data.id}`,
          {
            headers: {
              Authorization: `JWT ${response.data.token}`,
            },
          }
        );

        if (res.data.is_admin === 0) {
          Toastify("error", "Unauthorized Admin");
          return;
        }
        localStorage.setItem(
          "super_admin",
          JSON.stringify({
            id: response.data.id,
            name: response.data.name,
            email: email,
            user_hash: res.data.user_hash,
            brand: res.data.brand,
            brand_hash: res.data.brand_hash,
            image: response.data.image,
            track: response.data.track_usage,
            team: response.data.team,
            admin: res.data.admin_verified,
            weights: res.data.weights,
            fintech_brands: res.data.fintech_brands,
            brand_pub_mapping: res.data.brand_pub_mapping,
            brand_goals: res.data.brand_goals,
            brand_images: res.data.brand_images,
            notifications: res.data.notifications,
            mobile: res.data.mobile,
            meeting_link: res.data.meeting_link,
            position: res.data.position,
            mobile: res.data.mobile,
            meeting_link: res.data.meeting_link,
            position: res.data.position,
            is_admin: res.data.is_admin,
          })
        );

        localStorage.setItem(
          "super_token",
          JSON.stringify({
            token: response.data.token,
          })
        );

        const desc = {
          message: `User with email ${email} logged in`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };

        await actionLogTrack(JSON.stringify(desc), false, response.data.token);

        Toastify("success", "Login Successfully");

        navigate("/home", {
          state: {
            email: email,
            is_admin: res.data.is_admin,
          },
        });
      } else {
        navigate("/waiting", {
          state: {
            id: response.data.id,
            name: response.data.name,
            email: email,
            brand: response.data.brand,
            image: response.data.image,
            track: response.data.track_usage,
            team: response.data.team,
          },
        });
        Toastify("success", "Login Successfully");
      }
    } catch (error) {
      setShowLoading(false)
      try {
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/login`,
          {
            email,
            password,
          }
        );

        console.log(res, "res data");
        delete res.data.created_at;
        sessionStorage.setItem("register", 1);
        localStorage.setItem("admin_auth", JSON.stringify(res.data));
        navigate("/home");
        Toastify("success", "Login Successfully");
      } catch (error) {
        console.log(error, "error");
        Toastify("warning", "Please enter correct credentials!");

        console.log(error.response.data.message, "error");
      }
    }
  };

  return (
    <div className="login-component">
      <p className="title-login">Log in</p>
      <p className="title-login-tag">Get access to admin controls of LIFT</p>
      <AuthFormInput
        label="Email"
        type="email"
        hint="jane@lt.partners"
        value={email}
        changeFunction={(event) => setEmail(event.target.value)}
        error=""
      />
      <AuthFormInput
        label="Password"
        type="password"
        hint="paS5w0rD"
        value={password}
        changeFunction={(event) => setPassword(event.target.value)}
        error=""
      />

      <Button
        className="login-btn"
        onClick={() => {
          if (!showLoading) {
            handleSubmit();
          }
        }}
      >
        {showLoading ? (
          <div className="loading">
            <Spinner
              className="spin"
              radius={23}
              color={"white"}
              stroke={3}
              visible={true}
            />
            <div className="spacer">Loading</div>
          </div>
        ) : (
          "Log In"
        )}
      </Button>

      <br />

      <div className="forgot-pwd-text">
        <p
          className="forgot-pwd-text-child"
          onClick={() => changeUserAction("forgot-pwd")}
        >
          Forgot Password ?
        </p>
      </div>
    </div>
  );
};

export default Login;
