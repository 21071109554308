const initialState = {
  send_invitation: false,
  delete_org: false,
};

const superAdminReducer = (state = initialState, action) => {
  //schedule request

  switch (action.type) {
    case "SEND_INVITATION_EXECUTE":
      state = {
        ...state,
        send_invitation: true,
      };
      break;

    case "SEND_INVITATION_SUCCESS":
      state = {
        ...state,
        send_invitation: false,
      };
      break;
    case "SEND_INVITATION_ERROR":
      state = {
        ...state,
        send_invitation: false,
      };
      break;
    case "DELETE_ORG_EXECUTE":
      state = {
        ...state,
        delete_org: true,
      };
      break;
    case "DELETE_ORG_SUCCESS":
      state = {
        ...state,
        delete_org: false,
      };
      break;
    case "DELETE_ORG_ERROR":
      state = {
        ...state,
        delete_org: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }

  return state;
};

export default superAdminReducer;
