import React, { useState, useEffect, Fragment, useCallback } from 'react';
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { Refresh, Search, Edit } from '@mui/icons-material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import ShowPub from './showPub';
import './brand-publishers.styles.scss';
import './showPub/show-pub.styles.scss';
import { debounce } from '../../utils/debounce';
import apiClient from '../../utils/axiosClient';

const PubMapping = () => {
  const [brandPub, setBrandPub] = useState([]);
  const [brandPubBackup, setBrandPubBackup] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  console.log('testing');
  const [showEditBrandPubDialog, setShowEditBrandPubDialog] = useState(false);

  const { superAdmin } = useSelector((state) => state.admin);

  //search
  const [searchText, setSearchText] = useState('');

  //super token

  const super_token = JSON.parse(localStorage.getItem('super_token'));
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // getting ll brand weights
  const [listLoad, setListLoad] = useState(true);

  const getBrandPubMapping = () => {
    if (superAdmin) {
      apiClient
        .get(`/affcrm/v1/admin/brand/pub`)
        .then((res) => {
          setListLoad(false);
          setBrandPub(res.data.response.sort(customSort).reverse());
          setBrandPubBackup(res.data.response.sort(customSort).reverse());
        })
        .catch((err) => {
          Toastify('error', err.response.data.message);
          console.error(err);
        });
    }
  };

  const customSort = (a, b) => a.brand_id - b.brand_id;

  // Search results
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < brandPubBackup.length; i++) {
      if (
        brandPubBackup[i]['brand'].toLowerCase().includes(query.toLowerCase())
      )
        results.push(brandPubBackup[i]);
    }

    setBrandPub(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));
  const admin_data = JSON.parse(localStorage.getItem('admin_auth'));

  const [brandInfo, setBrandInfo] = useState(null);
  const [brandInfoChange, setBrandInfoChange] = useState(false);

  useEffect(() => {
    getBrandPubMapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandInfoChange]);
  return (
    <div className="br-weights">
      <ShowPub
        showEditBrandPubDialog={showEditBrandPubDialog}
        setShowEditBrandPubDialog={setShowEditBrandPubDialog}
        brandInfo={brandInfo}
        setBrandInfoChange={setBrandInfoChange}
        brandInfoChange={brandInfoChange}
      />

      <div className="head">
        <div className="head-txt">Brand Publisher Mapping</div>
        <Refresh className="head-refresh" />
        {admin_data ? (
          <div className="btn-control-link">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}>
              <Button className="add-user-btn">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://app.lift.lt.partners/testload?_id=${
                    admin_data.user
                  }&token=${admin_data?.token}&onboard=${1}`}>
                  <div
                    style={{
                      backgroundColor: '#a60071',
                      color: 'white',
                      padding: '0px 30px',
                      borderRadius: '5px',
                    }}
                    className="add-user-content">
                    {/* <Add className="add-icon" /> */}
                    <p
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        fontSize: '0.7rem',
                      }}
                      className="add-text">
                      Go to LIFT
                    </p>
                  </div>
                </a>
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="secondary-div">
        <div className="secondary-content">
          <p className="selected-text">{brandPub.length} Brands</p>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Brand Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Not Selected Publishers
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brandPub
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.brand}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {row.publishers.length !== 0
                              ? row.publishers.length > 60
                                ? row.publishers.substring(0, 60) + '...'
                                : row.publishers
                              : 'Not Set'}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="actions-div">
                          <Tooltip title="Edit">
                            <Edit
                              onClick={() => {
                                setBrandInfo(row);
                                setShowEditBrandPubDialog(true);
                              }}
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(252, 198, 3)',
                                backgroundColor: 'rgba(252, 227, 3, 0.3)',
                                border: '1px solid rgb(252, 198, 3)',
                                padding: '3px',
                                fontSize: '15px',
                                marginLeft: '10px',
                                borderRadius: '5px',
                              }}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {listLoad ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={brandPub.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default PubMapping;
