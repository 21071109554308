import { Button, MenuItem, TextField } from "@mui/material";
import FormContainer from "../form-container";
import "./one-network-direct.styles.scss";
import { timeZone } from "../../const/timeZone";
import { money } from "../../const/currency";
import { useState } from "react";
import { Toastify } from "../../../../App";

const OneNetworkDirect = ({ Connect, currentNetImg }) => {
  const time = timeZone;
  const currency = money;
  const [details, setDetails] = useState({
    account_id: "",
    client_id: "",
    login_url: "",
    time_zone: "",
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    if (e.target.name === "ncr_val" || e.target.name === "ncr_type") {
      console.log("click");
      setDetails({
        ...details,
        network_commision_rate: {
          ...details.network_commision_rate,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const checkPass = () => {
    if (details.account_id.length === 0 || details.account_id === "") {
      return "Account ID required !!";
    } else if (details.client_id.length === 0 || details.client_id === "") {
      return "Client Id required !!";
    } else if (details.login_url.length === 0 || details.login_url === "") {
      return "Login Url required !!";
    } else if (details.time_zone.length === 0 || details.time_zone === "") {
      return "TimeZone required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="one-network-direct-container">
        {" "}
        <TextField
          required
          size="small"
          label="Access ID"
          style={{
            margin: "10px 0",
          }}
          name="account_id"
          value={details.account_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Client ID"
          style={{
            margin: "10px 0",
          }}
          name="client_id"
          value={details.client_id}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Login URL"
          style={{
            margin: "10px 0",
          }}
          name="login_url"
          value={details.login_url}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          select
          required
          size="small"
          label="Select Timezone"
          style={{
            margin: "10px 0",
          }}
        >
          <MenuItem>Please select a timezone..</MenuItem>
          {time.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "time_zone",
                      value: t.time,
                    },
                  });
                }}
                value={i + 1}
              >
                {t.time}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "10px 0",
          }}
          onChange={(e) => {}}
        >
          <MenuItem>Please select a timezone..</MenuItem>
          {currency.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: t.option,
                    },
                  });
                }}
                value={i + 1}
              >
                {t.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "10px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "one-network-direct" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default OneNetworkDirect;
