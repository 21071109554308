import React, { useEffect, useRef, useState } from 'react';

import './inviteboarding.styles.scss';
import WestIcon from '@mui/icons-material/West';
import Pricing from '../../components/pricing';
import {
  TextField,
  createTheme,
  CircularProgress,
  Dialog,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Payment from '../../components/onboarding/payment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Toastify } from '../../App';
import PasswordValidator from 'password-validator';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../utils/firebaseConfig';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { keys } from '../../lib/consts/keys';
import { UnSetCoupon } from '../../redux/action/payment.action';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const InviteOnBoarding = () => {
  const payment = useSelector((state) => state.payment);

  const { invite_token } = useParams();
  const [invitation, setInvitation] = useState({
    pwd: '',
    re_pwd: '',
    payment: '',
    admin: {
      name: '',
      email: '',
    },
  });

  // password visibility

  const [passwordUpdate, setPasswordUpdate] = useState({
    current: 'password',
    confirm: 'password',
  });

  const handlePassword = (type) => {
    passwordUpdate[type] =
      passwordUpdate[type] === 'text' ? 'password' : 'text';
    setPasswordUpdate({
      ...passwordUpdate,
    });
  };

  // password visibility

  // payment

  const [showPaymentDialog, SetShowPaymentDialog] = useState(false);
  const sub_click = React.useRef();
  const [subscribed, setSubscribed] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentRequired, setPaymentRequired] = useState(null);
  // payment

  // get intivation

  const getInvitation = async () => {
    try {
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/invite/details?token=${invite_token}`
      );

      const invitationinfo = JSON.parse(localStorage.getItem('invitationInfo'));
      console.log(res.data, 'get invitgation infor');
      if (
        res.data?.details === undefined ||
        res.data?.details === null ||
        Object.keys(res.data?.details).length == 0
      ) {
        Toastify('error', res.data?.message);
      } else {
        console.log(res.data, '(res.data');
        setSubscribed(res.data.organization_subscription);
        setPaymentRequired(res.data.payment_required);
        setPaymentDetails(res.data.payment_details);

        if (
          invitationinfo !== null &&
          invitationinfo !== undefined &&
          invitationinfo !== ''
        ) {
          delete invitationinfo.admin;

          localStorage.setItem(
            'invitationInfo',
            JSON.stringify({
              ...invitationinfo,
              admin: {
                ...res.data.details,
              },
            })
          );

          setInvitation({
            ...invitationinfo,
            admin: {
              ...res.data.details,
            },
          });
        } else {
          localStorage.setItem(
            'invitationInfo',
            JSON.stringify({
              pwd: '',
              re_pwd: '',
              payment: '',
              admin: {
                ...res.data.details,
              },
            })
          );

          setInvitation({
            ...invitation,
            admin: {
              ...res.data.details,
            },
          });
        }
      }
    } catch (error) {
      console.log(error);

      Toastify('error', error.response?.data?.message);
    }
  };

  const get_ref = useRef(true);
  React.useEffect(() => {
    if (get_ref.current) {
      get_ref.current = false;
      logEvent(analytics, 'onboading_page_visited');
      getInvitation();
    }

    let stage = JSON.parse(localStorage.getItem('stage'));
    if (stage != null && stage !== undefined && stage !== '') {
      setOpen(stage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = React.useState(0);

  // get intivation
  const [details, setDetails] = React.useState({
    name: '',
    email: '',
    pwd: '',
    re_pwd: '',
    payment: '',
  });

  const [detailsCheck, setDetailsCheck] = React.useState({
    name: '',
    email: '',
    pwd: '',
    re_pwd: '',
    payment: '',
  });

  React.useState(() => {
    const info = JSON.parse(localStorage.getItem('invitationInfo'));

    if (info !== null && info !== undefined) {
      setInvitation({
        ...info,
      });
    }
  }, [details]);

  const changeDetails = (e) => {
    if (
      e.target.name === 're_pwd' ||
      e.target.name === 'pwd' ||
      e.target.name === 'payment'
    ) {
      const newdata = {
        ...invitation,
        [e.target.name]: e.target.value,
      };
      setInvitation(newdata);
      localStorage.setItem('invitationInfo', JSON.stringify(newdata));

      return;
    }
  };

  React.useEffect(() => {
    checkStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation]);

  const rename = {
    name: 'Name',
    email: 'Email',
    pwd: ' Password',
    re_pwd: 'Comfirm Password',
    org_name: 'Organization name',
    // org_img: "",
    brand_name: 'Brand Name',
    // brand_image: "",
    brand_vertical: 'Brand Vertical',
    brand_domain: 'Brand Domain',
    payment: '',
  };

  const checkValidation = () => {
    let flag = true;
    if (open === 0) {
      for (let i = 0; i < open1.length; i++) {
        let val = open1[i];
        console.log(val, 'val');
        if (val === 'pwd' || val === 're_pwd') {
          if (invitation[val].length === 0) {
            detailsCheck[val] = `${rename[val]} required !`;
            setDetailsCheck({
              ...detailsCheck,
            });
            console.log(details[val].length, val);

            flag = false;
          } else {
            detailsCheck[val] = ``;
            setDetailsCheck({
              ...detailsCheck,
            });
          }
        }
      }

      if (
        invitation.pwd.length !== 0 &&
        invitation.re_pwd !== 0 &&
        invitation.pwd !== invitation.re_pwd
      ) {
        detailsCheck.re_pwd = `Confirm password does not match with password!`;
        setDetailsCheck({
          ...detailsCheck,
        });
        flag = false;
      }
    } else if (open === 1) {
      if (invitation.payment.length === 0) {
        Toastify('error', 'Please Select your subscription plan!');
        flag = false;
      }
    }

    console.log(flag, 'flag');
    return flag;
  };

  const open1 = ['pwd', 're_pwd'];
  const checkStage = () => {
    for (let i = 0; i < open1.length; i++) {
      let key = open1[i];

      if (invitation[key] === '') {
        console.log(invitation, 'key');
        localStorage.setItem('stage', 0);
        return;
      }
    }

    if (!checkValidation()) {
      localStorage.setItem('stage', 0);
      return;
    }

    localStorage.setItem('stage', 1);

    if (!checkValidation()) {
      localStorage.setItem('stage', 1);
      return;
    }
  };

  const navigator = useNavigate();

  const [onboarding, setOnboarding] = useState(false);
  const onboard = async () => {
    try {
      setOnboarding(true);

      const respone_1 = await axios.post(
        'https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/onboarding',
        {
          token: invite_token,
          admin_password: invitation.pwd,
          payment_model:
            paymentDetails.payment_model === '' ||
            paymentDetails.payment_model === null ||
            paymentDetails.payment_model === undefined
              ? null
              : paymentDetails.payment_model,
          subscription_id:
            paymentDetails.subscription_id === '' ||
            paymentDetails.subscription_id === null ||
            paymentDetails.subscription_id === undefined
              ? null
              : paymentDetails.subscription_id,
          customer_id:
            paymentDetails.customer_id === '' ||
            paymentDetails.customer_id === null ||
            paymentDetails.customer_id === undefined
              ? null
              : paymentDetails.customer_id,
        }
      );
      localStorage.clear();

      delete respone_1.data.details.password;
      localStorage.setItem(
        'admin_auth',
        JSON.stringify({
          ...respone_1.data.details,
          user: respone_1.data.user_profile.id,
        })
      );

      delete respone_1.data.user_profile.password;
      localStorage.setItem(
        'user_auth',
        JSON.stringify(respone_1.data.user_profile)
      );
      setOnboarding(false);
      sessionStorage.setItem('register', 1);
      navigator('/home');
      Toastify('Success', 'Success! Check your email for the invoice.');
    } catch (error) {
      setOnboarding(false);
      Toastify('error', error.response?.data?.message);
      console.log(error, 'error.response.data.message');
    }
  };

  const pay_onbarod = () => {
    let passwordSchema = new PasswordValidator();
    passwordSchema
      .is()
      .min(6)
      .is()
      .has()
      .uppercase(1)
      .has()
      .lowercase(1)
      .has()
      .digits(1)
      .has()
      .not()
      .spaces();
    if (open < 1) {
      if (checkValidation()) {
        if (!passwordSchema.validate(invitation.pwd)) {
          detailsCheck.pwd =
            'Password should be of length 6 - 10 and should be containing atleast 1 uppercase, 1 lowercase and 1 digit and should not have any spaces';
          setDetailsCheck({
            ...detailsCheck,
          });
          return;
        }
        setOpen(open + 1);
      }
    } else {
      SetShowPaymentDialog(true);
    }
  };
  const [showCost, setShowCost] = useState(0);
  let org_cost = useRef(
    keys.priceIdMonthly === invitation.payment ? 1000 : 10000
  );
  // let org_cost = keys.priceIdMonthly === invitation.payment ? 1000 : 10000;
  useEffect(() => {
    if (payment.coupon === null || payment.coupon === undefined) {
      setShowCost(showCost);
    } else {
      setShowCost(
        showCost - (showCost * payment?.coupon?.coupon?.percent_off) / 100
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment.coupon]);

  useEffect(() => {
    const cost = keys.priceIdMonthly === invitation.payment ? 1000 : 10000;
    org_cost.current = cost;
    setShowCost(cost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation.payment]);

  const dispatch = useDispatch();

  return (
    <div className="onboarding">
      <Dialog disableEscapeKeyDown open={showPaymentDialog}>
        {/* <DialogContent> */}

        <div className="payment-dialog-container">
          <div className="header">
            <img
              alt=""
              src={
                'https://lift.lt.partners/static/media/lift_logo_pink-cloud.7b51fda768fdc959c87a.png'
              }
            />
            <div className="section">
              <div className="label">
                {invitation.payment === keys.priceIdMonthly
                  ? 'Monthly'
                  : 'Yearly'}
              </div>
              <div className="value">
                ${invitation.payment === keys.priceIdMonthly ? 1000 : 10000}
              </div>
            </div>

            {payment.coupon != null ? (
              <div className="section">
                <div className="label">
                  Discount ({payment?.coupon?.coupon?.coupon})
                </div>
                <div className="value">
                  - $
                  {(org_cost.current * payment?.coupon?.coupon?.percent_off) /
                    100}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="payment-margin"></div>
            <div className="section net-pay">
              <div className="label">Net Payable</div>
              <div className="value">${showCost}</div>
            </div>
          </div>
          {/* <div className="content">
              orem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
            </div> */}
          <Payment
            setShowCost={setShowCost}
            org_cost={org_cost.current}
            sub_click={sub_click}
            details={{ ...invitation.admin, ...invitation }}
          />
          <ThemeProvider theme={theme}>
            <div className="btn-container-payment">
              <div
                className={`button-control-close-pay ${
                  payment.payment_process ? 'button-control-close-diable' : ''
                }`}
                onClick={() => {
                  if (!payment.payment_process) {
                    dispatch(UnSetCoupon());
                    SetShowPaymentDialog(false);
                  }
                }}>
                Close
              </div>
              {!payment.payment_process ? (
                <div
                  className="button-control-pay"
                  onClick={() => {
                    sub_click.current.click();
                  }}>
                  {/* {open == 2 ? "Subscribe" : "Next Step"} */}
                  Pay
                </div>
              ) : (
                <div className="button-control-pay">
                  <CircularProgress
                    style={{
                      color: '#fff',
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </div>
              )}
            </div>
          </ThemeProvider>
        </div>
        {/* </DialogContent> */}
      </Dialog>
      <div className="top-bar">
        <div className="logo">
          <img alt="" src={process.env.PUBLIC_URL + '/assets/lift_fav.png'} />
        </div>
      </div>

      <div className="content-container">
        <h2 className={'header'}>Welcome to LIFT</h2>
        <p className="content">
          Please complete the following steps to continue
        </p>
      </div>
      <div className={'form-container'}>
        <ThemeProvider theme={theme}>
          {open === 0 ? (
            <div className={'form-type form-1'}>
              <TextField
                required
                disabled
                error={detailsCheck.name === '' ? false : true}
                size={'small'}
                label="Name"
                helperText={
                  detailsCheck.name === '' ? 'Your Name' : detailsCheck.name
                }
                value={invitation.admin.name}
                name={'name'}
                style={{
                  width: '100%',
                  marginTop: '25px',
                }}
              />
              <TextField
                required
                disabled
                error={detailsCheck.email === '' ? false : true}
                size={'small'}
                label="Email"
                helperText={
                  detailsCheck.email === '' ? 'Your Email' : detailsCheck.email
                }
                value={invitation.admin.email}
                name={'email'}
                style={{
                  width: '100%',
                  marginTop: '25px',
                }}
              />
              <TextField
                required
                error={detailsCheck.pwd === '' ? false : true}
                size={'small'}
                type={passwordUpdate.current}
                label="Password"
                helperText={
                  detailsCheck.pwd === '' ? 'Your Password' : detailsCheck.pwd
                }
                value={invitation.pwd}
                name={'pwd'}
                style={{
                  width: '100%',
                  marginTop: '25px',
                }}
                onChange={changeDetails}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          handlePassword('current');
                        }}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {passwordUpdate.current === 'password' ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                error={detailsCheck.re_pwd}
                size={'small'}
                type={passwordUpdate.confirm}
                label="Confirm-Password"
                helperText={
                  detailsCheck.re_pwd === ''
                    ? 'Should be the same as Password'
                    : detailsCheck.re_pwd
                }
                value={invitation.re_pwd}
                name={'re_pwd'}
                style={{
                  width: '100%',
                  marginTop: '25px',
                }}
                onChange={changeDetails}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          handlePassword('confirm');
                        }}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {passwordUpdate.confirm === 'password' ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : (
            <></>
          )}

          {open === 1 ? (
            <div className={'form-type form-4'}>
              <Pricing
                changeDetails={changeDetails}
                details={{ ...invitation.admin, ...invitation }}
              />
            </div>
          ) : (
            <></>
          )}

          {/* {open === 2 ? (
            <div className={"form-type form-4"}>
              <Payment
                sub_click={sub_click}
                details={{ ...invitation.admin, ...invitation }}
              />
            </div>
          ) : (
            <></>
          )} */}
        </ThemeProvider>
      </div>
      <div className="control-section">
        <div className="controls">
          <div
            onClick={() => {
              if (open > 0) {
                setOpen(open - 1);
              }
            }}
            className="back-control">
            <WestIcon size={30} /> <span>Back</span>
          </div>

          {subscribed || paymentRequired === 0 ? (
            onboarding ? (
              <div className="button-control">
                <CircularProgress
                  style={{
                    color: '#fff',
                    height: '20px',
                    width: '20px',
                  }}
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  onboard();
                }}
                className="button-control">
                {/* {open == 2 ? "Subscribe" : "Next Step"} */}
                {'Onboard'}
              </div>
            )
          ) : !payment.payment_process ? (
            <div
              onClick={() => {
                pay_onbarod();
              }}
              className="button-control">
              {/* {open == 2 ? "Subscribe" : "Next Step"} */}
              {'Next Step'}
            </div>
          ) : (
            <div className="button-control">
              <CircularProgress
                style={{
                  color: '#fff',
                  height: '20px',
                  width: '20px',
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="footer">
        <p>All rights reserved | LT Partners</p>
      </div>
    </div>
  );
};

export default InviteOnBoarding;
