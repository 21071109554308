import { signInWithEmailAndPassword } from 'firebase/auth';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { authFirebase } from '../firebase.config';

// Provide a default value for the context
const defaultContextValue = {
  authUser: null,
  loginUser: async () => {},
  signUpUser: async () => {},
  loading: true,
};

const AuthContext = createContext(defaultContextValue);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const loginUser = async () => {
    await signInWithEmailAndPassword(
      authFirebase,
      'admin@lt.partners',
      'Analytics@2022'
    );
  };
  const signUpUser = async () => {};

  useEffect(() => {
    const unsubscribe = authFirebase.onAuthStateChanged((user) => {
      setAuthUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const contextValue = {
    authUser,
    loginUser,
    signUpUser,
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
