import React from "react";

import "./crm-user-analytics.styles.scss";

const CRMUserAnalytics = () => (
  <div className="crmUserAnalytics-component">

    <iframe
    className="report"
      // src="https://lookerstudio.google.com/embed/reporting/eaa463ff-aeba-49d9-bff9-6fb9652a7fca/page/p_gxz2or2xed"
      src="https://lookerstudio.google.com/embed/reporting/49c76edc-e2b9-4e3b-b93b-e6834ecb26ff/page/p_ha54jcxwed"
      title="CRM User Analytics"
    />
  </div>
);

export default CRMUserAnalytics;
