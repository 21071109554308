import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  ThemeProvider,
  Button,
  CircularProgress,
  Box,
  createTheme,
  TextField,
  Divider,
} from '@mui/material';

import { Edit, Refresh, Search } from '@mui/icons-material';
import './matrix.styles.scss';
import { debounce } from '../../utils/debounce';
import { actionLogTrack } from '../../lib/logs';
import { Toastify } from '../../App';
import apiClient from '../../utils/axiosClient';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const MatrixComponent = forwardRef((props, ref) => {
  //search

  const super_token = JSON.parse(localStorage.getItem('super_token'));
  const admin_data = JSON.parse(localStorage.getItem('admin_auth'));

  const [brandWeights, setBrandWeights] = useState([]);
  const [brandWeightsBackup, setBrandWeightsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showEditBrandWeightDialog, setShowEditBrandWeightDialog] =
    useState(false);

  const [editBrand, setEditBrand] = useState();

  const [
    hideEditBrandWeightProgressDialog,
    setHideEditBrandWeightProgressDialog,
  ] = useState(true);

  const editBrandWeight = (row) => {
    setBrandGoals({ ...row });
    console.log(row, 'row value');
    setShowEditBrandWeightDialog(true);
  };
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // getting ll brand weights
  const [brandPubList, setBrandPubList] = useState([]);
  const [brandPubListBackup, setBrandPubListBackup] = useState([]);
  const getBrandGoals = async () => {
    try {
      const res = await apiClient.get(`/brand/v1/admin/brand/goals/get`);

      setBrandPubList(res.data.response);
      setBrandPubListBackup(res.data.response);
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  // Search results
  const [searchText, setSearchText] = useState('');
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < brandPubListBackup.length; i++) {
      if (
        brandPubListBackup[i]['brand']
          .toLowerCase()
          .includes(query.toLowerCase())
      )
        results.push(brandPubListBackup[i]);
    }

    setBrandPubList(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));

  useEffect(() => {
    getBrandGoals();
  }, []);

  const [brandInfo, setBrandInfo] = useState(null);
  const [brandGoals, setBrandGoals] = useState({
    id: -1,
    revenue: 0,
    spend: 0,
    comm_rate: 0,
    conv_rate: 0,
    clicks: 0,
    actions: 0,
    cac: 0,
    new_user: 0,
    new_rev: 0,
    discount: 0,
    aov: 0,
    roas: 0,
  });
  const handleChange = (e) => {
    setBrandGoals({
      ...brandGoals,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const changeDetected = () => {
    const brand = brandPubListBackup.find((b) => b.id === brandGoals.id);
    const changes = [];

    if (brand) {
      if (brand.actions != brandGoals.actions) {
        changes.push(`${brand.actions} => ${brandGoals.actions} (actions)`);
      }

      if (brand.aov != brandGoals.aov) {
        changes.push(`${brand.aov} => ${brandGoals.aov} (aov)`);
      }

      if (brand.cac != brandGoals.cac) {
        changes.push(`${brand.cac} => ${brandGoals.cac} (cac)`);
      }

      if (brand.canc != brandGoals.canc) {
        changes.push(`${brand.canc} => ${brandGoals.canc} (canc)`);
      }

      if (brand.clicks != brandGoals.clicks) {
        changes.push(`${brand.clicks} => ${brandGoals.clicks} (clicks)`);
      }

      if (brand.comm_rate != brandGoals.comm_rate) {
        changes.push(
          `${brand.comm_rate} => ${brandGoals.comm_rate} (comm_rate)`
        );
      }

      if (brand.conv_rate != brandGoals.conv_rate) {
        changes.push(
          `${brand.conv_rate} => ${brandGoals.conv_rate} (conv_rate)`
        );
      }

      if (brand.discount != brandGoals.discount) {
        changes.push(`${brand.discount} => ${brandGoals.discount} (discount)`);
      }

      if (brand.new_rev != brandGoals.new_rev) {
        changes.push(`${brand.new_rev} => ${brandGoals.new_rev} (new_rev)`);
      }

      if (brand.new_user != brandGoals.new_user) {
        changes.push(`${brand.new_user} => ${brandGoals.new_user} (new_user)`);
      }

      if (brand.revenue != brandGoals.revenue) {
        changes.push(`${brand.revenue} => ${brandGoals.revenue} (revenue)`);
      }

      if (brand.roas != brandGoals.roas) {
        changes.push(`${brand.roas} => ${brandGoals.roas} (roas)`);
      }

      if (brand.spend != brandGoals.spend) {
        changes.push(`${brand.spend} => ${brandGoals.spend} (spend)`);
      }
    }

    return changes;
  };

  const updateBrandGoal = async () => {
    try {
      setHideEditBrandWeightProgressDialog(false);
      const res = await apiClient.put(`/brand/v1/admin/brand/goals/update`, {
        ...brandGoals,
      });

      setShowEditBrandWeightDialog(false);
      setHideEditBrandWeightProgressDialog(true);
      getBrandGoals();
      Toastify('success', 'Brand goal updated !!');
      const changeVar = changeDetected();
      console.log(changeVar, 'changevar');
      const desc = {
        message: `Brand goal ${changeVar.toString()} updated`,
        status: res.status,
        method: res.config.method,
        url: res.config.url,
      };
      await actionLogTrack(JSON.stringify(desc), false, super_token?.token);
    } catch (error) {
      console.log(error, 'error');
      if (error.response) {
        Toastify('error', error.response.data.message);
      } else {
        Toastify('error', 'Somthing went wrong !!');
      }
      actionLogTrack(JSON.stringify(error), true, super_token?.token);
    }
  };
  return (
    <div className="br-weights">
      <div className="head">
        <div className="head-txt">Goal Matrix</div>
        <Refresh className="head-refresh" />
        {admin_data ? (
          <div className="btn-control-link">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}>
              <Button className="add-user-btn">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://app.lift.lt.partners/testload?_id=${
                    admin_data.user
                  }&token=${admin_data?.token}&onboard=${1}`}>
                  <div
                    style={{
                      backgroundColor: '#a60071',
                      color: 'white',
                      padding: '0px 30px',
                      borderRadius: '5px',
                    }}
                    className="add-user-content">
                    {/* <Add className="add-icon" /> */}
                    <p
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        fontSize: '0.7rem',
                      }}
                      className="add-text">
                      Go to LIFT
                    </p>
                  </div>
                </a>
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="secondary-div">
        <div className="secondary-content">
          <p className="selected-text">{brandPubList.length} Brands</p>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Paper className="table-paper">
        <Dialog disableEscapeKeyDown open={showEditBrandWeightDialog}>
          <DialogTitle
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1rem',
            }}>
            Edit Brand Goal Matrix
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '1.1rem',
                fontWeight: '700',
              }}>
              {editBrand}
            </span>
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.9rem',
                width: '510px',
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}>
              <ThemeProvider theme={theme}>
                <div>
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Revenue"
                    name="revenue"
                    value={brandGoals.revenue}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Spend"
                    name="spend"
                    value={brandGoals.spend}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Comm Rate"
                    name="comm_rate"
                    value={brandGoals.comm_rate}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Conv Rate"
                    name="conv_rate"
                    value={brandGoals.conv_rate}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />

                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Clicks"
                    name="clicks"
                    value={brandGoals.clicks}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="New Actions"
                    name="actions"
                    value={brandGoals.actions}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="CAC"
                    name="cac"
                    value={brandGoals.cac}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="New Users %"
                    name="new_user"
                    value={brandGoals.new_user}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="New Rev %"
                    name="new_rev"
                    value={brandGoals.new_rev}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="Discount %"
                    name="discount"
                    value={brandGoals.discount}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="AOV"
                    name="aov"
                    value={brandGoals.aov}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    required
                    size="small"
                    type="number"
                    label="ROAS"
                    name="roas"
                    value={brandGoals.roas}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </ThemeProvider>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.8rem',
                color: 'rgb(166, 0, 113)',
              }}
              onClick={() => {
                if (hideEditBrandWeightProgressDialog) {
                  updateBrandGoal();
                }
              }}>
              <div>
                {hideEditBrandWeightProgressDialog ? (
                  <p>Save</p>
                ) : (
                  <ThemeProvider theme={theme}>
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </ThemeProvider>
                )}
              </div>
            </Button>
            <Button
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '0.8rem',
                color: 'rgb(166, 0, 113)',
              }}
              onClick={() => setShowEditBrandWeightDialog(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Divider />
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Brand Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Revenue
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Spend
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Comm Rate
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Conv Rate
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Clicks
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  New Actions
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  CAC
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  CAnC
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  New Users %
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  New Rev %
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Discount %
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  AOV
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  ROAS
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}
                  align="right">
                  Matrix Setup
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brandPubList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.brand}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.revenue}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.spend}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.comm_rate}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.conv_rate}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.clicks}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.actions}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.cac}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.canc ? row.canc : '--'}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.new_user}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.new_rev}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.discount}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.aov}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                              textAlign: 'right',
                            }}>
                            {row.roas}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          className="actions-div"
                          style={{
                            marginLeft: 'auto',
                          }}>
                          <Tooltip title="Edit">
                            {/* <Checkbox
                            style={{
                              cursor: "pointer",
                              color: "rgb(166, 0, 113)",
                              fontSize: "15px",
                            }}
                          /> */}

                            <Edit
                              onClick={() => {
                                editBrandWeight(row);
                              }}
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(252, 198, 3)',
                                backgroundColor: 'rgba(252, 227, 3, 0.3)',
                                border: '1px solid rgb(252, 198, 3)',
                                padding: '3px',
                                fontSize: '15px',
                                marginLeft: '10px',
                                borderRadius: '5px',
                              }}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={brandWeights.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
});

export default MatrixComponent;
