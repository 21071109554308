import { AddPhotoAlternateOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onCMSChange } from "../../../../redux/action/cms.actions";
import "./image-input.styles.scss";
import ImgCrop from "../img-crop";

const ImageInput = ({ content, width }) => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const cmsData = useSelector((state) => state.cmsData);

  // const UpdateImage = (img) => {
  //   let contentList = cmsData.cms.blocks.filter((c) => {
  //     console.log(c.id, content.id);
  //     if (c.id == content.id) {
  //       c.image = URL.createObjectURL(img);
  //     }
  //     return c;
  //   });

  //   let cms = {
  //     ...cmsData.cms,
  //     blocks: [...contentList],
  //   };

  //   dispatch(onCMSChange(cms));
  // };

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      const img = URL.createObjectURL(blob);

      let contentList = cmsData.cms.blocks.filter((c) => {
        if (c.id === content.id) {
          c.image = img;
        }
        return c;
      });

      let cms =
        content.id === -1
          ? {
              ...cmsData.cms,
              hero: img,
            }
          : {
              ...cmsData.cms,
              blocks: [...contentList],
            };

      dispatch(onCMSChange(cms));
    });

    setImgInput(null);
  }

  const previewCanvasRef = useRef(null);
  const [imgInput, setImgInput] = useState(null);

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              // height: "100%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
        fullWidth
        disableEscapeKeyDown
        open={imgInput != null}
      >
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Edit Image
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            {/* <ThemeProvider theme={theme}> */}
            <div
              style={{
                width: "100%",
                // height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // onClick={() => inputFile.current.click()}
            >
              <ImgCrop
                imgInput={imgInput}
                previewCanvasRef={previewCanvasRef}
              />
            </div>
            {/* </ThemeProvider> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={onDownloadCropClick}
          >
            <div>
              <p>Add</p>
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => setImgInput(null)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="image-input"
        onClick={() => {
          // setShowAddImgDialog(true);
          inputFile.current.click();
        }}
        style={{
          width: width,
          border:
            content.image === null || content.image === undefined
              ? "1px solid #ccc"
              : "0px solid #ccc",
        }}
      >
        <div
          style={{
            display:
              content.image === null || content.image === undefined
                ? "flex"
                : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AddPhotoAlternateOutlined />

          <p>Upload Image</p>
          <input
            type="file"
            ref={inputFile}
            style={{
              display: "none",
            }}
            onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                // UpdateImage(event.target.files[0]);
                setImgInput(event);
              }
            }}
          />
        </div>

        <div
          style={{ display: content.image === null ? "none" : "" }}
          className="image-container"
        >
          <img src={content.image} alt="content-img" />
        </div>
      </div>
    </>
  );
};

export default ImageInput;
