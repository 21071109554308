import React, { useState, useEffect, Fragment } from 'react';
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Button,
} from '@mui/material';
import {
  DeleteOutline,
  Refresh,
  Add,
  Visibility,
  Edit,
} from '@mui/icons-material';


import './cms.styles.scss';
import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import { Link, useLocation } from 'react-router-dom';
import { useThrottledFn } from '../../utils/throttle';
import apiClient from '../../utils/axiosClient';

const AdminUsers = () => {
  const [toggle, setToggle] = useState('publish');
  const [pages, setPages] = useState([]);
  const [pagesBackUp, setPagesBackUp] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { state } = useLocation();
  //super token
  const super_token = JSON.parse(localStorage.getItem('super_token'));

  // useEffect(() => {
  //   if (get_ref.current && super_token?.token) {
  //     get_ref.current = false;
  //     getPages();
  //   }
  // }, []);

  useEffect(() => {
    getPages();
    sessionStorage.removeItem('preview');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Format Date
  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    );
  };
  const [loadList, setLoadList] = useState(true);

  const getPages = async () => {
    try {
      const res = await apiClient.get(
        toggle === 'publish'
          ? `/outreach/v1/admin/cms/get/published`
          : `/outreach/v1/admin/cms/get/draft`
      );

      setLoadList(false);
      setPagesBackUp(res.data.response.sort(customSortBasedOnDate).reverse());
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }

    // pageStatus(toggle);
  };

  const throttleGetPages = useThrottledFn(getPages, 1000);

  const deletePage = async (page) => {
    try {
      console.log(page?.page_id, 'page id');
      const res = await apiClient.delete(
        `/outreach/v1/admin/cms/delete/${page?.page_id}`
      );
      console.log('deleted', res);
      if (res.status === 200) {
        getPages();
        Toastify('success', 'Page deleted!!');
      }
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log('error', error);
    }
  };

  const throttleDeletePage = useThrottledFn(deletePage, 1000);

  useEffect(() => {
    setPages(pagesBackUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesBackUp]);

  // const pageStatus = (status) => {
  //   if (status === "publish") {
  //     setPages(pagesBackUp);
  //     return;
  //   }
  //   let results = [];

  //   for (let i = 0; i < pagesBackUp.length; i++) {
  //     if (
  //       pagesBackUp[i]["status"].toLowerCase().includes(status.toLowerCase())
  //     ) {
  //       results.push(pagesBackUp[i]);
  //     }
  //   }

  //   setPages(results);
  // };

  // Function to sort users data based on their date
  const customSortBasedOnDate = (a, b) => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  // Function to update data
  const updateDataOnToggle = (toggleActionName) => {
    setToggle(toggleActionName);
    setPage(0);
    // pageStatus(toggleActionName);
  };

  const createPage = async () => {
    try {
      const res = await apiClient.post('/outreach/v1/admin/cms/register');

      const { page_id } = res.data;
      localStorage.setItem('pageId', page_id);
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  return (
    <div className="cms">
      <div className="head">
        <div className="head-txt">Pages</div>
        <Refresh
          className="head-refresh"
          onClick={() => {
            throttleGetPages();
          }}
        />
        <Link
          onClick={() => {
            sessionStorage.removeItem('preview');
          }}
          style={{
            textDecoration: 'none',
            color: '#fff',
          }}
          to="/home/create"
          state={{
            email: state.email,
            password: state.password,
          }}
          target="_blank"
          className="add-brand-btn">
          <Button
            onClick={() => {
              // navigate("/home/create", {
              //   state: {
              //     email: state.email,
              //     password: state.password,
              //   },
              // });
            }}>
            <div
              className="add-brand-content"
              onClick={() => {
                createPage();
              }}>
              <Add className="add-icon" />
              <p className="add-text">Create Content</p>
            </div>
          </Button>
        </Link>
      </div>
      <div className="toggle-div">
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle('publish');
          }}>
          <p className={toggle === 'publish' ? 'selected-toggle-text' : ''}>
            Published pages
          </p>
          <div
            className={toggle === 'publish' ? 'selected-toggle-bar' : ''}></div>
        </div>
        <div
          className="toggle-content"
          onClick={() => {
            updateDataOnToggle('draft');
            // pageStatus("succeeded");
          }}>
          <p className={toggle === 'draft' ? 'selected-toggle-text' : ''}>
            Draft pages
          </p>
          <div
            className={toggle === 'draft' ? 'selected-toggle-bar' : ''}></div>
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Page
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Title
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Type
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Date
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {pages
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}>
                        <img
                          alt="fav"
                          className="avtar"
                          src={row?.hero}
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            height: '60px',
                            width: '120px',
                            objectFit: 'contain',
                            borderRadius: '5px',
                            marginRight: '15px',
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}>
                          <div
                            style={{
                              fontWeight: '600',
                            }}>
                            {row?.title}
                          </div>
                          <div
                            style={{
                              fontWeight: '500',
                              color: 'rgb(166, 0, 113)',
                              fontSize: '0.8rem',
                            }}>
                            {/* {userTeamStatus[row.id] === 1
                              ? "All Brands"
                              : `${row.organization.split(",").length} Brand(s)`} */}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}>
                          <div
                            style={{
                              fontWeight: '600',
                            }}>
                            {row?.public === 1 ? 'Public' : 'Private'}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <p
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '5px',
                            }}>
                            {formatDate(row?.created_at)}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: '500',
                        }}>
                        <div>
                          <Tooltip title="Delete">
                            <DeleteOutline
                              onClick={() => {
                                throttleDeletePage(row);
                              }}
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(252, 3, 3)',
                                backgroundColor: 'rgba(252, 3, 3, 0.3)',
                                border: '1px solid rgb(252, 3, 3)',
                                padding: '3px',
                                fontSize: '15px',
                                marginRight: '10px',
                                borderRadius: '5px',
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Edit">
                            <Link
                              onClick={() => {
                                sessionStorage.removeItem('preview');
                              }}
                              style={{
                                textDecoration: 'none',
                                color: '#fff',
                              }}
                              // 'ltp-analytics-cient/cms/pages/cms-page_id.json'
                              to={`/home/page-edit/${row.page_id}/${row?.subdomain}/${row?.endpoint}`}
                              state={{
                                email: state.email,
                                password: state.password,
                              }}
                              target="_blank"
                              className="add-brand-btn">
                              <Edit
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(252, 198, 3)',
                                  backgroundColor: 'rgba(252, 227, 3, 0.3)',
                                  border: '1px solid rgb(252, 198, 3)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginRight: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Link>
                          </Tooltip>
                          <Tooltip title="View">
                            <Link
                              onClick={() => {
                                sessionStorage.removeItem('preview');
                              }}
                              style={{
                                textDecoration: 'none',
                                color: '#fff',
                              }}
                              // 'ltp-analytics-cient/cms/pages/cms-page_id.json'
                              to={`/home/page-view/${row?.subdomain}/${row?.endpoint}`}
                              state={{
                                email: state.email,
                                password: state.password,
                              }}
                              target="_blank"
                              className="add-brand-btn">
                              <Visibility
                                onClick={() => {}}
                                style={{
                                  cursor: 'pointer',
                                  color: 'rgb(36, 169, 181)',
                                  backgroundColor: 'rgba(36, 169, 181, 0.3)',
                                  border: '1px solid rgb(36, 169, 181)',
                                  padding: '3px',
                                  fontSize: '15px',
                                  marginRight: '10px',
                                  borderRadius: '5px',
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={pages.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>

      <div className="foot">
        <p>All rights reserved | LT Partners</p>
      </div>
    </div>
  );
};

export default AdminUsers;
