export const timeZone = [
  {
    value: 1,
    time: "(UTC +14:00) Line Is. Time (Pacific/Kiritimati)",
  },
  {
    value: 2,
    time: "(UTC +13:00) Phoenix Is.Time (Pacific/Enderbury)",
  },
  {
    value: 3,
    time: "(UTC +13:00) Tonga Time (Pacific/Tongatapu)",
  },
  {
    value: 4,
    time: "(UTC +13:45) Chatham Standard Time (Pacific/Chatham)",
  },
  {
    value: 5,
    time: "(UTC +13:00) New Zealand Standard Time (Pacific/Auckland)",
  },
  {
    value: 6,
    time: "(UTC +12:00) Fiji Time (Pacific/Fiji)",
  },
  {
    value: 7,
    time: "(UTC +12:00) Petropavlovsk-Kamchatski Time (Asia/Kamchatka)",
  },
  {
    value: 8,
    time: "(UTC +12:00) Norfolk Time (Pacific/Norfolk)",
  },
  {
    value: 9,
    time: "(UTC +11:00) Lord Howe Standard Time (Australia/Lord_Howe)",
  },
  {
    value: 10,
    time: "(UTC +11:00) Solomon Is. Time (Pacific/Guadalcanal)",
  },
  {
    value: 11,
    time: "(UTC +10:30) Australian Central Standard Time (Australia/Adelaide)",
  },
  {
    value: 12,
    time: "(UTC +11:00) Australian Eastern Standard Time (Australia/Sydney)",
  },
  {
    value: 13,
    time: "(UTC +10:00) Australian Eastern Standard Time (Australia/Brisbane)",
  },
  {
    value: 14,
    time: "(UTC +09:30) Australian Central Standard Time (Australia/Darwin)",
  },
  {
    value: 15,
    time: "(UTC +09:00) Korea Standard Time (Asia/Seoul)",
  },
  {
    value: 16,
    time: "(UTC +09:00) Japan Standard Time (Asia/Tokyo)",
  },
  {
    value: 17,
    time: "(UTC +08:00) Hong Kong Time (Asia/Hong_Kong)",
  },
  {
    value: 18,
    time: "(UTC +08:00) Malaysia Time (Asia/Kuala_Lumpur)",
  },
  {
    value: 19,
    time: "(UTC +08:00) Philippines Time (Asia/Manila)",
  },
  {
    value: 20,
    time: "(UTC +08:00) China Standard Time (Asia/Shanghai)",
  },
  {
    value: 21,
    time: "(UTC +08:00) Singapore Time (Asia/Singapore)",
  },
  {
    value: 22,
    time: "(UTC +08:00) China Standard Time (Asia/Taipei)",
  },
  {
    value: 23,
    time: "(UTC +08:00) Australian Western Standard Time (Australia/Perth)",
  },
  {
    value: 24,
    time: "(UTC +07:00) Indochina Time (Asia/Bangkok)",
  },
  {
    value: 25,
    time: "(UTC +07:00) Indochina Time (Asia/Ho_Chi_Minh)",
  },
  {
    value: 26,
    time: "(UTC +07:00) West Indonesia Time (Asia/Jakarta)",
  },
  {
    value: 27,
    time: "(UTC +06:30) Myanmar Time (Asia/Rangoon)",
  },
  {
    value: 28,
    time: "(UTC +06:00) Bangladesh Time (Asia/Dhaka)",
  },
  {
    value: 29,
    time: "(UTC +05:45) Nepal Time (Asia/Kathmandu)",
  },
  {
    value: 30,
    time: "(UTC +05:30) India Standard Time (Asia/Colombo)",
  },
  {
    value: 31,
    time: "(UTC +05:30) India Standard Time (Asia/Kolkata)",
  },
  {
    value: 32,
    time: "(UTC +05:00) Pakistan Time (Asia/Karachi)",
  },
  {
    value: 33,
    time: "(UTC +05:00) Uzbekistan Time (Asia/Tashkent)",
  },
  {
    value: 34,
    time: "(UTC +05:00) Yekaterinburg Time (Asia/Yekaterinburg)",
  },
  {
    value: 35,
    time: "(UTC +04:30) Afghanistan Time (Asia/Kabul)",
  },
  {
    value: 36,
    time: "(UTC +04:00) Azerbaijan Summer Time (Asia/Baku)",
  },
  {
    value: 37,
    time: "(UTC +04:00) Gulf Standard Time (Asia/Dubai)",
  },
  {
    value: 38,
    time: "(UTC +04:00) Georgia Time (Asia/Tbilisi)",
  },
  {
    value: 39,
    time: "(UTC +04:00) Armenia Time (Asia/Yerevan)",
  },
  {
    value: 40,
    time: "(UTC +03:30) Iran Daylight Time (Asia/Tehran)",
  },
  {
    value: 41,
    time: "(UTC +03:00) East African Time (Africa/Nairobi)",
  },
  {
    value: 42,
    time: "(UTC +03:00) Arabia Standard Time (Asia/Baghdad)",
  },
  {
    value: 43,
    time: "(UTC +03:00) Arabia Standard Time (Asia/Kuwait)",
  },
  {
    value: 44,
    time: "(UTC +03:00) Arabia Standard Time (Asia/Riyadh)",
  },
  {
    value: 45,
    time: "(UTC +03:00) Moscow Standard Time (Europe/Minsk)",
  },
  {
    value: 46,
    time: "(UTC +03:00) Moscow Standard Time (Europe/Moscow)",
  },
  {
    value: 47,
    time: "(UTC +02:00) Eastern European Summer Time (Africa/Cairo)",
  },
  {
    value: 48,
    time: "(UTC +03:00) Eastern European Summer Time (Asia/Beirut)",
  },
  {
    value: 49,
    time: "(UTC +03:00) Israel Daylight Time (Asia/Jerusalem)",
  },
  {
    value: 50,
    time: "(UTC +03:00) Eastern European Summer Time (Europe/Athens)",
  },
  {
    value: 51,
    time: "(UTC +03:00) Eastern European Summer Time (Europe/Bucharest)",
  },
  {
    value: 52,
    time: "(UTC +03:00) Eastern European Summer Time (Europe/Helsinki)",
  },
  {
    value: 53,
    time: "(UTC +03:00) Eastern European Summer Time (Europe/Istanbul)",
  },
  {
    value: 54,
    time: "(UTC +02:00) South Africa Standard Time (Africa/Johannesburg)",
  },
  {
    value: 55,
    time: "(UTC +02:00) Central European Summer Time (Europe/Amsterdam)",
  },
  {
    value: 56,
    time: "(UTC +02:00) Central European Summer Time (Europe/Berlin)",
  },
  {
    value: 57,
    time: "(UTC +02:00) Central European Summer Time (Europe/Brussels)",
  },
  {
    value: 58,
    time: "(UTC +02:00) Central European Summer Time (Europe/Paris)",
  },
  {
    value: 59,
    time: "(UTC +02:00) Central European Summer Time (Europe/Prague)",
  },
  {
    value: 60,
    time: "(UTC +02:00) Central European Summer Time (Europe/Rome)",
  },
  {
    value: 61,
    time: "(UTC +01:00) Western European Summer Time (Europe/Lisbon)",
  },
  {
    value: 62,
    time: "(UTC +01:00) Central European Time (Africa/Algiers)",
  },
  {
    value: 63,
    time: "(UTC +01:00) British Summer Time (Europe/London)",
  },
  {
    value: 64,
    time: "(UTC -01:00) Cape Verde Time (Atlantic/Cape_Verde)",
  },
  {
    value: 65,
    time: "(UTC +01:00) Western European Time (Africa/Casablanca)",
  },
  {
    value: 66,
    time: "(UTC +01:00) Irish Summer Time (Europe/Dublin)",
  },
  {
    value: 67,
    time: "(UTC +00:00) Greenwich Mean Time (UTC)",
  },
  {
    value: 68,
    time: "(UTC +00:00) Eastern Greenland Summer Time (America/Scoresbysund)",
  },
  {
    value: 69,
    time: "(UTC +00:00) Azores Summer Time (Atlantic/Azores)",
  },
  {
    value: 70,
    time: "(UTC -02:00) South Georgia Standard Time (Atlantic/South_Georgia)",
  },
  {
    value: 71,
    time: "(UTC -02:30) Newfoundland Daylight Time (America/St_Johns)",
  },
  {
    value: 72,
    time: "(UTC -03:00) Brasilia Summer Time (America/Sao_Paulo)",
  },
  {
    value: 73,
    time: "(UTC -03:00) Argentina Time (America/Argentina/Buenos_Aires)",
  },
  {
    value: 74,
    time: "(UTC -03:00) Chile Summer Time (America/Santiago)",
  },
  {
    value: 75,
    time: "(UTC -03:00) Atlantic Daylight Time (America/Halifax)",
  },
  {
    value: 76,
    time: "(UTC -04:00) Atlantic Standard Time (America/Puerto_Rico)",
  },
  {
    value: 77,
    time: "(UTC -03:00) Atlantic Daylight Time (Atlantic/Bermuda)",
  },
  {
    value: 78,
    time: "(UTC -04:00) Venezuela Time (America/Caracas)",
  },
  {
    value: 79,
    time: "(UTC -04:00) Eastern Daylight Time (America/Indiana/Indianapolis)",
  },
  {
    value: 80,
    time: "(UTC -04:00) Eastern Daylight Time (America/New_York)",
  },
  {
    value: 81,
    time: "(UTC -05:00) Colombia Time (America/Bogota)",
  },
  {
    value: 82,
    time: "(UTC -05:00) Peru Time (America/Lima)",
  },
  {
    value: 83,
    time: "(UTC -05:00) Eastern Standard Time (America/Panama)",
  },
  {
    value: 84,
    time: "(UTC -05:00) Central Daylight Time (America/Mexico_City)",
  },
  {
    value: 85,
    time: "(UTC -05:00) Central Daylight Time (America/Chicago)",
  },
  {
    value: 86,
    time: "(UTC -06:00) Central Standard Time (America/El_Salvador)",
  },
  {
    value: 87,
    time: "(UTC -06:00) Mountain Daylight Time (America/Denver)",
  },
  {
    value: 88,
    time: "(UTC -06:00) Mountain Standard Time (America/Mazatlan)",
  },
  {
    value: 89,
    time: "(UTC -07:00) Mountain Standard Time (America/Phoenix)",
  },
  {
    value: 90,
    time: "(UTC -07:00) Pacific Daylight Time (America/Los_Angeles)",
  },
  {
    value: 91,
    time: "(UTC -07:00) Pacific Daylight Time (America/Tijuana)",
  },
  {
    value: 92,
    time: "(UTC -08:00) Pitcairn Standard Time (Pacific/Pitcairn)",
  },
  {
    value: 93,
    time: "(UTC -08:00) Alaska Daylight Time (America/Anchorage)",
  },
  {
    value: 94,
    time: "(UTC -09:00) Gambier Time (Pacific/Gambier)",
  },
  {
    value: 95,
    time: "(UTC -09:00) Hawaii-Aleutian Standard Time (America/Adak)",
  },
  {
    value: 96,
    time: "(UTC -09:30) Marquesas Time (Pacific/Marquesas)",
  },
  {
    value: 97,
    time: "(UTC -10:00) Hawaii-Aleutian Standard Time (Pacific/Honolulu)",
  },
  {
    value: 98,
    time: "(UTC -11:00) Niue Time (Pacific/Niue)",
  },
  {
    value: 99,
    time: "(UTC -11:00) Samoa Standard Time (Pacific/Pago_Pago)",
  },
];
