import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
  useImperativeHandle,
} from 'react';
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Switch,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from '@mui/material';
import {
  Refresh,
  Search,
  Add,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import axios from 'axios';
import SummarizeIcon from '@mui/icons-material/Summarize';
import './affluent-imports.styles.scss';
import { useSelector } from 'react-redux';
import { Toastify } from '../../App';
import TableSkeleton from '../skeleton-loader/table';
import { debounce } from '../../utils/debounce';
import { useThrottledFn } from '../../utils/throttle';
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { actionLogTrack } from '../../lib/logs';
import apiClient from '../../utils/axiosClient';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(166, 0, 113)',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const AffluentImports = ({ childRef }) => {
  const [affluent, setAffluent] = useState([]);
  const [affluentBackup, setAffluentBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [pageLog, setPageLog] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowsPerPageLog, setRowsPerPageLog] = useState(25);
  const [showTriggerBackFill, setShowTriggerBackFill] = useState(false);

  // const [trigger, setTrigger] = useState({
  //   checked: false,
  //   import_start: dayjs(new Date(new Date() - 60 * 24 * 60 * 60 * 1000)),
  //   email: '',
  //   import_type: 1,
  // });
  const [trigger, setTrigger] = useState({
    checked: false,
    import_start: dayjs(new Date(new Date() - 60 * 24 * 60 * 60 * 1000)), // 60 days ago
    import_end: dayjs(), // Today
    email: '',
    import_type: 1,
  });

  const [hideTriggerProgressDialog, setHideTriggerProgressDialog] =
    useState(true);

  //super token

  const super_token = JSON.parse(localStorage.getItem('super_token'));
  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangePageLog = (_, newPage) => {
    setPageLog(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeRowsPerPageLog = (event) => {
    setRowsPerPageLog(+event.target.value);
    setPageLog(0);
  };

  const get_ref = useRef(true);
  useEffect(() => {
    if (get_ref.current) {
      get_ref.current = false;
      getAffluentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getting ll brand weights
  const [listLoad, setListLoad] = useState(true);
  const getAffluentData = () => {
    apiClient
      .get(`/affcrm/v1/admin/affluent/history`)
      .then((res) => {
        setListLoad(false);
        setAffluent(res.data.response.sort(customSort));
        setAffluentBackup(res.data.response.sort(customSort));
      })
      .catch((err) => {
        Toastify('error', err.response.data.message);
      });
  };

  const throttleGetBrandWeights = useThrottledFn(getAffluentData, 1000);

  const customSort = (a, b) => new Date(b.id) - new Date(a.id);

  // Search results
  const searchResults = (query) => {
    let results = [];
    for (let i = 0; i < affluentBackup.length; i++) {
      if (
        setAffluentBackup[i]['brand']
          .toLowerCase()
          .includes(query.toLowerCase())
      )
        results.push(affluentBackup[i]);
    }

    setAffluent(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 600));
  const [showlogs, setShowLogs] = useState(null);

  const explandRow = (idx) => {
    if (showlogs === idx) {
      setShowLogs(null);
      return;
    }
    setShowLogs(idx);
  };
  const [logsDetails, setLogsDetails] = useState([]);
  const [logsDetailsBackup, setLogsDetailsBackUp] = useState([]);

  const getLogs = async (id) => {
    try {
      const res = await apiClient.get(`/affcrm/v1/admin/affluent/logs/${id}`);
      console.log(res.data.response, 'res');
      const sorted = res.data.response.sort(customSort);
      setLogsDetails(sorted);
      setLogsDetailsBackUp(sorted);
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error);
    }
  };

  const TriggerBackFull = async () => {
    try {
      if (affluentBackup[0].status === 1) {
        Toastify('error', 'One Trigger is still in process !!');
        return;
      }
      if (trigger.checked) {
        if (!isEmail(trigger.email)) {
          Toastify('error', 'Not a valid email !!');
          return;
        }
      }

      const checkData = {
        notify: trigger.checked ? 1 : 0,
        email: trigger.email.length === 0 ? '' : trigger.email,
        import_start: moment(new Date(trigger.import_start)).format(
          'YYYY-MM-DD'
        ),
      };

      setHideTriggerProgressDialog(false);

      // admin/trigger/affluent/actuals

      if (trigger.import_type === 1) {
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/admin/trigger/affluent/custom`,

          {
            notify: trigger.checked ? 1 : 0,
            email: trigger.email.length === 0 ? '' : trigger.email,
            import_start: moment(new Date(trigger.import_start)).format(
              'YYYY-MM-DD'
            ),
            import_end: moment(new Date(trigger.import_end)).format('YYYY-MM-DD'),
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        const importMsg = {
          notify: trigger.checked ? 1 : 0,
          email: trigger.email.length === 0 ? '' : trigger.email,
          import_start: moment(new Date(trigger.import_start)).format(
            'YYYY-MM-DD'
          ),
          import_end: moment(new Date(trigger.import_end)).format('YYYY-MM-DD'),
        };
        const desc = {
          message: `Trigger import ${JSON.stringify(importMsg)} type 1`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        actionLogTrack(JSON.stringify(desc), false, super_token?.token);
        console.log(importMsg, 'importMsgf')
      } else if (trigger.import_type === 2) {
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/admin/trigger/affluent/actuals`,

          {
            notify: trigger.checked ? 1 : 0,
            email: trigger.email.length === 0 ? '' : trigger.email,
            import_start: moment(new Date(trigger.import_start)).format(
              'YYYY-MM-DD'
            ),
            import_end: moment(new Date(trigger.import_end)).format('YYYY-MM-DD'),
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        const importMsg = {
          notify: trigger.checked ? 1 : 0,
          email: trigger.email.length === 0 ? '' : trigger.email,
          import_start: moment(new Date(trigger.import_start)).format(
            'YYYY-MM-DD'
          ),
          import_end: moment(new Date(trigger.import_end)).format('YYYY-MM-DD'),
        };
        const desc = {
          message: `Trigger import ${JSON.stringify(importMsg)} type 2`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        actionLogTrack(JSON.stringify(desc), false, super_token?.token);
      } else if (trigger.import_type === 3) {
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/admin/trigger/affluent/mergedpublishers`,

          {
            notify: trigger.checked ? 1 : 0,
            email: trigger.email.length === 0 ? '' : trigger.email,
            import_start: moment(new Date(trigger.import_start)).format(
              'YYYY-MM-DD'
            ),
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        const importMsg = {
          notify: trigger.checked ? 1 : 0,
          email: trigger.email.length === 0 ? '' : trigger.email,
          import_start: moment(new Date(trigger.import_start)).format(
            'YYYY-MM-DD'
          ),
        };
        const desc = {
          message: `Trigger import ${JSON.stringify(importMsg)} type 3`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        actionLogTrack(JSON.stringify(desc), false, super_token?.token);
      } else if (trigger.import_type === 4) {
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/admin/trigger/affluent/contentanalysis`,
          {
            notify: trigger.checked ? 1 : 0,
            email: trigger.email.length === 0 ? '' : trigger.email,
            import_start: moment(new Date(trigger.import_start)).format(
              'YYYY-MM-DD'
            ),
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        const importMsg = {
          notify: trigger.checked ? 1 : 0,
          email: trigger.email.length === 0 ? '' : trigger.email,
          import_start: moment(new Date(trigger.import_start)).format(
            'YYYY-MM-DD'
          ),
        };
        const desc = {
          message: `Trigger import ${JSON.stringify(importMsg)} type 4`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        actionLogTrack(JSON.stringify(desc), false, super_token?.token);
      } else if (trigger.import_type === 5) {
        const res = await axios.post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/admin/trigger/affluent/all`,
          {
            notify: trigger.checked ? 1 : 0,
            email: trigger.email.length === 0 ? '' : trigger.email,
            import_start: moment(new Date(trigger.import_start)).format(
              'YYYY-MM-DD'
            ),
            import_end: moment(new Date(trigger.import_end)).format('YYYY-MM-DD'),
          },
          {
            headers: {
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        );

        const importMsg = {
          notify: trigger.checked ? 1 : 0,
          email: trigger.email.length === 0 ? '' : trigger.email,
          import_start: moment(new Date(trigger.import_start)).format(
            'YYYY-MM-DD'
          ),
          import_end: moment(new Date(trigger.import_end)).format(
            'YYYY-MM-DD'
          ),
        };
        const desc = {
          message: `Trigger import ${JSON.stringify(importMsg)} type 4`,
          status: res.status,
          method: res.config.method,
          url: res.config.url,
        };
        actionLogTrack(JSON.stringify(desc), false, super_token?.token);
      } else {
        throw new Error(
          'Trigger import type is not correct. Make sure to select correct import type!!'
        );
      }

      getAffluentData();
      Toastify(
        'success',
        trigger.import_type == 1
          ? 'Affluent import triggered successfully !!'
          : trigger.import_type == 2
            ? 'Daily actuals import triggered successfully !!'
            : trigger.import_type === 3
              ? 'Merge publishers import triggered successfully !!'
              : trigger.import_type === 4
                ? 'Content analysis import triggered successfully !!'
                : 'All import triggered successfully !!'
      );
      setHideTriggerProgressDialog(true);
      setShowTriggerBackFill(false);
      setTrigger({
        checked: false,
        import_start: dayjs(new Date()),
        email: '',
        import_type: 1,
      });
    } catch (error) {
      // console.log(error, 'error trigger');
      Toastify('error', 'Error Trigger');
      setHideTriggerProgressDialog(true);
      setShowTriggerBackFill(false);
      if (error.response) {
        Toastify('error', error.response.data.message);
      } else {
        Toastify('error', 'Somthing went wrong !!');
      }
      actionLogTrack(JSON.stringify(error), true, super_token?.token);
    }
  };

  const formatDate = (timeStamp) => {
    let date = new Date(timeStamp);
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    );
  };
  //setShowTriggerBackFill(true)

  useImperativeHandle(childRef, () => ({
    addAffluentTrigger() {
      setShowTriggerBackFill(true);
    },
    searchResultTrigger(query) {
      searchResults(query);
    },
  }));

  const [logType, setLogType] = useState(0);

  const Filter = (type) => {
    console.log(type, 'type');
    if (type !== 0) {
      const res = logsDetailsBackup.filter((d) => d.type === type);
      setLogsDetails(res);
    } else {
      setLogsDetails([...logsDetailsBackup]);
    }
    setLogType(type);
  };

  const defaultSetForTrigger = () => {
    setShowTriggerBackFill(false);
    setTrigger({
      checked: false,
      import_start: dayjs(new Date(new Date() - 60 * 24 * 60 * 60 * 1000)),
      email: '',
      import_type: 1,
    });
  };

  const importType = (type) => {
    if (type === 1) {
      return 'Affluent Import';
    } else if (type === 2) {
      return 'Daily Actual Imports';
    } else if (type === 3) {
      return 'Merged Publisher Imports';
    } else if (type === 4) {
      return 'Content Analysis import';
    } else if (type === 4) {
      return 'Import all';
    }
    return '';
  };

  const handleStartDateChange = (newValue) => {
    const maxEndDate = dayjs(newValue).add(6, 'month');
    if (maxEndDate.isBefore(trigger.import_end, 'day')) {
      setTrigger({
        ...trigger,
        import_start: newValue,
        import_end: maxEndDate,
      });
    } else {
      setTrigger({
        ...trigger,
        import_start: newValue,
      });
    }
  };

  const handleEndDateChange = (newValue) => {
    if (newValue > new Date()) {
      Toastify('error', 'Date must be before today');
      return;
    }
    setTrigger({
      ...trigger,
      import_end: newValue,
    });
  };

  return (
    <div className="br-weights">
      <Dialog disableEscapeKeyDown open={showTriggerBackFill}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '1rem',
          }}>
          Trigger Backfill
          <span
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1.1rem',
              fontWeight: '700',
            }}></span>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '510px',
              '& .MuiTextField-root': { margin: "10px 0px", width: '25ch' },
            }}>
            <ThemeProvider theme={theme}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'Start Date'}
                    value={trigger.import_start}
                    onChange={(newValue) => {
                      console.log(newValue, 'newValue');
                      if (newValue > new Date()) {
                        // alert('Date must be before today !!');
                        Toastify('error', 'Date must be before today');
                        return;
                      }
                      setTrigger({
                        ...trigger,
                        import_start: newValue,
                      });
                    }}
                  />
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'Start Date'}
                    value={dayjs(trigger.import_start)}
                    onChange={handleStartDateChange}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'End Date'}
                    value={dayjs(trigger.import_end)}
                    onChange={handleEndDateChange}
                    minDate={dayjs(trigger.import_start)}
                    maxDate={dayjs(trigger.import_start).add(6, 'month')}
                  />
                </LocalizationProvider>

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Trigger Select
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={trigger.import_type}
                    label="Trigger Select"
                    onChange={(e) =>
                      setTrigger({
                        ...trigger,
                        import_type: e.target.value,
                      })
                    }>
                    <MenuItem value={1}>Affluent Import</MenuItem>
                    <MenuItem value={2}>Daily Actuals Import</MenuItem>
                    <MenuItem value={3}>Merged Publisher Import</MenuItem>
                    <MenuItem value={4}>Content Analysis import</MenuItem>
                    <MenuItem value={5}>Import all</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <FormControlLabel
                  label="Receive Mail"
                  style={{
                    padding: '0 8px',
                  }}
                  control={
                    <Checkbox
                      checked={trigger.checked}
                      onChange={() => {
                        setTrigger({
                          ...trigger,
                          checked: !trigger.checked,
                        });
                      }}
                    />
                  }
                />

                {trigger.checked && (
                  <TextField
                    style={{
                      width: '97%',
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        top: '4px',
                      },
                      '& .MuiInputBase-root': {
                        padding: '7px',
                      },
                    }}
                    required
                    size="small"
                    label="Email"
                    value={trigger.email}
                    onChange={(e) => {
                      setTrigger({
                        ...trigger,
                        email: e.target.value,
                      });
                    }}
                  />
                )}
              </div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              if (hideTriggerProgressDialog) {
                TriggerBackFull();
              }
            }}>
            <div>
              {hideTriggerProgressDialog ? (
                <p>Trigger</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => defaultSetForTrigger()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableEscapeKeyDown
        open={showlogs !== null}
        style={{}}
        maxWidth="md" // Set the maximum width here
        fullWidth>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '20px',
          }}>
          <span
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1.5rem',
              fontWeight: '700',
            }}>
            Logs
          </span>
          <span
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '1.5rem',
              fontWeight: '700',
            }}>
            <ThemeProvider theme={theme}>
              <Button
                style={{
                  margin: '0 5px',
                }}
                variant={logType === 0 ? 'contained' : 'outlined'}
                onClick={() => {
                  Filter(0);
                }}>
                All
              </Button>
              <Button
                style={{
                  margin: '0 5px',
                }}
                onClick={() => {
                  Filter(2);
                }}
                variant={logType === 2 ? 'contained' : 'outlined'}>
                Logs
              </Button>
              <Button
                style={{
                  margin: '0 5px',
                }}
                variant={logType === 1 ? 'contained' : 'outlined'}
                onClick={() => {
                  Filter(1);
                }}>
                Trigger
              </Button>
              <Button
                style={{
                  margin: '0 5px',
                }}
                variant={logType === 3 ? 'contained' : 'outlined'}
                onClick={() => {
                  Filter(3);
                }}>
                Error
              </Button>
            </ThemeProvider>
          </span>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.9rem',
              width: '800px',
              '& .MuiTextField-root': { m: 1, width: '710px' },
            }}>
            <ThemeProvider theme={theme}>
              <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                        }}>
                        Created At
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                        }}>
                        Type
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          color: 'grey',
                        }}>
                        Message
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {logsDetails.length !== 0 ? (
                    <>
                      <TableBody>
                        {logsDetails
                          .slice(
                            pageLog * rowsPerPageLog,
                            pageLog * rowsPerPageLog + rowsPerPageLog
                          )
                          .map((row, idx) => {
                            return (
                              <>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={idx}>
                                  <TableCell
                                    style={{
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: '500',
                                    }}>
                                    <div>
                                      <p
                                        style={{
                                          display: 'inline-block',
                                          verticalAlign: 'middle',
                                          marginRight: '5px',
                                        }}>
                                        {moment(row.created_at).format(
                                          'MM/DD/YYYY'
                                        )}
                                      </p>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: '500',
                                    }}>
                                    <div
                                      style={
                                        row.type === 2
                                          ? {
                                            color: 'rgb(252, 198, 3)',
                                            backgroundColor:
                                              'rgba(252, 227, 3, 0.3)',
                                            border:
                                              '1px solid rgb(252, 198, 3)',
                                            borderRadius: '20px',
                                            textAlign: 'center',
                                            fontSize: '0.8rem',
                                            width: '75px',
                                            paddingTop: '3px',
                                            paddingBottom: '3px',
                                          }
                                          : row.type === 1
                                            ? {
                                              color: 'rgb(39, 181, 36)',
                                              backgroundColor:
                                                'rgba(71, 237, 52, 0.3)',
                                              border:
                                                '1px solid rgb(39, 181, 36)',
                                              borderRadius: '20px',
                                              textAlign: 'center',
                                              fontSize: '0.8rem',
                                              width: '75px',
                                              paddingTop: '3px',
                                              paddingBottom: '3px',
                                            }
                                            : {
                                              color: 'rgb(252, 3, 3)',
                                              backgroundColor:
                                                'rgba(252, 3, 3, 0.3)',
                                              border:
                                                '1px solid rgb(252, 3, 3)',
                                              borderRadius: '20px',
                                              textAlign: 'center',
                                              fontSize: '0.8rem',
                                              width: '75px',
                                              paddingTop: '3px',
                                              paddingBottom: '3px',
                                            }
                                      }>
                                      {row.type === 1
                                        ? 'Trigger'
                                        : row.type === 2
                                          ? 'Log'
                                          : 'Error'}
                                    </div>
                                  </TableCell>

                                  <TableCell>
                                    <div className="actions-div">
                                      {row.message}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </TableBody>
                    </>
                  ) : (
                    <TableBody>
                      <>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell
                            style={{
                              fontFamily: '"Montserrat", sans-serif',
                              fontWeight: '500',
                            }}></TableCell>
                          <TableCell
                            style={{
                              fontFamily: '"Montserrat", sans-serif',
                              fontWeight: '500',
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                              }}>
                              <p>
                                {logType === 0
                                  ? 'No Message'
                                  : logType === 2
                                    ? 'No Message Logs'
                                    : logType === 1
                                      ? 'No Message Trigger'
                                      : 'No Message Error'}
                              </p>
                            </div>
                          </TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  )}
                </Table>
                {listLoad ? (
                  [1, 2, 3, 4].map((d, i) => {
                    return (
                      <Fragment key={i}>
                        <TableSkeleton />
                      </Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
                <div className="spacer"></div>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100, 200]}
                  component="div"
                  count={logsDetails.length}
                  rowsPerPage={rowsPerPageLog}
                  page={pageLog}
                  onPageChange={handleChangePageLog}
                  onRowsPerPageChange={handleChangeRowsPerPageLog}
                  style={{}}
                  sx={{
                    marginTop: '10px',
                    fontFamily: '"Montserrat", sans-serif',
                  }}
                />
              </TableContainer>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: '0.8rem',
              color: 'rgb(166, 0, 113)',
            }}
            onClick={() => {
              setShowLogs(null);
              setLogsDetails([]);
              setLogType(0);
            }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <div className="secondary-div">
        <div className="secondary-content">
          <p className="selected-text">{affluent.length} Imports</p>
        </div>
      </div>

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: 'calc(100% - 60px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Started At
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Completed At
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // textAlign: "center",
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {' '}
                    Status
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  Import Start
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                    // paddingLeft: "50px",
                  }}>
                  Notify
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {' '}
                    Type
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: 'grey',
                  }}>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {' '}
                    Show Logs
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {affluent
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <>
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            fontWeight: '500',
                          }}>
                          <div>
                            <p>{formatDate(row.started_at)}</p>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            fontWeight: '500',
                          }}>
                          <div>
                            <p>
                              {row.completed_at &&
                                row.completed_at.length !== 0 &&
                                formatDate(row.completed_at)}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            fontWeight: '500',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <p
                              style={
                                row.status === 2
                                  ? {
                                    color: 'rgb(39, 181, 36)',
                                    backgroundColor: 'rgba(71, 237, 52, 0.3)',
                                    border: '1px solid rgb(39, 181, 36)',
                                    borderRadius: '20px',
                                    textAlign: 'center',
                                    fontSize: '0.8rem',
                                    width: '75px',
                                    padding: '3px',
                                  }
                                  : {
                                    color: 'rgb(252, 198, 3)',
                                    backgroundColor: 'rgba(252, 227, 3, 0.3)',
                                    border: '1px solid rgb(252, 198, 3)',
                                    borderRadius: '20px',
                                    textAlign: 'center',
                                    fontSize: '0.8rem',
                                    width: '75px',
                                    padding: '3px',
                                  }
                              }>
                              {row.status === 2 ? 'Completed' : 'Processing'}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            fontWeight: '500',
                          }}>
                          <div>
                            <p
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginRight: '5px',
                              }}>
                              {moment(row.import_start_date).format('MM/DD/YY')}
                            </p>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div
                            className="actions-div"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}>
                            {row.notify === 1 ? row.email : 'Not Set'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            className="actions-div"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '0 auto',
                              width: '70%',
                            }}>
                            {console.log(
                              importType(row.type),
                              row.type,
                              'testing'
                            )}
                            {importType(row.type)}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            fontWeight: '500',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0 10px',
                            }}>
                            <SummarizeIcon
                              onClick={() => {
                                explandRow(idx);
                                getLogs(row.id);
                              }}
                              style={{
                                color: 'rgb(166, 0, 113)',
                                cursor: 'pointer',
                                // marginRight: 'auto',
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
          {listLoad ? (
            [1, 2, 3, 4].map((d, i) => {
              return (
                <Fragment key={i}>
                  <TableSkeleton />
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={affluent.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: '10px',
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default AffluentImports;
