import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  Box,
  TextField,
  MenuItem,
  CircularProgress,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";

import {
  Refresh,
  Add,
  Edit,
  Search,
  AddPhotoAlternateOutlined,
  DeleteOutline,
  Mail,
} from "@mui/icons-material";
import axios from "axios";
import validator from "validator";
import "./organization.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_organization,
  send_invitation_admin,
  send_invitation_user,
} from "../../redux/action/super_admin.action";
import { Toastify } from "../../App";
import TableSkeleton from "../skeleton-loader/table";
import styled from "@emotion/styled";
import { pink } from "@mui/material/colors";
import { debounce } from "../../utils/debounce";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const Organization = () => {
  // redux
  const dispatch = useDispatch();
  const { send_invitation } = useSelector((state) => state.super_admin);
  const { superAdmin, admin_auth } = useSelector((state) => state.admin);

  const [orgs, setOrgs] = useState([]);
  const [orgsBackup, setOrgsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const inputFile = useRef(null);
  const [showAddOrgDialog, setShowAddOrgDialog] = useState(false);
  const [loadList, setLoadList] = useState(true);

  // add
  const [addOrgImageRaw, setAddOrgImageRaw] = useState(null);
  const [addOrgImage, setAddOrgImage] = useState(null);
  const [addOrgName, setAddOrgName] = useState();
  const [paymentRequired, setPaymentRequired] = useState(true);
  const [hideAddOrgProgressDialog, setHideAddOrgProgressDialog] =
    useState(true);

  // add

  //edit
  const editInputFile = useRef(null);
  const [editOrgData, setEditOrgData] = useState();
  const [showEditOrgDialog, setShowEditOrgDialog] = useState(false);
  const [editOrgImageRaw, setEditOrgImageRaw] = useState(null);
  const [editOrgImage, setEditOrgImage] = useState(null);
  const [editOrgName, setEditOrgName] = useState();

  const [hideEditOrgProgressDialog, setHideEditOrgProgressDialog] =
    useState(true);
  //edit

  //change
  const org_init = {
    org_name_change: 0,
    org_image_change: 0,
  };
  const [orgChange, setOrgChange] = useState(org_init);

  //change

  //invite
  const [inviteOrgdata, setInviteOrgData] = useState();
  const [inviteOrgPersonName, setInviteOrgPersonName] = useState();
  const [inviteType, setInviteType] = useState(0);
  const [inviteOrgPersonEmail, setInviteOrgPersonEmail] = useState();
  const [showInviteOrgDialog, setShowInviteOrgDialog] = useState(false);

  //invite

  //delete
  const [aboutToDeleteOrg, setAboutToDeleteOrg] = useState(null);
  const [hideDeleteOrgProgressDialog, setHideDeleteOrgProgressDialog] =
    useState(true);
  //delete

  //update payment status
  const [aboutToUpdatePaymentOrgShow, setAboutToUpdatePaymentOrgShow] =
    useState(false);
  const [aboutToUpdatePaymentOrgProgress, setAboutToUpdatePaymentOrgProgress] =
    useState(false);
  const [aboutToUpdatePaymentOrg, setAboutToUpdatePaymentOrg] = useState(null);
  //update payment status

  const [searchText, setSearchText] = useState("");

  //super token
  const super_token = JSON.parse(localStorage.getItem("super_token"));

  // Update data on page change
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Update data when rows per page count is changed
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //react version 18 2x rendering
  const get_orgs_ref = useRef(true);
  useEffect(() => {
    if (get_orgs_ref.current && super_token?.token) {
      get_orgs_ref.current = false;
      getOrgs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Getting all brands
  const getOrgs = () => {
    axios
      .get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/get`,
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      )
      .then((response) => {
        setLoadList(false);
        setOrgs(response.data.sort(customSortBasedOnDate).reverse());
        setOrgsBackup(response.data.sort(customSortBasedOnDate).reverse());
      })
      .catch((err) => {
        Toastify("error", err.response.data.message);
        console.error(err);
      });
  };

  const customSortBasedOnDate = (a, b) => {
    // console.log(a, "a.lauch");
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  // Add brand to db
  const addOrganization = () => {
    if (
      typeof addOrgName === "undefined" ||
      addOrgName === null ||
      addOrgName === ""
    ) {
      Toastify("warning", "Name of Organization is required!");
    } else {
      setHideAddOrgProgressDialog(false);

      let formData = new FormData();
      formData.append("org_name", addOrgName);
      formData.append("org_image", addOrgImage != null ? 1 : 0);
      formData.append("org_image_file", addOrgImageRaw);
      formData.append("payment_required", paymentRequired ? 1 : 0);
      axios
        .post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/new`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((_) => {
          Toastify("success", "Organization added!");
          setHideAddOrgProgressDialog(true);
          setShowAddOrgDialog(false);
          setAddOrgImageRaw(null);
          setAddOrgImage(null);
          setAddOrgName();
          setPaymentRequired(false);
          getOrgs();
        })
        .catch((err) => {
          Toastify("error", err.response.data.message);
          console.error(err, "error is ");
        });
    }
  };

  // edit organization
  const editOrganization = (org) => {
    setEditOrgData(org);
    setEditOrgImage(org.image);
    setEditOrgName(org.name);
    setShowEditOrgDialog(true);
  };

  //invite admin organization
  const inviteOrganization = (org) => {
    setInviteOrgData(org);
    setInviteType(0);
    setInviteOrgPersonName();
    setInviteOrgPersonEmail();
    setShowInviteOrgDialog(true);
  };

  //send invitation
  const sendInvitation = async () => {
    try {
      if (
        typeof inviteOrgPersonName === "undefined" ||
        inviteOrgPersonName === null ||
        inviteOrgPersonName === ""
      ) {
        Toastify("warning", "Name of Admin is required!");
      } else if (
        typeof inviteOrgPersonEmail === "undefined" ||
        inviteOrgPersonEmail === null ||
        inviteOrgPersonEmail === ""
      ) {
        Toastify("warning", "Email of Admin is required!");
      } else if (!validator.isEmail(inviteOrgPersonEmail)) {
        Toastify("warning", "Enter a valid email");
      } else {
        if (superAdmin && inviteType === 0) {
          const reqBody = {
            organization: inviteOrgdata.id,
            name: inviteOrgPersonName,
            email: inviteOrgPersonEmail,
          };
          dispatch(
            send_invitation_admin(
              reqBody,

              setShowInviteOrgDialog,
              setInviteType,
              setInviteOrgData,
              setInviteOrgPersonEmail,
              setInviteOrgPersonName,
              "",
              super_token
            )
          );
        } else {
          const reqBody = {
            organization: inviteOrgdata.id,
            name: inviteOrgPersonName,
            email: inviteOrgPersonEmail,
          };

          const adminDetails = {
            superAdmin: superAdmin,
            admin: { ...admin_auth },
          };

          dispatch(
            send_invitation_user(
              reqBody,

              setShowInviteOrgDialog,
              setInviteType,
              setInviteOrgData,
              setInviteOrgPersonEmail,
              setInviteOrgPersonName,
              "",
              adminDetails,
              super_token
            )
          );
        }
      }
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, "sendinvitation");
    }
  };
  // update organization
  const updateOrganization = () => {
    if (
      typeof editOrgName === "undefined" ||
      editOrgName === null ||
      editOrgName === ""
    ) {
      Toastify("warning", "Name of Organization is required!");
    } else if (editOrgImageRaw === null) {
      setHideEditOrgProgressDialog(false);

      let formData = new FormData();
      formData.append("id", editOrgData.id);
      formData.append("org_name", editOrgName);
      formData.append("org_image_file", null);

      formData.append("org_name_change", orgChange.org_name_change);
      formData.append("org_image_change", 0);

      axios
        .post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/update`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((d) => {
          Toastify("success", "Organization updated!");
          setHideEditOrgProgressDialog(true);
          setShowEditOrgDialog(false);
          setEditOrgImage(null);
          setEditOrgImageRaw(null);
          setEditOrgName();
          setOrgChange(org_init);
          setEditOrgData();
          getOrgs();
        })
        .catch((err) => {
          Toastify("error", err.response.data.message);
          console.error(err, "edit error");
        });
    } else {
      setHideEditOrgProgressDialog(false);

      let formData = new FormData();
      formData.append("id", editOrgData.id);
      formData.append("org_name", editOrgName);
      formData.append("org_image_file", editOrgImageRaw);

      formData.append("org_name_change", orgChange.org_name_change);
      formData.append("org_image_change", orgChange.org_image_change);

      // console.log(formData, "information1");
      axios
        .post(
          `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/update`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${super_token?.token}`,
            },
          }
        )
        .then((_) => {
          Toastify("success", "Organization updated!");
          setHideEditOrgProgressDialog(true);
          setShowEditOrgDialog(false);
          setEditOrgImage(null);
          setEditOrgImageRaw(null);
          setEditOrgName();
          setEditOrgData();
          setOrgChange(org_init);
          getOrgs();
        })
        .catch((err) => {
          Toastify("error", err.response.data.message);
          console.error(err, "edit error");
        });
    }
  };

  //Delete Organization
  const deleteOrganization = async (org) => {
    // console.log(org, "organization");
    setHideDeleteOrgProgressDialog(false);
    const res = await dispatch(delete_organization(org, super_token));

    if (res) {
      getOrgs();
      setHideDeleteOrgProgressDialog(true);
      setAboutToDeleteOrg(null);
    }
  };

  // Search result
  const searchResults = (query) => {
    let results = [];

    for (let i = 0; i < orgsBackup.length; i++) {
      if (
        orgsBackup[i]["name"]?.toLowerCase().includes(query.toLowerCase()) ||
        ("monthly".includes(query.toLowerCase()) &&
          orgsBackup[i]["payment_model"] === 1) ||
        ("yearly".includes(query.toLowerCase()) &&
          orgsBackup[i]["payment_model"] === 2)
      ) {
        results.push(orgsBackup[i]);
      }
    }

    setOrgs(results);
  };

  const searchOptimized = useCallback(debounce(searchResults, 500));

  const updatePayment = async (org) => {
    try {
      setAboutToUpdatePaymentOrgProgress(true);
      const res = await axios.post(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/super/org/payment/update/${org.id}`,
        {},
        {
          headers: {
            Authorization: `JWT ${super_token?.token}`,
          },
        }
      );

      if (res.status === 200) {
        getOrgs();
        setAboutToUpdatePaymentOrgProgress(false);
        setAboutToUpdatePaymentOrgShow(false);
        setAboutToUpdatePaymentOrg(null);
        Toastify("success", res.data.message);
      }
    } catch (err) {
      setAboutToUpdatePaymentOrgProgress(false);
      setAboutToUpdatePaymentOrgShow(false);
      setAboutToUpdatePaymentOrg(null);
      Toastify("error", err?.response?.data?.message);
      console.log(err.response);
    }
  };

  const CusSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  return (
    <div className="brands">
      {/* Add brand Dialog */}
      <Dialog disableEscapeKeyDown open={showAddOrgDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Add Organization
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div
                style={{
                  height: "200px",
                  width: "500px",
                  marginBottom: "25px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => inputFile.current.click()}
              >
                {addOrgImage === null ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlined />
                    <p>Upload Image</p>
                    <input
                      type="file"
                      ref={inputFile}
                      style={{
                        display: "none",
                      }}
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          setAddOrgImageRaw(event.target.files[0]);
                          setAddOrgImage(
                            URL.createObjectURL(event.target.files[0])
                          );
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: "200px",
                      width: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={addOrgImage} alt="Brand" height="200" />
                  </div>
                )}
              </div>
              <TextField
                required
                size="small"
                label="Organizations's Name"
                helperText="Eg. - LT Partners"
                value={addOrgName}
                onChange={(event) => setAddOrgName(event.target.value)}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setPaymentRequired(!paymentRequired)}
                      checked={paymentRequired}
                    />
                  }
                  labelPlacement="start"
                  label="Payment required"
                />
              </FormGroup>
              {/* <div>Make sure the brand name matches with Affluent!</div> */}
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={addOrganization}
          >
            <div>
              {hideAddOrgProgressDialog ? (
                <p>Add</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => setShowAddOrgDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Brand Dialog */}
      <Dialog disableEscapeKeyDown open={showEditOrgDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Edit Organization
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div
                style={{
                  height: "200px",
                  width: "500px",
                  marginBottom: "25px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => editInputFile.current.click()}
              >
                <div
                  style={{
                    height: "200px",
                    width: "500px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={editOrgImage} alt="Brand" height="200" />
                  <input
                    type="file"
                    ref={editInputFile}
                    style={{
                      display: "none",
                    }}
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        setEditOrgImageRaw(event.target.files[0]);
                        setOrgChange({
                          ...orgChange,
                          org_image_change: 1,
                        });
                        setEditOrgImage(
                          URL.createObjectURL(event.target.files[0])
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <TextField
                required
                size="small"
                label="Name"
                helperText="Eg. - LT Partners"
                value={editOrgName}
                onChange={(event) => {
                  setOrgChange({
                    ...orgChange,
                    org_name_change: 1,
                  });
                  setEditOrgName(event.target.value);
                }}
              />

              <div>Make sure the brand name matches with Affluent!</div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={updateOrganization}
          >
            <div>
              {hideEditOrgProgressDialog ? (
                <p>Update</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => setShowEditOrgDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Invite Admin Dialog */}
      <Dialog disableEscapeKeyDown open={showInviteOrgDialog}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Invite
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              {superAdmin ? (
                <TextField
                  select
                  required
                  size="small"
                  label="Type"
                  helperText="Eg. Admin "
                  value={inviteType}
                  onChange={(e) => {
                    setInviteType(e.target.value);
                  }}
                >
                  <MenuItem value={0}>Admin</MenuItem>
                  <MenuItem value={1}>User</MenuItem>
                </TextField>
              ) : (
                <TextField
                  select
                  required
                  size="small"
                  label="Type"
                  helperText="Eg. User "
                  value={inviteType}
                  onChange={(e) => {
                    setInviteType(e.target.value);
                  }}
                  disabled
                >
                  {/* <MenuItem value={0}>Admin</MenuItem> */}
                  <MenuItem value={0}>User</MenuItem>
                </TextField>
              )}

              <TextField
                required
                size="small"
                label="Name"
                helperText="Eg. - Ross "
                value={inviteOrgPersonName}
                onChange={(event) => {
                  setInviteOrgPersonName(event.target.value);
                }}
              />
              <TextField
                required
                size="small"
                label="Email"
                type={"email"}
                helperText="Eg. - ross@lt.partners"
                value={inviteOrgPersonEmail}
                onChange={(event) => {
                  setInviteOrgPersonEmail(event.target.value);
                }}
              />

              {/* <div>Make sure the brand name matches with Affluent!</div> */}
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!send_invitation) {
                sendInvitation();
              }
            }}
          >
            <div>
              {!send_invitation ? (
                <p>Send</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => setShowInviteOrgDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Org */}
      <Dialog
        disableEscapeKeyDown
        open={aboutToDeleteOrg == null ? false : true}
      >
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Delete Organization
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div>
                Deleting this organization will remove all data related to it.
                Are you sure you want to delete this organization?!
              </div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => setAboutToDeleteOrg(null)}
          >
            Cancel
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (hideDeleteOrgProgressDialog) {
                deleteOrganization(aboutToDeleteOrg);
              }
            }}
          >
            <div>
              {hideDeleteOrgProgressDialog ? (
                <p>Yes</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Payment change Org */}
      <Dialog disableEscapeKeyDown open={aboutToUpdatePaymentOrgShow}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Update Payment Status
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "510px",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div>
                You are about to change the payment status for the organization{" "}
                {aboutToUpdatePaymentOrg?.name}. Do you want to continue?
                {/* You are about to change from{" "}
                {aboutToUpdatePaymentOrg?.payment_required == 0 ? (
                  <span style={{ fontWeight: "600" }}>
                    payment not required to payment required
                  </span>
                ) : (
                  <span style={{ fontWeight: "600" }}>
                    payment required to payment not required
                  </span>
                )}
                . Are you sure you want to update payment status for this
                organization?! */}
              </div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!aboutToUpdatePaymentOrgProgress) {
                setAboutToUpdatePaymentOrgShow(!aboutToUpdatePaymentOrgShow);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              if (!aboutToUpdatePaymentOrgProgress) {
                updatePayment(aboutToUpdatePaymentOrg);
              }
            }}
          >
            <div>
              {!aboutToUpdatePaymentOrgProgress ? (
                <p>Yes</p>
              ) : (
                <ThemeProvider theme={theme}>
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </ThemeProvider>
              )}
            </div>
          </Button>
        </DialogActions>
      </Dialog>
      <div className="head">
        <div className="head-txt">Organization</div>
        <Refresh className="head-refresh" />
        <Button
          className="add-brand-btn"
          onClick={() => setShowAddOrgDialog(true)}
        >
          <div className="add-brand-content">
            <Add className="add-icon" />
            <p className="add-text">Add Organization</p>
          </div>
        </Button>
      </div>

      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">{orgs.length} Organization </span>
          </p>
        </div>
        <div className="input-container">
          <Search className="icon" />
          <input
            className="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              searchOptimized(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />

      {/* Content */}
      <Paper className="table-paper">
        <TableContainer sx={{ maxHeight: "calc(100% - 60px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Organization
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                  }}
                >
                  Payment Model
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Actions
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    color: "grey",
                    // paddingLeft: "50px",
                  }}
                >
                  Controls
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                        }}
                      >
                        <img
                          alt="fav"
                          className="avtar"
                          src={row.image}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                            height: "60px",
                            width: "120px",
                            objectFit: "contain",
                            borderRadius: "5px",
                            marginRight: "15px",
                            cursor: "pointer",
                          }}
                          // onClick={() => {
                          //     window.open(`https://${row.domain}`);
                          // }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.name}
                          </p>

                          {/* {null !== "invited-admins" ? (
                            <Tooltip
                              // title={
                              //   row.verified === 0
                              //     ? "Email not verified!"
                              //     : "Email Verified!"
                              // }
                              title={
                                row.payment_required == 0
                                  ? "Payment not required"
                                  : row.payment_required == 1 &&
                                    isValid(row.payment_model) &&
                                    isValid(row.customer) &&
                                    isValid(row.subscription)
                                  ? "Payment recieved"
                                  : "Payment not recieved"
                              }
                              style={{
                                fontFamily: '"Montserrat", sans-serif',
                              }}
                            >
                              <img
                                alt="status"
                                src={
                                  row.payment_required === 0 ||
                                  (row.payment_required === 1 &&
                                    isValid(row.payment_model) &&
                                    isValid(row.customer) &&
                                    isValid(row.subscription))
                                    ? process.env.PUBLIC_URL +
                                      "/assets/correct.png"
                                    : process.env.PUBLIC_URL +
                                      "/assets/close.png"
                                }
                                height="18"
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )} */}
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                          >
                            {row.payment_model === "" ||
                            row.payment_model === null ||
                            row.payment_model === undefined ||
                            row.payment_model === 0
                              ? "Not Set"
                              : row.payment_model === 1
                              ? "Monthly"
                              : "Yearly"}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: '"Montserrat", sans-serif',
                          fontWeight: "500",
                        }}
                      >
                        <Tooltip title="Edit">
                          <Edit
                            style={{
                              cursor: "pointer",
                              color: "rgb(252, 198, 3)",
                              backgroundColor: "rgba(252, 227, 3, 0.3)",
                              border: "1px solid rgb(252, 198, 3)",
                              padding: "3px",
                              fontSize: "15px",
                              marginRight: "15px",
                              borderRadius: "5px",
                            }}
                            onClick={() => editOrganization(row)}
                          />
                        </Tooltip>

                        <Tooltip title="Invite">
                          <Mail
                            style={{
                              cursor: "pointer",
                              color: "rgb(36, 169, 181)",
                              backgroundColor: "rgba(36, 169, 181, 0.3)",
                              border: "1px solid rgb(36, 169, 181)",
                              padding: "3px",
                              fontSize: "15px",
                              marginRight: "15px",
                              borderRadius: "5px",
                            }}
                            onClick={() => inviteOrganization(row)}
                          />
                        </Tooltip>

                        {superAdmin ? (
                          <Tooltip title="Delete">
                            <DeleteOutline
                              onClick={() => {
                                setAboutToDeleteOrg(row);
                                // deleteOrganization(row);
                              }}
                              style={{
                                cursor: "pointer",
                                color: "rgb(252, 3, 3)",
                                backgroundColor: "rgba(252, 3, 3, 0.3)",
                                border: "1px solid rgb(252, 3, 3)",
                                padding: "3px",
                                fontSize: "15px",
                                marginRight: "15px",
                                borderRadius: "5px",
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell>
                        <div
                          className="actions-div-user"
                          style={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip
                            // title={
                            //   userTeamStatus[row.id] === 1
                            //     ? "Remove from Team"
                            //     : "Add to Team"
                            // }
                            title={
                              row.payment_required === 1
                                ? "Payment required"
                                : "Payment not required"
                            }
                          >
                            <CusSwitch
                              style={{
                                cursor: "pointer",
                                color: "rgb(166, 0, 113)",
                              }}
                              onClick={() => {
                                setAboutToUpdatePaymentOrgShow(
                                  !aboutToUpdatePaymentOrgShow
                                );
                                setAboutToUpdatePaymentOrg(row);
                              }}
                              checked={row.payment_required === 1}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {loadList ? (
            [1, 2, 3, 4].map((d, i) => {
              return <TableSkeleton key={i} />;
            })
          ) : (
            <></>
          )}
        </TableContainer>
        <div className="spacer"></div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={orgs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{}}
          sx={{
            marginTop: "10px",
            fontFamily: '"Montserrat", sans-serif',
          }}
        />
      </Paper>
    </div>
  );
};

export default Organization;
