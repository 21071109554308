import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import FormContainer from "../form-container";
import { ExpandMoreSharp } from "@mui/icons-material";
import "./awin.styles.scss";
import { useState } from "react";
import { money } from "../../const/currency";
import { Toastify } from "../../../../App";
const Awin = ({ Connect, currentNetImg }) => {
  const [details, setDetails] = useState({
    account_sid: "",
    auth_token: "",
    model_id: "",
    network_commision_rate: {
      ncr_val: 0,
      ncr_type: "commision",
    },
    currency: "",
    nick_name: "",
  });

  const onDetailChange = (e) => {
    if (e.target.name === "ncr_val" || e.target.name === "ncr_type") {
      console.log("click");
      setDetails({
        ...details,
        network_commision_rate: {
          ...details.network_commision_rate,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const currency = money;
  const checkPass = () => {
    if (details.account_sid.length === 0 || details.account_sid === "") {
      return "Accout SID required !!";
    } else if (details.auth_token.length === 0 || details.auth_token === "") {
      return "Auth Token required !!";
    } else if (details.currency.length === 0 || details.currency === "") {
      return "Currency required !!";
    } else if (details.model_id.length === 0 || details.account_sid === "") {
      return "Model Id required !!";
    }
    return "";
  };
  return (
    <FormContainer currentNetImg={currentNetImg}>
      <div className="awin-container">
        {" "}
        <TextField
          required
          size="small"
          label="API Key"
          style={{
            margin: "15px 0",
          }}
          name="account_sid"
          value={details.account_sid}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Merchant Id"
          style={{
            margin: "15px 0",
          }}
          name="account_sid"
          value={details.account_sid}
          onChange={(event) => onDetailChange(event)}
        />
        <TextField
          required
          size="small"
          label="Auth Token"
          style={{
            margin: "15px 0",
          }}
          name="auth_token"
          value={details.auth_token}
          onChange={(event) => onDetailChange(event)}
        />
        <div className="number-and-option">
          <TextField
            required
            size="small"
            label="Network Commission Rate (Optional)"
            type="number"
            style={{
              width: "100%",
              margin: "15px 0",
            }}
            name="ncr_val"
            value={details.network_commision_rate.ncr_val}
            onChange={(event) => onDetailChange(event)}
          />
          <div style={{ fontSize: "1rem", fontWeight: "500" }}> Of </div>
          <ButtonGroup
            style={{
              width: "100%",
            }}
          >
            <Button
              variant={
                details.network_commision_rate.ncr_type === "commision"
                  ? "contained"
                  : "outlined"
              }
              onClick={(event) =>
                onDetailChange({
                  target: {
                    name: "ncr_type",
                    value: "commision",
                  },
                })
              }
            >
              Commision
            </Button>
            <Button
              variant={
                details.network_commision_rate.ncr_type === "revenue"
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                onDetailChange({
                  target: {
                    name: "ncr_type",
                    value: "revenue",
                  },
                })
              }
            >
              Revenue
            </Button>
          </ButtonGroup>
        </div>
        <Accordion style={{ margin: "15px 0" }}>
          <AccordionSummary expandIcon={<ExpandMoreSharp />}>
            <span className="accordion-title">Advance Mapping</span>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              required
              size="small"
              label="New Customer Type"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="model_id"
              value={details.model_id}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              required
              size="small"
              label="Repeate Customer Type"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="model_id"
              value={details.model_id}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              required
              size="small"
              label="Check In Date"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="model_id"
              value={details.model_id}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              required
              size="small"
              label="Check Out Date"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="model_id"
              value={details.model_id}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              required
              size="small"
              label="Night Stayed"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="model_id"
              value={details.model_id}
              onChange={(event) => onDetailChange(event)}
            />
            <TextField
              required
              size="small"
              label="Number of Rooms"
              style={{
                width: "100%",
                margin: "15px 0",
              }}
              name="model_id"
              value={details.model_id}
              onChange={(event) => onDetailChange(event)}
            />
          </AccordionDetails>
        </Accordion>
        <TextField
          select
          required
          size="small"
          label="What currency does this platform report in?"
          style={{
            margin: "15px 0",
          }}
          // value={inviteType}
          onChange={(e) => {
            // console.log(e.target.value, "e.target.value");
            // setInviteType(e.target.value);
          }}
        >
          {/* <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>User</MenuItem> */}
          {/* {console.log(orgs, "orgs")} */}
          <MenuItem
          // onClick={() => {
          //   setInviteOrgData(null);
          // }}
          // value={0}
          >
            Please select a timezone..
          </MenuItem>
          {currency.map((t, i) => {
            return (
              <MenuItem
                onClick={() => {
                  onDetailChange({
                    target: {
                      name: "currency",
                      value: t.option,
                    },
                  });
                }}
                value={i + 1}
              >
                {t.option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          required
          size="small"
          label="NickName (Optional)"
          style={{
            margin: "15px 0",
          }}
          name="nick_name"
          value={details.nick_name}
          onChange={(event) => onDetailChange(event)}
        />
      </div>
      <Button
        onClick={() => {
          const res = checkPass();
          if (res.length !== 0) {
            Toastify("error", res);
            return;
          }
          Connect({ ...details, net: "awin" });
        }}
        style={{
          width: "120px",
        }}
        variant="contained"
      >
        Connect
      </Button>
    </FormContainer>
  );
};

export default Awin;
