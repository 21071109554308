import { combineReducers } from "redux";
import paymentReducer from "./payment.reducer";
import authReducer from "./auth.reducer";
import superAdminReducer from "./super_admin.reducer";
import cmsReducer from "./cms.reducer";
import reduceReducers from "reduce-reducers";

const combineReducer = combineReducers({
  payment: paymentReducer,
  admin: authReducer,
  super_admin: superAdminReducer,
  cmsData: cmsReducer,
});

const rootReducer = reduceReducers(combineReducer);

export default rootReducer;
