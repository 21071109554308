import { Button } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import "./step-sec.styles.scss";
import EditorContainer from "../editor-container";
import { useDispatch, useSelector } from "react-redux";
import { onCMSChange } from "../../../../redux/action/cms.actions";
import { Toastify } from "../../../../App";

const OptionBlock = ({ addContentBlock }) => {
  const Selected = (option) => {
    addContentBlock({
      type: option,
    });

    optionsBlockRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const optionsBlockRef = useRef(null);

  return (
    <div className="options-block" ref={optionsBlockRef}>
      <Button
        onClick={() => {
          Selected(1);
        }}
        className="add-brand-btn"
      >
        <div className="add-brand-content">
          <p className="add-text">Image</p>
        </div>
      </Button>

      <Button
        onClick={() => {
          Selected(2);
        }}
        className="add-brand-btn"
      >
        <div className="add-brand-content">
          <p className="add-text">Text</p>
        </div>
      </Button>

      <Button
        onClick={() => {
          Selected(3);
        }}
        className="add-brand-btn"
      >
        <div className="add-brand-content">
          <p className="add-text">Image and Text</p>
        </div>
      </Button>

      <Button
        onClick={() => {
          Selected(4);
        }}
        className="add-brand-btn"
      >
        <div className="add-brand-content">
          <p className="add-text">Text and Image</p>
        </div>
      </Button>
    </div>
  );
};

const StepSec = () => {
  const dispatch = useDispatch();
  const cmsData = useSelector((state) => state.cmsData);
  const [contents, setContents] = useState([
    {
      id: 1,
      type: 2,
      text: null,
      image: null,
    },
  ]);

  const addContentBlock = (content) => {
    // let contentList = contents;
    // console.log("testing");
    Toastify('info', "Testing");
    if (content.type === 1) {
      let cms = {
        ...cmsData.cms,
        blocks: [
          ...cmsData.cms.blocks,
          {
            id: cmsData.cms.blocks.length + 1,
            type: 1,
            text: null,
            image: null,
          },
        ],
      };

      dispatch(onCMSChange(cms));
    } else if (content.type === 2) {
      let cms = {
        ...cmsData.cms,
        blocks: [
          ...cmsData.cms.blocks,
          {
            id: cmsData.cms.blocks.length + 1,
            type: 2,
            text: null,
            image: null,
          },
        ],
      };

      dispatch(onCMSChange(cms));
    } else if (content.type === 3) {
      let cms = {
        ...cmsData.cms,
        blocks: [
          ...cmsData.cms.blocks,
          {
            id: cmsData.cms.blocks.length + 1,
            type: 3,
            text: null,
            image: null,
          },
        ],
      };

      dispatch(onCMSChange(cms));

      // setTimeout(() => {

      // }, 5000);
    } else {
      let cms = {
        ...cmsData.cms,
        blocks: [
          ...cmsData.cms.blocks,
          {
            id: cmsData.cms.blocks.length + 1,
            type: 4,
            text: null,
            image: null,
          },
        ],
      };
      console.log("testin");
      dispatch(onCMSChange(cms));
    }

    setTimeout(() => {
      const editor = document.querySelector(
        `#cms-block-${cmsData.cms.blocks.length + 1} .ql-editor`
      );

      editor?.focus();
    }, 600);
  };

  const removeContentBlock = (content) => {
    let contentList = cmsData.cms.blocks.filter((c) => c.id !== content.id);

    let cms = {
      ...cmsData.cms,
      blocks: [...contentList],
    };

    dispatch(onCMSChange(cms));
  };

  const BlockEditor = (c) => {
    switch (c.type) {
      case 1:
        return (
          <EditorContainer
            id={`cms-block-${c.id}`}
            content={c}
            removeContentBlock={removeContentBlock}
            width1={""}
            width2={"100%"}
          />
        );

      case 2:
        return (
          // <Editor
          //   content={c}
          //   key={c.id}
          //   width={"60%"}
          //   removeContentBlock={removeContentBlock}
          // />
          <EditorContainer
            id={`cms-block-${c.id}`}
            content={c}
            removeContentBlock={removeContentBlock}
            width1={"100%"}
            width2={""}
          />
        );

      case 3:
        return (
          <EditorContainer
            id={`cms-block-${c.id}`}
            content={c}
            removeContentBlock={removeContentBlock}
            width1={"60%"}
            width2={"40%"}
          />
        );

      case 4:
        return (
          <EditorContainer
            id={`cms-block-${c.id}`}
            content={c}
            contents={contents}
            setContents={setContents}
            removeContentBlock={removeContentBlock}
            width1={"60%"}
            width2={"40%"}
          />
        );

      default:
        return (
          <EditorContainer
            id={`cms-block-${c.id}`}
            content={c}
            contents={contents}
            setContents={setContents}
            removeContentBlock={removeContentBlock}
            width1={"100%"}
            width2={""}
          />
        );
    }
  };
  return (
    <div className="step-two-cms">
      {cmsData.cms.blocks.map((c, i) => {
        return <Fragment key={i}>{BlockEditor(c)}</Fragment>;
      })}
      <OptionBlock addContentBlock={addContentBlock} />
    </div>
  );
};

export default StepSec;
