import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { preLoaderAnim } from "./animation";
import "./preloader.scss";
import { PuffLoader } from "react-spinners";

const PreLoader = () => {
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const time = location.pathname === "/" ? 3000 : 1000;
    setTimeout(() => {
      setShowLoader(false);
    }, [time]);
  }, []);


  return (
    <div
      className="preloader"
      style={{
        display: showLoader ? "flex" : "none",
        opacity: location.pathname === "/" ? "1" : ".8",
      }}
    >
      <PuffLoader
        className="puff-loader-preloader"
        size={80}
        color="rgb(166, 0, 113)"
        speedMultiplier={0.8}
      />
    </div>
  );
};

export default PreLoader;
