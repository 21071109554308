import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Toastify } from "../../App";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});
export default function PrivateRoute({ children }) {
  const [validBrand, setValidBrand] = useState(false);
  const admin = JSON.parse(localStorage.getItem("admin_auth"));
  const getDeatails = async () => {
    try {
      const res = await axios.get(
        `https://ltpautomatedpublisherscorecard.uc.r.appspot.com/public/admin/get/${admin?.id}`,
        {
          headers: {
            Authorization: `JWT ${admin?.token}`,
          },
        }
      );
      let val =
        res.data.organization.subscription === null ||
        res.data.organization.subscription === undefined ||
        res.data.organization.customer === null ||
        res.data.organization.customer === undefined ||
        res.data.organization.payment_model === null ||
        res.data.organization.payment_model === undefined;

      if (res.data.organization.payment_required === 0) {
        setValidBrand(false);
      } else {
        if (res.data.organization.payment_required === 1 && val) {
          setValidBrand(true);
        }
      }

      console.log(validBrand, "validBrand");
    } catch (error) {
      Toastify('error', error.response.data.message);
      console.log(error, "testing yooyoy");
    }
  };

  useEffect(() => {
    if (admin) {
      getDeatails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validBrand]);
  const navigate = useNavigate();
  const { admin_profile } = useSelector((state) => state.admin);
  return validBrand ? (
    <div>
      <Dialog disableEscapeKeyDown open={validBrand}>
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Notice
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              width: "280px",
            }}
          >
            <ThemeProvider theme={theme}>
              <p>
                Hello {admin_profile?.name}. Your Organizations{" "}
                {admin_profile?.organization?.name} requires payment before
                moving further
                <br />
                <br />
                {/* Please Try varify and click on go back in order to resume your
                task */}
                <br />
                <br />
                In case of any queries,
                <a
                  href="mailto:lift@lt.partners"
                  style={{
                    color: "rgb(166, 0, 113)",
                    textDecoration: "none",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  email here
                </a>
                <br />
              </p>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => {
              setValidBrand(true);
              navigate(`/admin/payment`);
            }}
          >
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </div>
  ) : (
    children
  );
}
