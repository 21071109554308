import axios from "axios";
import { authFirebase } from './firebase.config';


const apiClient = axios.create({
  baseURL: "https://api.lift.newengen.com/dev",
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    const user = authFirebase.currentUser;
    if (user) {
      const idToken = await user.getIdToken(true);
      console.log(idToken, "idToken");
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default apiClient;
