import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PostHogProvider } from "posthog-js/react";
import { AuthProvider } from "./utils/provider/AuthProvider";



if (process.env.NODE_ENV !== "development") {
  if (window.location.protocol !== "https:") {
    window.location.href =
      "https:" +
      window.location.href.substring(window.location.protocol.length);
  }
  console.log = () => { };
}


// const options = {
//   api_host: REACT_POSTHOG_HOST,
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <PostHogProvider
      apiKey={REACT_POSTHOG_KEY}
      options={options}
    > */}
      <Provider store={store}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    {/* </PostHogProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
