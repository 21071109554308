import { AddPhotoAlternateOutlined } from "@mui/icons-material";
import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onCMSChange } from "../../../../redux/action/cms.actions";
import ImgCrop from "../img-crop";
import "./step-one.styles.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(166, 0, 113)",
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const ImageInput = ({ inputFile }) => {
  const cmsData = useSelector((state) => state.cmsData);
  const dispatch = useDispatch();

  // const classes = makeStyles(() => ({
  //   paper: { minWidth: "500px" },
  // }));

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      const img = URL.createObjectURL(blob);

      let cms = {
        ...cmsData.cms,
        hero: img,
      };

      dispatch(onCMSChange(cms));
    });
    setImgInput(null);
  }

  const previewCanvasRef = useRef(null);
  const [imgInput, setImgInput] = useState(null);
  return (
    <div style={{ width: "80%" }}>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              // height: "100%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
        fullWidth
        disableEscapeKeyDown
        open={imgInput != null}
      >
        <DialogTitle
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.2rem",
            fontWeight: "700",
          }}
        >
          Edit Image
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.9rem",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <ThemeProvider theme={theme}>
              <div
                style={{
                  width: "100%",
                  // height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // onClick={() => inputFile.current.click()}
              >
                <ImgCrop
                  imgInput={imgInput}
                  previewCanvasRef={previewCanvasRef}
                />
              </div>
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={onDownloadCropClick}
          >
            <div>
              <p>Add</p>
            </div>
          </Button>
          <Button
            style={{
              fontFamily: '"Montserrat", sans-serif',
              fontSize: "0.8rem",
              color: "rgb(166, 0, 113)",
            }}
            onClick={() => setImgInput(null)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="image-input"
        onClick={() => {
          inputFile.current.click();
          // setShowAddImgDialog(true);
        }}
      >
        <div
          style={{
            display: cmsData.cms.hero === null ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AddPhotoAlternateOutlined />

          <p>Upload Image</p>
          <input
            type="file"
            ref={inputFile}
            style={{
              display: "none",
            }}
            onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                setImgInput(event);
                // let cms = {
                //   ...cmsData.cms,
                //   hero: URL.createObjectURL(event.target.files[0]),
                // };

                // dispatch(onCMSChange(cms));
              }
            }}
          />
        </div>

        <div
          style={{ display: cmsData.cms.hero === null ? "none" : "" }}
          className="image-input-img-container"
        >
          <img src={cmsData?.cms?.hero} alt="cms-hero" />
        </div>
      </div>
    </div>
  );
};

const StepOne = () => {
  const inputFile = useRef(null);
  const cmsData = useSelector((state) => state.cmsData);

  const dispatch = useDispatch();

  const onInputChange = (event) => {
    let cms =
      event.target.name === "subdomain" || event.target.name === "endpoint"
        ? {
            ...cmsData.cms,
            meta_data: {
              ...cmsData.cms.meta_data,
              [event.target.name]: event.target.value,
            },
          }
        : {
            ...cmsData.cms,
            [event.target.name]: event.target.value,
          };
    dispatch(onCMSChange(cms));
    // setInputs({
    //   ...inputs,
    //   [event.target.name]: event.target.value,
    // });
  };

  return (
    <div className="step-one-cms">
      <ThemeProvider theme={theme}>
        <ImageInput inputFile={inputFile} />
        <TextField
          required
          style={{
            width: "80%",
            marginBottom: "2%",
          }}
          size="small"
          name="title"
          label="Title"
          helperText="Eg. - Affiliate Marketing"
          value={cmsData.cms.title}
          onChange={(event) => onInputChange(event)}
        />
        <TextField
          style={{
            width: "80%",
            marginBottom: "2%",
          }}
          required
          size="small"
          label="Sub Title"
          name="subtitle"
          helperText="Eg. - Affiliate Marketing"
          value={cmsData.cms.subtitle}
          onChange={(event) => onInputChange(event)}
        />

        <TextField
          style={{
            width: "80%",
            marginBottom: "2%",
          }}
          required
          size="small"
          label="Endpoint"
          name="endpoint"
          helperText="Eg. - https://lt.partners"
          value={cmsData.cms.meta_data.endpoint}
          onChange={(event) => onInputChange(event)}
        />
        <TextField
          style={{
            width: "80%",
            marginBottom: "2%",
          }}
          required
          size="small"
          label="Subdomain"
          name="subdomain"
          helperText="Eg. - lift.lt.partners"
          value={cmsData.cms.meta_data.subdomain}
          onChange={(event) => onInputChange(event)}
        />
      </ThemeProvider>
    </div>
  );
};

export default StepOne;
