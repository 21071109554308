import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckOutForm";
// import CheckoutForm from "../../../pages/UITest/CheckOutForm/index";

const STRIPE_PUBLISHABLE_KEY = `pk_live_51LwRk1Aw6YDq2ZPmQUiwePnhRl9uE5n6OEtCWb1viDINKsbsN30n203UdJgyzVAlrGAUfWtCZAqxlnb4I7rndWRA002EuWisnc`;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY); // starts wit
const appearance = {
  theme: "stripe",

  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#30313d",
    colorDanger: "#df1b41",
    fontFamily: "Ideal Sans, system-ui, sans-serif",
    spacingUnit: "2px",
    borderRadius: "4px",
    // See all possible variables below
  },
};
function Payment(props) {
  return (
    <div style={{ width: "100%" }}>
      <Elements stripe={stripePromise} options={{ appearance: appearance }}>
        <CheckoutForm
          setShowCost={props.setShowCost}
          org_cost={props.org_cost}
          sub_click={props?.sub_click}
          details={props?.details}
        />
      </Elements>
    </div>
  );
}

export default Payment;
